import { Button } from 'antd'
import { Link } from 'react-router-dom'

const NoResults: React.FC<{goBackLink:string}> = ({goBackLink}) => {
  return (
    <div style={{display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop: '20px'}}>
      <div style={{marginBottom: '10px'}}>
        No results found!
      </div>
      <Link to={goBackLink}>
        <Button type='primary'>
          Go To Search
        </Button>
      </Link>
    </div>
  )
}

export default NoResults
