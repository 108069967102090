import { useState } from 'react'
import { Button, InputNumber } from 'antd'
import { InputGoogleAPI } from '../../common/InputGoogleAPI/InputGoogleAPI'
import { copyToClipboard } from '../../../helpers/linksHelper'
import classes from './GenerateWidget.module.css'
import { WidgetSettingsType } from '../../../types/widgetTypes'

const GenerateWidget = () => {
  const [formValues, setFormValues] = useState<WidgetSettingsType>({width: undefined, height: undefined, location: ''})
  const [validationError, setValidationError] = useState('')

  const handleLocationSelect = (location: string) => {
    setValidationError('')
    setFormValues({...formValues, location})
  }

  const generateWidgetCode = () => {
    if (!formValues?.location) {
      setValidationError('Select location first!')
    } else {
      setValidationError('')
      const root = window.location.origin
      const url = root + '/widget?' + formValues.location
      const code = `<iframe title='Relavanti widget' width='${formValues?.width ? formValues?.width + 'px' : '100%'}' height='${formValues?.height ? formValues?.height + 'px' : '100%'}' style='border: 1px solid black' src='${url}'/>`
      copyToClipboard(code, 'Widget code copied successfully')
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        Select location
      </div>
      <InputGoogleAPI
        placeholder='Start typing location'
        suggestionsPosition='vertical'
        onChange={(value:string) => handleLocationSelect(value)}
        style={{maxWidth: 'none', height: '46px'}}
        wrapperStyle={{height: '46px'}}
      />

      <div className={classes.label} style={{marginTop: '15px'}}>
        Size
      </div>
      <div className={classes.description}>
        Widget size is flexible so it will adjust to the container size. But you can set width and height manually (min value is 250px)
      </div>
      <div className={classes.doubleInput}>
        <InputNumber
          placeholder='Width'
          controls={false}
          style={{width: '100%'}}
          addonAfter='px'
          value={formValues.width}
          onChange={(val) => setFormValues({...formValues, width: val || 100})}
          min={250}
        />
        <InputNumber
          placeholder='Height'
          controls={false}
          style={{width: '100%'}}
          addonAfter='px'
          value={formValues.height}
          onChange={(val) => setFormValues({...formValues, height: val || 100})}
          min={250}
        />
      </div>
      <div className={classes.validationError}>
        {validationError}
      </div>
      <Button
        type='primary'
        onClick={generateWidgetCode}
        className={classes.generateBtn}
      >
        Generate
      </Button>
    </div>
  )
}

export default GenerateWidget
