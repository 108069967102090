import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Input, Modal, Pagination, Select, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectNodeServiceDetails } from '../../../../store/searchResultsReducer'
import classes from './CruisesForPort.module.css'
import { ReactComponent as CruisesIcon } from './../../../../img/icons/cruisesIcon.svg'
import { ReactComponent as SearchIcon } from './../../../../img/icons/search.svg'
import { debounce } from 'lodash'
import SortBtn from '../../../common/SortBtn/SortBtn'
import CruiseForPortFilters from './CruiseForPortFilters/CruiseForPortFilters'
import { GetCruisesForPortThunk, selectPortCruises, selectPortCruisesFilters, selectPortCruisesTotalCount, setPortCruises, setPortCruisesFiltersData, setPortCruisesFiltersPagination, setPortCruisesFiltersToDefault, setPortCruisesTotalCount } from '../../../../store/portReducer'
import axios from './../../../../helpers/axiosHelper'
import { getCruiseSearchUrl } from '../../../../helpers/linksHelper'

const CruisesForPort = () => {
  const dispatch = useAppDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className={classes.blockWrapper}>
      <CruisesBtn onClick={() => setIsModalOpen(true)}/>

      <CruisesModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false)
          dispatch(setPortCruises(null))
          dispatch(setPortCruisesTotalCount(0))
          dispatch(setPortCruisesFiltersToDefault())
        }}
      />
    </div>
  )
}

export const CruisesBtn: React.FC<{style?: object, onClick?: () => void}> = ({style, onClick}) => {
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)

  return (
    <>
      <div
        className={classes.cruisesBtnWrapper} 
        onClick={() => {
          onClick && onClick()
        }}
        style={style}
      >
        <div className={classes.cruisesBtnTitle}>
          <CruisesIcon style={{marginRight: '7px'}}/>
          <div className={classes.resultInnerLinkText}>
            Cruises ({nodeServiceDetails?.cruise_count} {nodeServiceDetails?.cruise_count === 1 ? 'Cruise' : 'Cruises'})
          </div>
        </div>
        <div style={{fontSize: '14px', cursor: 'pointer', color: '#0068FF', fontWeight: 700}}>
          View
        </div>
      </div>
    </>
  )
}

const CruisesModal: React.FC<AirlinesModalPropTypes> = ({isOpen, closeModal}) => {
  const dispatch = useAppDispatch()
  const portCruisesTotalCount = useAppSelector(selectPortCruisesTotalCount)

  const [isSortOptionsOpen, setIsSortOptionsOpen] = useState(false)
  const [isCruisesLoading, setIsCruisesLoading] = useState(true)

  useEffect(() => {
    return () => {
      dispatch(setPortCruises(null))
      dispatch(setPortCruisesTotalCount(0))
      dispatch(setPortCruisesFiltersToDefault())
    }
  }, [dispatch])

  return (
    <Modal
      visible={isOpen}
      onCancel={() => {
        setIsSortOptionsOpen(false)
        closeModal()
      }}
      footer={null}
      className={classes.wrapper}
      closable={true}
      width={660}
      destroyOnClose
      centered
    >
      <div onClick={() => setIsSortOptionsOpen(false)}>
        <div className={classes.modalTitle}>
          Cruises
          {!isCruisesLoading &&
            <span>
              ({portCruisesTotalCount} {portCruisesTotalCount === 1 ? 'Cruise' : 'Cruises'})
            </span>
          }
        </div>
        <Divider />
        <CruiseList
          isSortOptionsOpen={isSortOptionsOpen}
          setIsSortOptionsOpen={(isOpen: boolean) => setIsSortOptionsOpen(isOpen)}
          isCruisesLoading={isCruisesLoading}
          setIsCruisesLoading={setIsCruisesLoading}
        />
      </div>
    </Modal>
  )
}

const CruiseList: React.FC<CruiseListPropTypes> = ({
  isSortOptionsOpen,
  setIsSortOptionsOpen,
  isCruisesLoading,
  setIsCruisesLoading,
}) => {
  const dispatch = useAppDispatch()
  const portCruises = useAppSelector(selectPortCruises)
  const portCruisesTotalCount = useAppSelector(selectPortCruisesTotalCount)
  const portCruisesFilters = useAppSelector(selectPortCruisesFilters)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)

  const [searchType, setSearchType] = useState<'CRUISE_NAME' | 'CRUISE_CODE' | 'LINE_NAME'>('CRUISE_NAME')
  const [searchValue, setSearchValue] = useState<null | string>(null)
  const [searchValueInputVal, setSearchValueInputVal] = useState<string>('')

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setIsCruisesLoading(true)
    dispatch(GetCruisesForPortThunk({
      portId: nodeServiceDetails?.id!,
      filtersData: portCruisesFilters,
      source
    })).then(() => setIsCruisesLoading(false))
    // return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, portCruisesFilters])

  useEffect(() => {
    searchValue !== null && dispatch(setPortCruisesFiltersData({
      filter: {
        ...portCruisesFilters.filter,
        search_type: searchType,
        search_value: searchValue!
      },
      pagination: {...portCruisesFilters.pagination, page: 1}
    }))
    // eslint-disable-next-line
  }, [dispatch, searchValue, searchType])

  const updatePortCruisesFiltersPagination = (updatedValue: any) => {
    dispatch(setPortCruisesFiltersPagination({
      ...portCruisesFilters.pagination,
      ...updatedValue,
      ...(Object.keys(updatedValue)[0] === 'page' ? {} : {page: 1})}))
  }

  // eslint-disable-next-line
  const onSearch = useCallback(
    debounce((val) => {
      setSearchValue(val)
    }, 400), []
  )

  return (
    <div>
      <div
        onClick={(e) => {e.stopPropagation(); setIsSortOptionsOpen(false)}}
        onKeyDown={(e) => e.stopPropagation()}
        className={classes.searchBarWrapper}
      >
        <div>
          <Input.Group compact style={{display:'flex'}}>
            <Select
              style={{ width: '150px' }}
              value={searchType}
              onChange={(val) => setSearchType(val as 'CRUISE_NAME' | 'CRUISE_CODE' | 'LINE_NAME')}
            >
              <Select.Option value={'CRUISE_NAME'}>By name</Select.Option>
              <Select.Option value={'CRUISE_CODE'}>By code</Select.Option>
              <Select.Option value={'LINE_NAME'}>By line</Select.Option>
            </Select>
            <Input
              placeholder='Search'
              suffix={<SearchIcon />}
              value={searchValueInputVal}
              onChange={(e) => {
                onSearch(e.target.value)
                setSearchValueInputVal(e.target.value)
              }}
              className={classes.searchInput}
            />
          </Input.Group>
        </div>
        <div className={classes.sortWrapper}>
          <SortBtn
            isSortOptionsOpen={isSortOptionsOpen}
            setIsSortOptionsOpen={(isOpen: boolean) => setIsSortOptionsOpen(isOpen)}
            sortType={portCruisesFilters.pagination?.sort?.[0]?.direction?.toLowerCase() as 'desc' | 'asc' || false}
            applySort={(sortType: 'asc' | 'desc' | false, sortBy: string | null) => updatePortCruisesFiltersPagination({sort: sortType === false || sortBy === null ? [] : [{field: sortBy, direction: sortType.toUpperCase()}]})}
            sortBy={portCruisesFilters.pagination?.sort?.[0]?.field || null}
            sortOptions={[
              {name: 'Name', value: 'name'},
              {name: 'Code', value: 'code'},
              // {name: 'Ship', value: 'ship_name'},
              {name: 'Line', value: 'cruise_line_name'},
            ]}
          />
          <CruiseForPortFilters />
        </div>
      </div>
      {!!isCruisesLoading ? (
        <Spin style={{width: '100%'}}/>
      ) : (
        <>
          {portCruises?.map((cruise, index) => (
            <div key={cruise.id+cruise.code+index} className={classes.cruiseWrapper}>
              <div className={classes.cruise}>
                <div className={classes.cruiseColumn}>
                  {cruise.name}
                  {!!cruise?.cruise_line_name &&
                    <span>{cruise?.cruise_line_name}</span>
                  }
                  {!!cruise?.night_quantity &&
                    <span>{cruise?.night_quantity} nights</span>
                  }
                </div>
                <div className={`${classes.codeColumn} ${classes.desktop}`}>
                  {cruise?.code}
                </div>
                <div className={classes.cruiseColumn}>
                  {cruise?.ships_name?.length &&
                    <span>Ship: {cruise?.ships_name}</span>
                  }
                  {cruise?.departure_port_name?.length &&
                    <span>Departure: {cruise?.departure_port_name}</span>
                  }
                  {!!cruise?.start_date_locations?.[0]?.departure_date &&
                    <span>{cruise?.start_date_locations?.[0]?.departure_date}</span>
                  }
                </div>
                <div className={`${classes.codeColumn} ${classes.mobile}`}>
                  {cruise?.code}
                </div>
              </div>
              <a
                  href={getCruiseSearchUrl({
                    cruiseLine: {value: cruise?.cruise_line_id},
                    cruiseName: {label: [cruise?.name]},
                    date: cruise?.start_date_locations?.[0]?.departure_date
                  })}
                  target='_blank'
                  rel='noreferrer'
                  className={classes.viewCruiseSearchBtn}
                >
                  View
                </a>
            </div>
          ))}
          {!!portCruises?.length &&
            <Pagination
              current={portCruisesFilters?.pagination?.page}
              pageSize={portCruisesFilters?.pagination?.size}
              total={portCruisesTotalCount}
              onChange={page => updatePortCruisesFiltersPagination({page})}
              style={{paddingTop: '20px', display: 'flex', justifyContent: 'center'}}
              showSizeChanger={false}
            />
          }
          {!portCruises?.length && portCruises !== null &&
            <div style={{textAlign:'center', marginTop: '20px'}}>
              No results for your search
            </div>
          }
        </>
      )}
    </div>
  )
}

interface AirlinesModalPropTypes {
  isOpen: boolean
  closeModal: () => void
}

interface CruiseListPropTypes {
  isSortOptionsOpen: boolean
  setIsSortOptionsOpen(isOpen: boolean): void
  isCruisesLoading: boolean
  setIsCruisesLoading(isLoading: boolean): void
}


export default CruisesForPort
