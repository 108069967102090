import { Button, DatePicker, Form, FormInstance, Spin } from 'antd'
import moment, { Moment } from 'moment'
import { useNavigate } from 'react-router-dom'
import BackBtn from '../../../common/BackBtn/BackBtn'
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import Header from '../../../common/Header/Header'
import classes from './SearchPageWithBgImage.module.css'
import { ReactComponent as DatePickerCalendar } from './../../../../img/icons/datePickerCalendar.svg'
import { useState } from 'react'

const SearchPageWithBgImage: React.FC<SearchPageWithBgImagePropTypes> = (props) => {
  return (
    <div className={`${classes.wrapper} ${props.bgType === 'cruise' ? classes.cruise : classes.flight}`}>
      <Header showMenu={true}/>
      <div className={classes.searchAreaWrapper}>
        <Breadcrumbs className={classes.breadcrumbs} />
        <SearchForm {...props}/>
      </div>
    </div>
  )
}

const SearchForm:React.FC<SearchPageWithBgImagePropTypes> = ({
  title,
  description,
  children,
  getDataOnSearch,
  getResultsLink,
  bgType,
  form,
  hasFormatError,
  showDateForm,
  checkIsDateAvailable,
  datePickerPlaceholder,
  datePickerDisabled,
  dateIsLoading,
  closestAvailableDate,
}) => {
  const navigate = useNavigate()

  const onSearch = (data: any) => {
    getDataOnSearch()
    if (!!getResultsLink) {
      const url = getResultsLink(data)
      navigate(url)
    }
  }

  return (
    <div className={classes.search}>
      <BackBtn type='closeIcon' className={classes.closeBtn} onClick={() => navigate('/travel')}/>
      <h1>
        {title}
      </h1>
      <div className={classes.searchDescription}>
        {description}
      </div>
      {!!showDateForm ? (
        <Form
          name='search'
          onFinish={onSearch}
          autoComplete='off'
          validateTrigger='onBlur'
          className={classes.form}
          form={form}
        >
          {children}
          <AvailableDatesDatePicker
            form={form}
            hasFormatError={hasFormatError}
            bgType={bgType}
            checkIsDateAvailable={checkIsDateAvailable}
            datePickerPlaceholder={datePickerPlaceholder}
            datePickerDisabled={datePickerDisabled}
            dateIsLoading={dateIsLoading}
            closestAvailableDate={closestAvailableDate}
          />
          <Form.Item className={classes.searchButtonWrapper}>
            <Button
              type='primary'
              htmlType='submit'
              className={classes.searchButton}
              disabled={hasFormatError}
            >
              {!!getResultsLink ? 'Search' : 'Next'}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>
          {children}
        </>
      )}
    </div>
  )
}

export const AvailableDatesDatePicker:React.FC<AvailableDatesDatePickerPropTypes> = ({
  form,
  hasFormatError,
  bgType,
  checkIsDateAvailable,
  datePickerPlaceholder,
  datePickerDisabled,
  dateIsLoading,
  closestAvailableDate,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const disableDatesWithNoResults = (current: any) => {
    if (!!checkIsDateAvailable) {
      return checkIsDateAvailable(current)
    } else {
      return current < moment().startOf('day')
    }
  }

  return (
      <Form.Item
        name='date'
        style={{width: '100%'}}
        rules={[{required: true, message: `Please select ${bgType} date!`}]}
      >
        <DatePicker
          placeholder={datePickerPlaceholder && datePickerDisabled
            ? datePickerPlaceholder
            : `Select the date of your ${bgType}`
          }
          suffixIcon={dateIsLoading ? <Spin style={{marginBottom: '-10px'}}/> : <DatePickerCalendar/>}
          style={{width: '100%'}}
          disabledDate={(current) => {
            return disableDatesWithNoResults(current)
          }}
          disabled={datePickerDisabled || !!hasFormatError}
          open={isDatePickerOpen}
          onOpenChange={(isOpen) => setIsDatePickerOpen(isOpen)}
          showToday={false}
          renderExtraFooter={() => (
            <div
              className={classes.datePickerFooter}
              onClick={() => {
                form.setFieldsValue({date: closestAvailableDate})
                setIsDatePickerOpen(false)
              }}
            >
              Select closest available date: {moment(closestAvailableDate).format('DD.MM.YYYY')}
            </div>
          )}
          dateRender={current => {
            const style: React.CSSProperties = {}
            if (!disableDatesWithNoResults(current)) {
              style.backgroundColor = '#191587'
              style.color = 'white'
              style.borderColor = '#191587'
              style.cursor = 'pointer'
            }
            return (
              <div style={style}>
                {current.date()}
              </div>
            )
          }}
        />
      </Form.Item>
  )
}

interface SearchPageWithBgImagePropTypes {
  title: string
  description: string | JSX.Element
  children: any
  getDataOnSearch: () => Promise<any>
  getResultsLink?: (data: any) => string
  bgType: 'flight' | 'cruise'
  form: FormInstance
  hasFormatError?: boolean
  showDateForm?: boolean
  checkIsDateAvailable: (current: Moment) => boolean
  datePickerPlaceholder?: string
  datePickerDisabled: boolean
  dateIsLoading?: boolean
  closestAvailableDate: Moment | undefined
}

interface AvailableDatesDatePickerPropTypes {
  form: FormInstance
  hasFormatError?: boolean
  bgType: 'flight' | 'cruise'
  checkIsDateAvailable: (current: Moment) => boolean
  datePickerPlaceholder?: string
  datePickerDisabled: boolean
  dateIsLoading?: boolean
  closestAvailableDate: Moment | undefined
}

export default SearchPageWithBgImage
