import { message } from 'antd'
import moment, { Moment } from 'moment'
import { ActiveResultBlockType, resultCategories } from '../store/searchResultsReducer'
import { store } from '../store/store'
import { SearchRequestType, SharedDetailsType, SharedFlightCruiseDetailsType } from '../types/appTypes'
import { AdvancedCruiseSearchData, BeforeAfterLocationsType, CruiseSearchDataType } from '../types/cruiseTypes'
import { RentalCarsLinkDataType, SearchAdditionalDataType, SearchRequestDataType } from '../types/searchResultTypes'
import { isNumber } from 'lodash'
import { AdvancedFlightSearchAirportsRequestDataType } from '../types/flightTypes'

export const copyToClipboard = (text: string, successMessage: string) => {
  navigator.clipboard.writeText(text)
  !!successMessage?.length && message.success(successMessage)
}
// build search link

export const getDatesForUrl = (dates: [Moment, Moment] = [moment(), moment().add(1, 'days')]):string => {
  const fromDate = (moment(dates[0]).format('YYYYMMDD'))
  const toDate = (moment(dates[1]).format('YYYYMMDD'))
  return `-from-${fromDate}-to-${toDate}`
}

export const getPeopleCountForUrl = (travelers: {adults: number, children: number} = {adults: 1, children: 0}): string => {
  return `-adults-${travelers.adults}-children-${travelers.children}`
}

export const getLinkForSearch = (requests: {[key:string]: SearchRequestType | undefined}) => {
  const requestsAsString = Object.values(requests)
    .filter(r => !!r && !!r?.request && r?.request?.length)
    .map(r => `${r?.request}${r?.dates ? getDatesForUrl(r?.dates) : ''}${r?.travelers?.adults === 1 && r?.travelers?.children === 0 ? '' : getPeopleCountForUrl(r?.travelers)}`)
    .join('&')
  return `/travel/results?${requestsAsString}`
}

export const modifyUrl = (oldUrl: string, locationName: string, newData: SearchAdditionalDataType) => {
  const [mainData, sharedData] = oldUrl.split('-shared')
  const updatedSearchItems = getSeparatedSearchItems(mainData).map(item => {
    const data = getSearchItemAdditionalData(item)
    return data.location === locationName ? `${locationName}${getDatesForUrl(newData.dates)}${getPeopleCountForUrl(newData.travelers)}` : item
  })
  return `/travel/results?${updatedSearchItems.join('&')}${sharedData?.length ? '-shared' + sharedData : ''}`
}

export const deleteSearchItemFromUrl = (oldUrl: string, deletedLocationName: string) => {
  const mainData = oldUrl.split('-shared')[0]
  const updatedSearchItems = getSeparatedSearchItems(mainData).filter(item => {
    const data = getSearchItemAdditionalData(item)
    return data.location !== deletedLocationName
  })
  return `/travel/results?${updatedSearchItems.join('&')}`
}

export const getSeparatedSearchItems = (url: string): string[] => {
  return url
  // removing '?'
  .substring(1)
  // separating different search items
  .split('&')
}

export const getLocationsFromLink = (url: string): string[] => {
  return getSeparatedSearchItems(url).map(r => decodeURIComponent(
    // removing dates & travelers count from search item
    r.split('-from')[0]
  ))
}

export const getSearchItemAdditionalData = (searchItem: string): SearchRequestDataType => {
  const location = decodeURIComponent(searchItem.split('-from')[0])
  // date format YYYYMMDD
  const fromDateString = searchItem.includes('-from-')
    ? searchItem.substring(searchItem.indexOf('-from-') + '-from-'.length, searchItem.indexOf('-to-'))
    : moment().format('YYYYMMDD')
  const fromDate = moment(fromDateString, 'YYYYMMDD')
  const toDateString = searchItem.includes('-to-')
    ? searchItem.substring(searchItem.indexOf('-to-') + '-to-'.length, searchItem.includes('-adults-') ? searchItem.indexOf('-adults-') : searchItem.length)
    : moment().add(1, 'day').format('YYYYMMDD')
  const toDate = moment(toDateString, 'YYYYMMDD')
  const adultsCount = +searchItem.substring(
    searchItem.indexOf('-adults-') + '-adults-'.length , 
    searchItem.indexOf('-children-')
  )
  const childrenCount = +searchItem.substring(
    searchItem.indexOf('-children-') + '-children-'.length
  )
  return {
    location,
    travelers: {adults: adultsCount, children: childrenCount},
    dates: [fromDate, toDate],
    latitude: 1,
    longitude: 1
  }
}

// by flight

export const getFlightDataFromSearchString = (searchString: string): {number: string, date: string, from: string, to: string} => {
  const searchData = searchString
  // removing '?'
  .substring(1)
  .split('-shared')[0]
  // separating different search items
  .split('&')
  const number = searchData.find(item => item.includes('number='))?.split('number=')[1] || ''
  const date = searchData.find(item => item.includes('date='))?.split('date=')[1] || ''
  const from = searchData.find(item => item.includes('from='))?.split('from=')[1] || ''
  const to = searchData.find(item => item.includes('to='))?.split('to=')[1] || ''
  return {number, date, from, to}
}

export const getFlightSearchUrl = (data: any, additionalData: {startCity: BeforeAfterLocationsType, endCity: BeforeAfterLocationsType}):string => {
  return `/travel/by-flight/results?number=${data.number.split(' ').join('').toUpperCase()}&date=${data.date.format('YYYY-MM-DD')}${!!additionalData.startCity.placeId.length ? '&from='+additionalData.startCity.placeId : ''}${!!additionalData.endCity.placeId.length ? '&to='+additionalData.endCity.placeId : ''}`
}

export const getAdvanceFlightSearchUrl = (page: 'airports' | 'summary', formValues: AdvancedFlightSearchAirportsRequestDataType):string => {
  return `/travel/by-flight-advanced/${page}?${encodeURIComponent(formValues.from)}-${formValues.type}-flight-to-${encodeURIComponent(formValues.to)}-start-date-${formValues.startDate}-end-date-${formValues.endDate}${formValues?.airports ? `-airports-${Object.values(formValues?.airports || {}).map(val => val.join('_')).join('-')}` : ''}`
}

export const getDataFromAdvanceFlightSearchUrl = (url:string):AdvancedFlightSearchAirportsRequestDataType => {
  const removedQuestionMark = url.slice(1)
  const searchType = removedQuestionMark.includes('-oneway-') ? 'oneway' : 'round'
  const locationA = removedQuestionMark.split(`-${searchType}-flight-to-`)[0]
  const locationB = removedQuestionMark.split(`-${searchType}-flight-to-`)[1].split('-start-date-')[0]
  const startDate = removedQuestionMark.split('-start-date-')[1].split('-end-date-')[0]
  const endDate = removedQuestionMark.split('-end-date-')[1].split('-airports-')[0]
  const airportsInfo = removedQuestionMark.split('-airports-')[1]?.split('-')
  const airports:{[key: string]: number[]} = {}
  airportsInfo?.forEach((val, index) => {
    airports[String(index + 1)] = val.split('_').map(id => +id)
  })

  return {
    type: searchType as 'oneway' | 'round',
    from: decodeURIComponent(locationA),
    to: decodeURIComponent(locationB),
    startDate: startDate,
    endDate: endDate,
    ...(airportsInfo ? {airports} : {})
  }
}

// by cruise
export const getCruiseDataFromSearchString = (searchString: string): CruiseSearchDataType => {
  const searchData = searchString
  // removing '?'
  .substring(1)
  .split('-shared')[0]
  // separating different search items
  .split('&')
  const lineId = searchData.find(item => item.includes('line-id='))?.split('line-id=')[1] || 0
  const cruiseName = searchData.find(item => item.includes('cruise-name='))?.split('cruise-name=')[1] || ''
  const date = searchData.find(item => item.includes('date='))?.split('date=')[1] || ''
  const from = searchData.find(item => item.includes('from='))?.split('from=')[1] || ''
  const to = searchData.find(item => item.includes('to='))?.split('to=')[1] || ''
  return ({lineId: +lineId, cruiseName, date, from, to})
}

export const getCruiseDataFromSearchStringAdvanced = (searchString: string): AdvancedCruiseSearchData => {
  const searchData = searchString
  // removing '?'
  .substring(1)
  .split('-shared')[0]
  // separating different search items
  .split('&')
  const start_date = searchData.find(item => item.includes('startFrom='))?.split('startFrom=')[1] || ''
  const end_date = searchData.find(item => item.includes('startTill='))?.split('startTill=')[1] || ''
  const regions = searchData.find(item => item.includes('regions='))?.split('regions=')[1] || ''
  const cities = searchData.find(item => item.includes('cities='))?.split('cities=')[1] || ''
  const countries = searchData.find(item => item.includes('countries='))?.split('countries=')[1] || ''
  const countriesFrom = searchData.find(item => item.includes('countriesFrom='))?.split('countriesFrom=')[1] || ''
  const countriesTo = searchData.find(item => item.includes('countriesTo='))?.split('countriesTo=')[1] || ''
  const destination_locations = searchData.find(item => item.includes('destinations='))?.split('destinations=')[1] || ''
  const lengths = searchData.find(item => item.includes('lengths='))?.split('lengths=')[1] || ''
  const cruise_lines = searchData.find(item => item.includes('lines='))?.split('lines=')[1] || ''
  const cruise_line_types = searchData.find(item => item.includes('lineTypes='))?.split('lineTypes=')[1] || ''

  return ({
    start_date: start_date ? moment(start_date, 'YYYYMMDD').format('YYYY-MM-DD') : undefined,
    end_date: end_date ? moment(end_date, 'YYYYMMDD').format('YYYY-MM-DD') : undefined,
    // regions: regions?.length
    //   ? regions.split(',loc:').map(l => ({region_id: +l.split('/')[0], name: decodeURIComponent(l.split('/')[1])}))
    //   : undefined,
    regions: regions?.length
    ? regions.split(',').map(cId => Number(cId))
    : undefined,
    cities: cities?.length
      ? cities.split(',loc:').map(l => ({city_id: +l.split('/')[0], name: decodeURIComponent(l.split('/')[1])}))
      : undefined,
    countries: countries?.length
      ? countries.split(',loc:').map(l => ({country_id: +l.split('/')[0], name: decodeURIComponent(l.split('/')[1])}))
      : undefined,
    countries_from: countriesFrom?.length
      ? countriesFrom.split(',').map(cId => Number(cId))
      : undefined,
    countries_to: countriesTo?.length
      ? countriesTo.split(',').map(cId => Number(cId))
      : undefined,
    destination_locations: destination_locations?.length
      ? destination_locations
        .split(',loc:')
        .map(loc => {
          const dataItems = loc.split('/')
          return {latitude: +dataItems[0], longitude: +dataItems[1], name: decodeURIComponent(dataItems[2]) || ''}
        })
      : undefined,
    lengths: lengths?.length ? lengths.split(',').map(l => +l) : undefined,
    cruise_lines: cruise_lines?.length
      ? cruise_lines.split(',line:').map(l => ({name: decodeURIComponent(l.split('/')[0]), id: +l.split('/')[1], type_id: +l.split('/')[2]}))
      : undefined,
    cruise_line_types: cruise_line_types?.length ? cruise_line_types.split(',').map(l => +l) : undefined,
  })
  
}

export const getCruiseSearchUrl = (data: any, additionalData?: {startCity: BeforeAfterLocationsType, endCity: BeforeAfterLocationsType}):string => {
  return `/travel/by-cruise/results?line-id=${data.cruiseLine?.value}${data?.cruiseName?.label?.[0] ? '&cruise-name='+data?.cruiseName?.label?.[0]: ''}&date=${moment(data.date).format('YYYY-MM-DD')}${!!additionalData?.startCity?.placeId?.length ? '&from='+additionalData.startCity.placeId : ''}${!!additionalData?.endCity?.placeId?.length ? '&to='+additionalData.endCity.placeId : ''}`
}

export const getCruiseSearchUrlAdvanced = (data: any):string => {
  const startFrom = data?.start_date ? `startFrom=${moment(data?.start_date).format('YYYYMMDD')}&` : ''
  const startTill = data?.end_date ? `startTill=${moment(data?.end_date).format('YYYYMMDD')}&` : ''
  const regions = data?.regions?.length ? `regions=${data?.regions.join(',')}&` : ''//`regions=${data?.regions.map((r:any) => r.region_id+'/'+r.name).join(',loc:')}&` : ''
  const cities = data?.cities?.length ? `cities=${data?.cities.map((c:any) => c.city_id+'/'+c.name).join(',loc:')}&` : ''
  const countries = data?.countries?.length ? `countries=${data?.countries.map((c:any) => c.country_id+'/'+c.name).join(',loc:')}&` : ''
  const countriesFrom = data?.countries_from?.length ? `countriesFrom=${data?.countries_from.join(',')}&` : ''
  const countriesTo = data?.countries_to?.length ? `countriesTo=${data?.countries_to.join(',')}&` : ''
  const destinations = data?.destination_locations?.length
    ? `destinations=${data?.destination_locations?.map(
        (loc:any) => loc.latitude+'/'+loc.longitude+'/'+loc.name
      ).join(',loc:')}&`
    : ''
  const cruise_lines = data?.cruise_lines?.length
    ? `lines=${data?.cruise_lines?.map(
      (line:any) => line.name+'/'+line.id+'/'+line.type_id
    ).join(',line:')}&`
  : ''
  const cruise_line_types = data?.cruise_line_types?.length ? `lineTypes=${data?.cruise_line_types.join(',')}&` : ''
  const lengths = data?.lengths?.length ? `lengths=${data?.lengths.join(',')}&` : ''

  return `/travel/by-cruise-advanced/results?${startFrom}${startTill}${regions}${cities}${countries}${countriesFrom}${countriesTo}${destinations}${lengths}${cruise_lines}${cruise_line_types}`
}

export const validateSearchString = (type: 'byFlight' | 'byCruise', searchString: string, newData: {from?: string, to?: string}):string => {
  const [mainData, sharedDetails] = searchString.split('-shared')
  const {date, from, to, ...rest} = type === 'byCruise' ? getCruiseDataFromSearchString(mainData) : getFlightDataFromSearchString(mainData)
  if (type === 'byCruise') {
    const data = rest as {lineId: number, cruiseName?: string | undefined}
    return `?line-id=${data?.lineId}${!!data?.cruiseName ? '&cruise-name='+data?.cruiseName : ''}&date=${date}${!!from?.length ? '&from='+ newData?.from : ''}${!!to?.length ? '&to='+ newData?.to : ''}${sharedDetails?.length ? '-shared' + sharedDetails : ''}`
  } else {
    const data = rest as {number: string}
    return `?number=${data.number}&date=${date}${!!from?.length ? '&from='+ newData?.from : ''}${!!to?.length ? '&to='+ newData?.to : ''}${sharedDetails?.length ? '-shared' + sharedDetails : ''}`
  }
}

// build external links

export const getPricelineLink = (data: SearchRequestDataType) => {
  return `https://www.priceline.com/drive/search/r/listings/${data?.location}/${data?.location}/${moment(data.dates?.[0]).format('YYYYMMDD')}-12:00/${moment(data.dates?.[1]).format('YYYYMMDD')}-12:00/list`
}

export const getBookingLink = (data: SearchRequestDataType) => {
  return `https://www.booking.com/searchresults.en.html?aid=7942112&latitude=${data?.latitude}&longitude=${data?.longitude}&radius=100&${data?.event_code ? 'label='+data?.event_code+'&' : ''}order=distance&checkin_year=${moment(data?.dates?.[0]).format('YYYY')}&checkin_month=${moment(data?.dates?.[0]).format('MM')}&checkin_monthday=${moment(data?.dates?.[0]).format('DD')}&checkout_year=${moment(data?.dates?.[1]).format('YYYY')}&checkout_month=${moment(data?.dates?.[1]).format('MM')}&checkout_monthday=${moment(data?.dates?.[1]).format('DD')}&efdco=1&group_adults=${data?.travelers?.adults}&group_children=${data?.travelers?.children}`
}

export const getRentalCarsLink = (data: RentalCarsLinkDataType):string => {
  if (data?.airportIATACode) {
    return `https://www.awin1.com/cread.php?awinmid=29081&awinaffid=1344629${!!data?.eventCode ? '&clickref=' + data?.eventCode : ''}&ued=https%3A%2F%2Fwww.rentalcars.com%2FSearchLoaderWidget.do%3Fpreflang%3Dus%26from%3D${data?.airportIATACode}%26to%3D${data?.airportIATACode}%26puDay%3D${data.dates[0].format('DD')}%26puMonth%3D${data.dates[0].format('MM')}%26puYear%3D${data.dates[0].format('YYYY')}%26puHour%3D${data.dates[0].format('HH')}%26puMinute%3D00%26doDay%3D${data.dates[1].format('DD')}%26doMonth%3D${data.dates[1].format('MM')}%26doYear%3D${data.dates[1].format('YYYY')}%26doHour%3D${data.dates[1].format('HH')}%26doMinute%3D00%26driversAge%3D30%26prefcurrency%3DUSD`
  } else {
    return `https://www.awin1.com/cread.php?awinmid=29081&awinaffid=1344629${!!data?.eventCode ? '&clickref=' + data?.eventCode : ''}&ued=https%3A%2F%2Fwww.rentalcars.com%2FSearchRequest.do%3FlocID%3D-1%26locName%3D${encodeURIComponent(data?.locName || '')}%26lat%3D${data?.lat}%26lng%3D${data?.lng}%26ftsKey%3D-1%26pickDate%3D${data.dates[0].format('DD')}%2F${data.dates[0].format('MM')}%2F${data.dates[0].format('YYYY')}%2B${data.dates[0].format('HH')}%3A00%3A00%26dropDate%3D${data.dates[1].format('DD')}%2F${data.dates[1].format('MM')}%2F${data.dates[1].format('YYYY')}%2B${data.dates[1].format('HH')}%3A00%3A00%26searchTyp+e%3D%26driverAge%3D30`
  }
}

// build share links 
export const getShareLink = (selectedLocations: { name: string, url: string}[]):string => {
  const isFlightAdvanced = window.location.pathname.includes('by-flight-advanced')
  const isRegularSearch = !window.location.pathname.includes('by-flight') && !window.location.pathname.includes('by-cruise') && !isFlightAdvanced

  if (isFlightAdvanced) {
    return window.location.host + window.location.pathname + window.location.search 
  } else {
    return isRegularSearch
      ? getSearchResultsExportLink(selectedLocations)
      : getFlightCruiseShareLink()
  }
}

export const getSearchResultsExportLink = (selectedLocations: { name: string, url: string}[]):string => {
  const root = window.location.host
  const basicUrl = root
    + window.location.pathname
    + '?'
    + selectedLocations.map(location => location.url).join('&')
    +'-shared'
  const appStore = store.getState()

  const activeTabName = selectedLocations?.some(l => l.name === appStore.searchResults.activeTab.name) || !selectedLocations?.length
    ? appStore.searchResults.activeTab.name
    : selectedLocations[0]?.name

  const activeResultCategories = appStore.searchResults.activeResultCategories.map(cat => resultCategories.find(c => c.value === cat)?.id)
  const selectedNode = appStore.searchResults.nodeServiceDetails?.id && selectedLocations?.some(l => l.name === appStore.searchResults.activeTab.name)
    ? appStore.searchResults.nodeServiceDetails?.id
    : undefined
  const selectedStop = appStore.searchResults.selectedTransportStop?.id && selectedLocations?.some(l => l.name === appStore.searchResults.activeTab.name)
    ? appStore.searchResults.selectedTransportStop?.id
    : undefined
  const activeResultBlock = !selectedNode && appStore.searchResults.activeResultBlock === 'NodeDetails'
    ? 'Nodes'
    : appStore.searchResults.activeResultBlock

  const resultUrl = basicUrl
    + '&name=' + activeTabName
    + '&categ=' + activeResultCategories
    + '&res-block=' + activeResultBlock
    + (!!selectedNode ? '&node-id=' + selectedNode : '')
    + (!!selectedStop ? '&stop-id=' + selectedStop : '')

  return resultUrl.replaceAll(' ', '%20')
}

export const getFlightCruiseShareLink = () :string => {
  const root = window.location.host
  const basicUrl = root
    + window.location.pathname
    + '?'
    + window.location.search.split('-shared')[0]
    +'-shared'
  const appStore = store.getState()
  const activeTabKey  = appStore.travelSearch.activeResultsTabKey
  const isCityResultsOpen  = appStore.travelSearch.isCityResultsOpen
  const isStopTabSelected  = appStore.travelSearch.isStopTabSelected
  const selectedResultItemId  = appStore.travelSearch.selectedResultItemId
  const selectedCategoryTabKey  = appStore.travelSearch.selectedCategoryTabKey
  const customizedCruise = appStore.cruise.customizedCruise

  const activeResultCategories = appStore.searchResults.activeResultCategories.map(cat => resultCategories.find(c => c.value === cat)?.id)
  const selectedNode = appStore.searchResults.nodeServiceDetails?.id
    ? appStore.searchResults.nodeServiceDetails?.id
    : undefined
  const activeResultBlock = !selectedNode && appStore.searchResults.activeResultBlock === 'NodeDetails'
    ? 'Nodes'
    : appStore.searchResults.activeResultBlock
  const selectedStop = appStore.searchResults.selectedTransportStop?.id
    ? appStore.searchResults.selectedTransportStop?.id
    : undefined

  const resultUrl = basicUrl
    + '&key=' + activeTabKey
    + '&isCity=' + isCityResultsOpen
    + '&isStop=' + isStopTabSelected
    + '&res-block=' + activeResultBlock
    + '&categ-tab=' + selectedCategoryTabKey
    + (!!selectedResultItemId ? '&res-item-id=' + selectedResultItemId : '')
    + (!!activeResultCategories ? '&categ=' + activeResultCategories : '')
    + (!!selectedNode ? '&node-id=' + selectedNode : '')
    + (!!selectedStop ? '&stop-id=' + selectedStop : '')
    + (!isNumber(customizedCruise) && !!customizedCruise?.id ? '&customized=' + customizedCruise?.id : '')

  return resultUrl.replaceAll(' ', '%20')
}

// decode share links

export const decodeSearchResultsExportLink = (url: string): {locations: string[], sharedDetails?: SharedDetailsType} => {
  const [locationsData, additionalData] = url
    // removing '?'
    .substring(1)
    // separating search items from shared link details 
    .split('-shared')

  // separating different search items
  const locations = locationsData.split('&')

  return {
    locations,
    ...(additionalData?.length ? {
      sharedDetails: {
        activeTabName: getSharedDetail(additionalData, 'name='),
        activeResultCategories: getSharedDetail(additionalData, 'categ=').split(',').map(cat => resultCategories.find(c => c.id === cat)?.value!),
        activeResultBlock: getSharedDetail(additionalData, 'res-block=') as ActiveResultBlockType,    
        selectedNodeId: getSharedDetail(additionalData, 'node-id='), 
        selectedTransportStop: getSharedDetail(additionalData, 'stop-id=')
      }
    } : {})
  }
}

export const decodeFlightCruiseSearchResultsExportLink = (getSearchDataFromUrl: (data:any) => object): {searchData: object, sharedDetails?: SharedFlightCruiseDetailsType} => {
  const [searchData, additionalData] = window.location.search.split('-shared')

  return {
    searchData: getSearchDataFromUrl(searchData),
    ...(additionalData?.length ? {
      sharedDetails: {
        activeTabKey: getSharedDetail(additionalData, 'key='),
        isCityResultsOpen: getSharedDetail(additionalData, 'isCity=') === 'true',
        isStopTabSelected: getSharedDetail(additionalData, 'isStop=') === 'true',
        selectedCategoryTabKey: getSharedDetail(additionalData, 'categ-tab='),
        selectedResultItemId: getSharedDetail(additionalData, 'res-item-id='),
        activeResultCategories: getSharedDetail(additionalData, 'categ=').split(',').map(cat => resultCategories.find(c => c.id === cat)?.value!),
        activeResultBlock: getSharedDetail(additionalData, 'res-block=') as ActiveResultBlockType,    
        selectedNodeId: getSharedDetail(additionalData, 'node-id='),  
        selectedTransportStop: getSharedDetail(additionalData, 'stop-id='), 
        customizedCruise: getSharedDetail(additionalData, 'customized='), 
      }
    } : {})
  }
}

export const getSharedDetail = (additionalData: string, detailName: string) => {
  // separating shared details
  const detailValue = additionalData?.split('&')
    // searching for requested detail name
    .find(detail => detail.includes(detailName))
    // removing detail name from detail value
    ?.split(detailName)[1]
  
  return decodeURIComponent(detailValue || '')
}

export const decodeWidgetUrl = () => {
  
}
