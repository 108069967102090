import { useEffect, useState } from 'react'
import { Button, DatePicker, Modal, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import moment, { Moment } from 'moment'
import formClasses from './../../common/SearchPageWithBgImage/SearchPageWithBgImage.module.css'
import classes from './CruiseAdvancedSearch.module.css'
import { ReactComponent as DatePickerCalendar } from './../../../../img/icons/datePickerCalendar.svg'
import BackBtn from '../../../common/BackBtn/BackBtn'
import Header from '../../../common/Header/Header'
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetCruiseLengthsThunk, GetCruiseLineTypesThunk, GetCruisesByAdvancedSearchThunk, selectAdvancedSearchPagination, selectAdvancedSearchRequestData, selectCruiseLengths, setAdvancedSearchRequestData, setCruiseStartLocations, setCruises } from '../../../../store/cruiseReducer'
import DestinationLocationsField from './DestinationLocationsField/DestinationLocationsField'
import DepartureLocationsField from './DepartureLocationsField/DepartureLocationsField'
import { getCruiseSearchUrlAdvanced } from '../../../../helpers/linksHelper'
import SpecificCruiseLinesField from './SpecificCruisesField/SpecificCruiseLinesField'
import { AdvancedCruiseSearchData, AdvancedCruiseSearchRequestData } from '../../../../types/cruiseTypes'
import { Helmet } from 'react-helmet-async'
import { GetCountryListThunk, GetRegionListThunk } from '../../../../store/additionalDataReducer'
import CountryLocationField from './CountryLocationField/CountryLocationField'
import RegionsLocationField from './RegionsLocationField/RegionsLocationField'

const CruiseAdvancedSearch:React.FC<{isFiltersModalOpen?: boolean, onModalClose?: () => void}> = ({isFiltersModalOpen, onModalClose}) => {
  if (isFiltersModalOpen) {
    return (
      <Modal
        closable={false}
        visible={isFiltersModalOpen}
        onCancel={() => onModalClose!()}
        footer={null}
        width='600px'
      >
        <CruiseAdvancedSearchForm onModalClose={onModalClose}/>
      </Modal>
    )
  } else {
    return (
      <div className={`${formClasses.wrapper} ${formClasses.cruise}`}>
        <Header showMenu={true}/>
        <div className={formClasses.searchAreaWrapper}>
          <Breadcrumbs className={formClasses.breadcrumbs} />
          <CruiseAdvancedSearchForm />
        </div>
      </div>
    )
  }
}

const CruiseAdvancedSearchForm: React.FC<{onModalClose?: () => void}> = ({onModalClose}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState<AdvancedCruiseSearchData>({})

  const cruiseLengths = useAppSelector(selectCruiseLengths)
  const advancedSearchPagination = useAppSelector(selectAdvancedSearchPagination)
  const searchData = useAppSelector(selectAdvancedSearchRequestData)

  useEffect(() => {
    dispatch(GetCruiseLengthsThunk())
    dispatch(GetCruiseLineTypesThunk())
    dispatch(GetCountryListThunk())
    dispatch(GetRegionListThunk())
  }, [dispatch])

  useEffect(() => {
    if (onModalClose && !Object.keys(formValues)?.length) {
      setFormValues({
        ...searchData,
        // regions: searchData?.regions,
        cities: searchData?.cities,
        countries: searchData?.countries,
      })
      dispatch(setCruiseStartLocations({
        // regions: searchData?.regions || [],
        // @ts-ignore
        locations: [...(searchData?.cities || []), ...(searchData?.countries || [])]
      }))
    }
    // eslint-disable-next-line
  }, [dispatch])

  const onStartDateChange = (val: [Moment, Moment] | null) => {
    setFormValues({
      ...formValues,
      start_date: val === null ? null : val?.[0]?.format('YYYY-MM-DD'),
      end_date: val === null ? null : val?.[1]?.format('YYYY-MM-DD')
    })
  }

  const onSearch = () => {
    if (onModalClose) {
      navigate(getCruiseSearchUrlAdvanced(formValues))
      dispatch(setCruises(null))
      dispatch(GetCruisesByAdvancedSearchThunk({
        cruiseInfo: {
          ...formValues,
          destination_locations: (formValues as AdvancedCruiseSearchData)?.destination_locations?.map(l => ({latitude: l.latitude, longitude: l.longitude})),
          regions: (formValues as AdvancedCruiseSearchData)?.regions,
          cities: (formValues as AdvancedCruiseSearchData)?.cities?.map(c => c.city_id),
          countries: (formValues as AdvancedCruiseSearchData)?.countries?.map(r => r.country_id),
          cruise_lines: (formValues as AdvancedCruiseSearchData)?.cruise_lines?.map(c => c.id),
          countries_to: (formValues as AdvancedCruiseSearchData)?.countries_to,
          countries_from: (formValues as AdvancedCruiseSearchData)?.countries_from,
          pagination: advancedSearchPagination
        } as AdvancedCruiseSearchRequestData 
      }))
      dispatch(setAdvancedSearchRequestData(formValues as AdvancedCruiseSearchData))
      onModalClose()
    } else {
      dispatch(setCruiseStartLocations({regions: [], locations: []}))
      navigate(getCruiseSearchUrlAdvanced(formValues))
    }
  }

  return (
    <div className={formClasses.search} style={onModalClose ? {width: '100%'} : {}}>
      <Helmet>
        <link rel='canonical' href={window.location.href} />
        <meta name='description' content='Advanced cruise search by start location, destination location, cruise line'/>
        <title>Relavanti | Advanced cruise search</title>
      </Helmet>
      <BackBtn
        type='closeIcon'
        className={formClasses.closeBtn}
        onClick={() => onModalClose ? onModalClose() : navigate('/travel')}
      />
      <h1>
        Cruise
      </h1>
      <div className={formClasses.searchDescription}>
        Advanced Search
      </div>
      <div className={formClasses.form}>
        <div className={classes.doubleInputWrapper}>
          <div className={classes.formItemWrapper}>
            <div className={classes.formItemLabel}>
              When would you like to cruise?
            </div>
            <div id='datesSelect'>
              <DatePicker.RangePicker
                allowClear
                value={formValues?.start_date
                  ? [moment(formValues?.start_date), moment(formValues?.end_date)]
                  : [null, null]
                }
                placeholder={['From date', 'Till date']}
                suffixIcon={<DatePickerCalendar/>}
                style={{width: '100%', marginBottom: '25px', height: '48px'}}
                getPopupContainer={() => document.getElementById('datesSelect')!}
                onChange={(val) => onStartDateChange(val as [Moment, Moment] | null)}
                disabledDate={(current) => current.startOf('day').isBefore(moment().startOf('day'))}
                ranges={{
                  "This Week": [moment(), moment().endOf('week')],
                  "This Month" : [moment(), moment().endOf('month')],
                  "This Year": [moment(), moment().endOf('year')],
                }}
              />
            </div>
          </div>

          <div className={classes.formItemWrapper}>
            <div className={classes.formItemLabel}>
              How long a cruise would you like?
            </div>
            <div id='lengthsSelect'>
              <Select
                style={{width: '100%', marginBottom: '25px'}}
                options={cruiseLengths?.map(length => ({value: length.id, label: length.name}))}
                optionFilterProp='label'
                placeholder='Select length'
                allowClear
                mode='tags'
                getPopupContainer={() => document.getElementById('lengthsSelect')!}
                value={formValues?.lengths}
                onSelect={(value: number) => setFormValues({...formValues, lengths: [...(formValues?.lengths || []), value]})}
                onDeselect={(value: number) => setFormValues({...formValues, lengths: formValues?.lengths?.filter((l:number) => l !== value)})}
              />
            </div>
          </div>
        </div>

        <DestinationLocationsField setFormValues={setFormValues} formValues={formValues} />

        <DepartureLocationsField setFormValues={setFormValues} formValues={formValues} />

        <RegionsLocationField setFormValues={setFormValues} formValues={formValues} />

        <CountryLocationField 
          setFormValues={setFormValues}
          formValues={formValues}
          label='Cruise: TO Country/countries'
          field='countries_to'
        />

        <CountryLocationField 
          setFormValues={setFormValues}
          formValues={formValues}
          label='Cruise: FROM Country/countries'
          field='countries_from'
        />

        <SpecificCruiseLinesField setFormValues={setFormValues} formValues={formValues}/>
        
        <div className={formClasses.searchButtonWrapper}>
          <Button
            type='primary'
            htmlType='submit'
            className={formClasses.searchButton}
            onClick={onSearch}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  )
}



export default CruiseAdvancedSearch
