import classes from './CopyLinkBlock.module.css'
import { copyToClipboard, getShareLink } from '../../../../../../helpers/linksHelper'
import { ReactComponent as CopyIcon } from './../../../../../../img/icons/copy.svg'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'

const CopyLinkBlock: React.FC<{goBack?: () => void, selectedLocations: {name: string, url: string}[]}> = ({goBack, selectedLocations}) => {
  const link = getShareLink(selectedLocations)

  return (
    <>
      <div style={{fontSize: '16px', alignSelf: 'start', marginTop: '10px'}}>
        Link
      </div>
      <div className={classes.linkWrapper}>
        <div className={classes.link}>
          {link}
        </div>
        <CopyIcon
          className={classes.copyIcon}
          onClick={() => copyToClipboard(link, 'Link copied successfully')}
        />
      </div>
      <SocialShareBlock link={link}/>
      {!!goBack &&
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={goBack}>
          Back
        </div>
      }
    </>
  )
}

const SocialShareBlock:React.FC<{link: string}> = ({link}) => {
  return (
    <div className={classes.socialShareBlock}>
      <div style={{color: '#667085', fontSize: '14px', textAlign: 'center', marginBottom: '15px'}}>
        or send
      </div>
      <div className={classes.socialOptions}>
        <TwitterShareButton url={link} title=''>
          <div className={classes.socialOption}>
            <TwitterIcon round size={48}/>
            Twitter
          </div>
        </TwitterShareButton>
        <FacebookShareButton url={link}>
          <div className={classes.socialOption}>
            <FacebookIcon round size={48}/>
            Facebook
          </div>
        </FacebookShareButton>
        <EmailShareButton url={link}>
          <div className={classes.socialOption}>
            <EmailIcon round size={48}/>
            Email
          </div>
        </EmailShareButton>
      </div>
    </div>
  )
}

export default CopyLinkBlock
