import { DatePicker, InputNumber, Select } from 'antd'
import moment, { Moment } from 'moment'
import classes from './SearchAdditionalFields.module.css'
import { ReactComponent as PersonIcon } from './../../../img/icons/person.svg'
import { ReactComponent as DatePickerCalendar } from './../../../img/icons/datePickerCalendar.svg'
import { SearchAdditionalDataType } from '../../../types/searchResultTypes'

const SearchAdditionalFields: React.FC<SearchAdditionalFieldsPropTypes> = ({handleChange, values, style, className}) => {
  const adults = values?.travelers?.adults || 1
  const children = values?.travelers?.children || 0

  return (
    <div className={`${classes.additionalInputs} ${className}`} style={style}>
      <DatePicker.RangePicker
        style={{width: '100%', height: '40px', borderRadius: '10px'}}
        placeholder={['Depart', 'Return']}
        value={values.dates}
        onChange={val => handleChange({...values, dates: val as [Moment, Moment]})}
        disabledDate={(date) => date && date < moment().subtract(1, 'days')}
        suffixIcon={<DatePickerCalendar/>}
      />
      <Select
        style={{ width: '100%'}}
        className={classes.additionalInputsSelect}
        suffixIcon={<PersonIcon />}
        value={`${values?.travelers?.adults || 1} ${adults > 1 ? 'adults' : 'adult'}${children > 0 ? ', ' + (children || 0) + ' children' : ''}`}
        dropdownStyle={{padding: '0px'}}
        dropdownRender={() => (
          <div>
            <InputNumber
              prefix={<PersonIcon className={classes.numberInputIcon}/>}
              style={{ width: '100%', padding: '5px'}}
              min={1}
              value={values?.travelers?.adults || 1}
              addonAfter={<div style={{width: '60px'}}>{adults > 1 ? 'adults' : 'adult'}</div>}
              onChange={val => handleChange({...values, travelers: {...values.travelers, adults: val || 1}})}
            />
            <InputNumber
              prefix={<PersonIcon className={classes.numberInputIcon}/>}
              style={{ width: '100%', padding: '5px'}}
              min={0}
              value={values?.travelers?.children || 0}
              addonAfter={<div style={{width: '60px'}}>children</div>}
              onChange={val => handleChange({...values, travelers: {...values.travelers, children: val || 0}})}
            />
          </div>
        )}
      />
    </div>
  )
}

interface SearchAdditionalFieldsPropTypes {
  values: SearchAdditionalDataType
  handleChange: (updatedData: SearchAdditionalDataType) => void
  style?: object
  className?: string
}

export default SearchAdditionalFields
