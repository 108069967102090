import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy, uniq } from 'lodash'
import { cruiseApi } from '../app/api'
import { SharedFlightCruiseDetailsType } from '../types/appTypes'
import { AdvancedCruiseSearchData, AdvancedCruiseSearchRequestData, AdvancesSearchCruisePaginationType, CruiseLineType, CruiseRouteType, CruiseSearchTypeType, CruiseStartDateType, CruiseStartLocationsDataType, CruiseType, CustomizedCruiseDataType } from '../types/cruiseTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, fulfilledMetaType, RootState } from './../types/appTypes'
import { GetUserIPThunk } from './userReducer'
import { getRequestHeaders } from '../helpers/axiosHelper'

const mergeCruiseStopsForSamePort = (stops: CruiseRouteType[]) => {
  const result:CruiseRouteType[] = []
  const sortedStops = sortBy(stops, stop => +stop?.sequence)
  sortedStops.forEach(stop => {
    if (!stop?.port?.id && +stop?.sequence !== 1 && +stop?.sequence !== +sortedStops[sortedStops?.length - 1]?.sequence && !stop?.name?.includes('Fly to')) {
      return
    } else if (stop?.port?.id === result[result.length - 1]?.port?.id) {
      result[result.length - 1] = {...result[result.length - 1], departure_time: stop.departure_time}
    } else {
      result.push(stop)
    }
  })
  return result
}

export const getCruiseArrivalLocation = (cruise_routes: CruiseRouteType[], index: number):any => {
  const lastRoute = cruise_routes[cruise_routes?.length - index]
  if (lastRoute?.port?.id) {
    return lastRoute
  } else {
    return getCruiseArrivalLocation(cruise_routes, index + 1)
  }
}

type ActiveInfoBlockTypes = 'cruises' | 'cruiseDetails'

interface InitialStateType {
  cruises: CruiseType[] | null
  cruise: CruiseType | null
  isCruiseStopsMerged: boolean

  cruiseLine: CruiseLineType | null
  cruiseLineTypes: {id: number, name: string}[]
  cruiseLineOptions: CruiseLineType[] | null
  cruiseDateOptions: string[] | null
  cruiseOptions: CruiseType[] | null
  activeInfoBlock: ActiveInfoBlockTypes
  selectedStops: CruiseRouteType[]
  cruiseInfoActiveTab: 'description' | 'program' | 'photo'
  isCityResultsOpen: boolean

  cruiseSearchType: CruiseSearchTypeType
  cruiseStartLocations: CruiseStartLocationsDataType
  cruiseLengths: {id: number, name: string}[]
  advancedSearchPagination: AdvancesSearchCruisePaginationType
  advancedSearchTotalCount: number
  advancedSearchRequestData: AdvancedCruiseSearchData

  customizedCruise: null | CustomizedCruiseDataType | number
  customizedCruiseList: null | CustomizedCruiseDataType[]
}

const initialState: InitialStateType = {
  cruises: null,
  cruise: {} as CruiseType,
  isCruiseStopsMerged: false,
  cruiseLine: null,
  cruiseLineTypes: [],
  cruiseLineOptions: null,
  cruiseDateOptions: null,
  cruiseOptions: [],
  activeInfoBlock: 'cruises',
  selectedStops: [],
  cruiseInfoActiveTab: 'description',
  isCityResultsOpen: false,

  cruiseSearchType: 'default',
  cruiseStartLocations: {locations: [], regions: []},
  cruiseLengths: [],
  advancedSearchPagination: {page: 1, size: 5, sort: [{field: 'departure_time', direction: 'ASC'}]},
  advancedSearchTotalCount: 0,
  advancedSearchRequestData: {},

  customizedCruise: null,
  customizedCruiseList: null
}

export const cruiseSlice = createSlice({
  name: 'cruise',
  initialState,
  reducers: {
    setCruises: (state, action: PayloadAction<CruiseType[] | null>) => {state.cruises = action.payload},
    setCruise: (state, action: PayloadAction<CruiseType | null>) => {
      if (action.payload === null) {
        state.activeInfoBlock = 'cruises'
        state.cruise = action.payload
      } else {
        state.activeInfoBlock = 'cruiseDetails'
        const cruiseStops = state.isCruiseStopsMerged ? action.payload?.cruise_routes : mergeCruiseStopsForSamePort(action.payload?.cruise_routes)
        state.isCruiseStopsMerged = true
        state.cruise = {...action.payload, cruise_routes: cruiseStops}
      }
    },
    setIsCruiseStopsMerged: (state, action: PayloadAction<boolean>) => {
      state.isCruiseStopsMerged = action.payload
    },
    setCruiseLine: (state, action: PayloadAction<CruiseLineType | null>) => {
      state.cruiseLine = action.payload
    },
    setCruiseLineOptions: (state, action: PayloadAction<CruiseLineType[] | null>) => {
      state.cruiseLineOptions = action.payload
    },
    setCruiseDateOptions: (state, action: PayloadAction<CruiseStartDateType[] | null>) => {
      state.cruiseDateOptions = action.payload === null
        ? action.payload
        : uniq(action.payload.map(opt => opt?.departure_date))
    },
    setCruiseOptions: (state, action: PayloadAction<CruiseType[] | null>) => {
      state.cruiseOptions = action.payload
    },
    setActiveInfoBlock: (state, action: PayloadAction<ActiveInfoBlockTypes>) => {state.activeInfoBlock = action.payload},
    setSelectedStops: (state, action: PayloadAction<number>) => {
      if (state.selectedStops.some(stop => stop?.id === action.payload)) {
        state.selectedStops = state.selectedStops.filter(s => s.id !== action.payload)
      } else {
        const stopData = state?.cruise?.cruise_routes?.find(r => r.id === action.payload)
        state.selectedStops = sortBy([...state.selectedStops, stopData!], stop => +stop?.sequence)
      }
    },
    setCruiseInfoActiveTab: (state, action: PayloadAction<'description' | 'program' | 'photo'>) => {state.cruiseInfoActiveTab = action.payload},
    clearSelectedStops: (state) => {state.selectedStops = []},
    setCruiseSearchType: (state, action: PayloadAction<CruiseSearchTypeType>) => {state.cruiseSearchType = action.payload},
    setCruiseLineTypes: (state, action: PayloadAction<{name: string, id: number}[]>) => {state.cruiseLineTypes = action.payload},
    setCruiseStartLocations: (state, action: PayloadAction<CruiseStartLocationsDataType>) => {state.cruiseStartLocations = action.payload},
    setCruiseLengths: (state, action: PayloadAction<{name: string, id: number}[]>) => {state.cruiseLengths = action.payload},
    setAdvancedSearchPagination: (state, action: PayloadAction<AdvancesSearchCruisePaginationType>) => {state.advancedSearchPagination = action.payload},
    setAdvancedSearchTotalCount: (state, action: PayloadAction<number>) => {state.advancedSearchTotalCount = action.payload},
    setAdvancedSearchRequestData: (state, action: PayloadAction<AdvancedCruiseSearchData>) => {state.advancedSearchRequestData = action.payload},
    setCustomizedCruise: (state, action: PayloadAction<CustomizedCruiseDataType | null | number>) => {state.customizedCruise = action.payload}, 
    setCustomizedCruiseList: (state, action: PayloadAction<CustomizedCruiseDataType[] | null>) => {state.customizedCruiseList = action.payload}, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCruiseByLineAndDateThunk.fulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType
        if (meta.appStatus === AppStatusType.info) {
          state.cruises = []
          return 
        } else {
          const data = action.payload as {cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType}
          state.cruises = data.cruises
          if (!data.sharedDetails?.selectedResultItemId) {
            state.activeInfoBlock = 'cruises'
            state.cruises = data.cruises.map(cruise => ({...cruise}))
          } else {
            const cruise = data.cruises?.find(c => String(c.id) === data.sharedDetails?.selectedResultItemId) as  CruiseType
            const cruiseStops = state.isCruiseStopsMerged ? cruise?.cruise_routes : mergeCruiseStopsForSamePort(cruise?.cruise_routes)
            state.isCruiseStopsMerged = true
            state.cruise = {...cruise, cruise_routes: cruiseStops}
            state.activeInfoBlock = 'cruiseDetails'
            state.customizedCruise = data?.sharedDetails?.customizedCruise ? +data?.sharedDetails?.customizedCruise : null
          }
        }
      })
      .addCase(GetCruiseByLineAndDateThunk.rejected, (state) => {
        state.cruises = []
      })
      .addCase(GetCruiseLineOptionsThunk.fulfilled, (state, action) => {
        state.cruiseLineOptions = action.payload
      })
      .addCase(GetCruiseOptionsThunk.fulfilled, (state, action) => {
        state.cruiseOptions = action.payload
      })
      .addCase(GetCruisesByLineIdThunk.fulfilled, (state, action) => {
        state.cruiseOptions = action.payload
      })
      .addCase(GetCruiseByIdThunk.fulfilled, (state, action) => {
        state.cruise = action.payload
      })  
      .addCase(GetCruiseLineByIdThunk.fulfilled, (state, action) => {
        state.cruiseLine = action.payload
      })
      .addCase(GetCruiseLineTypesThunk.fulfilled, (state, action) => {
        state.cruiseLineTypes = action.payload
      })  
      .addCase(GetCruiseStartLocationsThunk.fulfilled, (state, action) => {
        state.cruiseStartLocations = action.payload
      })  
      .addCase(GetCruiseLengthsThunk.fulfilled, (state, action) => {
        state.cruiseLengths = action.payload
      })  
      .addCase(GetCruisesByAdvancedSearchThunk.fulfilled, (state, action) => {
        const data = action.payload as {cruises: CruiseType[], total_count: number, sharedDetails?: SharedFlightCruiseDetailsType, cruise?: CruiseType}
        state.cruises = data.cruises
        state.advancedSearchTotalCount = data.total_count
        state.customizedCruise = data?.sharedDetails?.customizedCruise ? +data?.sharedDetails?.customizedCruise : null
        if (!!data?.sharedDetails?.selectedResultItemId) {
          const cruiseFromList = data.cruises?.find(c => String(c.id) === data.sharedDetails?.selectedResultItemId) as  CruiseType
          const cruise = cruiseFromList || data?.cruise
          if (cruise) {
            const cruiseStops = state.isCruiseStopsMerged ? cruise?.cruise_routes : mergeCruiseStopsForSamePort(cruise?.cruise_routes)
            state.isCruiseStopsMerged = true
            state.cruise = {...cruise, cruise_routes: cruiseStops}
            state.activeInfoBlock = 'cruiseDetails'
          }
        }
      })
      .addCase(GetCruisesByAdvancedSearchThunk.rejected, (state, action) => {
        if(action.payload === 'Cruises not found') state.cruises = []
      })
      .addCase(GetCruiseAvailableDatesThunk.fulfilled, (state, action) => {
        state.cruiseDateOptions = uniq(action.payload.map(opt => opt.departure_date))
      })
      .addCase(CreateCustomCruiseThunk.fulfilled, (state, action) => {
        state.customizedCruise = action.payload
      })
      .addCase(GetCustomCruiseByIdThunk.fulfilled, (state, action) => {
        state.customizedCruise = action.payload
      }) 
      .addCase(GetCustomCruiseListThunk.fulfilled, (state, action) => {
        if (action.payload.cruiseId) {
          state.customizedCruise = action.payload.cruises[0] || null
        } else {
          state.customizedCruiseList = action.payload.cruises
        }
      })
      .addCase(DeleteCustomCruiseThunk.fulfilled, (state, action) => {
        state.customizedCruiseList = state.customizedCruiseList?.filter(cruise => cruise.id !== action.payload) || []
      })  
  }
})

export const { 
  setCruises,
  setCruise,
  setIsCruiseStopsMerged,
  setActiveInfoBlock,
  setCruiseLine,
  setCruiseLineOptions,
  setCruiseDateOptions,
  setCruiseOptions,
  setSelectedStops,
  setCruiseInfoActiveTab,
  clearSelectedStops,
  setCruiseSearchType,
  setCruiseLineTypes,
  setCruiseStartLocations,
  setCruiseLengths,
  setAdvancedSearchPagination,
  setAdvancedSearchTotalCount,
  setAdvancedSearchRequestData,
  setCustomizedCruise,
  setCustomizedCruiseList
} = cruiseSlice.actions

export const selectCruises = (state: RootState): CruiseType[] | null => state.cruise.cruises
export const selectCruise = (state: RootState): CruiseType | null => state.cruise.cruise
export const selectCruiseLine = (state: RootState): CruiseLineType | null => state.cruise.cruiseLine
export const selectCruiseLineOptions = (state: RootState): CruiseLineType[] | null => state.cruise.cruiseLineOptions
export const selectCruiseDateOptions = (state: RootState): string[] | null => state.cruise.cruiseDateOptions
export const selectCruiseOptions = (state: RootState): CruiseType[] | null => state.cruise.cruiseOptions
export const selectActiveInfoBlock = (state: RootState): ActiveInfoBlockTypes => state.cruise.activeInfoBlock
export const selectCruiseInfoActiveTab = (state: RootState): 'description' | 'program' | 'photo' => state.cruise.cruiseInfoActiveTab
export const selectSelectedStops = (state: RootState): CruiseRouteType[] => state.cruise.selectedStops
export const selectCruiseSearchType = (state: RootState): CruiseSearchTypeType => state.cruise.cruiseSearchType
export const selectCruiseLineTypes = (state: RootState): {name: string, id: number}[] => state.cruise.cruiseLineTypes
export const selectCruiseStartLocations = (state: RootState): CruiseStartLocationsDataType => state.cruise.cruiseStartLocations
export const selectCruiseLengths = (state: RootState): {name: string, id: number}[] => state.cruise.cruiseLengths
export const selectAdvancedSearchPagination = (state: RootState): AdvancesSearchCruisePaginationType => state.cruise.advancedSearchPagination
export const selectAdvancedSearchTotalCount = (state: RootState): number => state.cruise.advancedSearchTotalCount
export const selectAdvancedSearchRequestData = (state: RootState): AdvancedCruiseSearchData => state.cruise.advancedSearchRequestData
export const selectCustomizedCruise = (state: RootState): CustomizedCruiseDataType | null | number => state.cruise.customizedCruise
export const selectCustomizedCruiseList = (state: RootState): CustomizedCruiseDataType[] | null => state.cruise.customizedCruiseList

export const GetCruiseByLineAndDateThunk = createAsyncThunk<{cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType} |string, {cruiseInfo: {lineId: number, date: string, name?: string, with_cruise_routes?: boolean}, sharedDetails?: SharedFlightCruiseDetailsType}, AsyncThunkConfig>(
  'cruise/getCruiseByNumberAndDate',
  async ({cruiseInfo, sharedDetails}, thunkAPI) => {
    const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
    try {
      const {data, status} = await cruiseApi.getCruiseByLineAndDate(cruiseInfo.lineId, {cruise_date: cruiseInfo.date, cruise_name: cruiseInfo?.name, with_cruise_routes: cruiseInfo?.with_cruise_routes}, requestHeaders)
      if (status === 200 && data) {
        if (data.cruises.length > 0) {
          return thunkAPI.fulfillWithValue({cruises: data.cruises, sharedDetails}, {appStatus: AppStatusType.idle})
        } else {
          return thunkAPI.rejectWithValue('No cruises found')
        }
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseLineOptionsThunk = createAsyncThunk<CruiseLineType[], {name: string, type?: number, source: any}, AsyncThunkConfig>(
  'cruise/getCruiseLineOptions',
  async (lineData, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruiseLines({name: lineData.name, typeId: lineData.type}, lineData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruise_lines, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue('')
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue('')
    }
  }
)

export const GetCruiseOptionsThunk = createAsyncThunk<CruiseType[], {name: string, without_routes: boolean, source: any}, AsyncThunkConfig>(
  'cruise/getCruiseOptions',
  async ({name, without_routes, source}, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruises(name, without_routes, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruises, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseByIdThunk = createAsyncThunk<CruiseType, number, AsyncThunkConfig>(
  'cruise/getCruiseById',
  async (id, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruiseById(id)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseLineByIdThunk = createAsyncThunk<CruiseLineType, {lineId: number, withCruises: boolean}, AsyncThunkConfig>(
  'cruise/getCruiseLineById',
  async (requestData, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruiseLineById(requestData.lineId, requestData.withCruises)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseLineTypesThunk = createAsyncThunk<{id: number, name: string}[], void, AsyncThunkConfig>(
  'cruise/getCruiseLineTypes',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruiseLineTypes()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruise_line_types, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseStartLocationsThunk = createAsyncThunk<CruiseStartLocationsDataType, {name: string, source: any}, AsyncThunkConfig>(
  'cruise/getCruiseStartLocations',
  async (searchData, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruiseStartLocations(searchData.name, searchData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseLengthsThunk = createAsyncThunk<{id: number, name: string}[], void, AsyncThunkConfig>(
  'cruise/getCruiseLengths',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruiseLengths()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruise_lengths, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruisesByAdvancedSearchThunk = createAsyncThunk<{cruises:CruiseType[], total_count: number, sharedDetails?: SharedFlightCruiseDetailsType, cruise?: CruiseType}, {cruiseInfo: AdvancedCruiseSearchRequestData, sharedDetails?: SharedFlightCruiseDetailsType}, AsyncThunkConfig>(
  'cruise/getCruisesByAdvancedSearch',
  // @ts-ignore
  async (requestData, thunkAPI) => {
    const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
    try {
      const {data, status} = await cruiseApi.getCruisesByAdvancedSearch(requestData.cruiseInfo, requestHeaders)
      let selectedCruise
      if (!!requestData?.sharedDetails?.selectedResultItemId) {
        selectedCruise = data.cruises?.find(c => String(c.id) === requestData?.sharedDetails?.selectedResultItemId) as  CruiseType
        if (!selectedCruise?.id) {
          const cruiseById = await thunkAPI.dispatch(GetCruiseByIdThunk(Number(requestData?.sharedDetails?.selectedResultItemId as string)))
          selectedCruise = cruiseById?.payload
        }
      }
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({
          cruises: data?.cruises,
          total_count: data?.total_count,
          sharedDetails: requestData?.sharedDetails,
          cruise: selectedCruise
        }, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return thunkAPI.fulfillWithValue({cruises: []}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(error?.response?.data?.message)
      }
    }
  }
)

export const GetCruisesByLineIdThunk = createAsyncThunk<
  CruiseType[],
  {
    lineId: number,
    data: {
      cruise_date?: string
      cruise_name?: string
      code_to_cruise_id?: string
      with_cruise_routes?: boolean
    },
    source: any
  },
  AsyncThunkConfig
>(
  'cruise/getCruisesByLineId',
  async (requestData, thunkAPI) => {
    const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
    try {
      const {data, status} = await cruiseApi.getCruiseByLineAndDate(requestData.lineId, requestData.data, requestHeaders, requestData?.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruises, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseAvailableDatesThunk = createAsyncThunk<CruiseStartDateType[], {cruise_line: number, cruise_id_list?: number[]}, AsyncThunkConfig>(
  'cruise/getCruiseAvailableDates',
  async (requestData, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getCruiseAvailableDates(requestData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.start_date_locations, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateCustomCruiseThunk = createAsyncThunk<CustomizedCruiseDataType, CustomizedCruiseDataType, AsyncThunkConfig>(
  'cruise/createCustomCruise',
  async (customizedData, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.createUserCustomCruise(customizedData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditCustomCruiseThunk = createAsyncThunk<CustomizedCruiseDataType, {userCustomCruiseId: number, customizedData: CustomizedCruiseDataType}, AsyncThunkConfig>(
  'cruise/editCustomCruise',
  async ({userCustomCruiseId, customizedData}, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.editUserCustomCruise(userCustomCruiseId, customizedData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCustomCruiseByIdThunk = createAsyncThunk<CustomizedCruiseDataType, number, AsyncThunkConfig>(
  'cruise/getCustomCruiseById',
  async (userCustomCruiseId, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getUserCustomCruise(userCustomCruiseId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCustomCruiseListThunk = createAsyncThunk<{cruises: CustomizedCruiseDataType[], cruiseId?: number}, number | undefined, AsyncThunkConfig>(
  'cruise/getCustomCruiseList',
  async (cruiseId, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.getUserCustomCruiseList(cruiseId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({cruises: data.cruises, cruiseId}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteCustomCruiseThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'cruise/deleteCustomCruise',
  async (customizedId, thunkAPI) => {
    try {
      const {data, status} = await cruiseApi.deleteUserCustomCruise(customizedId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(customizedId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default cruiseSlice.reducer
 