import { getBookingLink, getRentalCarsLink } from '../../../../helpers/linksHelper'
import classes from './ExternalLinksBlock.module.css'
import pricelineIcon from './../../../../img/icons/priceline.svg'
import bookingIcon from './../../../../img/icons/booking.svg'
import { NodeServiceDetails, selectActiveTab, selectNodeServiceDetails, selectSearchResults, selectTabs } from '../../../../store/searchResultsReducer'
import { useAppSelector } from '../../../../app/hooks'
import { useLocation } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { Modal, Popover } from 'antd'
import bookingWidgetIcon from './../../../../img/icons/bookingWidget.png'
import { useMediaQuery } from 'react-responsive'

const ExternalLinksBlock: React.FC<{nodeData?: NodeServiceDetails | null, dates?: [Moment, Moment]}> = ({
  nodeData,
  dates
}) => {
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const searchResults = useAppSelector(selectSearchResults)
  const {pathname} = useLocation()

  const [isBookingWidgetOpen, setIsBookingWidgetOpen] = useState(false)

  const getEventCode = () => {
    if (pathname.includes('/events/details')) {
      return pathname.split('details/')[1] 
    } else {
      return ''
    }
  }
  const getAirportIATACode = () => {
    if (nodeServiceDetails?.type === 'airport') {
      const airportData = searchResults.airports?.find(a => a.details.id === nodeServiceDetails?.id)
      return !!airportData?.details?.with_schedule_flights && airportData?.details?.code ? airportData?.details?.code : undefined
    }
  }

  const getDateAndTime = (): [Moment, Moment] => {
    const validDates = dates || activeTab?.dates
    const isStartsToday = validDates[0]?.startOf('day').isSame(moment().startOf('day'), 'day')
    validDates[0].set({hour: isStartsToday ? moment().add(3, 'hours').get('hour') : 10, minute: 0})
    return [validDates[0], validDates[1].set('hour', 10).set('minute', 0)]
  }

  return (
    <>
      <div className={classes.externalLinks}>
        <div className={classes.linkBlock}>
          <img src={bookingIcon} alt='booking'/>
          <p className={classes.linkBlockText}>Find and book hotels, B&Bs, hostels, resorts, apartments and other accommodations nearest to your destination location.</p>
          <div className={classes.linkOptionsWrapper}>
            <Popover content='Hotel Map' overlayInnerStyle={{padding: '0px 10px'}}>
              <img
                onClick={() => setIsBookingWidgetOpen(true)}
                src={bookingWidgetIcon}
                alt='Booking.com widget'
                className={classes.bookingWidgetIcon}
              />
            </Popover>
            <Popover content='Hotel List' overlayInnerStyle={{padding: '0px 10px'}}>
              <a
                target='_blank'
                rel='noreferrer'
                href={getBookingLink({
                  location: activeTab?.name,
                  dates: dates || tabs.find(t => t.name === activeTab?.name)?.dates!,
                  travelers: tabs.find(t => t.name === activeTab?.name)?.travelers!,
                  latitude: nodeData?.location?.latitude || activeTab?.coordinates?.[0],
                  longitude: nodeData?.location?.longitude || activeTab?.coordinates?.[1],
                  event_code: getEventCode()
                })}
              >
                <p className={classes.linkBlockBtn}>Search now</p>
              </a>
            </Popover>
          </div>
        </div>
        <a
          target='_blank'
          rel='noreferrer'
          href={getRentalCarsLink({
            locName: nodeData?.name || nodeServiceDetails?.name || activeTab?.cityName || activeTab?.countryName,
            lat: nodeData?.location?.latitude || nodeServiceDetails?.location?.latitude || activeTab?.coordinates?.[0],
            lng: nodeData?.location?.longitude || nodeServiceDetails?.location?.longitude || activeTab?.coordinates?.[1],
            dates: getDateAndTime(), // dates || activeTab?.dates,
            eventCode: getEventCode(),
            airportIATACode: nodeData?.type.toLowerCase() === 'terminal' ? undefined : getAirportIATACode()
          })}
          className={classes.linkBlock}
        >
          <img src={pricelineIcon} alt='priceline'/>
          <p className={classes.linkBlockText}>Find and rent cars nearest to your destination location.</p>
          <p className={classes.linkBlockBtn}>Search now</p>
        </a>
      </div>
      {isBookingWidgetOpen &&
        <BookingMapWidget
          isOpen={isBookingWidgetOpen}
          onClose={() => setIsBookingWidgetOpen(false)}
          locationName={nodeServiceDetails?.name || activeTab?.name}
          dates={dates || tabs.find(t => t.name === activeTab?.name)?.dates!}
          latitude={nodeData?.location?.latitude || activeTab?.coordinates?.[0]}
          longitude={nodeData?.location?.longitude || activeTab?.coordinates?.[1]}
        />
      }
    </>
  )
}

export const BookingMapWidget: React.FC<BookingMapWidgetPropTypes> = ({
  isOpen,
  onClose,
  locationName,
  // dates,
  latitude,
  longitude,
}) => {
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})
  // const container = document.getElementById('bookingAffiliateWidget_06cd5d15-cf1b-4697-b0df-4a498c9d0371')
  const container = document.getElementsByClassName(String(latitude)+String(longitude))

  useEffect(() => {
    if (!!container[0]) {
      const script = document.createElement('script')
      script.innerHTML = `
        (function () {
          var BookingAffiliateWidget = new Booking.AffiliateWidget({
            "iframeSettings": {
              "selector": "bookingAffiliateWidget_06cd5d15-cf1b-4697-b0df-4a498c9d0371",
              "responsive": true
            },
            "widgetSettings": {
              "ss": "${locationName}",
              "latitude": ${latitude},
              "longitude": ${longitude},
              "zoom": 12,
              "customMarkerTitle": "${locationName}"
            }
          })
        })()
      `
      script.async = true
      document.body.appendChild(script)
    }
    // eslint-disable-next-line
  }, [container])

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      closable={true}
      width={isSmallScreen? '100%' : '60%'}
      centered
      forceRender
      destroyOnClose
    >
      {/* <Spin /> */}
      <div id="bookingAffiliateWidget_06cd5d15-cf1b-4697-b0df-4a498c9d0371" className={`${classes.bookingWidgetContainer} ${String(latitude)+String(longitude)}`}>
      </div>
    </Modal>
  )
}

interface BookingMapWidgetPropTypes {
  isOpen: boolean
  onClose: () => void
  locationName: string
  dates: [Moment, Moment]
  latitude: number
  longitude: number
}

export default ExternalLinksBlock
