import { useState } from 'react'
import { Button, Form, Divider, Popover } from 'antd'
import { uniqueId } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form/Form'
import moment from 'moment'
import classes from './Search.module.css'
import BackBtn from '../../common/BackBtn/BackBtn'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import Header from '../../common/Header/Header'
import { ReactComponent as PlusIcon } from './../../../img/icons/plus.svg'
import { ReactComponent as MinusIcon } from './../../../img/icons/minus.svg'
import { ReactComponent as HelpIcon } from './../../../img/icons/helpIcon.svg'
import { InputGoogleAPI } from '../../common/InputGoogleAPI/InputGoogleAPI'
import { decodeSearchResultsExportLink, getLinkForSearch } from '../../../helpers/linksHelper'
import SearchAdditionalFields from '../../common/SearchAdditionalFields/SearchAdditionalFields'
import { ResultTabTypes, SearchAdditionalDataType } from '../../../types/searchResultTypes'
import { GetDataForResultTabsThunk } from '../../../store/searchResultsReducer'
import { useAppDispatch } from '../../../app/hooks'
import { Helmet } from 'react-helmet-async'

const Search = () => {
  const [requestFieldCount, setRequestFieldCount] = useState([uniqueId()])
  // const [hasValidationError, setHasValidationError] = useState(false)

  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  // const checkIfDataValid = () => {
  //   const searchData = form.getFieldsValue(true)
  //   for (let searchOptionKey in searchData) {
  //     if (!!searchData[searchOptionKey]?.isAddedManually && !!searchData[searchOptionKey]?.request?.length) {
  //       return false
  //     }
  //   }
  //   const searchOptionKeys = Object.keys(searchData).filter(key => searchData[key] !== undefined)
  //   if (searchOptionKeys?.length === 1 && !searchData[searchOptionKeys[0]]?.request?.length) {
  //     return false
  //   }
  //   return true
  // }

  const onSearch = () => {
    const data = form.getFieldsValue(true)
    const completedOptionsWithDates : {[key: string]: any} = {}
    const completedOptions = Object.entries(data).filter((optionArr) => optionArr[1] !== undefined)
    completedOptions.forEach((optionArr, index) => {
      const [key, value] = optionArr
      completedOptionsWithDates[key as string] = index === 0 ? value : {...value as any, dates: (value as any)?.dates || [moment(), moment().add(1, 'days')]}
    })
    const link = getLinkForSearch(completedOptionsWithDates)
    const searchData = decodeSearchResultsExportLink(link.split('/travel/results')[1])
    dispatch(GetDataForResultTabsThunk({searchRequests: searchData.locations, saveSearchTracking: true}))
      .then((r) => {
        if (!r.type.includes('rejected')) {
          const validTabsData = r.payload as {tabs: ResultTabTypes[]}
          const validSearchRequests = Object.values(completedOptionsWithDates)
            ?.filter(item => validTabsData?.tabs?.some(tab => tab.name === item.request))
            ?.reduce((acc, val, index) => {
              acc[index] = val
              return acc
            }, {})
          const updatedLink = getLinkForSearch(validSearchRequests)
          navigate(updatedLink)
        }
      })
  }

  const changeRequestFieldCount = (action: 'add' | 'remove', id?: string) => {
    if (action === 'add') {
      setRequestFieldCount([...requestFieldCount, uniqueId()])
    } else {
      setRequestFieldCount(requestFieldCount.filter((item) => item !== id))
      form.setFieldsValue({[id as string]: undefined})
    }
  }

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <meta name='description' content='Search location by address, attraction, city name'/>
        <title>Relavanti | Search by location</title>
        <link rel='canonical' href='https://www.relavanti.com/search' />
      </Helmet>
      <Header showMenu={true}/>
      <div className={classes.searchAreaWrapper}>
      <Breadcrumbs className={classes.breadcrumbs} />
        <div className={classes.search}>
          <BackBtn type='closeIcon' className={classes.closeBtn}/>
          <h1>
            Search
          </h1>
          <div className={classes.searchDescription}>
            Enter address, attraction, city name
          </div>
          <Form
            name='search'
            onFinish={onSearch}
            autoComplete='off'
            validateTrigger='onBlur'
            className={classes.form}
            form={form}
            initialValues={
              Object.fromEntries(
                requestFieldCount.map((item) => (
                  [item, {
                    request: '',
                    travelers: {adults: 1, children: 0},
                  }]
                ))
              )
            }
          >
            {requestFieldCount.map((id, index) => {
              return (
                <SearchItem
                  key={id}
                  form={form}
                  id={id}
                  changeRequestFieldCount={changeRequestFieldCount}
                  requestFieldCount={requestFieldCount} index={index}
                  // setHasValidationError={(hasError: boolean) => setHasValidationError(hasError)}
                />
            )})}
            <Form.Item className={classes.searchButtonWrapper}>
              {/* {hasValidationError &&
                <div className={classes.validationError}>
                  Make sure all locations are 
                </div>
              } */}
              <Button type='primary' htmlType='submit' className={classes.searchButton}>
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

const SearchItem: React.FC<SearchItemPropTypes> = ({form, id, changeRequestFieldCount, requestFieldCount, index}) => {
  const [data, setData] = useState<SearchAdditionalDataType>({travelers: {adults: 1, children: 0}, dates: [moment(), moment().add(1, 'days')]})

  const handleAdditionalDataChange = (updatedData: SearchAdditionalDataType) => {
    form.setFieldsValue({[id]: updatedData})
    setData(updatedData)
  }

  return (
    <div className={classes.searchRequestBlock}>
      <div className={classes.locationInput} >
        <InputGoogleAPI
          placeholder='Search by Typing / Search My Location'
          suggestionsPosition='horizontal'
          value={form.getFieldValue(id)?.request}
          onSelect={(val) => {
            form.setFieldsValue({[id]: {request: val, isAddedManually: false}})
          }}
          style={{marginBottom: '15px'}}
          addonAfter={
            <Popover
              content={
                <span style={{padding: '0px 10px'}}>
                  City, Address, Attraction or Business Name
                </span>
              }
            >
              <HelpIcon className={classes.helpIcon}/>
            </Popover>
          }
        />
        {index === requestFieldCount?.length - 1 && requestFieldCount?.length <= 3 ? (
            <PlusIcon
              className={classes.addRemoveFieldBtn}
              onClick={() => changeRequestFieldCount('add')}
            />
        ) : (
          <MinusIcon
            className={classes.addRemoveFieldBtn}
            onClick={() => changeRequestFieldCount('remove', id)}
          />
        )}
      </div>
      <div>
        <SearchAdditionalFields
          handleChange={handleAdditionalDataChange}
          values={{travelers: data?.travelers, dates: data?.dates}}
          className={classes.searchAdditionalFields}
        />
        {index === requestFieldCount?.length - 1 && requestFieldCount?.length <= 3 ? (
          <div
            onClick={() => changeRequestFieldCount('add')}
            className={classes.addRemoveFieldBtnSmallScreen}
          >
            <PlusIcon/>
            Add More
          </div>
        ) : (
          <div
            onClick={() => changeRequestFieldCount('remove', id)}
            className={classes.addRemoveFieldBtnSmallScreen}
          >
            <MinusIcon/>
            Remove
          </div>
        )}       
        {requestFieldCount.length > 1 && index < requestFieldCount.length - 1 &&
          <Divider className={classes.searchRequestBlockDivider}/>
        }
      </div>
    </div>
  )
}

interface SearchItemPropTypes {
  form: FormInstance
  id: string
  changeRequestFieldCount: (action: 'add' | 'remove', id?: string) => void
  requestFieldCount: string[]
  index: number
  // setHasValidationError: (hasError: boolean) => void
}

export default Search
