import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import classes from './FlightAdvancedSearch.module.css'
import Header from '../../../common/Header/Header'
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import BackBtn from '../../../common/BackBtn/BackBtn'
import AdvancedSearchForm from './AdvancedSearchForm/AdvancedSearchForm'
import SearchSummary from './SearchSummary/SearchSummary'

const FlightAdvancedSearch:React.FC<{showSummary: boolean}> = ({showSummary}) => {
  const navigate = useNavigate()

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <meta name='description' content='Search flight by locations'/>
        <title>Relavanti | Advanced search by flight</title>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <Header showMenu={true}/>
      <div className={classes.searchAreaWrapper}>
        <Breadcrumbs className={classes.breadcrumbs} />
        <div className={classes.search}>
          <BackBtn type='closeIcon' className={classes.closeBtn} onClick={() => navigate('/travel')}/>
          <h1>
            Flights
          </h1>
          <div className={classes.searchDescription}>
            Advanced Flight Search
            {showSummary &&
              <div style={{fontSize: '18px', color: '#191559'}}>
                Let’s check to make sure!
              </div>
            }
          </div>
          {showSummary
            ? <SearchSummary />
            : <AdvancedSearchForm />
          }
        </div>
      </div>
    </div>
  )
}

export default FlightAdvancedSearch
