import { Collapse } from 'antd'
import classes from './TerminalResults.module.css'
import { ReactComponent as TerminalsIcon } from './../../../img/icons/terminalsIcon.svg'
import { ReactComponent as GoBackIcon } from './../../../img/icons/goBack.svg'
import { ReactComponent as CollapseArrow } from './../../../img/icons/collapseArrow.svg'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectActiveServices, selectIsTerminalListOpen, selectNodeServiceDetails, selectSelectedTerminal, setIsTerminalListOpen, setSelectedTerminal } from '../../../store/searchResultsReducer'
import { ResultItem } from '../ResultComponents/ResultComponents'
import ExternalLinksBlock from '../ServiceResults/ExternalLinksBlock/ExternalLinksBlock'
import ServiceResults from '../ServiceResults/ServiceResults'
import { useState } from 'react'
import ServiceOptions from '../ServiceResults/ServiceOptions/ServiceOptions'
import { TerminalType } from '../../../types/searchResultTypes'
import PublicTransportStops from '../../common/PublicTransportStops/PublicTransportStops'
import ServicesAvailableString from '../../common/ServicesAvailableString/ServicesAvailableString'

const TerminalResults: React.FC<{terminals?: TerminalType[], wrapperStyle?: object}> = ({terminals, wrapperStyle}) => {
  const dispatch = useAppDispatch()
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const activeServices = useAppSelector(selectActiveServices)
  const isTerminalListOpen = useAppSelector(selectIsTerminalListOpen)

  const terminalsData = terminals || nodeServiceDetails?.terminals

  if (activeServices.includes('Terminals') || !!terminals?.length) {
    return (
      <div className={classes.collapseBtnWrapper} style={wrapperStyle}>
        <Collapse
          expandIconPosition='right'
          ghost
          expandIcon={({ isActive }) => (
            <CollapseArrow
              style={{
                rotate: isActive ? '90deg' : '0deg',
                right: isActive ? '15px' : '0px',
                top: isActive? '20px' : '50%'
              }}
            />
          )}
          activeKey={isTerminalListOpen ? ['1'] : []}
          onChange={() => dispatch(setIsTerminalListOpen(!isTerminalListOpen))}
        >
          <Collapse.Panel
            header={
              <div style={{display: 'flex', alignItems: 'center', marginLeft: '-15px', fontSize: '16px'}}>
                <TerminalsIcon style={{marginRight: '7px'}}/>
                Terminals ({terminalsData?.length} {terminalsData?.length === 1 ? 'Terminal' : 'Terminals'})
              </div>
            }
            key='1'
          >
            {terminalsData?.map((terminal) => (
              <ResultItem
                key={terminal.id}
                item={{
                  name: terminal.name,
                  id: terminal.id,
                  details: '',
                  type: 'terminal',
                  location: {latitude: terminal?.latitude, longitude: terminal.longitude},
                }}
                isNodeDetails={true}
              />
            ))}
          </Collapse.Panel>
        </Collapse>
      </div>
    )
  } else {
    return (<></>)
  }
}

export const TerminalDetails: React.FC<{onGoBack?: () => void}> = ({onGoBack}) => {
  const dispatch = useAppDispatch()
  const selectedTerminal = useAppSelector(selectSelectedTerminal)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)

  const [isServiceDataLoading, setIsServiceDataLoading] = useState(true)

  return (
    <>
      <ServiceOptions />
      <div className={classes.blockWrapper}>
        <div
          onClick={() => {
            dispatch(setSelectedTerminal(null))
            !!onGoBack && onGoBack()
          }}
          className={classes.goBackBtn}
        >
          <GoBackIcon /> Back to {nodeServiceDetails?.type || 'terminals list'}
        </div>
        <h2 style={{marginTop: '7px'}}>
          {selectedTerminal?.name}
        </h2>
        <ServicesAvailableString name={selectedTerminal?.name || 'terminal'} />
        <ExternalLinksBlock nodeData={{...selectedTerminal!, type: 'Terminal'}}/>
      </div>
        <PublicTransportStops
          nodeId={selectedTerminal?.id}
        />
      <div className={classes.resultList}>
        <ServiceResults
          nodeId={selectedTerminal?.id}
          nodeLocation={selectedTerminal?.location}
          isServiceDataLoading={isServiceDataLoading}
          setIsServiceDataLoading={setIsServiceDataLoading}
        />
      </div>
    </>
  )
}

export default TerminalResults
