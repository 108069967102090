import moment from 'moment'
import TripNumber from '../TripNumber/TripNumber'
import classes from './ResultList.module.css'
import {ReactComponent as FiltersIcon} from './../../../../img/icons/filters.svg'
import CruiseAdvancedSearch from '../../ByCruise/CruiseAdvancedSearch/CruiseAdvancedSearch'
import { useState } from 'react'
import { Button, Modal } from 'antd'
import {ReactComponent as ShipIcon} from './../../../../img/icons/shipIcon.svg'
import {ReactComponent as PlaneIcon} from './../../../../img/icons/planeIcon.svg'
import { setCruiseStartLocations } from '../../../../store/cruiseReducer'
import { useAppDispatch } from '../../../../app/hooks'

const ResultList:React.FC<ResultListPropTypes> = ({
  children,
  title,
  searchData,
  goBackLink,
  imageType,
  logo,
  logoLink,
  showAdvancedFilters,
  isLoading,
  searchType,
}) => {
  const dispatch = useAppDispatch()
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(true)

  return (
    <>
      <TripNumber
        tripNumber={`${searchData.number}`}
        secondaryInfo='Date of departure'
        primaryInfo={Array.isArray(searchData.date)
          ? searchData.date[0] === undefined
            ? 'No specific date provided'
            : moment(searchData.date[0], 'YYYY-MM-DD').format('dd DD MMM YYYY') + ' - ' + moment(searchData.date[1], 'YYYY-MM-DD').format('dd DD MMM YYYY')
          : moment(searchData.date, 'YYYY-MM-DD').format('dd DD MMM YYYY')
        }
        goBackLink={goBackLink}
        imageType={imageType || null}
        logo={logo}
        logoLink={logoLink}
      />
      <div className={classes.listTitle}>
        {title}
        {showAdvancedFilters && !isLoading &&
          <div className={classes.advancedFilterBtn} onClick={() => setIsFiltersModalOpen(true)}>
            <FiltersIcon />Filter
          </div>
        }
      </div>
      {isFiltersModalOpen &&
        <CruiseAdvancedSearch
          isFiltersModalOpen={isFiltersModalOpen}
          onModalClose={() => {
            setIsFiltersModalOpen(false)
            dispatch(setCruiseStartLocations({regions: [], locations: []}))
          }}
        />
      }
      {isWarningModalOpen &&
        <WarningModal
          searchType={searchType}
          onModalClose={() => setIsWarningModalOpen(false)}
        />
      }
      {children}
    </>
  )
}

const WarningModal: React.FC<{searchType: 'byFlight' | 'byCruise', onModalClose: () => void}> = ({onModalClose, searchType}) => {
  return (
    <Modal
      closable={false}
      visible={true}
      footer={null}
      width='600px'
      centered
    >
      <div className={classes.warningModal}>
        {searchType === 'byCruise' && <ShipIcon />}
        {searchType === 'byFlight' && <PlaneIcon />}
        <div className={classes.warningModalTitle}>
          Important Warning:
        </div>
        <div className={classes.warningModalTitle}>
          Possible Inaccuracies in {searchType === 'byCruise' ? 'Cruise' : 'Flight'} Data 
        </div>
        {searchType === 'byCruise' ? (
          <>
          <div style={{marginTop: '15px'}} className={classes.warningModalText}>
            We feel it's crucial to bring to your attention the possibility of inaccuracies in the cruise data displayed. While we're working diligently to maintain the highest level of accuracy, there's a chance that some information might not be entirely correct at the moment.
          </div>
          <div style={{marginTop: '20px'}} className={classes.warningModalText}>
            Your cruise experience matters greatly to us, and we recommend exercising caution and verifying details independently before making any firm plans based on the current data.
          </div>
          </>
        ) : (
          <>
          <div style={{marginTop: '15px'}} className={classes.warningModalText}>
            We feel it's important to make you aware of the possibility of inaccuracies in the flight data displayed. While we work diligently to maintain the highest level of accuracy, there is a possibility that some information may not be completely accurate at this time.
          </div>
          <div style={{marginTop: '20px'}} className={classes.warningModalText}>
            Your flight experience is important to us, and we encourage you to exercise caution and independently verify details before making any firm plans based on the current data.
          </div>
          </>
        )}
        <div style={{marginTop: '20px'}} className={classes.warningModalText}>
          Thank you for your understanding and cooperation.
        </div>
        <Button type='primary' onClick={onModalClose}>
          Understand
        </Button>
      </div>
    </Modal>
  )
}

interface ResultListPropTypes {
  children: any
  title: string
  searchData: {
    number: string
    date: string | [string, string]
  }
  goBackLink: string
  imageType?: 'ship' | 'plane' | null
  logo?: string
  logoLink?: string
  showAdvancedFilters?: boolean
  isLoading?: boolean
  searchType: 'byFlight' | 'byCruise'
}

export default ResultList
