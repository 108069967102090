import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { portAPI } from '../app/api'
import { NearPortType, PortCruisesFilterStateType, PortType } from '../types/portTypes'
import { ResultTabTypes } from '../types/searchResultTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, PaginationType, RootState } from './../types/appTypes'
import { GetUserIPThunk } from './userReducer'
import { CancelTokenSource } from 'axios'
import { CruiseType } from '../types/cruiseTypes'
import { getRequestHeaders } from '../helpers/axiosHelper'
import { WidgetLocationType } from '../types/widgetTypes'

interface InitialStateType {
  nearPorts: NearPortType[]
  portOptions: PortType[] | null

  portCruises: CruiseType[] | null
  portCruisesTotalCount: number
  portCruisesFilters: {filter: PortCruisesFilterStateType, pagination: PaginationType}
}

export const defaultPortCruisesFilters = {
  filter: {
    visited_port_id_list: [],
    avoid_port_id_list: [],
    locations: [],
    search_type: 'CRUISE_NAME' as 'CRUISE_NAME',
    search_value: ''
  },
  pagination: {
    page: 1,
    size: 10,
    sort: [{field: 'name', direction: 'ASC' as 'ASC'}]
  }
}

const initialState: InitialStateType = {
  nearPorts: [],
  portOptions: null,

  portCruises: null,
  portCruisesTotalCount: 0,
  portCruisesFilters: defaultPortCruisesFilters
}

export const portSlice = createSlice({
  name: 'port',
  initialState,
  reducers: {
    setNearPorts: (state, action: PayloadAction<NearPortType[]>) => {state.nearPorts = action.payload},
    setPortOptions: (state, action: PayloadAction<PortType[] | null>) => {state.portOptions = action.payload},
    setPortCruises: (state, action: PayloadAction<CruiseType[] | null>) => {state.portCruises = action.payload},
    setPortCruisesTotalCount: (state, action: PayloadAction<number>) => {state.portCruisesTotalCount = action.payload},
    setPortCruisesFilters: (state, action: PayloadAction<PortCruisesFilterStateType>) => {state.portCruisesFilters = {...state.portCruisesFilters, filter: action.payload}},
    setPortCruisesFiltersPagination: (state, action: PayloadAction<PaginationType>) => {state.portCruisesFilters = {...state.portCruisesFilters, pagination: action.payload}},
    setPortCruisesFiltersData: (state, action: PayloadAction<{filter: PortCruisesFilterStateType, pagination: PaginationType}>) => {state.portCruisesFilters = action.payload},
    setPortCruisesFiltersToDefault: (state) => {state.portCruisesFilters = defaultPortCruisesFilters},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPortsByCityThunk.fulfilled, (state, action) => {
        state.nearPorts = action.payload
      })
      .addCase(GetPortsByNameOrCodeThunk.fulfilled, (state, action) => {
        state.portOptions = action.payload
      })
      .addCase(GetCruisesForPortThunk.fulfilled, (state, action) => {
        state.portCruises = action.payload.cruises
        state.portCruisesTotalCount = action.payload.total_count
      })
  }
})

export const {
  setNearPorts,
  setPortOptions,
  setPortCruises,
  setPortCruisesTotalCount,
  setPortCruisesFilters,
  setPortCruisesFiltersPagination,
  setPortCruisesFiltersData,
  setPortCruisesFiltersToDefault
} = portSlice.actions

export const selectNearPorts = (state: RootState): NearPortType[] => state.ports.nearPorts
export const selectPortOptions = (state: RootState): PortType[] | null => state.ports.portOptions
export const selectPortCruises = (state: RootState): CruiseType[] | null => state.ports.portCruises
export const selectPortCruisesTotalCount = (state: RootState): number => state.ports.portCruisesTotalCount
export const selectPortCruisesFilters = (state: RootState): {filter: PortCruisesFilterStateType, pagination: PaginationType} => state.ports.portCruisesFilters

export const GetPortsByCityThunk = createAsyncThunk<NearPortType[], {activeTabData?: ResultTabTypes, widgetLocation?: WidgetLocationType, source?: CancelTokenSource}, AsyncThunkConfig>(
  'ports/getPortsByCity',
  async ({activeTabData, widgetLocation, source}, thunkAPI) => {
    try {
      const userLocationHeader = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const requestHeaders = {
        ...userLocationHeader,
        ...(widgetLocation ? {Authorization: false} : {})
      }
      const requestData = {
        country_code: (activeTabData?.country_code || widgetLocation?.country_code) as string,
        city_name: activeTabData?.city_name || widgetLocation?.city_name,
        start_location: {
          latitude: (activeTabData?.coordinates?.[0] || widgetLocation?.coordinates?.[0]) as number,
          longitude: (activeTabData?.coordinates?.[1] || widgetLocation?.coordinates?.[1]) as number
        },
        ...activeTabData?.activeFilters?.ports || {}
      }
      const {data, status} = await portAPI.getPortsByCity(requestData, requestHeaders, source)
      if (status === 200 && data) {
        const portsAsNearPorts = data.ports.map(port => ({
          details: port,
          distance: 0,
          unit: 'KM',
          advertising_list: port?.advertising_list || []
        }))
        return thunkAPI.fulfillWithValue(portsAsNearPorts, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetPortsByNameOrCodeThunk = createAsyncThunk<PortType[], {name: string, source?: CancelTokenSource}, AsyncThunkConfig>(
  'ports/getPortsByNameOrCode',
  async ({name, source}, thunkAPI) => {
    try {
      const {data, status} = await portAPI.getPortsByNameOrCode(name, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.ports, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruisesForPortThunk = createAsyncThunk<{cruises: CruiseType[], total_count: number}, {portId: number, filtersData: {filter: PortCruisesFilterStateType, pagination: PaginationType}, source?: CancelTokenSource}, AsyncThunkConfig>(
  'ports/getCruisesForPort',
  async ({portId, filtersData, source}, thunkAPI) => {
    try {
      const filtersWithIdArrays = {
        ...filtersData.filter,
        visited_port_id_list: filtersData.filter.visited_port_id_list?.map(port => port.value) || [],
        avoid_port_id_list: filtersData.filter.avoid_port_id_list?.map(port => port.value) || [],
        locations: filtersData.filter.locations?.map(loc => ({latitude: loc.latitude, longitude: loc.longitude})) || [],
      }
      const formData = new FormData()
      formData.append('filter', new Blob([JSON.stringify(filtersWithIdArrays, null, 2)], {type: 'application/json'}))
      formData.append('pagination', new Blob([JSON.stringify(filtersData.pagination, null, 2)], {type: 'application/json'}))
      const {data, status} = await portAPI.getCruisesForPort(portId, formData, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default portSlice.reducer
 