import { copyToClipboard } from '../../../../../helpers/linksHelper'
import classes from './../EventCard.module.css'
import { ReactComponent as CopyIcon } from './../../../../../img/icons/copy.svg'

const Code: React.FC<{code: string}> = ({code}) => {
  return (
    <div className={classes.code} onClick={() => copyToClipboard(code, 'Code copied successfully')}>
      Code: <span>{code}</span>
      <CopyIcon />
    </div>
  )
}

export default Code
