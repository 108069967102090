import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { defaultActiveFilters } from '../helpers/searchFiltersHelper'
import { AirlineType, AirportSearchResultType } from '../types/airportTypes'
import { AsyncThunkCustomConfig, SharedDetailsType } from '../types/appTypes'
import { BusStationSearchResultType } from '../types/busTypes'
import { PortSearchResultType } from '../types/portTypes'
import { RailwayStationSearchResultType } from '../types/railwayTypes'
import { ActiveFiltersType, ResultTabTypes, SearchRequestDataType, SearchResultType, TerminalType } from '../types/searchResultTypes'
import { NodeServiceResultType } from '../types/serviceTypes'
import { SearchGeneralTrackingType } from '../types/userSearchTypes'
import { GetAirportsByCityThunk } from './airportReducer'
import { AppStatusType } from './appStatusReducer'
import { GetBusesByCityThunk } from './busReducer'
import { GetPortsByCityThunk } from './portReducer'
import { GetRailwaysByCityThunk } from './railwayReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { GetTransportStopByKeyThunk } from './transportStopsReducer'
import { SaveSearchTrackingThunk } from './userSearchReducer'
import { AdvertisingType } from '../types/advertisingTypes'
import { CruiseType } from '../types/cruiseTypes'
import { getSearchItemAdditionalData, getSharedDetail } from '../helpers/linksHelper'

export interface NodeServiceDetails {
  id: number
  name: string
  type: string
  location: {
    latitude: number
    longitude: number
  }
  terminals?: TerminalType[]
  airlines?: AirlineType[]
  cruises?: CruiseType[]
  advertising_list?: AdvertisingType[]
  cruise_count?: number
  category?: number
}
export type ActiveResultBlockType = 'Activities' | 'Services' | 'Nodes' | 'NodeDetails' | 'TransportStopDetails' | 'TerminalDetails'

export const resultCategories = [
  {name: 'Nearby', value: 'near', id: '1'},
  {name: 'Air', value: 'airports', id: '2'},
  {name: 'Sea', value: 'ports', id: '3'},
  {name: 'Rail', value: 'railways', id: '4'},
  {name: 'Bus', value: 'buses', id: '5'},
  // {name: 'Car', value: 'cars', id: '6'},
]

interface InitialStateType {
  tabs: ResultTabTypes[]
  activeTab: ResultTabTypes
  activeResultCategories: string[]
  activeServices: string[]
  activeResultBlock: ActiveResultBlockType

  searchResults: SearchResultType
  nodeServiceDetails: NodeServiceDetails | null
  selectedTransportStop: NodeServiceResultType | null
  selectedTerminal: {id: number, location: {latitude: number, longitude: number}, name: string} | null
  selectedTransportStops: NodeServiceResultType[]

  isTerminalListOpen: boolean
}

const initialState: InitialStateType = {
  tabs: [],
  activeTab: {} as ResultTabTypes,
  activeResultCategories: ['near'],
  activeServices: [],
  activeResultBlock: 'Services',

  searchResults: {airports: null, ports: null, railways: null, buses: null},
  nodeServiceDetails: null,
  selectedTransportStop: null,
  selectedTerminal: null,
  selectedTransportStops: [],
  
  isTerminalListOpen: false
}

export const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState,
  reducers: {
    setTabs: (state, action: PayloadAction<ResultTabTypes[]>) => {state.tabs = action.payload},
    setActiveTab: (state, action: PayloadAction<ResultTabTypes>) => {
      state.activeTab = action.payload
      state.searchResults = {airports: null, ports: null, railways: null, buses: null}
    },
    modifyActiveTab: (state, action: PayloadAction<ResultTabTypes>) => {
      state.activeTab = action.payload
      state.tabs = state.tabs.map(tab => tab.name === action.payload.name ? action.payload : tab)
    },
    setTabActiveFilters: (state, action: PayloadAction<ActiveFiltersType>) => {
      state.activeTab = {...state.activeTab, activeFilters: action.payload}
      state.tabs = state.tabs.map(tab => tab.name === state.activeTab.name ? {...tab, activeFilters: action.payload} : tab)
    },
    setActiveResultCategories: (state, action: PayloadAction<string[]>) => {
      state.activeResultCategories = action.payload
      state.selectedTransportStops = []
      state.selectedTransportStop = null
      if (action.payload[0] === 'near') {
        state.activeResultBlock = 'Services'
        state.selectedTerminal = null
      } else {
        state.activeResultBlock = 'Nodes'
      }
    },
    setActiveServices: (state, action: PayloadAction<string[]>) => {state.activeServices = action.payload},
    addTerminalsToActiveServices: (state) => {state.activeServices = [...state.activeServices, 'Terminals']},
    setActiveResultBlock: (state, action: PayloadAction<ActiveResultBlockType>) => {state.activeResultBlock = action.payload},
    setSearchResults: (state, action: PayloadAction<SearchResultType>) => {state.searchResults = action.payload},

    removeTab: (state, action: PayloadAction<string>) => {state.tabs = state.tabs.filter(tab => tab.name !== action.payload)},
    resetTabsData: (state) => {return state = initialState},

    setNodeServiceDetails: (state, action: PayloadAction<NodeServiceDetails | null>) => {
      state.nodeServiceDetails = action.payload
      if (!!action.payload) {
        state.activeResultBlock = 'NodeDetails'
      }
    },
    setSelectedTransportStop: (state, action: PayloadAction<NodeServiceResultType | null>) => {state.selectedTransportStop = action.payload},
    setSelectedTerminal: (state, action: PayloadAction<{id: number, location: {latitude: number, longitude: number}, name: string}  | null>) => {
      state.selectedTerminal = action.payload
      state.activeResultBlock = !!action.payload ? 'TerminalDetails' : 'NodeDetails'
    },
  
    setSelectedTransportStops: (state, action: PayloadAction<NodeServiceResultType[]>) => {
      state.selectedTransportStops = action.payload
      if (!!action.payload?.length) {
        state.activeResultBlock = 'TransportStopDetails'
      } else if (!!state.selectedTerminal) {
        state.activeResultBlock = 'TerminalDetails'
      } else {
        state.activeResultCategories[0] === 'near' ? state.activeResultBlock = 'Services' : state.activeResultBlock = 'NodeDetails'
      }
    },
    setIsTerminalListOpen: (state, action: PayloadAction<boolean>) => {state.isTerminalListOpen = action.payload},
    clearSearchData: (state) => {
      state.tabs = []
      state.activeTab = {} as ResultTabTypes
      state.activeResultCategories = ['near']
      state.activeServices = []
      state.activeResultBlock = 'Services'
      state.searchResults = {airports: null, ports: null, railways: null, buses: null}
      state.nodeServiceDetails = null
      state.selectedTransportStop = null
      state.selectedTerminal = null
      state.selectedTransportStops = []
      state.isTerminalListOpen = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetDataForResultTabsThunk.fulfilled, (state, action) => {
        state.tabs = action.payload.tabs
        state.activeTab = action.payload.newActiveTab !== undefined ? action.payload.newActiveTab : action.payload.tabs[0]
      })
      .addCase(GetDataForResultTabsFromSharedLinkThunk.fulfilled, (state, action) => {
        state.tabs = action.payload.tabs
        state.activeTab = action.payload.sharedDetails.activeTab
        state.activeResultCategories = action.payload.sharedDetails.activeResultCategories
        state.activeResultBlock = action.payload.sharedDetails.activeResultBlock
      })
      .addCase(AddResultTabThunk.fulfilled, (state, action) => {
        if (state.activeResultBlock === 'NodeDetails') {
          state.activeResultBlock = 'Nodes'
        }
        state.nodeServiceDetails = null
        state.tabs = [...state.tabs, action.payload]
        state.activeResultCategories = ['near']
      })
      .addCase(GetAirportsByCityThunk.fulfilled, (state, action) => {
        const resultsWithType = action.payload.map(airport => ({...airport, type: 'airport'})) as AirportSearchResultType[] 
        state.searchResults = {...state.searchResults, airports: resultsWithType}
        if (window.location.search.includes('-shared&') && window.location.search.includes('&node-id=')) {
          const id = getSharedDetail(window.location.search, 'node-id=')
          const nodeDetails = Object.values(state.searchResults).flat(1).find(val => val?.details.id === Number(id))
          const a = {
            id: nodeDetails?.details?.id!,
            name: nodeDetails?.details?.name!,
            type: nodeDetails?.type!,
            location: {
              latitude: nodeDetails?.details?.latitude!,
              longitude: nodeDetails?.details?.longitude!,
            },
            terminals: nodeDetails?.details?.terminals,
            // @ts-ignore
            airlines: nodeDetails?.details?.airlines
          }
          state.nodeServiceDetails = a
        }
      })
      .addCase(GetPortsByCityThunk.fulfilled, (state, action) => {
        const resultsWithType = action.payload.map(port => ({...port, type: 'port'})) as PortSearchResultType[] 
        state.searchResults = {...state.searchResults, ports: resultsWithType}
      })
      .addCase(GetBusesByCityThunk.fulfilled, (state, action) => {
        const resultsWithType = action.payload.map(bus => ({...bus, type: 'bus'})) as BusStationSearchResultType[] 
        state.searchResults = {...state.searchResults, buses: resultsWithType}
      })
      .addCase(GetRailwaysByCityThunk.fulfilled, (state, action) => {
        const resultsWithType = action.payload.map(railway => ({...railway, type: 'railway'})) as RailwayStationSearchResultType[] 
        state.searchResults = {...state.searchResults, railways: resultsWithType}
      })
      .addCase(GetTransportStopByKeyThunk.fulfilled, (state, action) => {
        const transportStopData = {
          name: action.payload.stop_name,
          id: action.payload.id,
          serviceType: 'stop',
          location: action.payload.location,
        }
        state.selectedTransportStop = transportStopData
        state.selectedTransportStops = [transportStopData]
      })   
  }
})

export const {
  setTabs,
  setActiveTab,
  modifyActiveTab,
  setTabActiveFilters,
  setActiveResultCategories,
  setActiveServices,
  addTerminalsToActiveServices,
  setActiveResultBlock,
  setSearchResults,
  removeTab,
  resetTabsData,
  setNodeServiceDetails,
  setSelectedTransportStop,
  setSelectedTerminal,
  setSelectedTransportStops,
  setIsTerminalListOpen,
  clearSearchData,
} = searchResultsSlice.actions

export const selectActiveResultCategories = (state: RootState): string[] => state.searchResults.activeResultCategories
export const selectActiveServices = (state: RootState): string[] => state.searchResults.activeServices
export const selectTabs = (state: RootState): ResultTabTypes[] => state.searchResults.tabs
export const selectActiveTab = (state: RootState): ResultTabTypes => state.searchResults.activeTab
export const selectActiveResultBlock = (state: RootState): ActiveResultBlockType => state.searchResults.activeResultBlock
export const selectSearchResults = (state: RootState): SearchResultType => state.searchResults.searchResults
export const selectNodeServiceDetails = (state: RootState): NodeServiceDetails | null => state.searchResults.nodeServiceDetails
export const selectSelectedTransportStop = (state: RootState): NodeServiceResultType | null => state.searchResults.selectedTransportStop
export const selectSelectedTerminal = (state: RootState): {id: number, location: {latitude: number, longitude: number}, name: string}  | null => state.searchResults.selectedTerminal
export const selectSelectedTransportStops = (state: RootState): NodeServiceResultType[] => state.searchResults.selectedTransportStops
export const selectIsTerminalListOpen = (state: RootState): boolean => state.searchResults.isTerminalListOpen

export const GetDataForResultTabsThunk = createAsyncThunk<{tabs: ResultTabTypes[], newActiveTab?: ResultTabTypes}, {searchRequests: string[], saveSearchTracking?: boolean, newActiveTab?: ResultTabTypes}, AsyncThunkCustomConfig>(
  'searchResults/getDataForResultTabs',
  async ({searchRequests, saveSearchTracking, newActiveTab}, thunkAPI) => {
    try {
      const saveSearchTrackingFunc = 
      saveSearchTracking
        ? (data: SearchGeneralTrackingType) => thunkAPI.dispatch(SaveSearchTrackingThunk(data))
        : undefined
      // @ts-ignore
      const tabs = await getDataForTabs(searchRequests, saveSearchTrackingFunc)
      const isEmptySearch = searchRequests?.length === 1 && searchRequests[0] === ''
      if (tabs !== null) {
        const allTabsValid = searchRequests.length === tabs?.length
        const invalidTabs = searchRequests
          .map(req => getSearchItemAdditionalData(req))
          .filter(req => !tabs?.some(tab => tab.name === req.location))
        return thunkAPI.fulfillWithValue({tabs, newActiveTab}, {appStatus: allTabsValid ? AppStatusType.idle : AppStatusType.info, ...(allTabsValid ? {} : {infoMessage: `No result for ${invalidTabs.map(t => t.location).join(', ')} location is found`})})
      } else {
        return thunkAPI.rejectWithValue(isEmptySearch ? '' : 'Can\'t find results for selected location', {appStatus: isEmptySearch ? AppStatusType.info : AppStatusType.failed, infoMessage: isEmptySearch ? 'Select at least one location!' : ''})
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error === 'ZERO_RESULTS' ? 'Can\'t find results for selected location' : error?.response?.data?.message, {appStatus: AppStatusType.failed})
    }
  }
)

export const GetDataForResultTabsFromSharedLinkThunk = createAsyncThunk<
  {
    tabs: ResultTabTypes[],
    sharedDetails: {
      activeTab: ResultTabTypes
      activeResultCategories: string[]
      activeResultBlock: ActiveResultBlockType,
      selectedNode?: string,
      selectedTransportStop?: string,
    }
  },
  {locations: string[], additionalInfo: SharedDetailsType},
  AsyncThunkConfig
> (
  'searchResults/getDataForResultTabsFromSharedLink',
  async ({locations, additionalInfo}, thunkAPI) => {
    try {
      if (!!additionalInfo.selectedTransportStop) {
        thunkAPI.dispatch(GetTransportStopByKeyThunk(Number(additionalInfo.selectedTransportStop)))
      }
      const tabs = await getDataForTabs(locations)
      if (tabs !== null) {
        const allTabsValid = locations.length === tabs?.length
        const invalidTabs = locations
          .map(req => getSearchItemAdditionalData(req))
          .filter(req => !tabs?.some(tab => tab.name === req.location))
        return thunkAPI.fulfillWithValue({
          tabs,
          sharedDetails: {
            activeTab: tabs.find(tab => tab.name === additionalInfo.activeTabName)!,
            activeResultCategories: additionalInfo.activeResultCategories,
            activeResultBlock: additionalInfo.activeResultBlock,
            selectedNode: additionalInfo.selectedNodeId,
            selectedTransportStop: additionalInfo.selectedTransportStop
          }
        }, {appStatus: allTabsValid ? AppStatusType.idle : AppStatusType.info, ...(allTabsValid ? {} : {infoMessage: `No result for ${invalidTabs.map(t => t.location).join(', ')} location is found`})})
      } else {
        return thunkAPI.rejectWithValue('Can\'t find results for selected location')
      }   
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const AddResultTabThunk = createAsyncThunk<ResultTabTypes, string, AsyncThunkConfig>(
  'searchResults/addResultTab',
  async (tabName, thunkAPI) => {
    try {
      const saveSearchTrackingFunc = (data: SearchGeneralTrackingType) => thunkAPI.dispatch(SaveSearchTrackingThunk(data))
      // @ts-ignore
      const newTabData = await getDataForTabs([tabName], saveSearchTrackingFunc)
      if (newTabData !== null) {
        return thunkAPI.fulfillWithValue(newTabData?.[0]!, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(`No result for ${tabName} location is found`)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)
// @ts-ignore
const getDataForTabs = async(locations: string[], saveSearchTracking?: (data: SearchGeneralTrackingType) => Promise<PayloadAction<number, string, any>>): Promise<ResultTabTypes[]> | null => {
  const searchItemsData = locations.map(req => getSearchItemAdditionalData(req))
  const geocodeData = async(searchItem: SearchRequestDataType) => {
    try {
      const geocode = await geocodeByAddress(searchItem?.location)
      return {searchData: searchItem, geocode: geocode}
    } catch (e) {
      return null
    }
  }
  const addressData = await Promise.all(searchItemsData?.map((request) => geocodeData(request)))
  const addressDataValidOnly = addressData?.filter(item => item !== null) as Array<{searchData: SearchRequestDataType, geocode: google.maps.GeocoderResult[]}>
  if (!addressDataValidOnly?.length) {
    return null
  }
 
  const coordinates: google.maps.LatLngLiteral[] = await Promise.all(addressDataValidOnly.map(d => d.geocode[0]).flat(1)?.map(address => getLatLng(address)))
  const tabs =  await Promise.all(coordinates?.map(async(coordinates, index) => {
    const addresses: google.maps.GeocoderResult[] = addressDataValidOnly.map(item => item.geocode).flat(1)
    const country_code = addresses[index].address_components.find(address => address.types.includes('country'))?.short_name || ''
    const country_name = addresses[index].address_components.find(address => address.types.includes('country'))?.long_name || ''
    const countryData = country_name === addressDataValidOnly[index]?.searchData?.location ? [] : await geocodeByAddress(country_name)
    const city_name = addresses[index].address_components.find(address => address.types.includes('locality'))?.long_name || ''
    
    if (!!saveSearchTracking) {
      saveSearchTracking({
        searched_address: addressDataValidOnly[index]?.searchData?.location || '',
        searched_city: city_name,
        searched_state: addresses[0].address_components.find(address => address.types.includes('administrative_area_level_1'))?.long_name || '',
        searched_country: addresses[0].address_components.find(address => address.types.includes('country'))?.long_name || '',
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        searched_type: 'Search'
      })
    }

    return {
      coordinates: [coordinates.lat, coordinates.lng] as [number, number],
      country_code,
      city_name,
      name: addressDataValidOnly[index]?.searchData?.location || '',
      results: null,
      activeFilters: defaultActiveFilters,
      travelers: isNaN(addressDataValidOnly[index]?.searchData?.travelers?.adults) ? {adults: 1, children: 0} : addressDataValidOnly[index]?.searchData?.travelers,
      dates: addressDataValidOnly[index]?.searchData.dates,
      placeId: addresses[index].place_id,
      countryPlaceId: countryData?.[0]?.place_id,
      countryName: country_name,
      cityName: city_name
    }
  }))
  return tabs
}

export default searchResultsSlice.reducer
 