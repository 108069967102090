import Footer from '../common/Footer/Footer'
import Header from '../common/Header/Header'
import classes from './AdvertisingPage.module.css'
import { ReactComponent as AdvertisingInNodesIcon } from './../../img/advertisingPage/advertisingInNodesIcon.svg'
import { ReactComponent as TargetingAudienceIcon } from './../../img/advertisingPage/targetingAudienceIcon.svg'
import { ReactComponent as PointCheckIcon } from './../../img/advertisingPage/pointCheckIcon.svg'
import advertisingInNodesImg from './../../img/advertisingPage/advertisingInNodes.webp'
import targetingAudienceImg from './../../img/advertisingPage/targetingAudience.webp'
import GoToTopBtn from '../common/GoToTopBtn/GoToTopBtn'
import { Helmet } from 'react-helmet-async'

const AdvertisingPage = () => {
  const explanationBlocks = [
    {
      title: 'Targeting Your Audience',
      description: 'You can target your audience according to the user’s location (global, country, city) and/or according to the location(s) searched by the user:',
      points: [
        'In a specific country (i.e., a location in Greece).',
        'In a specific city (i.e., a location in Mumbai).',
        'Within a specified radius around a specific address, Lat/Lon coordinates or node of transportation, such as an airport (i.e., within 100 km radius of the Eiffel Tower or Tilbury Port).',
        'Within an area defined by four sets of Lat/Lon coordinates.'
      ],
      icon: TargetingAudienceIcon,
      img: targetingAudienceImg
    },
    {
      title: 'Advertising in Nodes',
      description: 'A node is defined as a point of transportation: seaports, rail and bus stations and airports. The nodes are displayed to the user first as a listing and with more information when clicking to see the “Details” of each node in the list. You can advertise at nodes in several ways:',
      points: [
        'In the list view, as part of the node information.',
        'In the specific node details area (when the user clicks on “Details”).',
        'In the details of a Terminal at a specific node (i.e., when the user looks at the details of Terminal 5 at JFK International Airport).'
      ],
      icon: AdvertisingInNodesIcon,
      img: advertisingInNodesImg
    },
  ]
  return (
    <>
      <div className={classes.wrapper}>
        <Helmet>
          <title>Relavanti | Advertising</title>
          <meta property="og:title" content={'Relavanti | Advertising'} />
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header showMenu={true}/>
        <GoToTopBtn />
        <div className={classes.contentWrapper}>
          <h1>
            Advertising on Relavanti.com
          </h1>
          <div className={classes.infoBlock}>
            <h2>
              Type of Advertisement
            </h2>
            <div>
              You can choose to advertise your service or product with text or a banner.
            </div>
          </div>

          <div>
            {explanationBlocks.map((block, index) => (
               <div
                className={`${classes.explanationBlock}  ${index % 2 === 0 ? classes.rightImage : classes.leftImage}`}
                key={block.title}
              >
                <img src={block.img} alt='Targeting Your Audience' />
                <div className={classes.explanationText}>
                  <block.icon />
                  <h2>
                    {block.title}
                  </h2>
                  <div className={classes.description}>
                    {block.description}
                  </div>
                  <div>
                    {block.points.map(point => (
                      <div className={classes.point}>
                        <PointCheckIcon />
                        {point}
                      </div>
                    ))}
                  </div>
                </div>
             </div>
            ))}
          </div>
         

          <div className={`${classes.infoBlock} ${classes.pricingBlock}`}>
            <h2>
              Pricing
            </h2>
            <div style={{marginBottom: '40px'}}>
              Pricing for advertisements depends on many factors. Please send an<br/>email to discuss your specific needs or interests.
            </div>

            <h2>
              Future Advertising Opportunities
            </h2>
            <div style={{marginBottom: '20px'}}>
              As Relavanti.com adds functionality, more targeted advertising opportunities<br/>will become available.
            </div>
            <div>
              For answers to questions, pricing or more information, please email<br/>
              <a href='mailto:advertising@relavanti.com' style={{color: 'var(--app-primary-color)'}}>
                advertising@relavanti.com.
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AdvertisingPage
