import { Popover } from 'antd'
import classes from './ThreeDotsMenu.module.css'
import {ReactComponent as ThreeDotsIcon} from './../../../img/icons/threeDots.svg'
import {ReactComponent as ShareIcon} from './../../../img/icons/shareLight.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/delete.svg'
import {ReactComponent as EditIcon} from './../../../img/icons/edit.svg'
import { MouseEvent } from 'react'

const ThreeDotsMenu:React.FC<ThreeDotsMenuPropTypes> = ({onClick, onShare, onEdit, onDelete, style}) => {
  const menuItems = [
    ...(!!onShare ? [{icon: <ShareIcon />, text: 'Share', onClick: onShare}] : []),
    ...(!!onEdit ? [{icon: <EditIcon />, text: 'Edit', onClick: onEdit}] : []),
    ...(!!onDelete ? [{icon: <DeleteIcon />, text: 'Delete', onClick: onDelete}] : []),
  ]
  return (
    <>
      <Popover
        content={
          <div>
            {menuItems.map(menuItem => (
              <div
                className={classes.dotsMenuItem}
                onClick={e => {
                  e.stopPropagation()
                  menuItem.onClick()
                }}
                key={menuItem.text}
              >
                {menuItem.icon} {menuItem.text}
              </div>
            ))}
          </div>
        }
      >
        <ThreeDotsIcon style={{cursor: 'pointer', ...style}} onClick={onClick}/>
      </Popover>
    </>
  )
}

interface ThreeDotsMenuPropTypes {
  onClick?: (e: MouseEvent<SVGSVGElement>) => void
  onShare?: () => void
  onEdit?: () => void
  onDelete?: () => void
  style?: object
}

export default ThreeDotsMenu
