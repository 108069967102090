import { Collapse, Spin } from 'antd'
import classes from './UserSearchList.module.css'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { DeleteUserSearchListThunk, EditUserSearchListThunk, GetUserSearchListsThunk, selectUserSearchLists, setUserSearchLists } from '../../../store/userSearchReducer'
import { selectUserData } from '../../../store/userReducer'
import ThreeDotsMenu from '../../common/ThreeDotsMenu/ThreeDotsMenu'
import { UserSearchListType } from '../../../types/userSearchTypes'
import UserSearchListModal from './UserSearchListModal/UserSearchListModal'
import UserSearchItem from './UserSearchItem/UserSearchItem'
import { GetCruiseLengthsThunk, GetCruiseLineTypesThunk, setAdvancedSearchRequestData } from '../../../store/cruiseReducer'

const UserSearchList = () => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const userSearchLists = useAppSelector(selectUserSearchLists)

  const [activeKeys, setActiveKeys] = useState<string[] | null>(null)
  const [listDetailsModalFormType, setListDetailsModalFormType] = useState<'' | 'edit' | 'delete'>('')
  const [listDetailsId, setListDetailsId] = useState(0)

  useEffect(() => {
    dispatch(GetUserSearchListsThunk(userData.user_id))
    dispatch(GetCruiseLineTypesThunk())
    dispatch(GetCruiseLengthsThunk())
  }, [dispatch, userData])

  useEffect(() => {
    if (activeKeys === null && userSearchLists !== null) {
      setActiveKeys(userSearchLists?.map(list => String(list.user_search_list_id)) || [])
    }
  }, [userSearchLists, activeKeys])

  useEffect(() => {
    return () => {
      dispatch(setUserSearchLists(null))
      dispatch(setAdvancedSearchRequestData({}))
    }
  }, [dispatch, userData])

  return (
    <div className={classes.wrapper}>
      <Collapse
        ghost
        activeKey={activeKeys || []}
        onChange={keys => listDetailsModalFormType?.length ? undefined : setActiveKeys(keys as string[])}
      >
        {!!userSearchLists?.length &&
          userSearchLists?.map(list => (
            <Collapse.Panel
              header={
                <UserSearchListHeader
                  listData={list}
                  listDetailsModalFormType={listDetailsModalFormType}
                  setListDetailsModalFormType={setListDetailsModalFormType}
                  listDetailsId={listDetailsId}
                  setListDetailsId={setListDetailsId}
                />
              }
              key={String(list.user_search_list_id)}
            >
              <div className={classes.collection}>
                {!!list.user_searches?.length ? (
                  list.user_searches?.map(search => (
                    <UserSearchItem search={search} key={search.search_id}/>
                  ))
                ) : (
                  <div style={{opacity: '0.7'}}>No saved searches for this list</div>
                )}
              </div>
            </Collapse.Panel>
          ))
        }
        {userSearchLists !== null && !userSearchLists.length &&
          <div className={classes.noListsMessage}>
            You have no saved searches
          </div>  
        }
        {userSearchLists === null &&
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Spin />
          </div>  
        }
      </Collapse>
    </div>
  )
}

const UserSearchListHeader: React.FC<UserSearchListHeaderPropTypes> = ({listData, listDetailsModalFormType, setListDetailsModalFormType, listDetailsId, setListDetailsId}) => {
  const dispatch = useAppDispatch()

  const getModalSubmitAction = async(name?:string) => {
    if (listDetailsModalFormType === 'delete') {
      return dispatch(DeleteUserSearchListThunk(listData?.user_search_list_id!))
    } else {
      return dispatch(EditUserSearchListThunk({
        listId: listData?.user_search_list_id!,
        listData: {
          date_created: listData?.date_created!,
          user_id: listData?.user_id!,
          list_name: name!
        }
      }))
    }
  }

  return (
    <div className={classes.collectionTitle}>
      <span style={{marginRight: '7px'}}>
        {listData.list_name}
      </span>
      <ThreeDotsMenu
        onEdit={() => {setListDetailsModalFormType('edit'); setListDetailsId(listData?.user_search_list_id)}}
        onDelete={() => {setListDetailsModalFormType('delete'); setListDetailsId(listData?.user_search_list_id)}}
        onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
      />
   
      <UserSearchListModal
        isOpen={!!listDetailsModalFormType.length && listDetailsId === listData?.user_search_list_id}
        onClose={() => setListDetailsModalFormType('')}
        formType={listDetailsModalFormType as 'delete' | 'edit'}
        currentName={listData?.list_name}
        getSubmitAction={getModalSubmitAction}
        modalName='Search list'
      />
    </div>
  )
}

interface UserSearchListHeaderPropTypes {
  listData: UserSearchListType
  listDetailsModalFormType: 'delete' | 'edit' | ''
  setListDetailsModalFormType: Dispatch<SetStateAction<'delete' | 'edit' | ''>>
  setListDetailsId:  Dispatch<SetStateAction<number>>
  listDetailsId: number
}

export default UserSearchList
