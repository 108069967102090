import { createSlice, PayloadAction, isRejected, isPending, isFulfilled } from '@reduxjs/toolkit'
import { appSettingsHelper } from '../helpers/localStorageHelper'
import { failedMetaType, fulfilledMetaType, RootState } from './../types/appTypes'

export enum AppStatusType {'idle', 'loading', 'succeeded', 'failed', 'info'}

export type DistanceTypeType = 'km' | 'miles'

interface InitialStateType {
  appStatus: AppStatusType,
  errorMessage: string | null,
  infoMessage: string | null,
  distanceType: DistanceTypeType,
  isBetaModalOpen: boolean,
  betaModalLastShownTime: string,
  userPlaceId: string | undefined
}

const initialState: InitialStateType = {
  appStatus: AppStatusType.idle,
  errorMessage: null,
  infoMessage: null,
  distanceType: appSettingsHelper.getAppSettings()?.distanceType || 'km',
  isBetaModalOpen: true,
  betaModalLastShownTime: appSettingsHelper.getBetaModalLastShownTime() || '',
  userPlaceId: undefined
}

export const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<AppStatusType>) => {
      state.appStatus = action.payload
    },
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload
    },
    setInfoMessage: (state, action: PayloadAction<string | null>) => {
      state.infoMessage = action.payload
    },
    setDistanceType: (state, action: PayloadAction<DistanceTypeType>) => {
      state.distanceType = action.payload
    },
    setIsBetaModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isBetaModalOpen = action.payload
    },
    setBetaModalLastShownTime: (state, action: PayloadAction<string>) => {
      state.betaModalLastShownTime = action.payload
    },
    setUserPlaceId: (state, action: PayloadAction<string | undefined>) => {
      state.userPlaceId = action.payload
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending, (state) => {
        state.appStatus = AppStatusType.loading
      })
      .addMatcher(isRejected, (state, action) => {
        const requestMeta = action.meta as failedMetaType
        if (requestMeta?.appStatus === AppStatusType.info) {
          state.appStatus = AppStatusType.info
          state.infoMessage = requestMeta.infoMessage || ''
        } else {
          state.appStatus = action.payload === 'canceled' ? AppStatusType.idle : AppStatusType.failed
          state.errorMessage = action.payload === 'canceled' || !(action.payload as string)?.length ? null : action.payload as string || 'Something went wrong'
        }
      })
      .addMatcher(isFulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType
        meta.appStatus === AppStatusType.idle && (state.appStatus = AppStatusType.idle)
        if (meta.appStatus === AppStatusType.info) {
          state.appStatus = AppStatusType.info
          state.infoMessage = meta.infoMessage || null
        } else if (meta.appStatus === AppStatusType.succeeded) {
          state.appStatus = AppStatusType.succeeded
          state.infoMessage = meta.infoMessage || null
        }
      })
  },
})

export const {
  setAppStatus,
  setErrorMessage,
  setInfoMessage,
  setDistanceType,
  setIsBetaModalOpen,
  setBetaModalLastShownTime,
  setUserPlaceId,
} = appStatusSlice.actions

export const selectAppStatus = (state: RootState) => state.appStatus.appStatus
export const selectErrorMessage = (state: RootState) => state.appStatus.errorMessage
export const selectInfoMessage = (state: RootState) => state.appStatus.infoMessage
export const selectDistanceType = (state: RootState) => state.appStatus.distanceType
export const selectIsBetaModalOpen = (state: RootState) => state.appStatus.isBetaModalOpen
export const selectBetaModalLastShownTime = (state: RootState) => state.appStatus.betaModalLastShownTime
export const selectUserPlaceId = (state: RootState) => state.appStatus.userPlaceId

export default appStatusSlice.reducer
