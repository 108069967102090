import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { WidgetLocationType, WidgetSelectedTransportStopType, WidgetTabOptionsType } from '../types/widgetTypes'
import { RootState } from '../types/appTypes'
import airportIcon from './../img/widget/airportIcon.svg'
import portIcon from './../img/widget/portIcon.svg'
import publicTransportIcon from './../img/widget/publicTransportIcon.svg'
import activitiesIcon from './../img/widget/activitiesIcon.svg'
import { GetAirportsByCityThunk } from './airportReducer'
import { NearAirportType } from '../types/airportTypes'
import { GetPortsByCityThunk } from './portReducer'
import { NearPortType } from '../types/portTypes'
import { GetNearTransportStopsThunk } from './transportStopsReducer'
import { StopDataType } from '../types/transportStopsTypes'
import { GetViatorAttractionsFilteredThunk } from './viatorReducer'
import { ViatorAttractionFilteredTypes } from '../types/viatorTypes'

export const navMenuItems = [
  {icon: airportIcon, name: 'Airports', tab: 'airports'},
  {icon: portIcon, name: 'Ports', tab: 'ports'},
  {icon: publicTransportIcon, name: 'Public Transport', tab: 'transports'},
  {icon: activitiesIcon, name: 'Activities', tab: 'activities'},
]

interface InitialStateType {
  location: null | WidgetLocationType
  activeTab: WidgetTabOptionsType

  airports: null | NearAirportType[]
  ports: null | NearPortType[]
  transportStops: null | StopDataType[]
  selectedTransportStop: null | WidgetSelectedTransportStopType
  activities: null | ViatorAttractionFilteredTypes[]
}

const initialState: InitialStateType = {
  location: null,
  activeTab: 'main',

  airports: null,
  ports: null,
  transportStops: null,
  selectedTransportStop: null,
  activities: null,
}

export const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<null | WidgetLocationType>) => {state.location = action.payload},
    setActiveTab: (state, action: PayloadAction<WidgetTabOptionsType>) => {state.activeTab = action.payload},
    setSelectedTransportStop: (state, action: PayloadAction<null | WidgetSelectedTransportStopType>) => {state.selectedTransportStop = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAirportsByCityThunk.fulfilled, (state, action) => {
        state.airports = action.payload
      })
      .addCase(GetPortsByCityThunk.fulfilled, (state, action) => {
        state.ports = action.payload
      })
      .addCase(GetNearTransportStopsThunk.fulfilled, (state, action) => {
        state.transportStops = action.payload
      })
      .addCase(GetViatorAttractionsFilteredThunk.fulfilled, (state, action) => {
        state.activities = action.payload.products
      })
  }
})

export const { 
  setLocation,
  setActiveTab,
  setSelectedTransportStop
} = widgetSlice.actions

export const selectLocation = (state: RootState): null | WidgetLocationType => state.widget.location
export const selectActiveTab = (state: RootState): WidgetTabOptionsType => state.widget.activeTab

export const selectAirports = (state: RootState): null | NearAirportType[] => state.widget.airports
export const selectPorts = (state: RootState): null | NearPortType[] => state.widget.ports
export const selectTransportStops = (state: RootState): null | StopDataType[] => state.widget.transportStops
export const selectSelectedTransportStop = (state: RootState): null | WidgetSelectedTransportStopType => state.widget.selectedTransportStop
export const selectActivities = (state: RootState): null | ViatorAttractionFilteredTypes[] => state.widget.activities

export const getWidgetLocationData = async(locationName: string) => {
  const addressData = await geocodeByAddress(locationName)
  const coordinates = await getLatLng(addressData[0])
  return ({
    coordinates: [coordinates?.lat, coordinates?.lng] as [number, number],
    name: locationName,
    country_code: addressData[0].address_components.find(address => address.types.includes('country'))?.short_name || '',
    country_name: addressData[0].address_components.find(address => address.types.includes('country'))?.long_name || '',
    city_name: addressData[0].address_components.find(address => address.types.includes('locality'))?.long_name || '',
  })
}

export default widgetSlice.reducer
