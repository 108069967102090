import { Popover, Select, Spin } from 'antd'
import { useEffect, useState } from 'react'
import classes from './../Filters.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetCountryListThunk, selectCountries } from '../../../../store/additionalDataReducer'
import { ReactComponent as InfoIcon } from './../../../../img/icons/info.svg'

interface ExcludeCountryFilterPropTypes {
  onChange: (val: null | string[]) => void
  value: string[]
}

const ExcludeCountryFilter:React.FC<ExcludeCountryFilterPropTypes> = ({onChange, value}) => {
  const dispatch = useAppDispatch()
  const countries = useAppSelector(selectCountries)

  const [isLoading, setIsLoading] = useState(false)
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    if (countries === null && !isLoading) {
      setIsLoading(true)
      dispatch(GetCountryListThunk())
        .then(() => setIsLoading(false))
    }
  }, [dispatch, countries, isLoading])

  const onSelect = (countryCode: string) => {
    onChange([...(value || []), countryCode])
    setSearchString('')
  }

  const onDeselect = (deselectedCode: string) => {
    const updatedValue = value!.filter(val => val !== deselectedCode)
    onChange(updatedValue?.length ? updatedValue : null)
  }

  const getOptions = () => {
    if (searchString?.length) {
      return countries?.filter(country => {
        return country.name.toLowerCase().includes(searchString.toLowerCase())
          || country.code.toLowerCase().includes(searchString.toLowerCase())
      })
    } else {
      return countries
    }
  }

  return (
    <div className={classes.filterSubCategory}>
      <div className={classes.filterSubCategoryTitle}>
          Exclude countries
          <Popover
            content={
              <span style={{padding: '0px 10px', fontSize: '12px'}}>
                Do not show me the nodes of the selected countries
              </span>
            }
          >
            <InfoIcon className={classes.infoIcon} />
          </Popover>
      </div>
      <div id='excludedCountriesSelect'>
        <Select
          showSearch
          style={{width: '100%', marginBottom: '25px'}}
          placeholder='Start typing country name or code'
          onSearch={searchQuery => setSearchString(searchQuery)}
          onSelect={(val:any) => onSelect(val.value)}
          onDeselect={(val:any) => onDeselect(val.value)}
          searchValue={searchString}
          labelInValue
          placement='bottomLeft'
          // onFocus={onInputFocus}
          filterOption={false}
          dropdownStyle={{bottom: '10px'}}
          value={value}
          // onBlur={() => {
          //   setSearchString('')
          //   dispatch(setPortOptions(null))
          // }}
          mode='multiple'
          getPopupContainer={() => document.getElementById('excludedCountriesSelect')!}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!searchString.length && 'No results found'}
            </>
          )}
        >
          {getOptions()?.map(opt => (
            <Select.Option value={opt.code} key={opt.code}>
              {opt.name} ({opt.code})
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default ExcludeCountryFilter
