const ServicesAvailableString:React.FC<{name: string}> = ({name}) => {
  return (
    <>
      <div
        style={{
          marginTop: '-15px',
          marginBottom: '15px',
          fontSize: '14px'
        }}
      >
        Services available at {name} 
      </div>
    </> 
  )
}

export default ServicesAvailableString
