import React, { useEffect } from 'react'
import { Input } from 'antd'
import PlacesAutocomplete from 'react-places-autocomplete'
import classes from './InputGoogleAPI.module.css'

interface GoogleDataInputPropsType {
  placeholder: string
  onChange?: (value: string) => void
  value?: string
  suggestionsPosition?: 'vertical' | 'horizontal'
  style?: object
  wrapperStyle?: object
  searchOptions?:object
  addonAfter?: any
  addonBefore?: any
  status?: 'error' | 'warning'
  clearAfterSelect?: boolean
  onInputValueChange?: (value: string) => void
  onSelect?: (value: string) => void
  disabled?: boolean
}

export const InputGoogleAPI = (props: GoogleDataInputPropsType) => {
  const [address, setAddress] = React.useState('')

  const handleSelect = async (value: string) => {
    props.onChange && props.onChange(value)
    props.onSelect && props.onSelect(value)
    setAddress(props.clearAfterSelect ? '' : value)
  }

  const handleChange = (value: string) => {
    if (!value.length) {
      props.onChange && props.onChange(value)
    }
    props.onInputValueChange && props.onInputValueChange(value)
    setAddress(value)
  }

  useEffect(() => {
    setAddress(props.value || '')
  }, [props.value])

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={props.searchOptions}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <div className={props.suggestionsPosition === 'vertical' ? classes.verticalSuggestions : ''} style={props.wrapperStyle}>
            <Input
              style={props.style}
              {...getInputProps({placeholder: props.placeholder})}
              addonAfter={props?.addonAfter}
              addonBefore={props?.addonBefore}
              status={props.status}
              disabled={props.disabled}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  e.preventDefault()
                }
              }}
            />
            <div className={suggestions.length ? classes.suggestionsWrapper : ''}>
              {suggestions.map(suggestion => {
                return (
                  <div {...getSuggestionItemProps(suggestion)} key={suggestion.id} className={suggestion.active ? classes.active : ''}>
                    {suggestion.description}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  )
}
