import { Button, Form, Input } from 'antd'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Lottie } from '@crello/react-lottie'
import PasswordStrengthBar from 'react-password-strength-bar'
import { AuthPage } from '../Auth'
import classes from './../Auth.module.css'
import successAnimation from './../../../img/auth/success.json'
import { useAppDispatch } from '../../../app/hooks'
import { SetPasswordThunk } from '../../../store/userReducer'

const NewPasswordForm:React.FC<{page: AuthPage.ResetPassword | AuthPage.CreatePassword}> = ({page}) => {
  const hasToMatchValidator = (valueToCompare: string, errorText: string) => ({
    validator(_: any, value: any) {
      if (!value || valueToCompare === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(errorText))
    }
  })

  const dispatch = useAppDispatch()
  const location = useLocation()
  const [form] = Form.useForm()
  const [isCreated, setIsCreated] = useState(false)
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (formData: FormValuesType) => {
    // location.search example: ?token=be82790e-06c3-4f06-a016-dc9f12451d0e
    const token = location.search.substr(location.search.indexOf('=') + 1)
    setIsLoading(true)
    dispatch(SetPasswordThunk({password: formData.password, token, autoLogin: page !== AuthPage.ResetPassword}))
      .then((resp) => {
        setIsLoading(false)
        !resp.type.includes('rejected') && setIsCreated(true)
      })
  }

  if (isCreated) {
    return (
      <div className={classes.contentWrapper}>
        <Lottie
          config={{animationData: successAnimation}}
          height='53px'
          width='53px'
          style={{margin: '0 auto'}}
        />
        <h1 className={classes.formTitle}>
          Password reset
        </h1>
        <div className={classes.message}>
          Your password has been successfully reset
        </div>
        {page === AuthPage.CreatePassword && (
          <Link to='/sign-in'>
            <Button type='primary'>
              Continue
            </Button>
          </Link>
        )}
      </div>
    )
  }

  return (
    <Form
      name='newPassword'
      onFinish={onFinish}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
      form={form}
    >
      <h1 className={classes.formTitle}>
        {page === AuthPage.CreatePassword ? 'Create password' : 'Set new password'}
      </h1>

      <div className={classes.label}>
        {page === AuthPage.CreatePassword ? 'Password' : 'New password'}
      </div>
      <Form.Item
        name='password'
        rules={[
          { required: true, message: 'Please enter your password!' },
          { min: 4, message: 'Password must be at least 4 characters '}
        ]}
      >
        <Input.Password placeholder='Enter new password' onChange={(e) => setPassword(e.target.value)}/>
      </Form.Item>

      {!!password.length &&
        <PasswordStrengthBar 
          password={password} 
          barColors={['#ddd', '#25c281', '#25c281', '#25c281', '#25c281']}
          minLength={4}
          scoreWords={['', 'The password is weak', 'The password is okay', 'The password is good', 'The password is strong']}
          scoreWordStyle={{textAlign: 'left', color: '#25c281', marginBottom: '15px'}}
          shortScoreWord='The password is too short'
          className={classes.passwordStrengthBar}
        />
      }

      <div className={classes.label}>
        Confirm password
      </div>
      <Form.Item
        name='repeatPassword'
        dependencies={['password']}
        rules={[
          {required: true, message: 'Please repeat your password!'},
          ({getFieldValue}) => hasToMatchValidator(getFieldValue('password'), 'The two passwords that you entered do not match!'),
        ]}
      >
        <Input.Password placeholder='Confirm password'/>
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
          {page === AuthPage.CreatePassword ? 'Create' : 'Reset password'}
        </Button>
      </Form.Item>

      {page !== AuthPage.CreatePassword &&
        <Link to='/sign-in' className={classes.goBackLink}>
          Back to Sign in
        </Link>
      }
    </Form>
  )
}

interface FormValuesType {
  password: string
  repeatPassword: string
}

export default NewPasswordForm
