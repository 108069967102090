import { useEffect, useState } from 'react'
import { Spin } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { DeleteCustomCruiseThunk, GetCustomCruiseListThunk, selectCustomizedCruiseList } from '../../../store/cruiseReducer'
import classes from './CustomizedCruises.module.css'
import { copyToClipboard } from '../../../helpers/linksHelper'
import {ReactComponent as CopyIcon} from './../../../img/icons/copy.svg'
import ThreeDotsMenu from '../../common/ThreeDotsMenu/ThreeDotsMenu'
import UserSearchListModal from '../UserSearchList/UserSearchListModal/UserSearchListModal'

const CustomizedCruises = () => {
  const dispatch = useAppDispatch()
  const customizedCruiseList = useAppSelector(selectCustomizedCruiseList)

  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    dispatch(GetCustomCruiseListThunk())
      .then(() => setIsLoading(false))
  }, [dispatch])

  if (isLoading) {
    return <Spin style={{width: '100%'}}/>
  }

  if (customizedCruiseList !== null && !customizedCruiseList.length) {
    return (
      <div>
        No customized cruises found
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      {customizedCruiseList?.map(cruise => (
        <div key={cruise.cruise_id} className={classes.cruise}>
          <div className={classes.cardHeader}>
            <div style={{marginRight: '15px'}}>
              From: {cruise?.departure_from} • Ship: {cruise?.ship_name}
            </div>
            <ThreeDotsMenu
              onDelete={() => setIsModalOpen(true)}
              onShare={() => copyToClipboard(cruise.url, 'Link copied successfully')}
              style={{position: 'absolute', top: '0px', right: '0px'}}
            />
          </div>
          <div className={classes.cardTitle}>
            {cruise?.cruise_name}
          </div>
          <div className={classes.cardDescription}>
            {cruise?.cruise_line_name}
          </div>
          <div className={classes.searchLink}>
            <Link to={cruise?.url.substring(cruise?.url.indexOf('/'))} rel='noreferrer' target='_blank'>
              Link
            </Link>
            <CopyIcon className={classes.copyIcon} onClick={() => copyToClipboard(cruise?.url, 'Link copied successfully')}/>
          </div>
          <div className={classes.tagsWrapper}>
            <div className={classes.dateTag}>
              {moment(cruise?.start_date).format('YYYY-MM-DD')}
            </div>
            <div className={classes.daysTag}>
              {cruise?.number_of_days} days
            </div>
          </div>
          <UserSearchListModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            formType={'delete'}
            getSubmitAction={() => dispatch(DeleteCustomCruiseThunk(cruise?.id!))}
            modalName='Customized cruise'
          />
        </div>
      ))}
    </div>
  )
}

export default CustomizedCruises
