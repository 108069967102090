import { Button, DatePicker, Select, Spin } from 'antd'
import moment from 'moment'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classes from './SearchSummary.module.css'
import { GetKayakLinkThunk, clearAdvancedSearchRequest, selectAdvancedSearchRequest, selectKayakLink, setAdvancedSearchRequest, setKayakLink } from '../../../../../store/flightReducer'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { ReactComponent as DatePickerCalendar } from './../../../../../img/icons/datePickerCalendar.svg'
import { ReactComponent as CloseIcon } from './../../../../../img/icons/close.svg'
import { getAdvanceFlightSearchUrl, getDataFromAdvanceFlightSearchUrl } from '../../../../../helpers/linksHelper'
import { GetAirportsByIdThunk, selectAirportsById, setAirportsById } from '../../../../../store/airportReducer'
import { AirportDataType } from '../../../../../types/airportTypes'
import Save from '../../../../common/Breadcrumbs/searchTools/SaveSearch/SaveSearch'

const SearchSummary = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const advancedSearchRequest = useAppSelector(selectAdvancedSearchRequest)
  const airportsData = useAppSelector(selectAirportsById)
  const kayakLink = useAppSelector(selectKayakLink)

  useEffect(() => {
    if (!advancedSearchRequest?.from) {
      const data = getDataFromAdvanceFlightSearchUrl(search)
      dispatch(setAdvancedSearchRequest(data))
    }
  }, [dispatch, advancedSearchRequest, search])

  useEffect(() => {
    if (!!advancedSearchRequest?.airports && airportsData === null) {
      dispatch(GetAirportsByIdThunk(advancedSearchRequest?.airports))
    }
  }, [dispatch, advancedSearchRequest, airportsData])

  useEffect(() => {
    if (!!advancedSearchRequest?.airports && airportsData !== null) {
      dispatch(GetKayakLinkThunk({
        from_airport_list: airportsData['1']?.map(airport => airport.code),
        to_airport_list: airportsData['2']?.map(airport => airport.code),
        start_date: advancedSearchRequest.startDate,
        end_date: advancedSearchRequest.endDate,
        option: advancedSearchRequest.type === 'oneway' ? 'ONE_WAY' : 'ROUND_TRIP'
      }))
    }
  }, [dispatch, advancedSearchRequest, airportsData])

  useEffect(() => {
    return () => {
      dispatch(clearAdvancedSearchRequest())
      dispatch(setAirportsById(null))
      dispatch(setKayakLink(null))
    }
  }, [dispatch])

  const handleInputChange = (updatedFields: {[key: string]: string}) => {
    const updatedData = {
      ...advancedSearchRequest,
      ...updatedFields
    }
    dispatch(setAdvancedSearchRequest(updatedData))
    window.history.replaceState(null, '', getAdvanceFlightSearchUrl('summary', updatedData))
  }

  if (!advancedSearchRequest?.airports || airportsData === null) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <div className={classes.form}>
      <div className={classes.label}>
        Select flight type
      </div>
      <div id='searchTypeSelect' style={{width: '100%'}}>
        <Select
          style={{width: '100%', marginBottom: '15px'}}
          options={[
            {value: 'oneway', label: 'One Way'},
            {value: 'round', label: 'Round Trip'}
          ]}
          optionFilterProp='label'
          placeholder='Please select flight type'
          getPopupContainer={() => document.getElementById('searchTypeSelect')!}
          value={advancedSearchRequest?.type}
          onSelect={(value: 'oneway' | 'round') => {
            handleInputChange({
              type: value,
              ...(value === 'oneway' ? {endDate: advancedSearchRequest?.startDate || ''} : {})
            })
          }}
        />
      </div>

      <div className={classes.locationsWrapper}>
        <LocationAirports
          locationName={advancedSearchRequest.from}
          airports={airportsData['1']}
          locationOrder='1'
        />
        <LocationAirports
          locationName={advancedSearchRequest.to}
          airports={airportsData['2']}
          locationOrder='2'
        />
      </div>

      <div className={classes.label}>
        Select Date
      </div>
      {advancedSearchRequest.type === 'oneway' ? (
        <DatePicker
          placeholder='Please select date'
          style={{width: '100%'}}
          value={moment(advancedSearchRequest.startDate!, 'YYYY-MM-DD')}
          suffixIcon={<DatePickerCalendar/>}
          onChange={(val) => handleInputChange({startDate: val!.format('YYYY-MM-DD'), endDate:  val!.format('YYYY-MM-DD')})}
          disabledDate={(current) => current.startOf('day').isBefore(moment().startOf('day'))}
          allowClear={false}
        />
      ) : (
        <DatePicker.RangePicker
          value={advancedSearchRequest?.endDate
            ? [moment(advancedSearchRequest?.startDate), moment(advancedSearchRequest?.endDate)]
            : advancedSearchRequest?.startDate ? [moment(advancedSearchRequest?.startDate), moment(advancedSearchRequest?.startDate)] : undefined
          }
          style={{width: '100%', marginBottom: '20px'}}
          allowClear={false}
          suffixIcon={<DatePickerCalendar/>}
          onChange={(val) => handleInputChange({startDate: val![0]?.format('YYYY-MM-DD')!, endDate: val![1]?.format('YYYY-MM-DD')!})}
          disabledDate={(current) => current.startOf('day').isBefore(moment().startOf('day'))}
        />
      )}

      <div className={classes.btnWrapper}>
        <Save>
          <Button
            style={{
              color: '#0068FF',
              background: '#DAE5F5',
              border: 'none'
            }}
          >
            Save search to profile
          </Button>
        </Save>
        <a href={kayakLink || ''} target='_blank' rel='noreferrer'>
          <Button type='primary'>
            Search on Kayak
          </Button>
        </a>
      </div>
      <Link to={getAdvanceFlightSearchUrl('airports', advancedSearchRequest)} className={classes.goBack}>
        Back
      </Link>
    </div>
  )
}

const LocationAirports: React.FC<{locationName: string, airports: AirportDataType[], locationOrder: string}> = ({locationName, airports, locationOrder}) => {
  const dispatch = useAppDispatch()
  const advancedSearchRequest = useAppSelector(selectAdvancedSearchRequest)
  const airportsData = useAppSelector(selectAirportsById)

  const deleteAirport = (airportId: number) => {
    const updatedData = {
      ...advancedSearchRequest,
      airports: {
        ...advancedSearchRequest?.airports,
        [locationOrder]: advancedSearchRequest?.airports?.[locationOrder]?.filter(id => id !== airportId)!
      }
    }
    dispatch(setAdvancedSearchRequest(updatedData))
    dispatch(setAirportsById({
      ...airportsData,
      [locationOrder]: airportsData?.[locationOrder]?.filter(airport => airport.id !== airportId)!
    }))
    window.history.replaceState(null, '', getAdvanceFlightSearchUrl('summary', updatedData))
  }

  return (
    <div className={classes.location}>
      <div className={classes.locationTitle}>
        Location {locationOrder} (See Search)
      </div>
      <div className={classes.locationName}>
        {locationName}
      </div>
      {airports?.map(airport => (
        <div className={classes.airport} key={airport.id}>
          {airport.name}
          <CloseIcon onClick={() => deleteAirport(airport.id)}/>
        </div>
      ))}
    </div>
  )
}

export default SearchSummary
