import { useState } from 'react'
import { Button, Checkbox, Modal, Popover } from 'antd'
import classes from './Filters.module.css'
import {ReactComponent as FiltersIcon} from './../../../img/icons/filters.svg'
import {ReactComponent as InfoIcon} from './../../../img/icons/info.svg'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectActiveResultCategories, selectActiveTab, selectNodeServiceDetails, selectTabs, setTabActiveFilters, setTabs } from '../../../store/searchResultsReducer'
import { ActiveFiltersType, SearchAdditionalDataType } from '../../../types/searchResultTypes'
import { defaultActiveFilters } from '../../../helpers/searchFiltersHelper'
import SearchAdditionalFields from '../../common/SearchAdditionalFields/SearchAdditionalFields'
import { useLocation } from 'react-router-dom'
import { modifyUrl } from '../../../helpers/linksHelper'
import ServiceOptions from '../ServiceResults/ServiceOptions/ServiceOptions'
import { selectVIPAdvertisementNearFilters } from '../../../store/advertisementReducer'
import AdvertisingBanner from '../../common/AdvertisingBanner/AdvertisingBanner'
import { useMediaQuery } from 'react-responsive'
import AirportFilters from './AirportFilters/AirportFilters'
import PortFilters from './PortFilters/PortFilters'

const Filters: React.FC<FiltersPropTypes> = ({filtersAreAvailable, className, children}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  return (
    <div className={className}>
      <FiltersBtn
        setIsFiltersOpen={setIsFiltersOpen}
        filtersAreAvailable={filtersAreAvailable}
      >
        {children}
      </FiltersBtn>
      <FiltersWindow setIsFiltersOpen={setIsFiltersOpen} isFiltersOpen={isFiltersOpen}/>
    </div>
  )
}

const FiltersBtn: React.FC<FiltersBtnPropTypes> = ({setIsFiltersOpen, filtersAreAvailable, children}) => {
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const advertisement = useAppSelector(selectVIPAdvertisementNearFilters)

  const isAvailableForSmallScreens = !!filtersAreAvailable || !!activeResultCategories.includes('near')
  const getPopoverHintText = () => {
    if (!filtersAreAvailable) {
      return 'No filters available'
    } else if (activeResultCategories.length === 1 && activeResultCategories.includes('airports')) {
      return 'Click to Filter the Airports'
    } else if (activeResultCategories.length === 1 && activeResultCategories.includes('ports')) {
      return 'Click to Filter the Ports'
    } else {
      return 'Click to Filter search results'
    }
  }
  
  return (
    <>
      <div className={classes.filtersBtn}>
        <Popover
          content={getPopoverHintText()}
          overlayInnerStyle={{padding: '10px'}}
          trigger={['hover']}
          // trigger={!filtersAreAvailable ? 'hover' : 'null'}
          placement={'bottom'}
        >
          <div
            className={`
              ${classes.filterBtn}
              ${!!filtersAreAvailable && classes.active}
              ${!filtersAreAvailable && classes.disabled}
            `}
            onClick={() => filtersAreAvailable && setIsFiltersOpen(true)}
          >
            <FiltersIcon /> Filters
          </div>
        </Popover>
      </div>
      <div className={classes.filtersBtnSmallScreen}>
        {children}
        <Popover
          content={<div style={{textAlign: 'center'}}>No filters available</div>}
          overlayInnerStyle={{padding: '10px'}}
          trigger={!isAvailableForSmallScreens ? 'click' : 'null'}
        >
          <div
            className={`
              ${classes.filterBtn}
              ${isAvailableForSmallScreens && classes.active}
              ${!isAvailableForSmallScreens && classes.disabled}
            `}
            onClick={() => isAvailableForSmallScreens && setIsFiltersOpen(true)}
          >
            <FiltersIcon />
          </div>
        </Popover>
      </div>
      {!!advertisement &&
        <AdvertisingBanner
          adData={advertisement}
          style={{margin: '15px 0px'}}
        />
      }
    </>
  )
}

const FiltersWindow: React.FC<{isFiltersOpen: boolean, setIsFiltersOpen: (isOpen: boolean) => void}> = ({isFiltersOpen, setIsFiltersOpen}) => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectActiveTab)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})
  
  const [selectedFilters, setSelectedFilters] = useState(activeTab?.activeFilters)

  const handleClearAll = () => {
    setSelectedFilters(defaultActiveFilters)
  }

  const handleClose = () => {
    setIsFiltersOpen(false)
  }

  const handleDone = () => {
    dispatch(setTabActiveFilters(selectedFilters))
    handleClose()
  }

  const nearResultCategoryIsActive = activeResultCategories.includes('near')

  return (
    <>
      <Modal
        visible={isFiltersOpen}
        onCancel={handleClose}
        footer={null}
        bodyStyle={{
          padding:'0px',
          overflow: 'auto',
          // height: nearResultCategoryIsActive ? 'auto' : 'calc(100vh - 35px)',
          minHeight: '200px',
          height: isSmallScreen || nearResultCategoryIsActive ? 'auto' : 'calc(100vh - 35px)',
        }}
        centered={!!nearResultCategoryIsActive}
        style={{top: '12px'}}
        className={classes.filtersWindow}
      >
        <div className={classes.filtersTitle}>
          Filters
        </div>

        <SmallScreenFilters />

        {activeResultCategories.includes('airports') &&
          <AirportFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        }

        {activeResultCategories.includes('ports') &&
          <PortFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        }

        {activeResultCategories.includes('buses') &&
          <BusFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        }

        {activeResultCategories.includes('railways') &&
          <RailwayFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        }

        <div className={classes.filtersFooter}>
          <Button onClick={handleClearAll} type='text'>
            Clear all
          </Button>
          <Button type='primary' onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
    </>
  )
}

const SmallScreenFilters = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  
  const handleAdditionalDataChange = (data: SearchAdditionalDataType) => {
    dispatch(setTabs(tabs.map(tab => tab.name === activeTab.name ? {...activeTab, ...data} : tab)))
    window.history.replaceState(null, '', modifyUrl(location.search, activeTab.name, data))
  }

  return (
    <div className={classes.smallScreenFilters}>
      <div className={classes.filterCategoryTitle}>
        Search filters:
      </div>

      {(!!activeResultCategories.includes('near') || !!nodeServiceDetails) &&
        <div className={classes.filterSubCategory}>
          <div className={classes.filterSubCategoryTitle}>
            Service Categories
          </div>
          <ServiceOptions className={classes.smallScreens} />
        </div>
      }
      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
          Trip details
        </div>
        <SearchAdditionalFields
          values={{travelers: tabs.find(t => t.name === activeTab.name)?.travelers!, dates: tabs.find(t => t.name === activeTab.name)?.dates!}}
          handleChange={handleAdditionalDataChange}
        />
      </div>
    </div>
  )
}

const BusFilters: React.FC<FilterCategoryPropTypes> = ({selectedFilters, setSelectedFilters}) => {
  const handleChange = (fields: {[key: string]: any}) => {
    const updatedFilters = {
      ...selectedFilters,
      buses: {...(selectedFilters?.buses || {}), ...fields}
    }
    setSelectedFilters(updatedFilters)
  }

  return (
    <>
      <div className={classes.filterCategoryTitle}>
        Bus Filters:
      </div>

      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
            Include other countries
            <Popover
              content={
                <span style={{padding: '0px 10px', fontSize: '12px'}}>
                  Show nearest bus stations in other countries (if there are any available)
                </span>
              }
            >
              <InfoIcon className={classes.infoIcon} />
            </Popover>
        </div>
        <Checkbox
          onChange={e => handleChange({show_all_countries: e.target.checked})}
          checked={selectedFilters?.buses?.show_all_countries}
        >
          Include
        </Checkbox>
      </div>
    </>
  )
}

const RailwayFilters: React.FC<FilterCategoryPropTypes> = ({selectedFilters, setSelectedFilters}) => {
  const handleChange = (fields: {[key: string]: any}) => {
    const updatedFilters = {
      ...selectedFilters,
      railways: {...(selectedFilters?.railways || {}), ...fields}
    }
    setSelectedFilters(updatedFilters)
  }

  return (
    <>
      <div className={classes.filterCategoryTitle}>
        Railway Filters:
      </div>

      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
            Include other countries
            <Popover
              content={
                <span style={{padding: '0px 10px', fontSize: '12px'}}>
                  Show nearest railway stations in other countries (if there are any available)
                </span>
              }
            >
              <InfoIcon className={classes.infoIcon} />
            </Popover>
        </div>
        <Checkbox
          onChange={e => handleChange({show_all_countries: e.target.checked})}
          checked={selectedFilters?.railways?.show_all_countries}
        >
          Include
        </Checkbox>
      </div>
    </>
  )
}

interface FiltersPropTypes {
  className?: string
  children?: any
  filtersAreAvailable: boolean
}

interface FiltersBtnPropTypes {
  filtersAreAvailable: boolean
  setIsFiltersOpen: (isOpen: boolean) => void
  children?: any
}

interface FilterCategoryPropTypes {
  selectedFilters: ActiveFiltersType
  setSelectedFilters: (filters: ActiveFiltersType) => void
}

export default Filters
