export const slugify = (string: string) => {
  return string
    .toString()
    .normalize('NFD')
    // .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')
    // .replace(/[^\w-]+/g, '')
    // .replace(/--+/g, '_')
}

export const getContentFromHTMLString = (string: string) => {
  const getNumberedListData = (htmlString: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString
    const orderedLists = tempElement.querySelectorAll('ol')
    orderedLists.forEach(function (ol) {
      const listItems = ol.querySelectorAll('li')
      listItems.forEach(function (item, index) {
        item.innerHTML = (index > 0 ? ' ' : '') + (index + 1) + '. ' + item.innerHTML + (index === listItems.length - 1 ? ' ' : '')
      })
    })
    return tempElement.innerHTML
  }

  const getBulletListData = (htmlString: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString
    const unorderedLists = tempElement.querySelectorAll('ul');
    unorderedLists.forEach(function (ul) {
      const listItems = ul.querySelectorAll('li');
      listItems.forEach((item, index) => {
        item.innerHTML = (index > 0 ? ' ' : '') + '\u2022 ' + item.innerHTML  + (index === unorderedLists.length - 1 ? ' ' : '')
      });
    });
    return tempElement.innerHTML
  }

  const numberedListData = getNumberedListData(string)
  const bulletListData = getBulletListData(numberedListData)
  
  return bulletListData.replace(/<\/?[^>]+(>|$)/g, '').trim()
}
