import { fitBounds } from 'google-map-react'
import { DistanceTypeType } from '../store/appStatusReducer'
import { AirportSearchResultType } from '../types/airportTypes'
import { PortSearchResultType } from '../types/portTypes'
import { TransportType } from '../types/searchResultTypes'

export const getMarkerTypeIcon: (type: TransportType, color?: string) => string = (type, color) => {
  const icons = {
    'airport': `
      <g>
        <path fill='${!!color ? color : 'white'}' d='M41.3,20.9v0.2c-2.9-0.1-4.8,0.8-4.3-3.2c-0.2-0.1-0.5-0.1-0.7-0.1L17.7,18c-0.5-1.4-7-13-6.1-2.1
          c4.4-0.2,4.7,3.3,0.1,3.1c1,4.5,5.9,7.1,10.3,6.3c0.7-1.2,1.3-3.3,3-3.2c2.1,0.3,9.5-1.4,6.5,3.2C34.3,25.3,44.2,26.1,41.3,20.9z
          M23.7,21.1c-0.7,0.9-2.2-0.2-1.4-1.1C22.9,19.1,24.4,20.2,23.7,21.1z M27,21.5c-1.2,0-1.2-1.8,0-1.8C28.1,19.6,28.2,21.5,27,21.5
          z M31,21.5c-1.2,0-1.2-1.8,0-1.8C32.2,19.6,32.2,21.5,31,21.5z'
        />
        <path fill='${!!color ? color : 'white'}' d='M40.7,20.1h-2.6c-0.2-0.1-0.1-1.7-0.1-1.9C39.1,18.6,40,19.3,40.7,20.1z'/>
        <path fill='${!!color ? color : 'white'}' d='M31.1,24.5c-1.1,1.9-4.7,6.7-4.7,6.8h-4.9c0,0-0.9-0.2-0.6-1.6c0.7-1.3,2.4-5,3.2-6.2c0.5-1,6.2-0.3,7-0.5C31,23,31.8,23.2,31.1,24.5z'/>
        <path fill='${!!color ? color : 'white'}' d='M14.3,17.5c0.3,0.8-5.4,0.9-5.2,0C8.9,16.6,14.4,16.6,14.3,17.5z'/>
        <path fill='${!!color ? color : 'white'}' d='M41.3,20.9c-0.2-0.3-0.4-0.5-0.6-0.8'/>
        <line stroke='${!!color ? color : 'white'}' x1="7.6" y1="21.1" x2="11.2" y2="21.1"/>
        <line stroke='${!!color ? color : 'white'}' x1="12.5" y1="22.9" x2="9.7" y2="22.9"/>
        <line stroke='${!!color ? color : 'white'}' x1="6.5" y1="24.8" x2="14.6" y2="24.8"/>
      </g>
    `,
    'port': `
      <g>
        <path fill='${!!color ? color : 'white'}' d='M22.2,15.6L21.9,16c0,0,0,0-0.1,0L22.2,15.6z'/>
        <path fill='${!!color ? color : 'white'}' d='M22.2,10.6v3.9l-0.6,0.6l-0.2,0.2c-0.3,0-0.9,0-1.7,0v-4.6c0,0,0.6-0.3,1.3-0.3C21.5,10.3,21.9,10.4,22.2,10.6z'/>
        <path fill='${!!color ? color : 'white'}' d='M24.8,10.6v2h-0.6L24,12.8l-0.6,0.6h0l0,0l-0.3,0.3v-3.1c0,0,0.4-0.3,0.9-0.3C24.3,10.3,24.5,10.4,24.8,10.6z'/>
        <path fill='${!!color ? color : 'white'}' d='M23.4,13.4L23.4,13.4L23.4,13.4L23.4,13.4z'/>
        <path fill='${!!color ? color : 'white'}' d='M20.4,21.8h-1.2c-1.7,0-3.4,0-4.9,0c1.5,0,3.2,0,4.9,0L20.4,21.8L20.4,21.8z'/>
        <path fill='${!!color ? color : 'white'}' d='M22.2,15.6L21.9,16c0,0,0,0-0.1,0L22.2,15.6z'/>
        <path fill='${!!color ? color : 'white'}' d='M21.8,21.8h-1.4l0,0L21.8,21.8L21.8,21.8z'/>
        <path fill='${!!color ? color : 'white'}' d='M25.8,21.8c-1.3,0-2.6,0-3.9,0h-1.4l0,0H25.8z'/>
        <path fill='${!!color ? color : 'white'}' d='M35.4,19.2c-0.1,0-0.1,0-0.2,0.1v-0.1H35.4z'/>
        <path fill='${!!color ? color : 'white'}' d='M39.6,21.8L39.6,21.8c-0.3,0-0.6,0-1,0H39.6z'/>
        <path fill='${!!color ? color : 'white'}' d='M20.4,21.8h-1.2v0L20.4,21.8L20.4,21.8z'/>
        <path fill='${!!color ? color : 'white'}' d='M34.4,14.7v0.6h-5.2c-0.4,0-0.8,0.3-0.9,0.7l-0.5,1.4c-0.1,0.3-0.1,0.7,0,1h-5.2l-0.2,0.2L20,21
          c-4,0-7.7,0-10.2,0l1.1-4.2c0,0,0.5-0.7,1.4-0.8c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.8,0
          c0.4,0,0.9,0,1.5,0c1.1,0,2.4,0,3.5,0h0.3c0.3,0,0.6,0,0.8,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0
          c0.3,0,0.5,0,0.7,0c0.3,0,0.5,0,0.5,0c0.1,0,0.1,0,0.1,0l0.3-0.4l0.7-0.7l0.6-0.6l0,0l0.4-0.4l0,0l0.6-0.6H33
          C33.8,13.4,34.4,14,34.4,14.7z'
        />
        <path fill='${!!color ? color : 'white'}' d='M39.6,19.9V21h-1.8c-1,0-2.1,0-3.3,0h-3.8c-2.6,0-5.5,0-8.3,0h-1.1l1.8-1.8h12.3v0.1c0.1,0,0.1,0,0.2-0.1h3.5C39.3,19.2,39.6,19.5,39.6,19.9z'/>
        <path fill='${!!color ? color : 'white'}' d='M25.8,21.8c-1.3,0-2.6,0-3.9,0h-2.6c-1.7,0-3.4,0-4.9,0c1.5,0,3.2,0,4.9,0H25.8z'/>
        <path fill='${!!color ? color : 'white'}' d='M39.6,21.8L39.6,21.8c-0.3,0-0.6,0-1,0H39.6z'/>
        <path fill='${!!color ? color : 'white'}' d='M37.4,27c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0h0.1C37.6,27.1,37.5,27,37.4,27z M7.7,27.5L7.7,27.5c0.1,0,0.1,0,0.1,0C7.8,27.5,7.8,27.5,7.7,27.5z'/>
        <path fill='${!!color ? color : 'white'}' d='M43.6,25.4c-0.1-0.2-0.4-0.2-0.6-0.1c-0.9,0.7-2,1.4-3,1.4c-0.3,0-0.5,0-0.8-0.1c-0.3,0-0.6-0.1-1-0.2
          l3.7-3.4c0,0,0.3-1.1-0.8-1.2c-0.1,0-0.3,0-0.8,0c-0.2,0-0.5,0-0.8,0v0c-0.3,0-0.6,0-1,0c-1.1,0-2.6,0-4.2,0h-3.8
          c-1.6,0-3.2,0-4.9,0c-1.3,0-2.6,0-3.9,0h-2.6c-1.7,0-3.4,0-4.9,0c-1.9,0-3.5,0-4.8,0H8.7c-0.9,0-1.5,0-1.9,0
          c-0.2,0-0.3,0.2-0.3,0.3C6.6,23,6.8,25,7.4,26.8h0c-0.3,0-0.5-0.1-0.8-0.1c-0.6-0.2-1.4-0.6-2.3-1.3c-0.2-0.1-0.4-0.1-0.6,0.1
          c-0.1,0.2-0.1,0.4,0.1,0.6c0.8,0.6,2,1.3,3.1,1.5c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0H7.7c0,0,0.1,0,0.1,0
          c1.6-0.1,3.1-0.8,4.2-1.9c0.1-0.1,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
          c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1c0.9,0.9,2,1.5,3.3,1.8c0.4,0.1,0.9,0.1,1.4,0.1c0.5,0,1-0.1,1.5-0.2
          c1.2-0.3,2.2-0.9,3.1-1.7c0.1-0.1,0.2-0.2,0.4-0.2c0.3-0.1,0.6-0.1,0.9,0c0.1,0.1,0.3,0.1,0.4,0.2c0.7,0.7,1.7,1.3,2.7,1.6
          c0,0,0,0,0,0c0.6,0.2,1.3,0.3,1.9,0.3c0.7,0,1.4-0.1,2.1-0.3c1-0.3,1.8-0.9,2.5-1.6c0.1-0.1,0.1-0.1,0.2-0.1
          c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1c0.6,0.6,1.2,1,2,1.3
          c0.1,0.1,0.2,0.1,0.3,0.1h-0.1c0.3,0.1,0.7,0.2,1,0.3c0.5,0.1,1,0.2,1.5,0.2c1.2,0,2.4-0.8,3.5-1.6C43.7,25.8,43.7,25.6,43.6,25.4
          z M18.4,24.8c-0.5,0-0.9-0.4-0.9-0.8c0-0.5,0.4-0.8,0.9-0.8c0.5,0,0.9,0.4,0.9,0.8C19.3,24.5,18.9,24.8,18.4,24.8z M29.5,23.1
          c0.5,0,0.9,0.4,0.9,0.8c0,0.5-0.4,0.8-0.9,0.8c-0.5,0-0.9-0.4-0.9-0.8C28.6,23.5,29,23.1,29.5,23.1z'
        />
        <path fill='${!!color ? color : 'white'}' d='M37.7,27.1h-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1C37.5,27,37.6,27.1,37.7,27.1z'/>
        <path fill='${!!color ? color : 'white'}' d='M35,16.6V18c0,0.1,0,0.2,0,0.2h-6.2c0-0.1-0.1-0.2,0-0.4l0.5-1.4H35C35,16.5,35,16.5,35,16.6z'/>
      </g>
    `,
    'railway': `
      <g>
        <path fill='${!!color ? color : 'white'}' d='M20.3,14.3v9.2H19c0-0.1,0-0.1,0-0.2v-9H20.3z'/>
        <path fill='${!!color ? color : 'white'}' d='M41.3,19.4h-8.2c-0.1,0-0.1-0.1-0.1-0.1v-2.5c0-0.1,0-0.1,0.1-0.1h6.6C40.4,17.4,41.1,18.4,41.3,19.4z'/>
        <path fill='${!!color ? color : 'white'}' d='M17.8,13.8c-0.1-0.1-0.3-0.2-0.4-0.2H9.5c-0.3,0-0.6,0.3-0.6,0.6v1.9h0.7c0.5,0,0.9,0.4,0.9,0.9v2.1
          c0,0.5-0.4,0.9-0.9,0.9H8.9v3.4c0,0.3,0.3,0.6,0.6,0.6h7.9c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.1,0-0.2v-9.1
          C18,14,17.9,13.9,17.8,13.8z M15.3,19.1c0,0.5-0.4,0.9-0.9,0.9h-1c-0.5,0-0.9-0.4-0.9-0.9V17c0-0.5,0.4-0.9,0.9-0.9h1
          c0.5,0,0.9,0.4,0.9,0.9V19.1z'
        />
        <path fill='${!!color ? color : 'white'}' d='M33.1,20.5c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h5.4c-0.3-0.2-0.5-0.4-0.8-0.6
          c-0.4-0.3-0.9-0.6-1.2-0.8c-0.7-0.4-1.5-0.6-2.3-0.6H21.8c-0.1,0-0.3,0.1-0.3,0.2c0,0.1-0.1,0.1-0.1,0.2v9.5
          c0,0.2,0.2,0.4,0.4,0.4h18.7c0.8-0.6,1-2.4,1-3.5H33.1z M28.1,19.1c0,0.5-0.4,0.9-0.9,0.9h-1c-0.5,0-0.9-0.4-0.9-0.9V17
          c0-0.5,0.4-0.9,0.9-0.9h1c0.5,0,0.9,0.4,0.9,0.9V19.1z'
        />
        <path fill='${!!color ? color : 'white'}' d='M43.7,26c0,0.2-0.2,0.4-0.4,0.4H7.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h3.5
          c-0.2-0.1-0.3-0.3-0.3-0.6h1.7c0,0.2-0.1,0.4-0.3,0.6h3.8c-0.2-0.1-0.3-0.3-0.4-0.6H17c0,0.2-0.2,0.4-0.4,0.6h6.7
          C23.2,25.4,23,25.2,23,25h1.7c0,0.2-0.1,0.4-0.3,0.6h3.7c-0.2-0.1-0.3-0.3-0.3-0.6h1.7c0,0.2-0.1,0.4-0.3,0.6h3.3
          c-0.2-0.1-0.3-0.3-0.3-0.6h1.7c0,0.2-0.1,0.4-0.3,0.6h3.8C37.1,25.4,37,25.3,37,25h1.7c0,0.2-0.2,0.4-0.4,0.6h4.9
          C43.5,25.6,43.7,25.8,43.7,26z'
        />
        <path fill='${!!color ? color : 'white'}' d='M40.1,28.3H9.7c-0.2,0-0.4-0.2-0.4-0.5c0-0.3,0.2-0.5,0.4-0.5h30.4c0.2,0,0.4,0.2,0.4,0.5C40.5,28,40.3,28.3,40.1,28.3z'/>
      </g>
    `,
    'bus': `
      <g>
        <path fill='${!!color ? color : 'white'}' d='M17.2,12.7h-7.2v-0.9h5.7c0.2,0,0.4,0.1,0.6,0.2L17.2,12.7z'/>
        <path fill='${!!color ? color : 'white'}' d='M39.2,15.6h-4.6c-0.1,0-0.2,0.1-0.2,0.2v2.9c0,0.1,0.1,0.2,0.2,0.2h5.2C39.6,17.6,39.4,16.5,39.2,15.6z
          M40.2,21.6H8.4v2.7c0,0.5,0.4,1,1,1h0.3v-0.1c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v0.1h17.5v-0.1c0-1.4,1.1-2.5,2.5-2.5
          c1.4,0,2.5,1.1,2.5,2.5v0.1H39c0.7,0,1.4-0.6,1.4-1.4C40.4,23.4,40.3,22.6,40.2,21.6z M27.3,23.6H18c-0.3,0-0.5-0.2-0.5-0.5
          c0-0.3,0.2-0.5,0.5-0.5h9.4c0.3,0,0.5,0.2,0.5,0.5C27.8,23.4,27.6,23.6,27.3,23.6z'
        />
        <path fill='${!!color ? color : 'white'}' d='M39.9,19.7h-5.4c-0.6,0-1.1-0.5-1.1-1.1v-2.9c0-0.6,0.5-1.1,1.1-1.1h4.4c-0.1-0.4-0.4-0.9-0.9-0.9H9.4
          c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.7,0.5-0.7,0.9v6h31.7C40,20.3,40,20,39.9,19.7z M14.8,18.4c0,0.3-0.2,0.5-0.5,0.5h-3.2
          c-0.3,0-0.5-0.2-0.5-0.5v-2.5c0-0.3,0.2-0.5,0.5-0.5h3.2c0.3,0,0.5,0.2,0.5,0.5V18.4z M20.1,18.4c0,0.3-0.2,0.5-0.4,0.5
          c0,0-0.1,0-0.1,0h-3.2c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.3-0.4-0.5v-2.5c0-0.3,0.2-0.5,0.5-0.5h3.2c0.3,0,0.5,0.2,0.5,0.5V18.4z
          M25.4,18.4c0,0.3-0.2,0.5-0.5,0.5h-3.2c-0.3,0-0.5-0.2-0.5-0.5v-2.5c0-0.3,0.2-0.5,0.5-0.5h3.2c0.3,0,0.5,0.2,0.5,0.5V18.4z
          M30.7,18.4c0,0.3-0.2,0.5-0.5,0.5h-3.2c-0.3,0-0.5-0.2-0.5-0.5v-2.5c0-0.3,0.2-0.5,0.5-0.5h3.2c0.3,0,0.5,0.2,0.5,0.5V18.4z'
        />
        <path fill='${!!color ? color : 'white'}' d='M13.8,25.2C13.8,25.2,13.8,25.2,13.8,25.2c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.3-0.7,0.6-1.2,0.6c-0.5,0-0.9-0.2-1.2-0.6c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.1c0-0.9,0.7-1.6,1.6-1.6C13.1,23.6,13.8,24.3,13.8,25.2z'/>
        <path fill='${!!color ? color : 'white'}' d='M36.3,25.2C36.3,25.2,36.3,25.2,36.3,25.2c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.3-0.7,0.6-1.2,0.6c-0.5,0-0.9-0.2-1.2-0.6c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.1c0-0.9,0.7-1.6,1.6-1.6C35.6,23.6,36.3,24.3,36.3,25.2z'/>
      </g>
    `,
    'car': `
      <g>
        <path fill='${!!color ? color : 'white'}' d='M29.4,18.5h-8c-0.1,0-0.3-0.1-0.3-0.2v-2.1c0-0.1,0.1-0.3,0.3-0.3h5.3L29.4,18.5z'/>
        <path fill='${!!color ? color : 'white'}' d='M33.1,18.5h-2.8l-2.7-2.6h1.4c1.4,0.8,2.9,1.9,3.8,2.5L33.1,18.5z'/>
        <path fill='${!!color ? color : 'white'}' d='M41.1,23.5l-0.3-0.1l-0.1-1.7c0-0.1,0-0.2,0-0.2c-0.2-0.5-0.6-1.7-1.5-2c0,0-0.8-0.2-1.8-0.3
          c-1-0.2-2.3-0.3-3.2-0.3c-0.1,0-0.2,0-0.2,0c-0.2,0.2-0.4,0.3-0.7,0.3H21.4c-0.5,0-0.9-0.4-0.9-0.9v-2.1c0-0.5,0.4-0.9,0.9-0.9
          h6.4c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-0.1-1-0.2-1.3-0.2l-0.2,0l-3.8-0.2c-1.5,0-3.8,0.3-6.2,1.8c-0.1,0.1-0.1,0.1-0.2,0.1
          l-2.8,1.4c-0.4,0.2-1,0.5-1.6,0.5c-0.6,0-1.1,0.5-1.1,1.1v4l-0.2,0.1c-0.1,0-0.2,0.2-0.2,0.3v0.8c0,0.2,0.1,0.3,0.3,0.3H12
          c-0.1-0.2-0.1-0.5-0.1-0.7c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1c0,0.2,0,0.5-0.1,0.7h13.9c-0.1-0.2-0.1-0.5-0.1-0.7
          c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1c0,0.2,0,0.5-0.1,0.7h3.2c0.1,0,0.2-0.1,0.2-0.3v-0.8C41.4,23.7,41.2,23.5,41.1,23.5z
          M19.7,18.6c0,0.2-0.2,0.4-0.4,0.4h-4.1c-0.6,0-0.8-0.7-0.4-1l2.7-1.5c0,0,1-0.6,1.8-1c0.2-0.1,0.4,0.1,0.4,0.2V18.6z M23.1,20.7
          h-1.9c-0.2,0-0.3-0.2-0.3-0.3S21,20,21.2,20h1.9c0.2,0,0.3,0.2,0.3,0.3S23.3,20.7,23.1,20.7z'
        />
        <path fill='${!!color ? color : 'white'}' d='M17.4,24.2c0,0.3,0,0.5-0.1,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.6-1.2,1-2,1c-0.8,0-1.5-0.4-2-1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.5-0.1-0.7c0-1.3,1.1-2.4,2.4-2.4C16.3,21.8,17.4,22.9,17.4,24.2z'/>
        <path fill='${!!color ? color : 'white'}' d='M37.3,24.2c0,0.2,0,0.5-0.1,0.7c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.6-1.2,1-2,1c-0.8,0-1.5-0.4-2-1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.5-0.1-0.7c0-1.3,1.1-2.4,2.4-2.4C36.2,21.8,37.3,22.9,37.3,24.2z'/>
      </g>
    `,
  }
  return icons[type]
}

export const markerSizes: {[key: string]: [number, number]} = {
  '0': [30.2, 43.25], //[27.2, 40.25],
  '1': [30.2, 43.25],
  '2': [39.2, 52.25],
  '3': [48.2, 61.25],
}

export const markerColors: {[key: string]: string} = {
  'airport': '%2359bab4',
  'port': '%23ed9115',
  'railway': '%23afd13a',
  'bus': '%23f5c441',
  'car': '%23f31711',
}

export const getMarkerSvgIconGoogleMap = (markerData: AirportSearchResultType | PortSearchResultType, markerNumber: number | null) => {
  const iconSize = markerSizes[markerData.details?.category || 0]
  const iconColor = markerColors[markerData.type]
    // @ts-ignore
  const isLightStyleIcon = markerData.details?.with_schedule_flights === false

  return `data:image/svg+xml;charset=UTF-8,
    <svg width='${iconSize[1]}px' height='${iconSize[0]}px' viewBox='0 0 48.4 73.3' fill='none' xmlns='http://www.w3.org/2000/svg' className='node-marker'>
      <g>  
        <path fill='${isLightStyleIcon ? 'white' : iconColor}' d='M24,62.6c-0.6,0-1.2-0.1-1.8-0.3l-0.1,0l-0.1,0c-1.7-0.2-6.4-8-9.2-12.7c-1.6-2.7-2.9-4.8-3.6-5.5c-6.2-8.5-9.2-14.7-8.9-18.5C0,19.4,2.2,13.5,6.5,9C11.2,4,18.2,1,25,1c4.3,0,10.6,1.2,15.9,6.9c8,7.6,9.1,20.8,2.5,29.5c-4.6,6.8-10.8,16.4-14.9,22.9C27.5,61.7,25.9,62.6,24,62.6L24,62.6z'/>
        <path fill='${isLightStyleIcon ? iconColor : 'white'}' strokeWidth='3' d='M25,1.5c4.2,0,10.4,1.2,15.5,6.7l0,0l0,0c7.8,7.4,8.9,20.3,2.4,28.8l0,0l0,0C38.3,43.9,32.1,53.5,28,60c-0.8,1.3-2.3,2.2-4,2.2c-0.6,0-1.1-0.1-1.6-0.3l-0.1,0l-0.1,0c-1.5-0.3-6.7-8.8-8.9-12.5c-1.8-3-2.9-4.8-3.6-5.5c-6.1-8.4-9.1-14.5-8.8-18.2l0-0.1l0-0.1c-0.3-5.9,1.8-11.7,6-16.1C11.5,4.4,18.3,1.5,25,1.5 M25,0.5c-12.9,0-25.9,10.4-25.1,25c-0.4,5.9,6.8,15.8,9.1,18.9c1.8,1.9,10.2,18.1,13.1,18.4c0.6,0.2,1.3,0.4,2,0.4c1.9,0,3.8-1,4.8-2.6c3.9-6.3,10.2-15.9,14.9-22.8c6.8-8.9,5.6-22.4-2.5-30.1C36.7,2.6,30.8,0.5,25,0.5L25,0.5z'/>
        ${markerNumber === null ? '' : `<text font-family='century-gothic, sans-serif' font-weight='900' font-size='22px' fill='${isLightStyleIcon ? iconColor : 'white'}' text-anchor='middle' transform="matrix(1.0071 0 0 1 24 29.3242)">${markerNumber}</text>`}
      </g> 
      <path fill='${isLightStyleIcon ? 'white' : '%23191559'}' d='M43.9,37.5L25.3,72.1c-0.2,0.4-0.6,0.6-1,0.6c-0.4,0-0.8-0.2-1-0.6L4.6,37.5c-0.5-1,1.3-2.7,2.3-2c3,1.8,14,10.1,17.3,11.7c3.3-1.6,14.3-9.9,17.3-11.7C42.6,34.9,44.5,36.5,43.9,37.5z'/>
      <path fill='${isLightStyleIcon ? iconColor : 'white'}' d='M6.4,35.4c0.2,0,0.4,0,0.5,0.2l3.8,2.6c0.6,0,12.7,9,13.7,9.1c4.6-2.7,13.5-9.1,13.5-9.1l3.8-2.6
        c0.2-0.1,0.3-0.2,0.5-0.2c1,0,2.2,1.3,1.8,2.2c-13.3,23-16.5,34.4-19.6,34.4c-3.2,0-6.3-11.5-19.6-34.4
        C4.2,36.6,5.4,35.4,6.4,35.4 M42.1,34.2L42.1,34.2c-0.4,0-0.8,0.1-1.2,0.4l-3.8,2.6c-0.1,0.1-8.3,6-12.9,8.8
        c-1.3-0.7-4.9-3.3-7.3-5c-4.1-2.9-5.2-3.7-5.8-3.9l-3.6-2.5c-0.3-0.2-0.7-0.4-1.2-0.4c-1,0-2,0.7-2.6,1.6
        c-0.4,0.7-0.5,1.5-0.2,2.2l0,0l0,0C9.5,48.2,13.3,56,16.1,61.6c4,8.1,5.6,11.4,8.1,11.4c2.5,0,4.1-3.4,8.2-11.5
        c2.8-5.7,6.7-13.5,12.5-23.5l0,0l0,0c0.3-0.7,0.3-1.5-0.2-2.2C44.2,34.9,43.1,34.2,42.1,34.2L42.1,34.2z'
      />
    </svg>
  `
}

export const getMapMarker = (map: any, maps:any, location: {lat: number, lng: number}, point: AirportSearchResultType | PortSearchResultType, pointIndex: number, pointId: number, distanceType: DistanceTypeType) => {
  const size = markerSizes[point.details?.category || 0]
  const marker = !!maps ? new maps.Marker({
    position: location,
    map: map,
    icon: {
      url:  getMarkerSvgIconGoogleMap(point, pointIndex),
      size: new google.maps.Size(size[1], size[0])
    },
    // icon: getMarkerSvgIconGoogleMap(point, pointIndex),
    zIndex: 2,

  }) : null
  const itemCardInList = document.getElementsByClassName(String(pointId))
  const nodeDetailsBtn = document.getElementById('nodeDetailsBtn'+pointId)
  const nodeDetailsTerminalsBtn = document.getElementById('nodeDetailsTerminalsBtn'+pointId)
  const infoWindow = !!maps ? new maps.InfoWindow({
    content: `
      <div style='font-size: 18px; margin-bottom: 10px; width: 260px;'>${point?.details?.name} ${point?.details?.code ? '('+point?.details?.code+')' : ''}</div>
      <div>${point?.details?.city?.name}${point?.details?.city?.state_name ? ', ' : ''} ${point?.details?.city?.state_name || ''}
        (${point?.details?.distance && distanceType === 'km'
          ? point?.details?.distance.toFixed(0)
          : (point?.details?.distance / 1.609).toFixed(0)} ${distanceType})
      </div>
    `,
    id: pointId
  }) : null

  nodeDetailsBtn?.addEventListener('click', () => infoWindow.close())
  nodeDetailsTerminalsBtn?.addEventListener('click', () => infoWindow.close())
  itemCardInList[itemCardInList.length - 1]?.addEventListener('mouseover', () => infoWindow.open(map, marker))
  itemCardInList[itemCardInList.length - 1]?.addEventListener('mouseout', () => infoWindow.close())

  google.maps.event.addListener(marker, 'mouseover', (evt:any) => infoWindow.open(map, marker))
  google.maps.event.addListener(marker, 'mouseout', (evt:any) => infoWindow.close())

  return {marker, infoWindow}
}

export const searchLocationIcon = `
  data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width='50px' height='50px' viewBox="0 0 45.25 69.28">
    <g>
      <path class="cls-2" fill='%239f9eba' d="M22.73,29.63a9.48,9.48,0,1,1,9.48-9.48A9.5,9.5,0,0,1,22.73,29.63Zm21.42-7.31a.17.17,0,0,0,0-.07v-.34C44.16,22.05,44.16,22.19,44.15,22.32Z"/>
      <path class="cls-3" fill='%23191559' d="M44.16,21.91c0,.14,0,.28,0,.41a21.17,21.17,0,0,1-3,9.24l-.05.08L40.23,33l-.07.1c-1.95,3-4,5.95-4,5.95L27,53.32a5.08,5.08,0,0,1-1.42,1.47,4.74,4.74,0,0,1-1,.54,5.13,5.13,0,0,1-3.6,0,5.21,5.21,0,0,1-1.05-.54,5.08,5.08,0,0,1-1.42-1.47L9.33,39.05s-2.08-3-4-5.95L5.23,33l-.89-1.36,0-.08a21.17,21.17,0,0,1-3-9.24c0-.13,0-.27,0-.41v-.3A20.85,20.85,0,0,1,7.52,6.4,20.86,20.86,0,0,1,22.67.12h.12a20.6,20.6,0,0,1,15,6.45A21.11,21.11,0,0,1,44.16,21.91Z"/>
      <line class="cls-4" fill='%23none' stroke='%23fff' strokeLinecap='round' strokeWidth='2px' strokeMiterlimit='10' x1="16.68" y1="9.2" x2="16.68" y2="44.73"/>
      <path class="cls-5" fill='white' stroke='%23191559' strokeWidth='1.13px' strokeMiterlimit='10' d="M35.5,11.21c-.29-1.74-3.56,1.08-8.95,0,0,0-5.51-1.94-9-.88a1.28,1.28,0,0,0-.91,1.43l.18,8.7c0,1.05.89.78,1.78.55,3.29-.85,8.39.22,8.39.22A11.36,11.36,0,0,0,36.36,19a.44.44,0,0,0,0-.61l-2.95-2.61S35.51,11.27,35.5,11.21Z"/>
      <path class="cls-6" fill='%23f31711' d="M22.73,42.18V65a1,1,0,0,1-.91-.56L5.32,33.52c-.48-.94,1.19-2.39,2-1.79l3.34,2.32,11.45,8A1,1,0,0,0,22.73,42.18Z"/>
      <path class="cls-6" fill='%23f31711' d="M22.73,42.18V65a1,1,0,0,0,.9-.56l16.5-30.87c.48-.94-1.19-2.39-2-1.79l-3.34,2.32L23.3,42A1,1,0,0,1,22.73,42.18Z"/>
      <path class="cls-7" fill='white' d="M38.55,31.59c.87,0,2,1.15,1.58,1.92L23.63,64.39a1,1,0,0,1-.91.55,1,1,0,0,1-.9-.55L5.32,33.51c-.41-.77.69-1.92,1.56-1.92a.9.9,0,0,1,.48.14L10.69,34l11.46,8a1,1,0,0,0,.57.18A1,1,0,0,0,23.3,42l11.45-8,3.34-2.31a.83.83,0,0,1,.46-.14m0-.75h0a1.57,1.57,0,0,0-.9.28l-3.33,2.31-11.45,8a.26.26,0,0,1-.15,0,.2.2,0,0,1-.14,0l-11.47-8L7.78,31.12a1.55,1.55,0,0,0-.9-.28,2.63,2.63,0,0,0-2.09,1.29,1.73,1.73,0,0,0-.14,1.73l16.5,30.89a1.78,1.78,0,0,0,3.13,0L40.79,33.87a1.72,1.72,0,0,0-.14-1.73,2.62,2.62,0,0,0-2.1-1.3Z"/>
    </g>
  </svg>
`

export const showTransportStopMarker = (map: any, maps: any, selectedTransportStop: {id: number, location: {latitude: number, longitude: number}, name: string}, showInfoByDefault?: boolean) => {
  const infowindow = !!maps ? new maps.InfoWindow({
    content: `<div>${selectedTransportStop?.name}</div>`,
  }) : null

  function isInfoWindowOpen(infoWindow:any){
    var map = infoWindow.getMap();
    return (map !== null && typeof map !== "undefined");
}

  const marker = !!maps ? new maps.Marker({
    position: {lat: selectedTransportStop?.location?.latitude, lng: selectedTransportStop?.location?.longitude},
    map,
    id: selectedTransportStop?.id
  }) : null

  if (showInfoByDefault !== false) {
    infowindow.open({
      anchor: marker,
      map,
    })
  }

  marker?.addListener('click', () => {
    if (isInfoWindowOpen(infowindow)){
      infowindow.close()
    } else {
      infowindow.open({
        anchor: marker,
        map,
      })
    }
  })

  return marker
}

export const getMapZoom = (location: {lat: number, lng: number}, pointsAmount: number) => {
  return location.lng === -71.542969 && location.lat === -35.675147 ? 4
  : location.lng === -75.015152 && location.lat === -9.189967 ? 5
  : location.lng === 138.252924 && location.lat === 36.204824 ? 5
  : location.lng === -102.552784 && location.lat === 23.634501 ? 5
  : location.lng === -3.435973 && location.lat === 55.378051 ? 6
  : location.lng === 19.145136 && location.lat === 51.919438 ? 6
  : location.lng === 35.243322 && location.lat === 38.963745 ? 6
  : location.lng === -106.346771 && location.lat === 56.130366 ? 4
  : location.lng === 2.213749 && location.lat === 46.227638 ? 6
  : location.lng === 10.451526 && location.lat === 51.165691 ? 6
  : location.lng === 12.56738 && location.lat === 41.87194 ? 6
  : pointsAmount < 3 && location.lng === -95.712891 && location.lat === 37.09024 ? 4
  : pointsAmount > 6 ? 4
      : pointsAmount > 5 ? 6 : pointsAmount <= 5 ? 7
          : 8
}

export const getDashedPath = (
  maps: any,
  path: {
    id: number;
    info: string;
    lat: number;
    lng: number;
    stopData?: any;
  }[],
  showPlane: boolean
) => {
  const planePathIcon = 'M 20.8555 13.532 35.1964 23.2931 35.0708 26.9576 20.5713 21.8271 20.2349 31.648 25.6271 34.8889 25.5329 37.6373 17.3505 35.5225 9.04257 37.0723 9.13672 34.3239 14.7381 31.4579 15.0746 21.637 0.257908 25.7649 0.383453 22.1004 15.3587 13.3442 15.7006 3.36385 15.7256 2.63493 16.0391 1.94579 16.5722 1.44802 17.1053 0.950257 17.8143 0.684645 18.5432 0.709617 19.2721 0.734589 19.9612 1.0481 20.459 1.58118 20.9568 2.11426 21.2224 2.82325 21.1974 3.55216 20.8555 13.5325'

  const planeIcon = {
    path: planePathIcon,
    strokeColor: '#191559',
    fillColor: '#191559',
    fillOpacity: 1,
    scale: 0.84,
    anchor: new google.maps.Point(18, 0)
  };

  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    strokeWeight: 2,
    scale: 4,
  };

  const lineSymbolArrow = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    strokeOpacity: 1,
    scale: 2,
    fillColor: '#191559',
    fillOpacity: 1,
  };

  const getPathSegments = () => {
    return path
      .map((pathItem, i) => ([
        {lat: pathItem?.lat, lng: pathItem?.lng},
        {lat: path[i + 1]?.lat, lng: path[i + 1]?.lng}
      ]))
      .slice(0, -1)
  }

  const pathSegments = getPathSegments()

  return pathSegments.map(segment => (
    !!maps ? new google.maps.Polyline({
      path: segment,
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          repeat: '20px',
        },
        ...(showPlane ? [{
          icon: planeIcon,
          offset: '50%',
        }] : [{
            icon: lineSymbolArrow,
            offset: '50%',
          }]),
      ],
      geodesic: true,
      strokeColor: '#191559',
      strokeWeight: 2,
    }) : null
  ))
}

export const destinationIcon = `
  data:image/svg+xml;charset=UTF-8,
  <svg width='50px' height='50px' viewBox="0 0 45.25 69.28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.6571 6.36651C31.8973 4.42584 29.7746 2.88192 27.4201 1.8301C25.0656 0.778279 22.5292 0.240897 19.9677 0.251141H19.8646C17.2975 0.222443 14.7513 0.735587 12.3794 1.75969C10.0075 2.78379 7.85883 4.29767 6.06277 6.2102C4.23779 8.09535 2.7986 10.3469 1.83229 12.8287C0.865967 15.3104 0.392608 17.9707 0.440849 20.6487V20.932C0.440849 21.059 0.440849 21.1958 0.440849 21.3228C0.675031 24.4346 1.60569 27.4459 3.15811 30.1148V30.193L3.97329 31.4825L4.03888 31.5802C5.80979 34.4034 7.7025 37.2266 7.7025 37.2266L16.023 50.7957C16.2306 51.1311 16.476 51.4393 16.7538 51.714C16.9327 51.8841 17.1204 52.0439 17.316 52.1927C17.6106 52.3954 17.9246 52.5656 18.253 52.7007C19.3104 53.1126 20.475 53.1126 21.5324 52.7007C22.0993 52.4914 22.6168 52.1583 23.0504 51.7238C23.3407 51.4526 23.593 51.1402 23.8 50.7957L32.111 37.2462C32.111 37.2462 33.985 34.423 35.784 31.5997L35.8496 31.502C36.1213 31.0722 36.3931 30.6424 36.6554 30.2125L36.7023 30.1344C38.2465 27.4615 39.1765 24.4523 39.4195 21.3423C39.4241 21.3198 39.4241 21.2965 39.4195 21.2739V20.9516C39.4544 18.2406 38.9618 15.5504 37.9714 13.0436C36.9809 10.5367 35.5133 8.26529 33.6571 6.36651ZM19.9115 28.2587C18.2047 28.2587 16.5362 27.7311 15.1171 26.7424C13.6979 25.7538 12.5918 24.3486 11.9387 22.7046C11.2855 21.0606 11.1146 19.2515 11.4476 17.5063C11.7806 15.761 12.6025 14.1578 13.8094 12.8995C15.0162 11.6413 16.5539 10.7844 18.2279 10.4372C19.9019 10.09 21.637 10.2682 23.2139 10.9492C24.7907 11.6302 26.1385 12.7834 27.0868 14.2629C28.035 15.7425 28.5411 17.482 28.5411 19.2615C28.5362 21.6462 27.6254 23.9316 26.0081 25.6178C24.3908 27.304 22.1987 28.2536 19.9115 28.2587Z" fill="%2363BDC7"/>
  <path d="M33.7325 6.20994C31.9418 4.30081 29.7994 2.7888 27.434 1.76476C25.0687 0.740715 22.5292 0.225774 19.9681 0.250875H19.865C17.3035 0.240632 14.7672 0.778014 12.4126 1.82983C10.0581 2.88165 7.93541 4.42557 6.17565 6.36624C4.32005 8.26192 2.85199 10.5298 1.85999 13.0332C0.868003 15.5365 0.372646 18.2234 0.403803 20.9318V21.2541C0.399253 21.2767 0.399253 21.3 0.403803 21.3225C0.637985 24.4343 1.56864 27.4456 3.12107 30.1146V30.1927L3.93625 31.4822L4.00184 31.5799C5.77274 34.4032 7.66546 37.2264 7.66546 37.2264L16.0234 50.7955C16.2311 51.1308 16.4764 51.4391 16.7542 51.7137C16.9332 51.8838 17.1208 52.0436 17.3164 52.1924C17.611 52.3951 17.9251 52.5654 18.2534 52.7004C19.3109 53.1123 20.4754 53.1123 21.5329 52.7004C22.0998 52.4911 22.6173 52.158 23.0508 51.7235C23.3411 51.4523 23.5935 51.1399 23.8004 50.7955L32.1115 37.2459C32.1115 37.2459 33.9854 34.4227 35.7845 31.5995L35.85 31.5018C36.1218 31.0719 36.3935 30.6421 36.6559 30.2123L36.7027 30.1341C38.2469 27.4613 39.177 24.4521 39.42 21.3421C39.42 21.2151 39.42 21.0783 39.42 20.9513V20.668C39.4622 17.9819 38.9798 15.3149 38.002 12.8293C37.0242 10.3437 35.5716 8.09158 33.7325 6.20994ZM19.9119 28.2585C18.2051 28.2585 16.5366 27.7308 15.1175 26.7422C13.6984 25.7535 12.5923 24.3484 11.9391 22.7043C11.286 21.0603 11.1151 19.2513 11.448 17.506C11.781 15.7607 12.6029 14.1576 13.8098 12.8993C15.0167 11.641 16.5543 10.7841 18.2283 10.4369C19.9023 10.0898 21.6374 10.268 23.2143 10.9489C24.7912 11.6299 26.1389 12.7831 27.0872 14.2627C28.0354 15.7423 28.5415 17.4818 28.5415 19.2613C28.5366 21.6459 27.6258 23.9314 26.0085 25.6176C24.3912 27.3037 22.1991 28.2533 19.9119 28.2585Z" fill="%2363BDC7"/>
  <path d="M19.9128 40.1654V61.7743C19.7424 61.7729 19.5755 61.723 19.4302 61.6301C19.2849 61.5371 19.1667 61.4046 19.0883 61.2468L4.09647 31.9399C3.66546 31.0607 5.17401 29.6833 5.97045 30.2499L9.01566 32.4479L19.435 39.9895C19.5735 40.0973 19.7401 40.1586 19.9128 40.1654Z" fill="%23191559"/>
  <path d="M19.9121 40.1654V61.7743C20.0826 61.7729 20.2494 61.723 20.3947 61.6301C20.54 61.5371 20.6582 61.4046 20.7367 61.2468L35.7285 31.9399C36.1688 31.0607 34.6509 29.6833 33.8545 30.2499L30.8186 32.4479L20.4368 39.9895C20.2861 40.1085 20.1011 40.1705 19.9121 40.1654Z" fill="%2357BEC0"/>
  <path d="M34.3222 30.1136C35.1186 30.1136 36.1212 31.2077 35.7558 31.9404L20.764 61.2473C20.6836 61.4047 20.5634 61.5362 20.4163 61.6275C20.2693 61.7188 20.1012 61.7663 19.9301 61.765C19.7622 61.7628 19.5979 61.7136 19.4545 61.6225C19.3111 61.5314 19.1938 61.4018 19.1149 61.2473L4.1231 31.9404C3.7483 31.2566 4.68529 30.1136 5.4911 30.1136C5.64456 30.1139 5.79453 30.1615 5.92211 30.2504L8.94858 32.4484L19.3866 40.0486C19.5361 40.1629 19.7166 40.2245 19.902 40.2245C20.093 40.224 20.2794 40.1627 20.436 40.0486L30.8554 32.4972L33.9006 30.2797C34.0247 30.191 34.1717 30.1433 34.3222 30.1429M34.3222 29.166C33.9875 29.1737 33.662 29.2823 33.3852 29.4786L30.3494 31.6669L19.902 39.1987L9.48266 31.6473L6.45619 29.4493C6.18138 29.2487 5.85467 29.1397 5.51921 29.1367C5.08545 29.1655 4.665 29.3042 4.29434 29.5409C3.92368 29.7775 3.61402 30.1049 3.39224 30.4946C3.21751 30.7728 3.11375 31.0928 3.0908 31.4244C3.06784 31.7561 3.12645 32.0884 3.26107 32.3898L18.2529 61.6967C18.4114 62.0072 18.6475 62.2673 18.9361 62.4496C19.2247 62.6318 19.5552 62.7295 19.8926 62.7322C20.226 62.737 20.5546 62.649 20.8444 62.4773C21.1343 62.3056 21.375 62.0563 21.5417 61.7553L36.5335 32.4484C36.6844 32.1412 36.7519 31.7968 36.7287 31.4525C36.7056 31.1081 36.5928 30.7769 36.4023 30.4946C36.1799 30.1055 35.8701 29.7785 35.4996 29.542C35.1291 29.3054 34.7089 29.1663 34.2754 29.1367L34.3222 29.166Z" fill="white"/>  
  <path d="M19.9119 28.2639C24.6779 28.2639 28.5415 24.2358 28.5415 19.2667C28.5415 14.2977 24.6779 10.2695 19.9119 10.2695C15.1459 10.2695 11.2822 14.2977 11.2822 19.2667C11.2822 24.2358 15.1459 28.2639 19.9119 28.2639Z" fill="white"/>
  </svg>
`

export const flightTransferIcon = `
  data:image/svg+xml;charset=UTF-8,
  <svg width='50px' height='50px' viewBox="0 0 45.25 69.28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 0.5C10.4225 0.5 0.25 10.6725 0.25 23.25C0.25 40.3125 23 65.5 23 65.5C23 65.5 45.75 40.3125 45.75 23.25C45.75 10.6725 35.5775 0.5 23 0.5ZM23 31.375C20.8451 31.375 18.7785 30.519 17.2548 28.9952C15.731 27.4715 14.875 25.4049 14.875 23.25C14.875 21.0951 15.731 19.0285 17.2548 17.5048C18.7785 15.981 20.8451 15.125 23 15.125C25.1549 15.125 27.2215 15.981 28.7452 17.5048C30.269 19.0285 31.125 21.0951 31.125 23.25C31.125 25.4049 30.269 27.4715 28.7452 28.9952C27.2215 30.519 25.1549 31.375 23 31.375Z" fill="%23FF0000"/>
  </svg>
`
export const portWaypointIcon = `
  data:image/svg+xml;charset=UTF-8,
  <svg width="40px" height="43px" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.61 3.4502V38.9902" stroke="%23ED9115" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M31.43 5.46042C31.15 3.73042 27.87 6.54042 22.48 5.46042C22.48 5.46042 16.97 3.52042 13.48 4.57042C13.1742 4.6525 12.9084 4.84258 12.7319 5.10547C12.5555 5.36837 12.4802 5.68629 12.52 6.00042L12.7 14.6904C12.7 15.7404 13.59 15.4804 14.48 15.2504C17.77 14.4004 22.87 15.4704 22.87 15.4704C24.5129 15.8032 26.2088 15.7715 27.8382 15.3775C29.4675 14.9836 30.9906 14.237 32.3 13.1904C32.3766 13.1073 32.4191 12.9984 32.4191 12.8854C32.4191 12.7724 32.3766 12.6635 32.3 12.5804L29.38 10.0004C29.38 10.0004 31.44 5.53042 31.43 5.46042Z" fill="%23ED9115" stroke="%23ED9115" strokeWidth="1.13" strokeMiterlimit="10"/>
  </svg>
`

export const portExcursionIcon = `
  data:image/svg+xml;charset=UTF-8,
  <svg width="40px" height="41px" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 17.1663C21.7681 17.1663 23.4638 16.464 24.714 15.2137C25.9643 13.9635 26.6666 12.2678 26.6666 10.4997C26.6666 8.73156 25.9643 7.03587 24.714 5.78563C23.4638 4.53539 21.7681 3.83301 20 3.83301C18.2319 3.83301 16.5362 4.53539 15.2859 5.78563C14.0357 7.03587 13.3333 8.73156 13.3333 10.4997C13.3333 12.2678 14.0357 13.9635 15.2859 15.2137C16.5362 16.464 18.2319 17.1663 20 17.1663ZM20 17.1663V37.1663" stroke="%23191559" stroke-width="3"/>
  </svg>
`

export const portGenericIcon = `
  data:image/svg+xml;charset=UTF-8,
  <svg width="40px" height="41px" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 18C21.3807 18 22.5 16.8807 22.5 15.5C22.5 14.1193 21.3807 13 20 13C18.6193 13 17.5 14.1193 17.5 15.5C17.5 16.8807 18.6193 18 20 18Z" fill="%23191559"/>
    <path d="M20 3C13.1078 3 7.5 8.36328 7.5 14.9531C7.5 18.0914 8.93047 22.2648 11.7516 27.3578C14.0172 31.4469 16.6383 35.1445 18.0016 36.9844C18.2319 37.2987 18.5331 37.5544 18.8807 37.7306C19.2284 37.9069 19.6126 37.9987 20.0023 37.9987C20.3921 37.9987 20.7763 37.9069 21.1239 37.7306C21.4716 37.5544 21.7728 37.2987 22.0031 36.9844C23.3641 35.1445 25.9875 31.4469 28.2531 27.3578C31.0695 22.2664 32.5 18.093 32.5 14.9531C32.5 8.36328 26.8922 3 20 3ZM20 20.5C19.0111 20.5 18.0444 20.2068 17.2221 19.6573C16.3999 19.1079 15.759 18.327 15.3806 17.4134C15.0022 16.4998 14.9031 15.4945 15.0961 14.5245C15.289 13.5546 15.7652 12.6637 16.4645 11.9645C17.1637 11.2652 18.0546 10.789 19.0245 10.5961C19.9945 10.4031 20.9998 10.5022 21.9134 10.8806C22.827 11.259 23.6079 11.8999 24.1573 12.7222C24.7068 13.5444 25 14.5111 25 15.5C24.9986 16.8256 24.4713 18.0966 23.5339 19.0339C22.5966 19.9713 21.3256 20.4986 20 20.5Z" fill="%23ED9115"/>
  </svg>
`

export const getCenterIcon = (maps: any, map: any, lat: number, lng: number, hoverInfo?: string, id?: number, sizeCategory?: number) => {
  const size = sizeCategory ? new google.maps.Size(markerSizes[sizeCategory][1], markerSizes[sizeCategory][0]) : undefined
  const marker =  !!maps ? new maps.Marker({
    position: {lat, lng},
    map: map,
    icon: searchLocationIcon,
    zIndex: 1,
    ...(sizeCategory ? {size} : {})
  }) : null

  if (!!hoverInfo) {
    const infoWindow = !!maps ? new maps.InfoWindow({
      content: hoverInfo,
    }) : null
    marker?.addListener('mouseover', () => infoWindow.open(map, marker))
    marker?.addListener('mouseout', () => infoWindow.close())
    setTimeout(() => {
      const pathIconInStopList = document.getElementsByClassName(String(id))
      pathIconInStopList[0]?.addEventListener('mouseover', () => infoWindow?.open(map, marker))
      pathIconInStopList[0]?.addEventListener('mouseout', () => infoWindow.close())
      pathIconInStopList[1]?.addEventListener('mouseover', () => infoWindow?.open(map, marker))
      pathIconInStopList[1]?.addEventListener('mouseout', () => infoWindow.close())
    }, 200)

  }
  return marker
}

export const getDestinationIcon = (maps: any, map: any, lat: number, lng: number, id: any, hoverInfo?: string, handleClick?: (id: number) => void) => {
    const marker = !!maps ? new maps.Marker({
      position: {lat: lat, lng: lng},
      map,
      icon: destinationIcon,
      zIndex: 2,
      id: id
    }) : null
    if (!!hoverInfo) {
      const infoWindow = !!maps ? new maps.InfoWindow({
        content: hoverInfo
    }) : null

    marker?.addListener('mouseover', () => infoWindow.open(map, marker))
    marker?.addListener('mouseout', () => infoWindow.close())
    !!handleClick && marker?.addListener('click', () => handleClick(id))

    setTimeout(() => {
      const pathIconInStopList = document.getElementsByClassName(String(id))
      pathIconInStopList[0]?.addEventListener('mouseover', () => infoWindow?.open(map, marker))
      pathIconInStopList[0]?.addEventListener('mouseout', () => infoWindow.close())
    }, 200)
    return marker
  }
}

export const getCruiseStopIcon = (maps: any, map: any, lat: number, lng: number, stopData: any, stopNumber: number, hoverInfo?: string, handleClick?: (id: number) => void, notForDisplay?: boolean) => {
  if (notForDisplay) return 
  const categoryForStopPort = 1
  const size = markerSizes[categoryForStopPort]
  const marker = !!maps ? new maps.Marker({
    position: {lat: lat, lng: lng},
    map,
    icon: {
      url: stopData?.port?.port_type === 'WAYPOINT'
        ? portWaypointIcon
        : getMarkerSvgIconGoogleMap({
         // @ts-ignore
        details: {category: categoryForStopPort, with_schedule_flights: true},
        type: 'port'
      }, stopNumber),
      
      size: new google.maps.Size(size[1], size[0])
    },
    zIndex: 2,
    id: stopData.id
  }) : null
  if (!!hoverInfo) {
    const infoWindow = !!maps ? new maps.InfoWindow({
      content: hoverInfo
  }) : null

  marker?.addListener('mouseover', () => infoWindow.open(map, marker))
  marker?.addListener('mouseout', () => infoWindow.close())
  !!handleClick && marker?.addListener('click', () => handleClick(stopData.id))

  setTimeout(() => {
    const pathIconInStopList = document.getElementsByClassName(String(stopData.id))
    pathIconInStopList[0]?.addEventListener('mouseover', () => infoWindow?.open(map, marker))
    pathIconInStopList[0]?.addEventListener('mouseout', () => infoWindow.close())
  }, 200)
  return marker
}
}

export const getFlightTransferIcon = (maps: any, map: any, lat: number, lng: number, id: any, hoverInfo?: string, handleClick?: (id: number) => void) => {
  const marker = !!maps ? new maps.Marker({
    position: {lat: lat, lng: lng},
    map,
    icon: flightTransferIcon,
    zIndex: 2,
    id: id
  }) : null
  if (!!hoverInfo) {
    const infoWindow = !!maps ? new maps.InfoWindow({
      content: hoverInfo
    }) : null
    !!handleClick && marker?.addListener('click', () => handleClick(id))
    marker?.addListener('mouseover', () => infoWindow.open(map, marker))
    marker?.addListener('mouseout', () => infoWindow.close())

    setTimeout(() => {
      const pathIconInStopList = document.getElementsByClassName(String(id))
      pathIconInStopList[0]?.addEventListener('mouseover', () => infoWindow?.open(map, marker))
      pathIconInStopList[0]?.addEventListener('mouseout', () => infoWindow.close())
    }, 200)

  }
  return marker
}

export const getZoomForPath = (pathCoordinates: {lat: number, lng: number}[], ref: React.RefObject<HTMLElement>) => {
  const bounds = new google.maps.LatLngBounds()

  if (!pathCoordinates?.length) {
    return {zoom: 0, center: { lat: 0, lng: 0 }}
  }

  pathCoordinates!.forEach(marker => {
    bounds?.extend(new google.maps.LatLng(marker?.lat || 0, marker?.lng || 0));
  });

  const newBounds = {
    ne: {
      lat: bounds?.getNorthEast()?.lat() || 0,
      lng: bounds?.getNorthEast()?.lng() || 0
    },
    sw: {
      lat: bounds?.getSouthWest()?.lat() || 0,
      lng: bounds?.getSouthWest()?.lng() || 0
    }
  }
  const size = {
    width: ref.current ? ref.current?.offsetWidth : 0,
    height: ref.current ? ref.current?.offsetHeight : 0
  };  
  const {center, zoom} = fitBounds(newBounds, size);
  return {center, zoom: zoom === 0 ? 0 : zoom - 1}
}
