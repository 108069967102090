import { groupBy } from 'lodash'
import { Rate } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { getMarkerSvgIconGoogleMap, markerSizes } from '../../../../helpers/mapMarkerHelper'
import { selectDistanceType } from '../../../../store/appStatusReducer'
import { NearAirportType } from '../../../../types/airportTypes'
import { NearPortType } from '../../../../types/portTypes'
import { StopDataType } from '../../../../types/transportStopsTypes'
import { ResultItem } from '../../../SearchResults/ResultComponents/ResultComponents'
import { stopTypeIcons } from '../../../common/PublicTransportStops/TransportStopList/TransportStopList'
import classes from './ResultItems.module.css'
import { selectActivities, selectSelectedTransportStop, selectTransportStops, setSelectedTransportStop } from '../../../../store/widgetReducer'

export const NoResults = () => {
  return (
    <div className={classes.noResult}>
      No results found
    </div>
  )
}

export const NodesResult: React.FC<NodesResultPropTypes> = ({nodes, type}) => {
  return (
    <>
      {nodes?.length ? (
        nodes?.map((node, index) => (
          <div
            className={`${classes.nodeResultCard} ${String(node.details.id)}`}
            key={node.details.id}
            id={String(node.details.id)}
          >
            <div>
              <div className={classes.nodeName}>
                {node?.details?.name}
                {!!node?.details?.terminals?.length &&
                <div className={`${classes.nodeTerminalsTag} ${classes[type + '-terminals']}`}>
                  {node?.details?.terminals?.length}
                  {node?.details?.terminals?.length === 1 ? ' Terminal' : ' Terminals'}
                </div>
              }
              </div>
              <div className={classes.nodeDistance}>
                ({node?.details?.distance})
              </div>
            </div>
      
            <img
              // @ts-ignore
              src={getMarkerSvgIconGoogleMap({...node, type}, index + 1)}
              alt=''
              style={{
                height: markerSizes[node?.details?.category][0],
                width: markerSizes[node?.details?.category][1],
                justifySelf: 'center',
              }}
            />
          </div>
        ))
      ) : (
        <NoResults />           
      )}
    </>
  )
}

export const TransportStopResult = () => {
  const dispatch = useAppDispatch()
  const distanceType = useAppSelector(selectDistanceType)
  const transportStops = useAppSelector(selectTransportStops)
  const selectedTransportStop = useAppSelector(selectSelectedTransportStop)

  const stopsForDisplay = Object.entries({...groupBy(transportStops, 'type')})
    .map(([key, stops]) => ({
      [key]: stops.map((stop: StopDataType) => ({
        name: stop.stop_name,
        id: stop.id,
        details: `Distance from search point: ${distanceType === 'km' ? (stop?.distance * 1000).toFixed(0) : (stop?.distance / 1.609).toFixed(1)} ${distanceType === 'km' ? 'meters' : 'miles'}`,
        webLink: stop?.agency_web,
        additionalInfo: stop?.agency_name,
        serviceType: 'stop',
        location: stop.location,
        icon: stopTypeIcons[stop.route_types[0]?.route_type_name?.toLowerCase() as string || 'bus']
      }))
    }))
    .reduce((item,acc) => acc = {...acc, ...item}, {})

  return (
    <>
      {Object.values(stopsForDisplay)?.some(stopList => !!stopList?.length) ? (
        Object.keys(stopsForDisplay)
          .filter(key => !!stopsForDisplay[key]?.length)
          .map((key) => (
            <div key={key}>
              <div className={classes.stopType}>
                {key}
                <span className={classes.stopRadiusInfo}>
                  {stopsForDisplay[key][0]?.serviceType === 'stop' && (distanceType === 'km' 
                    ? '(Stops within 1 km radius)'
                    : '(Stops within 0.6 miles radius)')
                  }
                </span>
              </div>
              {stopsForDisplay[key].map((item) => (
                <ResultItem
                  item={{
                    name: item.name,
                    id: item.id,
                    details: item.details,
                    additionalInfo: item?.additionalInfo,
                    webLink: item.webLink,
                    type: item.serviceType,
                    location: item?.location,
                    icon: key.toLowerCase() !== 'services' ? item?.icon : '',
                  }}
                  isNodeDetails={false}
                  key={item.id}
                  wrapperStyle={{padding: '0px'}}
                  disableItemDetails={true}
                  customSelectItem={() => {
                    if (selectedTransportStop?.id === item.id) {
                      dispatch(setSelectedTransportStop(null))
                    } else {
                      dispatch(setSelectedTransportStop({
                        ...item.location,
                        id: item.id,
                        name: item.name
                      }))
                    }
                  }}
                />
              ))}
            </div>
          ))
      ) : (
        <NoResults />
      )}
    </>
  )
}

export const ActivitiesResult = () => {
  const activities = useAppSelector(selectActivities)
  return (
    <>
      {activities?.length ? (
        activities?.map((activity) => (
          <div className={classes.activityItemWrapper}>
            <a
              key={activity.productCode}
              className={classes.activityItem}
              href={activity.productUrl.replace('pid=P00071570', 'pid=P00070998')}
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={activity.images[0].variants.find(v => v.height > 400)?.url || activity.images[0].variants[0]?.url}
                alt={activity.title}
              />
              <div className={classes.activityTitleWrapper}>
                <div className={classes.activityTitle}>
                  {activity.title}
                </div>
                <Rate
                  disabled
                  value={Math.round(activity.reviews.combinedAverageRating*2)/2}
                  allowHalf
                />
              </div>
              {!!activity?.pricing?.summary?.fromPrice &&
                <div className={classes.activityPrice}>
                  <div>
                    from
                  </div>
                  {activity?.pricing?.summary?.fromPrice} {activity?.pricing?.currency}
                </div>
              }
            </a>
          </div>
        ))
      ) : (
        <NoResults />
      )}
    </>
  )
}

interface NodesResultPropTypes {
  nodes: NearAirportType[] | NearPortType[]
  type: 'airport' | 'port'
}
