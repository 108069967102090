import { Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/youtube'
import { ReactComponent as PlayIcon } from './../../../img/icons/play.svg'
import { GetTutorialVideoThunk, selectTutorialVideo, setTutorialVideo } from '../../../store/additionalDataReducer'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'

const ShowVideo:React.FC<{code: string}> = ({code}) => {
  const dispatch = useAppDispatch()
  const tutorial = useAppSelector(selectTutorialVideo)

  const [isVideoOpen, setIsVideoOpen] = useState(false)

  useEffect(() => {
    if (!!code && tutorial === null) {
      dispatch(GetTutorialVideoThunk(code))
    }
  }, [dispatch, code, tutorial])

  const closeModal = () => {
    setIsVideoOpen(false)
    dispatch(setTutorialVideo(null))
  }

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          color: '#0068FF',
          cursor: 'pointer',
          display: 'flex',
          fontSize: '14px',
          marginLeft: '18px'
        }}
        onClick={() => setIsVideoOpen(true)}
      >
        <PlayIcon style={{marginRight: '8px'}}/> Watch Video
      </div>
      {isVideoOpen &&
        <VideoModal
          onClose={closeModal}
          isOpen={isVideoOpen}
        />
      }
    </>
  )
}

const VideoModal:React.FC<VideoModalPropTypes> = ({onClose, isOpen}) => {
  const tutorial = useAppSelector(selectTutorialVideo)

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      width='660px'
    >
      <div style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
        {tutorial === null ? (
          <Spin />
        ) : (
          <ReactPlayer url={tutorial?.video_url} width={600}/>
        )}
      </div>
    </Modal>
  )
}

interface VideoModalPropTypes {
  onClose: () => void
  isOpen: boolean
}

export default ShowVideo
