import moment from 'moment'
import ThreeDotsMenu from '../../../common/ThreeDotsMenu/ThreeDotsMenu'
import classes from './EventCard.module.css'
import { EventType } from '../../../../types/eventTypes'
import EventDate from './cardElements/EventDate'
import Code from './cardElements/Code'
import EventTime from './cardElements/EventTime'
import CopyLink from './cardElements/CopyLink'
import EventDetailsTag from './cardElements/EventDetailsTag'

const EventCard: React.FC<{event: EventType, onActionIconClick: (event: EventType, action: 'edit' | 'delete') => void}> = ({event, onActionIconClick}) => {
  const startEndDiffDays = !moment(event.end_date).isSame(moment(event.start_date),'day')

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {event.name}
        <ThreeDotsMenu
          onEdit={event.is_creator ? () => onActionIconClick(event, 'edit') : undefined}
          onDelete={() => onActionIconClick(event, 'delete')}
        />
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <EventDate date={event.start_date} showDay={!startEndDiffDays}/>
        {startEndDiffDays &&
          <>
            <span style={{margin: '0px 6px'}}>-</span>
            <EventDate date={event.end_date} showDay={!startEndDiffDays}/>
          </>
        }
      </div>
      <EventTime start_date={event.start_date} end_date={event.end_date}/>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Code code={event.code}/>
        {!!event.is_creator && <EventDetailsTag tag='My event'/>}
      </div>
      <CopyLink code={event.code} type={event.event_type}/>
    </div>
  )
}

export default EventCard
