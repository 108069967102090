import { useEffect, useState } from 'react'
import moment from 'moment'
import classes from './UserSearchItem.module.css'
import {ReactComponent as CopyIcon} from './../../../../img/icons/copy.svg'
import { UserSearchType } from '../../../../types/userSearchTypes'
import { copyToClipboard, decodeSearchResultsExportLink, getCruiseDataFromSearchString, getCruiseDataFromSearchStringAdvanced, getDataFromAdvanceFlightSearchUrl, getFlightDataFromSearchString, getSearchItemAdditionalData } from '../../../../helpers/linksHelper'
import ThreeDotsMenu from '../../../common/ThreeDotsMenu/ThreeDotsMenu'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { DeleteUserSearchThunk } from '../../../../store/userSearchReducer'
import UserSearchListModal from '../UserSearchListModal/UserSearchListModal'
import { Link } from 'react-router-dom'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import { AdvancedCruiseSearchData } from '../../../../types/cruiseTypes'
import { selectCruiseLengths, selectCruiseLineTypes } from '../../../../store/cruiseReducer'
import { uniq } from 'lodash'

const UserSearchItem: React.FC<{search: UserSearchType}> = ({search}) => {
  const dispatch = useAppDispatch()
  const isTravelSearch = search.search_url.includes('travel/results')

  const searchItemTag = search.search_url.includes('by-cruise')
    ? 'Cruise'
    : search.search_url.includes('by-flight') ? 'Flight' : 'Travel'

  const [isModalOpen, setIsModalOpen] = useState(false)

  const shareUserSavedSearch = () => {
    copyToClipboard(search.search_url, 'Link copied successfully')
  }

  const getModalSubmitAction = async() => {
    return dispatch(DeleteUserSearchThunk({listId: search.user_search_list_id, searchId: search.search_id!}))
  }

return (
    <div className={classes.wrapper}>
      <div className={classes.searchHeader}>
        <div className={classes.title}>
          {search.search_name}
        </div>
        <ThreeDotsMenu onDelete={() => setIsModalOpen(true)} onShare={shareUserSavedSearch}/>
      </div>
      <div className={classes.searchAdditionalInfo}>
        {!!isTravelSearch && <TravelSearchDetails search={search}/>}
        {!isTravelSearch &&
          <FlightCruiseSearchDetails search={search}/>
        }
      </div>
      <div className={classes.searchLink}>
        <Link to={search.search_url.substring(search.search_url.indexOf('/'))} rel='noreferrer' target='_blank'>
          Link
        </Link>
        <CopyIcon className={classes.copyIcon} onClick={() => copyToClipboard(search.search_url, 'Link copied successfully')}/>
      </div>
      <div className={classes.searchTypeTag}>
        {searchItemTag}
      </div>
      <UserSearchListModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formType={'delete'}
        getSubmitAction={getModalSubmitAction}
        modalName='Saved search'
      />
    </div>
  )
}

const TravelSearchDetails: React.FC<{search: UserSearchType}> = ({search}) => {
  const searchLocations = decodeSearchResultsExportLink(search.search_url.split('travel/results')[1])
  const locationsData = searchLocations?.locations?.map(location => getSearchItemAdditionalData(location))

  return (
    <>
      {locationsData?.map(loc => (
        <div key={loc.location}>
          <div style={{color: 'black', fontSize: '14px'}}>
            {loc.location}
          </div>
          <div>
            {loc.travelers.adults ? loc.travelers.adults + (loc.travelers.adults > 1 ? ' adults' : ' adult') + (loc.travelers.children ? ' • ' : '') : ''} {loc.travelers.children ? loc.travelers.children + (loc.travelers.children > 1 || loc.travelers.children === 0 ? 'children' : 'child') : ''}
            {!loc.travelers.adults && !loc.travelers.children && '1 adult  • 0 children'}
          </div>
          <div>
            {moment(loc.dates[0]).format('DD.MM.YYYY')} • {moment(loc.dates[1]).format('DD.MM.YYYY')}
          </div>
        </div>
      ))}  
    </>
  )
}

const FlightCruiseSearchDetails: React.FC<{search: UserSearchType}> = ({search}) => {
  const cruiseLineTypes = useAppSelector(selectCruiseLineTypes)
  const lengths = useAppSelector(selectCruiseLengths)
  const isFlightSearch = search.search_url.includes('by-flight')
  const isCruiseSearch = search.search_url.includes('by-cruise')
  const isCruiseAdvancedSearch = search.search_url.includes('by-cruise-advanced') 
  const isFlightAdvancedSearch = search.search_url.includes('by-flight-advanced')

  const mainData = isFlightSearch
  ? isFlightAdvancedSearch
    ? getDataFromAdvanceFlightSearchUrl(search.search_url.split('/by-flight-advanced/summary')[1])
    : getFlightDataFromSearchString(search.search_url.split('/by-flight/results?')[1])
  : isCruiseAdvancedSearch
    ? getCruiseDataFromSearchStringAdvanced(search.search_url.split('/by-cruise-advanced/results?')[1])
    : isCruiseSearch
      ? getCruiseDataFromSearchString(search.search_url.split('/by-cruise/results?')[1])
      : ''
    const [additionalLocationsData, setAdditionalLocationsData] = useState<null | AdditionalLocationDataType[]>(null)

    useEffect(() => {
      // @ts-ignore
      const {from, to} = mainData
      if((from || to) && additionalLocationsData === null) {
        const getDataForPlaceIds = async() => {
          return await Promise.all([
            ...(from ? [from] : []),
            ...(to && to !== from ? [to] : []),
          ]?.map(placeId => geocodeByPlaceId(placeId))).then(resp => {
            return resp.flat(1).map(resp => ({...resp, type: resp.place_id === from ? 'From' : 'To'}))
          })
        }

        getDataForPlaceIds().then((data:any) => setAdditionalLocationsData(data))
      }
    }, [mainData, additionalLocationsData])

  return (
    <div>
      <div style={{color: 'black', fontSize: '14px'}}>
        {/* @ts-ignore */}
        {isFlightSearch ? mainData?.number : mainData?.lineId}
      </div>
      {!isCruiseAdvancedSearch &&
        <div>
          {/* @ts-ignore */}
          {moment(mainData?.date).format('DD.MM.YYYY')}
        </div>
      }
      {!!isFlightAdvancedSearch &&
        <>
          <div>
            From: <span style={{color: 'black'}}>
              {/* @ts-ignore */}
              {mainData?.from}
            </span>
          </div>
          <div>
          To: <span style={{color: 'black'}}>
            {/* @ts-ignore */}
            {mainData?.to}
          </span>
        </div>
        <div>
          Type: <span style={{color: 'black'}}>
            {/* @ts-ignore */}
            {mainData?.type === 'oneway' ? 'One Way' : 'Round trip'}
          </span>
        </div>
        <div>
          {/* @ts-ignore */}
          {mainData?.type === 'oneway' ? 'Date' : 'Start date'}: <span style={{color: 'black'}}>
            {/* @ts-ignore */}
            {mainData?.startDate}
          </span>
        </div>
        {/* @ts-ignore */}
        {mainData?.type !== 'oneway' &&
          <div>
            End date: <span style={{color: 'black'}}>
              {/* @ts-ignore */}
              {mainData?.endDate}
            </span>
          </div>
        }
        </>
      }
      {isCruiseAdvancedSearch &&
        <div>
          {(mainData as AdvancedCruiseSearchData)?.start_date &&
            <div>
              Start from: <span style={{color: 'black'}}>
                {moment((mainData as AdvancedCruiseSearchData)?.start_date).format('DD.MM.YYYY')}
              </span>
            </div>
          }
          {(mainData as AdvancedCruiseSearchData)?.end_date &&
            <div>
              Start till: <span style={{color: 'black'}}>
                {moment((mainData as AdvancedCruiseSearchData)?.end_date).format('DD.MM.YYYY')}
              </span>
            </div>
          }
          {(mainData as AdvancedCruiseSearchData)?.cruise_lines &&
            <div>
              Cruise lines: <span style={{color: 'black'}}>
                {(mainData as AdvancedCruiseSearchData)?.cruise_lines?.map(l => l.name).join(', ')}
              </span>
            </div>
          }
          {((mainData as AdvancedCruiseSearchData)?.regions
            || (mainData as AdvancedCruiseSearchData)?.cities
            || (mainData as AdvancedCruiseSearchData)?.countries) &&
            <div>
              Departure locations: <span style={{color: 'black'}}>
                {[
                  // ...(mainData as AdvancedCruiseSearchData)?.regions?.map(l => l.name!) || [],
                  ...(mainData as AdvancedCruiseSearchData)?.cities?.map(l => l.name!) || [],
                  ...(mainData as AdvancedCruiseSearchData)?.countries?.map(l => l.name!) || [],
                ].join(', ')}
              </span>
            </div>
          }
          {(mainData as AdvancedCruiseSearchData)?.destination_locations &&
            <div>
              Destinations: <span style={{color: 'black'}}>
                {(mainData as AdvancedCruiseSearchData)?.destination_locations?.map(l => l.name).join(', ')}
              </span>
            </div>
          }
          {(mainData as AdvancedCruiseSearchData)?.lengths &&
            <div>
              Lengths: <span style={{color: 'black'}}>
                {(mainData as AdvancedCruiseSearchData)?.lengths?.map(l => lengths.find(length => length.id === l)?.name).join(', ')}
              </span>
            </div>
          }
          {(mainData as AdvancedCruiseSearchData)?.cruise_lines &&
            <div>
              Cruise lines: <span style={{color: 'black'}}>
                {(mainData as AdvancedCruiseSearchData)?.cruise_lines?.map(l => l.name).join(', ')}
              </span>
            </div>
          }
          {!!(mainData as AdvancedCruiseSearchData)?.cruise_line_types?.length &&
            <div>
              Cruise line types: <span style={{color: 'black'}}>
                {uniq([
                  ...(mainData as AdvancedCruiseSearchData)?.cruise_line_types || [],
                  ...(mainData as AdvancedCruiseSearchData)?.cruise_lines?.map(l => l.type_id) || []
                ])?.map(l => cruiseLineTypes.find(t => t.id === l)?.name).join(', ')}
              </span>
            </div>
          }
        </div>
      }
      <div>
        {additionalLocationsData !== null && additionalLocationsData?.map(location => 
          <div key={location.place_id}>
            {location.type}: <span style={{color: 'black'}}>{location.formatted_address}</span>
          </div>
        )}
      </div>
    </div>
  )
}

interface AdditionalLocationDataType extends google.maps.GeocoderResult {
  type: 'From' | 'To'
}

export default UserSearchItem
