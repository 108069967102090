import { Button, Checkbox, DatePicker, Form, FormInstance, Input, Modal, Radio, TimePicker, Upload, message } from 'antd'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import classes from './EventFormModal.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CreateEventThunk, DeleteEventFromUserListThunk, DeleteEventThunk, EditEventThunk, selectSelectedEvent, setSelectedEvent } from '../../../store/eventReducer'
import { ReactComponent as UploadIcon } from './../../../img/icons/upload.svg'
import { ReactComponent as DeleteIcon } from './../../../img/icons/delete.svg'
import { ReactComponent as CopyIcon } from './../../../img/icons/copy.svg'
import avatarGroupImg from './../../../img/events/avatarGroup.webp'
import { InputGoogleAPI } from '../../common/InputGoogleAPI/InputGoogleAPI'
import { beforeUpload, dummyRequest, normFile } from '../../../helpers/files_helper'
import { EventLocationType, EventType } from '../../../types/eventTypes'
import { copyToClipboard } from '../../../helpers/linksHelper'
import { GetCruiseAvailableDatesThunk, selectCruiseDateOptions, setCruise, setCruiseDateOptions, setCruiseLine, setCruiseLineOptions } from '../../../store/cruiseReducer'
import { CruiseField, CruiseLineField } from '../../Travel/ByCruise/ByCruise'
import { AvailableDatesDatePicker } from '../../Travel/common/SearchPageWithBgImage/SearchPageWithBgImage'
import TermsAndConditions from './TermsAndConditions'

const EventFormModal:React.FC<EventFormModalPropTypes> = ({isOpen, onCancel, isDeleteConformation}) => {
  const dispatch = useAppDispatch()
  const selectedEvent = useAppSelector(selectSelectedEvent)
  const [form] = Form.useForm()

  const [selectedEventKind, setSelectedEventKind] = useState<'Cruise' | 'Location'>(selectedEvent ? selectedEvent?.event_kind || 'Location' : 'Location')
  const [locationValidationError, setLocationValidationError] = useState('')
  const [locationValue, setLocationValue] = useState('')
  const [imagePreviews, setImagePreviews] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [createdEventCode, setCreatedEventCode] = useState('')
  const [isTermsInfoOpen, setIsTermsInfoOpen] = useState(false)

  const changeSelectedEventKind = (kind: 'Cruise' | 'Location') => {
    setSelectedEventKind(kind)
    if (kind === 'Location') {
      form.setFieldsValue({cruiseLine: undefined, cruiseName: undefined, date: undefined})
    }
  }

  useEffect(() => {
    if (selectedEvent?.id) {
      form.setFieldsValue({
        name: selectedEvent.name,
        dates: [moment(selectedEvent.start_date), moment(selectedEvent.end_date)],
        time: [moment(selectedEvent.start_date), moment(selectedEvent.end_date)],
        description: selectedEvent.description,
        event_type: selectedEvent.event_type,
        ...(selectedEvent?.event_location ? {location: selectedEvent.event_location} : {}),
        ...(selectedEvent?.event_cruise ? {
          cruiseLine: {value: selectedEvent?.event_cruise.cruise_line_id, label: selectedEvent?.event_cruise.cruise_line_name},
          cruiseName: {value: 1, label: selectedEvent?.event_cruise?.cruise_name},
          date: moment.utc(selectedEvent?.event_cruise?.cruise_start_date)
        } : {}),
        terms_accepted: selectedEvent?.terms_accepted
      })
      setImagePreviews(selectedEvent.photo_urls)
      !!selectedEvent?.event_location && setLocationValue(selectedEvent.event_location.name)
    }
  }, [selectedEvent, form])

  const saveChanges = async(formData: FormData) => {
    if (!!selectedEvent?.id) {
      return dispatch(EditEventThunk({eventId: selectedEvent?.id!, eventData: formData}))
    } else {
      return dispatch(CreateEventThunk(formData))
    }
  }

  const createEvent = () => {
    setIsLoading(true)
    const data: FormDataType = form.getFieldsValue(true)
    if (!data?.location?.name && selectedEventKind === 'Location') {
      setLocationValidationError('Please select location!')
      return
    }
    const formData = new FormData()
    const eventData = {
      name: data.name,
      description: data.description || '',
      event_type: data.event_type,
      terms_accepted: data.terms_accepted,
      start_date: moment.utc({
        year: data.dates[0].year(),
        month: data.dates[0].month(),
        day: data.dates[0].date(),
        hour: data.time[0].hour(),
        minute: data.time[0].minute(),
      }),
      end_date: moment.utc({
        year: data.dates[1].year(),
        month: data.dates[1].month(),
        day: data.dates[1].date(),
        hour: data.time[1].hour(),
        minute: data.time[1].minute(),
      }),
      event_kind: selectedEventKind,
      ...(selectedEventKind === 'Location' ? {event_location: data.location} : {}),
      ...(selectedEventKind === 'Cruise' ? {event_cruise: {
        cruise_line_id: data.cruiseLine?.value,
        cruise_name: !!selectedEvent?.id && !Array.isArray(data.cruiseName?.label) && data.cruiseName?.label === selectedEvent?.event_cruise?.cruise_name
          ? data.cruiseName?.label
          : data.cruiseName?.label?.[0],
        cruise_start_date:  moment.utc({
          year: data.date.year(),
          month: data.date.month(),
          day: data.date.date(),
          hour: 0,
          minute: 0,
        }).format('YYYY-MM-DD HH:mm:ss'),
      }} : {}),
    }
    formData.append('event', new Blob([JSON.stringify(eventData, null, 2)], {type: 'application/json'}))
    !!data?.photos?.length && data.photos.forEach((p:any) => formData.append(!!selectedEvent?.id ? 'add_photos' : 'photos', p.originFileObj))
    !!selectedEvent?.id && !!data?.delete_photos?.length && formData.append('delete_photos', new Blob([JSON.stringify({urls: data?.delete_photos}, null, 2)], {type: 'application/json'}))
    saveChanges(formData).then((resp) => {
      setIsLoading(false)
      if (!resp.type.includes('rejected')) {
        message.success(`Event has been ${selectedEvent?.id ? 'changed' : 'created'} successfully`)
        !selectedEvent?.id && setCreatedEventCode(((resp.payload as {eventData: EventType}).eventData)?.code)
      }
      !!selectedEvent?.id && closeModal()
    })
  }

  const addPhoto = async(file:any) => {
    const prevPhotos = form.getFieldValue('photos')?.filter((p:any) => p.uid !== file?.uid)
    form.setFieldsValue({photos: [...(prevPhotos?.length ? prevPhotos : []), file]})
  }

  const removePhoto = (file:any) => {
    if (!!selectedEvent?.id) {
      const photosForDeleting = form.getFieldValue('delete_photos')?.urls
      form.setFieldsValue({'delete_photos':[...(photosForDeleting?.length ? photosForDeleting : []), file.uid]})
    } else {
      form.setFieldsValue({
        photos: form.getFieldValue('photos')?.filter((photo:any) => photo.uid !== file.uid)
      })
    }
  }

  const handleLocationChange = async(value: string) => {
    if (value.length) {
      const addressData = await geocodeByAddress(value)
      const coordinates = await getLatLng(addressData[0])
      const locationData = {
        name: addressData[0].formatted_address,
        country_code: addressData[0].address_components.find(address => address.types.includes('country'))?.short_name || '',
        country_name: addressData[0].address_components.find(address => address.types.includes('country'))?.long_name || '',
        city_name: addressData[0].address_components.find(address => address.types.includes('locality'))?.long_name || '',
        coordinates: {
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        }
      }
      form.setFieldsValue({location: locationData})
      setLocationValue(addressData[0].formatted_address)
      setLocationValidationError('')
    } else {
      form.setFieldsValue({location: null})
      setLocationValue('')
    }
  }

  const closeModal = () => {
    form.resetFields()
    setLocationValidationError('')
    setLocationValue('')
    setCreatedEventCode('')
    dispatch(setSelectedEvent(null))
    onCancel()
  }

  if (isDeleteConformation) {
    return <ConfirmDeleteModal isOpen={isOpen} onCancel={onCancel}/>
  }

  if (!!createdEventCode.length) {
    return <ShareEventModal isOpen={isOpen} onCancel={closeModal} code={createdEventCode}/>
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      footer={null}
      className={classes.wrapper}
      closable={false}
      width={660}
      destroyOnClose
    >
      <h3>
        {selectedEvent?.id ? 'Edit' : 'Create'} Event
      </h3>
      {!selectedEvent?.id &&
        <div className={classes.description}>
          After creating an event, you will receive a code for your event
        </div>
      }
      <div className={classes.eventTypeOptionsWrapper}>
        <div
          className={`${classes.eventTypeOption} ${selectedEventKind === 'Location' ? classes.activeEventTypeOption : ''}`}
          onClick={() => changeSelectedEventKind('Location')}
        >
          By Location
        </div>
        <div
          className={`${classes.eventTypeOption} ${selectedEventKind === 'Cruise' ? classes.activeEventTypeOption : ''}`}
          onClick={() => changeSelectedEventKind('Cruise')}
        >
          By Cruise
        </div>
      </div>
      <Form
        name='createEvent'
        onFinish={createEvent}
        autoComplete='off'
        validateTrigger='onBlur'
        form={form}
        initialValues={{
          dates: [moment(), moment()],
          time: [moment(), moment().add(1, 'hour')]
        }}
        onFinishFailed={() => {
          const formValues = form.getFieldsValue(true)
          if (!formValues?.location?.name) {
            setLocationValidationError('Please select location!')
            return
          }
        }}
      >
        <div className={classes.label}>
          Event Name
        </div>
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'Please enter event name!' }]}
        >
          <Input placeholder='Enter event name'/>
        </Form.Item>
        {selectedEventKind === 'Location' &&
          <>
            <div className={classes.label}>
              Event Location
            </div>
            <InputGoogleAPI
              placeholder='Enter location'
              status={!!locationValidationError.length ? 'error' : undefined}
              value={locationValue || ''}
              onChange={(value) => handleLocationChange(value)}
            />
            <div className={classes.validationError}>
              {locationValidationError}
            </div>
          </>
        }

        <div className={classes.label} style={{marginTop: '20px'}}>
          Start - End
        </div>
        <div className={classes.dateFieldsWrapper}>
          <Form.Item name='dates'>
            <DatePicker.RangePicker
              allowClear={false}
              style={{width: '100%'}}
            />
          </Form.Item>
      
          <Form.Item name='time'>
            <TimePicker.RangePicker
              format={'h:mm A'} 
              allowClear={false}
              use12Hours
              style={{width: '100%'}}
            />
          </Form.Item>
        </div>

        {selectedEventKind === 'Cruise' &&
          <EventForCruiseFormFields form={form}/>
        }

        <div style={{position: 'relative'}}>
          <span className={classes.privateExplanation}>
            Private and only available to those who are registered
          </span>
          <span className={classes.semiPrivateExplanation}>
            Private and only available to those who receive the link or who specifically search for it on our website
          </span>
          <span className={classes.publicExplanation}>
            Can be shared and searched in our website or crawled by Google.
          </span>
          <Form.Item
            name='event_type'
            rules={[{ required: true, message: 'Please select event type!' }]}
          >
            <Radio.Group style={{display: 'flex', flexDirection: 'column'}}>
              <Radio value='Private' className={classes.privateOption}> Private </Radio>
              <Radio value='Corporate' className={classes.semiPrivateOption}> Semi-Private </Radio>
              <Radio value='Public' className={classes.publicOption}> Public </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        

        <div className={classes.label}>
          About
        </div>
        <Form.Item name='description'>
          <Input.TextArea
            placeholder='Describe your event'
            showCount
            maxLength={360}
          />
        </Form.Item>

        <div className={classes.label}>
          Upload attachment
        </div>
        <Upload.Dragger
          name='files'
          customRequest={dummyRequest}
          beforeUpload={(file) => beforeUpload(file, 1)}
          className={`${classes.uploadDragger}`}
          accept={'image/png, image/jpeg'}
          maxCount={10}
          onChange={(e) => normFile(e, addPhoto)}
          onRemove={removePhoto}
          iconRender={() => <DeleteIcon />}
          listType='picture'
          showUploadList={{
            showRemoveIcon: true,
            removeIcon: <DeleteIcon onClick={removePhoto} />,
          }}
        >
          <UploadIcon />
          <div className={classes.uploadTitle}>
            Click to upload or drag and drop
          </div>
          <div className={classes.uploadDescription}>
            PNG or JPG
          </div>
        </Upload.Dragger>
        {imagePreviews?.map((photo:string) => (
          <div className={classes.selectedEventPhotoItem}>
            <div>
              <img
                src={photo}
                alt=''
                style={{
                  height: '48px',
                  width: '48px',
                  marginRight: '7px',
                  objectFit: 'scale-down'
                }}
              />
              <span style={{color: '#667085', userSelect: 'none'}}>Previously uploaded image</span>
            </div>
            <DeleteIcon
              style={{marginRight: '3px'}}
              onClick={() => {
                form.setFieldsValue({delete_photos: [...(form.getFieldValue('delete_photos')?.urls || []), photo]}) 
                setImagePreviews(imagePreviews.filter(prev => prev !== photo))
              }}
          />
          </div>
        ))}
        <Form.Item
          name='terms_accepted'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('You need to accept Terms & Conditions to create an event!')),
            },
          ]}
          style={{marginTop: '10px'}}
        >
          <Checkbox disabled={!!selectedEvent?.id && !!selectedEvent?.terms_accepted}>
            I accept
            <span
              onClick={e => {
                e.stopPropagation()
                setIsTermsInfoOpen(true)
              }}
              style={{marginLeft: '8px', textDecoration: 'underline'}}
            >
              Terms & Conditions
            </span>
          </Checkbox>
        </Form.Item>
        <TermsAndConditions
          isOpen={isTermsInfoOpen}
          onCancel={() => setIsTermsInfoOpen(false)}
          onAccept={() => {
            form.setFieldsValue({terms_accepted: true})
            setIsTermsInfoOpen(false)
          }}
        />
        <div className={classes.btnArea}>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {!!selectedEvent?.id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const EventForCruiseFormFields: React.FC<{form: FormInstance}> = ({form}) => {
  const dispatch = useAppDispatch()
  const availableDates = useAppSelector(selectCruiseDateOptions)
  const selectedEvent = useAppSelector(selectSelectedEvent)
  
  const [isLoading, setIsLoading] = useState(false)
  const [lineId, setLineId] = useState(0)
  const [cruiseId, setCruiseId] = useState(0)

  useEffect(() => {
    if (lineId === 0 && !!selectedEvent?.event_cruise?.cruise_line_id) {
      setLineId(selectedEvent?.event_cruise?.cruise_line_id)
    }
  }, [selectedEvent, lineId])

  useEffect(() => {
    return () => {
      dispatch(setCruise(null))
      dispatch(setCruiseLine(null))
      dispatch(setCruiseLineOptions(null))
      dispatch(setCruiseDateOptions(null))
    }
  }, [dispatch])

  const getAvailableDates = (lineId: number, cruiseId?: number[]) => {
    const formData = form.getFieldsValue(true)
    if (!cruiseId && lineId) {
      dispatch(GetCruiseAvailableDatesThunk({cruise_line: formData?.cruiseLine?.value}))
    } else if (cruiseId && lineId) {
      dispatch(GetCruiseAvailableDatesThunk({cruise_line: formData?.cruiseLine?.value, cruise_id_list: cruiseId}))
    }
  }

  useEffect(() => {
    if (!availableDates?.some(d => moment(d).isSame(form.getFieldValue('date'), 'day'))) {
      form.setFieldsValue({date: undefined})
    }
  }, [availableDates, form])

  const checkIsDateAvailable = (current: Moment):boolean => {
    return current < moment().startOf('day') || !availableDates?.includes(current.format('YYYY-MM-DD'))
  }

  return (
    <>
      <CruiseLineField
        form={form}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setLineId={setLineId}
        getAvailableDates={getAvailableDates}
      />
      <CruiseField
        form={form}
        getAvailableDates={getAvailableDates}
        isLoading={isLoading}
        lineId={lineId}
        placeholder={!lineId ? 'Select cruise line first' : 'Start typing cruise name'}
        allowClear={false}
        onSelect={(val:any) => setCruiseId(val)}
        disabled={!lineId}
        dynamicCruiseSearch
      />
      <AvailableDatesDatePicker
        form={form}
        bgType='cruise'
        checkIsDateAvailable={(current) => checkIsDateAvailable(current)}
        datePickerPlaceholder={isLoading 
          ? 'Loading options'
          : availableDates === null
            ? 'Select cruise line first'
            : !!cruiseId ? 'No available dates found' : 'Select cruise first'
        }
        datePickerDisabled={!availableDates?.length || isLoading || !cruiseId}
        closestAvailableDate={availableDates?.[0] ? moment(availableDates?.[0]) : undefined}
      />
    </>
  )
}

const ConfirmDeleteModal: React.FC<EventFormModalPropTypes> = ({isOpen, onCancel}) => {
  const dispatch = useAppDispatch()
  const selectedEvent = useAppSelector(selectSelectedEvent)

  const [isLoading, setIsLoading] = useState(false)

  const deleteEvent = () => {
    setIsLoading(true)
    // @ts-ignore
    dispatch(!!selectedEvent?.is_creator
      ? (DeleteEventThunk(selectedEvent?.id!))
      : (DeleteEventFromUserListThunk(selectedEvent?.code!))
    ).then((resp) => {
        setIsLoading(false)
        !resp.type.includes('rejected') && message.success(`Event has been deleted successfully`)
        onCancel()
      })
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      className={classes.wrapper}
      closable={false}
      width={400}
      destroyOnClose
    >
      <div className={classes.deleteConfirmationText}>
       {!!selectedEvent?.is_creator ? 'Are you sure to delete this event?' : 'Are you sure to remove this event from your list?'}
      </div>
      {!!selectedEvent?.is_creator &&
        <div className={classes.deleteConfirmationText} style={{marginTop: '7px'}}>
          The event will be also deleted for all joined users
        </div>
      }
      <div className={classes.btnArea}>
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button type='primary' onClick={deleteEvent} loading={isLoading}>
          Delete
        </Button>
      </div>
    </Modal>
  )
}

const ShareEventModal: React.FC<ShareEventModalPropTypes> = ({isOpen, onCancel, code}) => {
  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      className={classes.wrapper}
      closable={false}
      width={350}
      destroyOnClose
    >
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <img src={avatarGroupImg} alt='avatars' style={{width: '150px', marginBottom: '20px', alignSelf: 'center'}}/>
        <h3 className={classes.deleteConfirmationText}>
          Your code to event
        </h3>
        <div className={classes.description}>
          You’ve created a new event! Invite colleagues to collaborate on this event.
        </div>
        <div className={classes.label}>
          Share code
        </div>
        <div className={classes.shareCodeBlock}>
          <div className={classes.code}>
            {code}
          </div>
          <CopyIcon
            className={classes.copyIcon}
            onClick={() => copyToClipboard(code, 'Code copied successfully')}
          />
        </div>
        <Button type='primary' style={{width: '100%', marginTop: '20px'}} onClick={onCancel}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

interface EventFormModalPropTypes {
  isOpen: boolean
  onCancel: () => void
  isDeleteConformation?: boolean
}

interface ShareEventModalPropTypes extends EventFormModalPropTypes {
  code: string
}

interface FormDataType {
  name: string
  dates: [Moment, Moment]
  time: [Moment, Moment]
  description?: string
  location?: EventLocationType
  photos?: any[]
  delete_photos?: any[]
  event_type: 'Public' | 'Private' | 'Corporate'
  cruiseLine: {value: number}
  cruiseName: {value: number, label: string[]}
  date: Moment
  terms_accepted: boolean
}

export default EventFormModal
