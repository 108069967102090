import { Button, Form, Input, message } from 'antd'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { ChangePasswordThunk, selectUserData } from '../../../store/userReducer'
import classes from './Password.module.css'

const Password = () => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const old_password = Form.useWatch('old_password', form)
  const password = Form.useWatch('password', form)
  const repeat_password = Form.useWatch('repeat_password', form)

  const resetForm = () => {
    form.resetFields()
  }

  const onFinish = (formData: ChangePasswordFormValueTypes) => {
    setIsLoading(true)
    dispatch(ChangePasswordThunk({old_password: formData.old_password, password: formData.password, user_id: userData.user_id}))
      .then((resp) => {
        setIsLoading(false)
        if (!resp.type.includes('rejected')) {
          message.success('Password has been changed successfully')
          resetForm()
        }
      })
  }

  const hasToMatchValidator = (valueToCompare: string, errorText: string) => ({
    validator(_: any, value: any) {
      if (!value || valueToCompare === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(errorText))
    }
  })



  return (
    <Form
      name='newPassword'
      onFinish={onFinish}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
      form={form}
    >
      <div className={classes.label}>
        Current Password
      </div>
      <Form.Item
        name='old_password'
        rules={[{ required: true, message: 'Please enter your password!' }]}
      >
        <Input.Password placeholder='Enter current password' />
      </Form.Item>

      <div className={classes.label}>
        New Password
      </div>
      <Form.Item
        name='password'
        rules={[
          { required: true, message: 'Please enter your password!' },
          { min: 4, message: 'Password must be at least 4 characters '}
        ]}
      >
        <Input.Password placeholder='Enter new password' />
      </Form.Item>

      <div className={classes.label}>
        Confirm Password
      </div>
      <Form.Item
        name='repeat_password'
        dependencies={['password']}
        rules={[
          {required: true, message: 'Please repeat your password!'},
          ({getFieldValue}) => hasToMatchValidator(getFieldValue('password'), 'The two passwords that you entered do not match!'),
        ]}
      >
        <Input.Password placeholder='Confirm password'/>
      </Form.Item>

      <div className={classes.btnAreaWrapper}>
        <Button
          disabled={!old_password?.length && !password?.length && !repeat_password?.length}
          onClick={resetForm}
        >
          Cancel
        </Button>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save changes
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

interface ChangePasswordFormValueTypes {
  old_password: string
  password: string
  repeat_password: string
}

export default Password
