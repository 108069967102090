import { Button, Form, Input } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../../app/hooks'
import { LoginThunk } from '../../../store/userReducer'
import { SignInDataType } from '../../../types/userTypes'
import classes from './../Auth.module.css'

const SignInFrom = () => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)
  
  const onFinish = (formData: SignInDataType) => {
    setIsLoading(true)
    dispatch(LoginThunk(formData)).then(() => setIsLoading(false))
  }

  return (
    <Form
      name='signIn'
      onFinish={onFinish}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
    >
      <h1 className={classes.formTitle}>
        Sign in
      </h1>
      <div className={classes.label}>
        Email Address
      </div>
      <Form.Item
        name='email'
        rules={[
          { required: true, message: 'Please enter your email!' },
          { type: 'email', message: 'Invalid e-mail!' },
        ]}
      >
        <Input placeholder='Enter your email'/>
      </Form.Item>

      <div className={classes.label}>
        Password
      </div>
      <Form.Item
        name='password'
        rules={[
          { required: true, message: 'Please enter your password!' },
          { min: 4, message: 'Password must be at least 4 characters '}
        ]}
      >
        <Input.Password placeholder='Enter your password'/>
      </Form.Item>

      <div className={classes.forgotPasswordWrapper}>
        <Link to='/forgot-password' className={classes.forgotPassword}>
          Forgot your password?
        </Link>
      </div>

      <Form.Item>
        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
          Sign in
        </Button>
      </Form.Item>

      <div className={classes.additionalLink}>
        Don’t have an account? <Link to='/sign-up'>Sign up</Link>
      </div>
    </Form>
  )
}

export default SignInFrom
