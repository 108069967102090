import classes from './MainPage.module.css'
import logo from './../../../img/logo.png'
import { navMenuItems, selectLocation, setActiveTab } from '../../../store/widgetReducer'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Spin } from 'antd'
import { WidgetTabOptionsType } from '../../../types/widgetTypes'

const MainPage = () => {
  const dispatch = useAppDispatch()
  const widgetLocationData = useAppSelector(selectLocation)

  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        Logo
      </div>

      <div className={classes.content}>
        <h1>
          {`Infrastructure and Transportation nearby\n`}
          <span>
            {widgetLocationData?.name
              ? widgetLocationData?.name
              : <Spin style={{width: '100%'}} />
            }
          </span>
        </h1>
        <h2>
          Select what you're interested in:
        </h2>
        <ul>
          {navMenuItems.map(item => (
            <li key={item.name} onClick={() => dispatch(setActiveTab(item.tab as WidgetTabOptionsType))}>
              <img src={item.icon} alt={item.name}/>
              <div>
                {item.name}
              </div>
            </li>
          ))}
        </ul>
      </div>
      
      <a
        href='https://www.relavanti.com/'
        target='_blank'
        rel='noreferrer'
        className={classes.poweredBy}
      >
        Powered by <img src={logo} alt='Relavanti logo' />
      </a>
    </div>
  )
}

export default MainPage
