import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { decodeFlightCruiseSearchResultsExportLink, getCruiseDataFromSearchString, getCruiseDataFromSearchStringAdvanced } from '../../../../helpers/linksHelper'
import { setActiveResultCategories, setNodeServiceDetails } from '../../../../store/searchResultsReducer'
import { setNearServices } from '../../../../store/serviceReducer'
import { setNearTransportStops } from '../../../../store/transportStopsReducer'
import { setActiveResultsTabKey, setSelectedResultItemId } from '../../../../store/travelSearchReducer'
import { SharedFlightCruiseDetailsType } from '../../../../types/appTypes'
import { AdvancedCruiseSearchData, AdvancedCruiseSearchRequestData, CruiseRouteType, CruiseSearchDataType, CruiseType } from '../../../../types/cruiseTypes'
import NoResults from '../../common/NoResults/NoResults'
import ResultList from '../../common/ResultList/ResultList'
import SearchResultPage from '../../common/SearchResultPage/SearchResultPage'
import { selectCruises, selectCruise, GetCruiseByLineAndDateThunk, selectActiveInfoBlock, setCruise, GetCruiseLineByIdThunk, selectCruiseLine, setCruises, setCruiseLine, setCruiseLineOptions, setSelectedStops, setIsCruiseStopsMerged, GetCruisesByAdvancedSearchThunk, selectAdvancedSearchPagination, setAdvancedSearchPagination, selectAdvancedSearchTotalCount, setAdvancedSearchRequestData, getCruiseArrivalLocation } from './../../../../store/cruiseReducer'
import CruiseDetails from './CruiseDetails/CruiseDetails'
import CruiseItem from './CruiseItem/CruiseItem'
import { setCruiseAdvertisement } from '../../../../store/advertisementReducer'
import { Pagination, Spin } from 'antd'
import { Helmet } from 'react-helmet-async'

const SearchResults = () => {
  const dispatch = useAppDispatch()
  const { search, pathname } = useLocation()
  const isAdvancedSearch = pathname.includes('by-cruise-advanced')

  const cruises = useAppSelector(selectCruises)
  const cruise = useAppSelector(selectCruise)
  const activeInfoBlock = useAppSelector(selectActiveInfoBlock)
  const cruiseLine = useAppSelector(selectCruiseLine)
  const advancedSearchTotalCount = useAppSelector(selectAdvancedSearchTotalCount)
  const advancedSearchPagination = useAppSelector(selectAdvancedSearchPagination)

  const searchData = isAdvancedSearch
    ? getCruiseDataFromSearchStringAdvanced(search)
    : getCruiseDataFromSearchString(search)
  const [departureLocation, setDepartureLocation] = useState<any>()
  const [arrivalLocation, setArrivalLocation] = useState<any>()

  const goBackLink = isAdvancedSearch ? '/travel/by-cruise-advanced' : '/travel/by-cruise'
  // const departureLocation = cruise?.cruise_routes?.[0]
  // const arrivalLocation = cruise?.cruise_routes?.[cruise?.cruise_routes?.length - 1]
  const stops = cruise?.cruise_routes?.slice(1, -1) 

  useEffect(() => {
    // @ts-ignore
    if (!isAdvancedSearch && (cruiseLine === null || (!!cruiseLine?.id && +cruiseLine?.id !== +searchData?.lineId))) {
    // @ts-ignore
      dispatch(GetCruiseLineByIdThunk({lineId: +searchData.lineId, withCruises: false}))
    } else if (!!isAdvancedSearch) {
      const {sharedDetails} = decodeFlightCruiseSearchResultsExportLink(getCruiseDataFromSearchStringAdvanced)
      dispatch(setCruises(null))
      dispatch(GetCruisesByAdvancedSearchThunk({
        cruiseInfo: {
          ...searchData,
          destination_locations: (searchData as AdvancedCruiseSearchData)?.destination_locations?.map(l => ({latitude: l.latitude, longitude: l.longitude})),
          regions: (searchData as AdvancedCruiseSearchData)?.regions,
          cities: (searchData as AdvancedCruiseSearchData)?.cities?.map(c => c.city_id),
          countries: (searchData as AdvancedCruiseSearchData)?.countries?.map(r => r.country_id),
          countries_to: (searchData as AdvancedCruiseSearchData)?.countries_to,
          countries_from: (searchData as AdvancedCruiseSearchData)?.countries_from,
          cruise_lines: (searchData as AdvancedCruiseSearchData)?.cruise_lines?.map(l => l.id),
          pagination: advancedSearchPagination
        } as AdvancedCruiseSearchRequestData,
        sharedDetails
      }))
      dispatch(setAdvancedSearchRequestData(searchData as AdvancedCruiseSearchData))
    }
  // eslint-disable-next-line
}, [dispatch, cruiseLine, advancedSearchPagination])


  useEffect(() => {
    if (activeInfoBlock === 'cruises') {
      setDepartureLocation(null)
      setArrivalLocation(null)
    } else if (!!cruise?.cruise_routes?.[0]?.port) {
      setDepartureLocation(cruise?.cruise_routes?.[0])
      setArrivalLocation(getCruiseArrivalLocation(cruise?.cruise_routes, 1))//cruise?.cruise_routes?.[cruise?.cruise_routes?.length - 1])
    }
  }, [cruise, activeInfoBlock])

  useEffect(() => {
    return () => {
      dispatch(setCruise(null))
      dispatch(setCruises(null))
      dispatch(setCruiseLine(null))
      dispatch(setCruiseLineOptions(null))
      dispatch(setSelectedResultItemId(''))
      dispatch(setActiveResultCategories(['near']))
      dispatch(setNodeServiceDetails(null))
      dispatch(setNearTransportStops(null))
      dispatch(setNearServices([]))
      dispatch(setActiveResultsTabKey('general'))
      dispatch(setIsCruiseStopsMerged(false))
      setDepartureLocation(null)
      setArrivalLocation(null)
    }
  }, [dispatch])
  
  const getData = (data: {lineId: number, date: string, cruiseName?: string}, sharedDetails?: SharedFlightCruiseDetailsType) => {
    return dispatch(GetCruiseByLineAndDateThunk({cruiseInfo: {...data, name: decodeURIComponent(data?.cruiseName || ''), with_cruise_routes: true}, sharedDetails}))
  }

  const selectCruiseItem = (cruise: CruiseType) => {
    dispatch(setCruise(cruise))
    dispatch(setSelectedResultItemId(String(cruise.id)))
    dispatch(setCruiseAdvertisement(cruise?.advertising_list))
  }

  const handleStopMarkerClick = (id: number) => {
    dispatch(setSelectedStops(id))
    dispatch(setActiveResultsTabKey(String(id)!))
  }

  return (
    <SearchResultPage
      dataList={cruises}
      dataItem={cruise}
      getData={isAdvancedSearch ? undefined : getData}
      getSearchDataFromUrl={getCruiseDataFromSearchString}
      isDetailsPageOpen={activeInfoBlock === 'cruiseDetails'}
      itemMapCenter={{lat: departureLocation?.port?.latitude || 0, lng: departureLocation?.port?.longitude || 0}}
      departureLocation={{
        id: departureLocation?.id || 0,
        info: departureLocation?.name === arrivalLocation?.name 
          ? getMapIconHoverInfo({...departureLocation!, arrival_time: arrivalLocation?.arrival_time!})
          : getMapIconHoverInfo(departureLocation!),
        lat: departureLocation?.port?.latitude || 0,
        lng: departureLocation?.port?.longitude || 0
      }}
      stopLocations={stops?.filter(s => !!s?.port?.id).map(s => ({
        id: s?.id || 0,
        info: getMapIconHoverInfo(s),
        lat: s?.port?.latitude,
        lng: s?.port?.longitude,
        stopData: s
      })) || []}
      arrivalLocation={{
        id: arrivalLocation?.id || 0,
        info: arrivalLocation?.name === departureLocation?.name
          ? getMapIconHoverInfo({...arrivalLocation!, departure_time: departureLocation?.departure_time!})
          : getMapIconHoverInfo(arrivalLocation!),
        lat: arrivalLocation?.port?.latitude || 0,
        lng: arrivalLocation?.port?.longitude || 0
      }}
      searchType='byCruise'
      handleStopMarkerClick={handleStopMarkerClick}
      activeInfoBlock={activeInfoBlock}
    >
      <Helmet>
        <link rel='canonical' href={window.location.href} />
        {!isAdvancedSearch && !!cruiseLine?.name && !!(searchData as CruiseSearchDataType)?.date &&
          <meta
            name='description'
            content={`${cruiseLine?.name}, ${!!(searchData as CruiseSearchDataType)?.cruiseName?.length ? (searchData as CruiseSearchDataType)?.cruiseName + ', ' : ''}${(searchData as CruiseSearchDataType).date}`}
          />
        }
        {!isAdvancedSearch && !!cruiseLine?.name && !!(searchData as CruiseSearchDataType)?.date &&
          <title>
            Relavanti | {`${cruiseLine?.name}, ${!!(searchData as CruiseSearchDataType)?.cruiseName?.length ? (searchData as CruiseSearchDataType)?.cruiseName + ', ' : ''}${(searchData as CruiseSearchDataType).date}`}
          </title>
        }
        {!!isAdvancedSearch &&
          <meta
            name='description'
            content='Advanced cruise search by start location, destination location, cruise line'
          />
        }
        {!!isAdvancedSearch &&
          <title>
            Advanced cruise search by start location, destination location, cruise line
          </title>
        }
      </Helmet>
      {activeInfoBlock === 'cruises' &&
        <ResultList
          title='List of Cruises'
          // @ts-ignore
          searchData={{number: cruiseLine?.name || '', date: searchData?.date || [searchData?.start_date, searchData?.end_date] || moment().format('YYYY-MM-DD')}}
          goBackLink={goBackLink}
          imageType='ship'
          logo={cruiseLine?.logo_url}
          logoLink={cruiseLine?.website}
          showAdvancedFilters={isAdvancedSearch}
          isLoading={cruises === null}
          searchType='byCruise'
        >
          {!!cruises?.length &&
            (
              <>
                {cruises.map(cruise => (
                  <CruiseItem
                    key={cruise.id}
                    cruise={cruise}
                    onClick={() => selectCruiseItem(cruise)}
                    previewInfoOnly={false}
                  />
                ))}
                {isAdvancedSearch &&
                  <Pagination
                    pageSize={advancedSearchPagination.size}
                    current={advancedSearchPagination.page}
                    showSizeChanger={false}
                    total={advancedSearchTotalCount}
                    style={{marginBottom: '20px'}}
                    onChange={page => dispatch(setAdvancedSearchPagination({...advancedSearchPagination, page}))}
                />}
              </>
            )}
            {cruises === null && <Spin />}
            {!!cruises && !cruises.length && <NoResults goBackLink={goBackLink} />}
        </ResultList>
      }
      {activeInfoBlock === 'cruiseDetails' && !!cruises?.length  && <CruiseDetails />}
    </SearchResultPage>
  )
}

export const getMapIconHoverInfo = (route: CruiseRouteType) => {
  return (`
    <div style="font-size: 18px; margin-bottom: 10px">
      ${route?.name}
    </div>
    <div style="margin-bottom: 7px">
      ${!!route?.port?.name ? 'Port: ' + route?.port?.name + (!!route?.port?.code ? ' (' + route?.port?.code + ')' : '') : ''}
    </div>
    ${moment(route?.departure_time).isBefore(moment(route?.arrival_time)) ? (
      `
        <div style="margin-bottom: 7px">
          ${route?.departure_time ? 'Departure: '+ moment(route?.departure_time, 'YYYY-MM-DD').format('dd DD MMM YYYY') : ''}
        </div>
        <div style="margin-bottom: 7px">
          ${route?.arrival_time ? 'Arrival: '+ moment(route?.arrival_time, 'YYYY-MM-DD').format('dd DD MMM YYYY') : ''}
        </div>
      `
    ) : (
      `
        <div style="margin-bottom: 7px">
        ${route?.arrival_time ? 'Arrival: '+ moment(route?.arrival_time, 'YYYY-MM-DD').format('dd DD MMM YYYY') : ''}
        </div>
        <div style="margin-bottom: 7px">
        ${route?.departure_time ? 'Departure: '+ moment(route?.departure_time, 'YYYY-MM-DD').format('dd DD MMM YYYY') : ''}
        </div>
      `
    )}
  `)
}

export default SearchResults
