import { useEffect, useState } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Checkbox, Input, Popover, Radio, Select, Space, Tooltip } from 'antd'
import { orderBy } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetAirlinesThunk, GetAlliancesThunk, selectAirlines, selectAlliances } from '../../../../store/airportReducer'
import { InputGoogleAPI } from '../../../common/InputGoogleAPI/InputGoogleAPI'
import classes from './../Filters.module.css'
import { ActiveFiltersType } from '../../../../types/searchResultTypes'
import { ReactComponent as InfoIcon } from './../../../../img/icons/info.svg'
import ExcludeCountryFilter from '../common/ExcludeCountryFilter'
import { selectActiveTab } from '../../../../store/searchResultsReducer'

interface AirportFiltersPropTypes {
  selectedFilters: ActiveFiltersType
  setSelectedFilters: (filters: ActiveFiltersType) => void
}

const AirportFilters: React.FC<AirportFiltersPropTypes> = ({selectedFilters, setSelectedFilters}) => {
  const dispatch = useAppDispatch()
  const airlines = useAppSelector(selectAirlines)
  const alliances = useAppSelector(selectAlliances)
  const activeTab = useAppSelector(selectActiveTab)
  const [airlineOptions, setAirlineOptions] = useState(airlines)

  useEffect(() => {
    dispatch(GetAirlinesThunk())
    dispatch(GetAlliancesThunk())
  }, [dispatch])

  useEffect(() => {
    setAirlineOptions(airlines)
  }, [airlines])

  const handleChange = (fields: {[key: string]: any}) => {
    const updatedFilters = {
      ...selectedFilters,
      airports: {...(selectedFilters?.airports || {}), ...fields}
    }
    setSelectedFilters(updatedFilters)
  }

  const handleAllianceSelection = (allianceName: string) => {
    if (!!allianceName?.length) {
      const selectedAllianceData = alliances.find(alliance => alliance.name.toLowerCase() === allianceName.toLowerCase())
      const availableAirlines = selectedAllianceData?.airlines 
      const isSelectedAirlineUnavailable = !!selectedFilters?.airports?.airline_code && !availableAirlines?.some(airline => airline.code === selectedFilters?.airports?.airline_code)
      setAirlineOptions(availableAirlines || [])
      handleChange({
        alliance_name: allianceName,
        ...(isSelectedAirlineUnavailable ? {airline_code: null} : {})
      })
    } else {
      setAirlineOptions(airlines)
      handleChange({alliance_name: null})
    }
  }

  const handleLocationChange = async(value: string) => {
    if (value.length) {
      const addressData = await geocodeByAddress(value)
      const coordinates = await getLatLng(addressData[0])
      handleChange({
        destination_location: {latitude: coordinates.lat, longitude: coordinates.lng},
        destination_location_name: value,
        destination_country_code: addressData[0]?.address_components?.find(component => component?.types?.includes('country'))?.short_name,
        radius: selectedFilters?.airports?.radius || 200,
        unit: selectedFilters?.airports?.unit || 'KM',
        departure_date: activeTab?.dates[0]?.format('YYYY-MM-DD')
      })
    } else {
      handleChange({
        destination_location: null,
        destination_country_code: null,
        radius: null,
        unit: null
      })
    }
  }

  return (
    <>
      <div className={classes.filterSubCategory}>
        <div className={classes.filterCategoryTitle}>
          Airport Filters:
        </div>
        <div className={classes.filterSubCategoryTitle}>
          Type of flights
        </div>
        <Radio.Group
          onChange={(e) => handleChange({scheduled_flights: e.target.value})}
          value={selectedFilters?.airports?.scheduled_flights !== undefined ? selectedFilters?.airports?.scheduled_flights : null}
        >
          <Space direction='vertical'>
            <Radio value={null}>Select all</Radio>
            <Radio value={true}>Airports with Scheduled Flights</Radio>
            <Radio value={false}>Airports without Scheduled Flights</Radio>
          </Space>
        </Radio.Group>
      </div>

      {/* <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
            Include other countries
            <Popover
              content={
                <span style={{padding: '0px 10px', fontSize: '12px'}}>
                  Show nearest airports in other countries (if there are any available)
                </span>
              }
            >
              <InfoIcon className={classes.infoIcon} />
            </Popover>
        </div>
        <Checkbox
          onChange={e => handleChange({show_all_countries: e.target.checked})}
          checked={selectedFilters?.airports?.show_all_countries}
        >
          Include
        </Checkbox>
      </div> */}
      <ExcludeCountryFilter
        onChange={(val: null | string[]) => handleChange({excluded_countries: val})}
        value={selectedFilters?.airports?.excluded_countries || []}
      />

      <Tooltip title='Coming soon'>
      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
          Airports served by specific airline
        </div>
        <Select
          style={{ width: '100%' }}
          onChange={value => handleChange({airline_code: value})}
          value={selectedFilters?.airports?.airline_code}
          allowClear
          onClear={() => handleChange({airline_code: null})}
          placeholder='Select Airline'
          showSearch
          filterOption={(input, option) => {
            const airlineName = option!.children as unknown as string
            return airlineName?.toLowerCase().includes(input.toLowerCase())
          }}
          disabled
        >
          {orderBy(airlineOptions, [airline => airline?.name?.toLowerCase()]).map(a => <Select.Option value={a.code} key={a.name}>{a.name}</Select.Option>)}
        </Select>

        <div style={{marginTop: '15px'}}>
          <Checkbox
            onChange={e => handleChange({non_codeshare: e.target.checked})}
            checked={selectedFilters?.airports?.non_codeshare}
            // disabled={!selectedFilters?.airports?.airline_code}
            disabled
          />
          <span style={{color: selectedFilters?.airports?.airline_code ? 'black' : 'gray', marginLeft: '10px'}}>
            Only if airline actually operates the flight
          </span>
        </div>
      </div>
      
      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
          Airports served by specific alliance
        </div>
        <Select
          style={{ width: '100%' }}
          onChange={value => handleAllianceSelection(value)}
          value={selectedFilters?.airports?.alliance_name}
          allowClear
          onClear={() => handleAllianceSelection('')}
          placeholder='Select Alliance'
          showSearch
          filterOption={(input, option) => {
            const allianceName = option!.children as unknown as string
            return allianceName?.toLowerCase().includes(input.toLowerCase())
          }}
          disabled
        >
          {orderBy(alliances, [alliances => alliances?.name?.toLowerCase()]).map(a => <Select.Option value={a.name} key={a.name}>{a.name}</Select.Option>)}
        </Select>
      </div>

      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
          Airports with non-stop flights from nearest airport to location
          <Popover
            content={
              <span style={{padding: '0px 10px', fontSize: '12px', whiteSpace: 'pre-wrap'}}>
                {'Enter the location from where the flight will start, and allowable search radius for airports in this area.'}
              </span>
            }
          >
            <InfoIcon className={classes.infoIcon} />
          </Popover>
        </div>
        <InputGoogleAPI
          placeholder='Enter Location'
          value={selectedFilters?.airports?.destination_location_name || ''}
          onChange={(value) => handleLocationChange(value)}
          disabled
        />
        <div className={classes.doubleInput}>
          <Input
            type='number'
            value={selectedFilters?.airports?.radius || undefined}
            onChange={e => handleChange({radius: e.target.value})}
            placeholder='Radius'
            min={1}
            // disabled={!selectedFilters?.airports?.destination_location_name?.length}
            disabled
          />
          <Select
            value={selectedFilters?.airports?.unit}
            onChange={value => handleChange({unit: value})}
            placeholder='Units'
            // disabled={!selectedFilters?.airports?.destination_location_name?.length}
            disabled
          >
            <Select.Option value='KM'>km</Select.Option>
            <Select.Option value='MILES'>miles</Select.Option>
            <Select.Option value='NAUTICAL'>nautical</Select.Option>
          </Select>
        </div>
      </div>
      </Tooltip>
    </>
  )
}

export default AirportFilters
