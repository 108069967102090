import { Button, Form, Input } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../../app/hooks'
import { SendPasswordEmailThunk } from '../../../store/userReducer'
import classes from './../Auth.module.css'

const ForgotPasswordFrom = () => {
  const dispatch = useAppDispatch()
  const [isRequestSent, setIsRequestSent] = useState(false)
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (formData: {email: string, showSuccessMessage: boolean}) => {
    setIsLoading(true)
    dispatch(SendPasswordEmailThunk({email: formData.email, showSuccessMessage: formData.showSuccessMessage}))
      .then(resp => {
        setIsLoading(false)
        if (!resp.type.includes('rejected')) {
          setEmail(formData.email)
          setIsRequestSent(true)
        }
      })
  }

  if (isRequestSent) {
    return (
      <div className={classes.contentWrapper}>
        <h1 className={classes.formTitle}>
          Check your email
        </h1>
        <div className={classes.message}>
          We sent a password reset link to {email}
        </div>
        <div className={classes.additionalLink}>
          Didn’t receive the email? <span style={{color: 'var(--app-highlight-color)', cursor: 'pointer'}} onClick={() => onFinish({email, showSuccessMessage: true})}>Click to send again</span>
        </div>
      </div>
    )
  }

  return (
    <Form
      name='forgotPassword'
      onFinish={onFinish}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
    >
      <h1 className={classes.formTitle}>
        Forgot your password?
      </h1>
      <div className={classes.explanation}>
        Please enter your email address and we’ll send you a link to reset your password
      </div>

      <div className={classes.label}>
        Email Address
      </div>
      <Form.Item
        name='email'
        rules={[
          { required: true, message: 'Please enter your email!' },
          { type: 'email', message: 'Invalid e-mail!' },
        ]}
      >
        <Input placeholder='Enter your email'/>
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
          Reset password
        </Button>
      </Form.Item>

      <Link to='/sign-in' className={classes.goBackLink}>
        Back to Sign in
      </Link>
    </Form>
  )
}

export default ForgotPasswordFrom
