import { useEffect, useRef } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './../types/appTypes'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const usePrevious = (value:any) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
