import { Divider, Rate, Spin } from 'antd'
import moment from 'moment'
import classes from './CruiseItem.module.css'
import { ReactComponent as ShipIcon } from './../../../../../img/travel/shipIcon.svg'
import { ReactComponent as DatePickerCalendar } from './../../../../../img/icons/datePickerCalendar.svg'
import { ReactComponent as ShipWithArrowIcon } from './../../../../../img/travel/shipWithArrowIcon.svg'
import { AdditionalSearchDataType, CruiseType, CustomizedCruiseRouteType } from '../../../../../types/cruiseTypes'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { CreateCustomCruiseThunk, EditCustomCruiseThunk, getCruiseArrivalLocation, selectCruiseLine, selectCustomizedCruise, setSelectedStops } from '../../../../../store/cruiseReducer'
import { NodeServiceDetails } from '../../../../../store/searchResultsReducer'
import TripStopItem from '../../../common/TripStopItem/TripStopItem'
import { isNumber, maxBy, minBy, pickBy, uniq } from 'lodash'
import { getCruiseSearchUrl, getFlightCruiseShareLink } from '../../../../../helpers/linksHelper'
import { useEffect, useState } from 'react'
import { selectIsLoggedIn, selectUserData } from '../../../../../store/userReducer'
import { useMediaQuery } from 'react-responsive'

const CruiseItem: React.FC<CruiseItemPropTypes> = ({cruise, showStops, onClick, getNodeData, searchData, previewInfoOnly}) => {
  return (
    <div
      className={`${classes.wrapper} ${showStops ? classes.cruiseDetails : ''}`}
      onClick={() => onClick(cruise)}
    >
      <div className={classes.mainInfo}>
        <div className={classes.cardTitle}>
          {showStops ? 'Cruise itinerary' : cruise.name}
          <Rate value={Math.round(cruise?.rating || 0)} disabled style={{marginBottom: '3px', marginRight: '10px'}}/>
          <CruiseNightsCount cruise={cruise} />
        </div>
        {!showStops &&
            <div style={{fontSize: '14px'}}>
              {uniq(cruise?.cruise_routes?.map(r => r.name)).join(', ')}
            </div>
          }
      </div>

      {showStops ? (
        <DataForItemDetails
          cruise={cruise}
          getNodeData={getNodeData!}
          searchData={searchData!}
          previewInfoOnly={previewInfoOnly}
        />
      ) : (
        <DataForItemList cruise={cruise} />
      )}
    </div>
  )
}

export const CruiseNightsCount: React.FC<{cruise: CruiseType}> = ({cruise}) => {
  return (
    <div className={classes.nights}>
      {cruise.night_quantity > 1 ? cruise.night_quantity + ' nights' : ' night'}
    </div>
  )
}

const DataForItemList: React.FC<{cruise: CruiseType}> = ({cruise}) => {
  return (
    <>
      {!!cruise?.ship?.name &&
        <div className={classes.linerInfo}>
          Liner: {cruise?.ship?.name}
        </div>
      }
      <CruiseBriefInfo cruise={cruise}/>
    </>
  )
}

export const CruiseBriefInfo: React.FC<{cruise: CruiseType}> = ({cruise}) => {
  const cruiseLine = useAppSelector(selectCruiseLine)
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  return (
    <div>
      <div className={`${classes.additionalInfo} ${classes.secondaryInfo}`}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {!isSmallScreen &&
            <ShipIcon style={{width: '14px', height: '14px', marginRight: '5px'}} />
          }
          <span>Port start:</span> {minBy(cruise?.cruise_routes, (r) => r.sequence)?.port?.name}
        </div>
        <div>
          <span>Port end:</span> {maxBy(cruise?.cruise_routes, (r) => r.sequence)?.port?.name}
        </div>
      </div>
      <div className={`${classes.additionalInfo} ${classes.secondaryInfo}`}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <DatePickerCalendar style={{width: '14px', height: '14px', marginRight: '5px'}} /> {moment(cruise.start_date, 'YYYY-MM-DD').format('dd DD MMM YYYY')} - {moment(cruise.end_date , 'YYYY-MM-DD').format('dd DD MMM YYYY')}
        </div>
        <div>
          <span>Type:</span> {cruiseLine?.type_name} {cruiseLine?.type_name?.includes('Cruise') ? '' : 'Cruise'}
        </div>
      </div>
    </div>
  )
}

const DataForItemDetails: React.FC<DataForItemDetailsPropTypes> = ({cruise, getNodeData, searchData, previewInfoOnly}) => {
  const departureLocation = cruise?.cruise_routes[0]
  const arrivalLocation = cruise?.cruise_routes[cruise?.cruise_routes.length - 1]

  return (
    <div>
      <div className={classes.infoWrapper}>
        <LocationData 
          location={!!departureLocation?.port?.name ? departureLocation?.port?.name + ' / ' + departureLocation?.city : departureLocation?.city}
          date={cruise?.start_date}
          type='departure'
        />
        <ShipWithArrowIcon />
        <LocationData 
          location={!!arrivalLocation?.port?.name ? arrivalLocation?.port?.name! + ' / ' + arrivalLocation?.city : arrivalLocation?.city}
          date={cruise?.end_date}
          type='arrival'
        />
      </div>
      <TripLocationDetails 
        locations={cruise.cruise_routes}
        cruise={cruise}
        searchData={searchData}
        getNodeData={getNodeData}
        previewInfoOnly={previewInfoOnly}
      />
    </div>
  )
}

const LocationData: React.FC<LocationDataPropTypes> = ({location, date, type}) => {
  return (
    <div className={classes.locationDataWrapper}>
      <div className={classes.additionalInfo}>
        {type === 'arrival' ? 'Port end' : 'Port start'}
      </div>
      <div className={classes.flightSummaryInfo}>
        {location}
      </div>
      <div className={classes.additionalInfo}>
        {type === 'arrival' ? 'Journey End Date' : 'Journey Start Date'}
      </div>
      <div className={classes.flightSummaryInfo}>
        {moment(date, 'YYYY-MM-DD').format('dd DD MMM YYYY')}
      </div>
    </div>
  )
}

const TripLocationDetails: React.FC<TripLocationDetailsPropTypes> = ({locations, cruise, getNodeData, searchData, previewInfoOnly}) => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const customizedCruise = useAppSelector(selectCustomizedCruise)
  const userData = useAppSelector(selectUserData)
  const departureLocation = cruise?.cruise_routes[0]
  const arrivalLocation = getCruiseArrivalLocation(cruise?.cruise_routes, 1)//cruise?.cruise_routes[cruise?.cruise_routes?.length - 1]
  const stops = locations.slice(1, locations.findIndex((l) => l.id === arrivalLocation.id))//locations.slice(1, -1)
  // if last stop doesn't have coordinates and has "Fly to" in its name
  const stopsAfterArrivalLocation = locations.slice(locations.findIndex((l) => l.id === arrivalLocation.id) + 1)?.filter(l => l.name?.includes('Fly to'))
  
  const [isEditingRoutes, setIsEditingRoutes] = useState(false)
  const [customizedRoutes, setCustomizedRoutes] = useState<CustomizedCruiseRouteType[]>([])
  const [isSaving, setIsSaving] = useState(false)

  const saveCustomizedRoutes = () => {
    setIsEditingRoutes(false)
    if (!isNumber(customizedCruise) && !!customizedCruise?.id) {
      setIsSaving(true)
      dispatch(EditCustomCruiseThunk({
        userCustomCruiseId: customizedCruise.id,
        customizedData: {
          cruise_id: cruise?.id,
          url: customizedCruise?.url,
          itineraries: customizedRoutes.map(r => pickBy(r, (_, key) => key !== 'id') as CustomizedCruiseRouteType)
        }
      })).then(() => setIsSaving(false))
    } else {
      if (customizedRoutes?.length) {
        setIsSaving(true)
        dispatch(CreateCustomCruiseThunk({
          cruise_id: cruise?.id,
          url: getFlightCruiseShareLink() + '&customized=',
          itineraries: customizedRoutes
        })).then(() => setIsSaving(false))
      }
    }
  }

  useEffect(() => {
    if (!isNumber(customizedCruise) && !!customizedCruise?.cruise_id && !customizedRoutes?.length) {
      setCustomizedRoutes(customizedCruise?.itineraries)
    }
  }, [customizedCruise, customizedRoutes])

  return (
    <div style={{position: 'relative'}}>
      <Divider />
      {!previewInfoOnly
        &&!!isLoggedIn
        && (
          customizedCruise === null
          || (!isNumber(customizedCruise) && customizedCruise?.user_id === userData.user_id)
        ) && <div
          onClick={() => isEditingRoutes ? saveCustomizedRoutes() : setIsEditingRoutes(true)}
          className={classes.saveEditBtn}
        >
          {isSaving ? <Spin /> : isEditingRoutes ? 'Save' : 'Edit'}
        </div>
      }
      <div id={String(departureLocation?.id)}>
        <TripStopItem
          location={departureLocation?.name || ''}
          nodeName={departureLocation?.port?.name!}
          departureDate={departureLocation?.departure_time || ''}
          type='departure'
          id={departureLocation.id}
          searchData={searchData}
          getNodeData={getNodeData}
          tripType='byCruise'
          terminals={departureLocation?.port?.terminals}
          previewInfoOnly={previewInfoOnly}
        />
        <StopConnectionLine pointsData={{start: departureLocation?.id, end: stops[0]?.id}} top='85px'/>
      </div>
      {stops?.map((stop, index) => (
        <div style={{position: 'relative'}} id={stop?.id} key={stop?.id}>
          <TripStopItem
            location={stop?.name || ''}
            nodeName={stop?.port?.name!}
            nodeDetails={stop}
            departureDate={stop?.departure_time || ''}
            arrivalDate={stop?.arrival_time || ''}
            type='transfer'
            id={stop?.id}
            searchData={searchData}
            getNodeData={getNodeData}
            setSelectedStops={(id: number) => dispatch(setSelectedStops(id))}
            tripType='byCruise'
            stopOrder={index + 2}
            previewInfoOnly={previewInfoOnly}
            isEditingRoutes={isEditingRoutes}
            customizedRoutes={customizedRoutes}
            setCustomizedRoutes={(routes: CustomizedCruiseRouteType[]) => setCustomizedRoutes(routes)}
            terminals={stop?.port?.terminals}
          /> 
          <StopConnectionLine pointsData={{start: stop?.id, end: stops[index + 1]?.id || arrivalLocation?.id}}/>
        </div>
      ))}
      <div id={arrivalLocation?.id}>
        <TripStopItem
          location={arrivalLocation?.name || ''}
          nodeName={arrivalLocation?.port?.name!}
          arrivalDate={arrivalLocation?.arrival_time || ''}
          type='arrival'
          id={arrivalLocation?.id}
          searchData={searchData}
          getNodeData={getNodeData}
          tripType='byCruise'
          terminals={arrivalLocation?.port?.terminals}
          previewInfoOnly={previewInfoOnly}
        />
        <StopConnectionLine pointsData={{start: arrivalLocation?.id, end: stopsAfterArrivalLocation[0]?.id}} top='85px'/>
      </div>
      {!!stopsAfterArrivalLocation?.length && stopsAfterArrivalLocation?.map((stop, i) => (
        <div id={stop?.id} key={stop?.id}>
          <TripStopItem
            location={stop?.name || ''}
            nodeName={stop?.port?.name!}
            nodeDetails={stop}
            arrivalDate={stop?.arrival_time || ''}
            type='transfer'
            id={stop?.id}
            searchData={searchData}
            getNodeData={getNodeData}
            tripType='byCruise'
            previewInfoOnly={previewInfoOnly}
          />
          {!!stop[i + 1]?.id && <StopConnectionLine pointsData={{start: stop?.id, end: stop[i + 1]?.id}}/> }
        </div>
      ))}
      {previewInfoOnly && 
        <a
          href={getCruiseSearchUrl({
            cruiseLine: {value: cruise?.cruise_line_id},
            cruiseName: {label: [cruise?.name]},
            date: cruise?.start_date
          })}
          target='_blank'
          rel='noreferrer'
          className={classes.linkToFullResults}
        >
          View cruise details
        </a>
      }
    </div>
  )
}

const StopConnectionLine: React.FC<{pointsData: {start: number, end: number}, top?: string}> = ({pointsData, top}) => {
  const [lineHeight, setLineHeight] = useState(0)

  const additionalDistanceForHeight = -50

  useEffect(() => {
    const calculateHeights = () => {
      const startDiv = document.getElementById(String(pointsData.start))
      const endDiv = document.getElementById(String(pointsData.end))
      if (startDiv && endDiv) {
        const startDivRect = startDiv.getBoundingClientRect()
        const endDivRect = endDiv.getBoundingClientRect()
        const heightBetweenDivs = endDivRect.top - startDivRect.top
        setLineHeight(heightBetweenDivs)
      }
    }
    calculateHeights()
    window.addEventListener('resize', calculateHeights)

    return () => {
      window.removeEventListener('resize', calculateHeights)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        left: '21.5px',
        top: top ? top : '45px',
        // bottom: '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <svg height={lineHeight + (!!lineHeight ? additionalDistanceForHeight : 0) + 'px'} width="2">
        <line x1="1" y1="0" x2="1" y2="100%" stroke="#E3E3E3" strokeWidth="1.2" strokeDasharray="5 5" />
      </svg>
    </div>
  )
}

interface CruiseItemPropTypes {
  cruise: CruiseType
  showStops?: boolean
  onClick: (flight: CruiseType) => void
  searchData?: AdditionalSearchDataType
  getNodeData?: (key: string) => NodeServiceDetails
  previewInfoOnly: boolean
}

interface DataForItemDetailsPropTypes {
  cruise: CruiseType
  searchData: AdditionalSearchDataType
  getNodeData: (key: string) => NodeServiceDetails
  previewInfoOnly: boolean
}

interface LocationDataPropTypes {
  location: string
  date: string
  type: 'departure' | 'arrival'
}

interface TripLocationDetailsPropTypes {
  locations: any[]
  cruise: CruiseType
  searchData: AdditionalSearchDataType
  getNodeData: (key: string) => NodeServiceDetails
  previewInfoOnly: boolean
}

export default CruiseItem
