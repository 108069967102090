import { Table } from 'antd'
import { useMediaQuery } from 'react-responsive'
import Footer from '../common/Footer/Footer'
import GoToTopBtn from '../common/GoToTopBtn/GoToTopBtn'
import Header from '../common/Header/Header'
import classes from './B2B.module.css'
import { ReactComponent as PointCheckIcon } from './../../img/advertisingPage/pointCheckIcon.svg'
import { ReactComponent as B2BImage } from './../../img/advertisingPage/b2b.svg'
import { Helmet } from 'react-helmet-async'

const B2B = () => {
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  const tableRows = [
    {layer: 'Traveler interests, preferences and requirements', corporate: true, leisure: true},
    {layer: 'Corporate preferences and requirements (travel policy)', corporate: true, leisure: false},
    {layer: 'Corporate discounted and preferred suppliers', corporate: true, leisure: false},
    {layer: 'Agency preferred suppliers', corporate: true, leisure: true},
  ]

  const abilities = [
    {text: 'The white label product will remove all advertisements and affiliate links.'},
    {text: 'Your agency logo will be added to the home page.'},
    {text: 'You can supply all information about your agency’s preferred suppliers and those will be added to the search platform to be visible by your agents as they work: Airlines, transportation companies, local tour operators and any other preferred supplier.'},
    {text: 'When searching for flights or cruises, the preferred airlines and cruise lines will be sorted to show first in the lists, and color coded for easy reference.'},
    {text: 'We will create a unique admin login for your agency and for each of your agents. This will allow your agents to create, save and share searches and search lists.'},
    {text: 'If there is any service provider that wants their ad to be displayed before your agents, the advertisement will be added only upon your approval and your agency will share in the revenues of that advertisement (if specific to your agency).'},
    {text: 'Relavanti by Scopia LLC is a new search platform, rebuilding travel search from scratch. New features are added on a continuous basis. During the term of your white label contract, any new features added to Relavanti will be automatically added to your white label platform, at no additional charge.'},
  ]

  return (
    <>
      <div className={classes.wrapper}>
        <Helmet>
          <title>Relavanti | B2B</title>
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header showMenu={true}/>
        <GoToTopBtn />
        <div className={classes.contentWrapper}>
          <h1>
            B2B - White Label on Relavanti
          </h1>
          <div className={classes.infoBlock}>
            <h2>
              If you manage a travel agency (corporate or leisure), Relavanti can be a useful tool for your team, increasing efficiency through relevance and reducing search time for solutions.
            </h2>
            <div>
              Background: While planning trips, travelers spend a lot of time searching for travel and tourism solutions. Corporate and leisure travel agents are no different, spending valuable time searching for solutions, except that they look for these solutions utilizing 2-4 additional layers of relevance:
            </div>
          </div>

          <Table
            columns={[
              {
                title: <span style={{whiteSpace: 'break-spaces'}}>Layer</span>,
                dataIndex: 'layer',
                key: 'layer',
                ellipsis: true,
                width: isSmallScreen ? '44%' : '60%',
                render: (layer) => (<div style={{fontWeight: 700, whiteSpace: 'pre-line'}}>{layer}</div>)
              },
              {
                title: <span style={{whiteSpace: 'break-spaces'}}>Corporate Agent</span>,
                dataIndex: 'corporate',
                key: 'corporate',
                ellipsis: true,
                width: isSmallScreen ? '28%' : '20%',
                align: 'center',
                render: (isAvailable: boolean) => (isAvailable ? <PointCheckIcon /> : <></>)
              },
              {
                title: <span style={{whiteSpace: 'break-spaces'}}>Leisure Agent</span>,
                dataIndex: 'leisure',
                key: 'leisure',
                ellipsis: true,
                width: isSmallScreen ? '28%' : '20%',
                align: 'center',
                render: (isAvailable: boolean) => (isAvailable ? <PointCheckIcon /> : <></>)
              },
            ]}
            pagination={false}
            dataSource={tableRows}
            rowKey={(row) => row.layer}
            style={{marginTop: '50px'}}
          />

          <div className={classes.infoBlock} style={{marginTop: '60px'}}>
            <h2>
              Relavanti by Scopia LLC is specifically designed to reduce search time by mimicking the way travelers and travel agents work.
            </h2>
            <div>
              Here’s what the platform can do for your agency, as a white label work tool:
            </div>
          </div>
          <div className={classes.abilitiesBlockWrapper}>
            <div className={classes.abilitiesWrapper}>
              {abilities.map(ability => (
                <div key={ability.text}>
                  <PointCheckIcon /> {ability.text}
                </div>
              ))}
            </div>
            <B2BImage style={{width: '100%', display: isSmallScreen ? 'none' : 'block'}}/>
          </div>
        </div>
      </div>
      <div className={classes.infoBlock}>
        <h2>
          For more information and for an online demo, please email<br/>
          <a href='mailto:b2b@relavanti.com' style={{color: 'var(--app-primary-color)'}}>
            b2b@relavanti.com.
          </a>
        </h2>
        <div>
          Relavanti.com is a product of Scopia LLC, based in Milwaukee, WI. It will be an honor and a pleasure to serve your agency.
        </div>
      </div>
      <Footer />
    </>
  )
}

export default B2B
