import { configureStore } from '@reduxjs/toolkit'
import appStatus from './appStatusReducer'
import user from './userReducer'
import airports from './airportReducer'
import ports from './portReducer'
import searchResults from './searchResultsReducer'
import services from './serviceReducer'
import transportStops from './transportStopsReducer'
import railways from './railwayReducer'
import buses from './busReducer'
import viator from './viatorReducer'
import flight from './flightReducer'
import cruise from './cruiseReducer'
import travelSearch from './travelSearchReducer'
import map from './mapReducer'
import userSearch from './userSearchReducer'
import events from './eventReducer'
import advertisement from './advertisementReducer'
import terminal from './terminalReducer'
import blog from './blogReducer'
import widget from './widgetReducer'
import additionalData from './additionalDataReducer'

export const store = configureStore({
  reducer: {
    appStatus,
    user,
    airports,
    ports,
    searchResults,
    services,
    transportStops,
    railways,
    buses,
    viator,
    flight,
    cruise,
    travelSearch,
    map,
    userSearch,
    events,
    advertisement,
    terminal,
    blog,
    widget,
    additionalData,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  devTools: true,
})
