import {Button} from 'antd'
import {useState} from 'react'
import {geocodeByAddress} from 'react-places-autocomplete'
import {useNavigate} from 'react-router-dom'
import {BeforeAfterLocationsType} from '../../../../types/cruiseTypes'
import {InputGoogleAPI} from '../../../common/InputGoogleAPI/InputGoogleAPI'
import {useAppDispatch, useAppSelector} from '../../../../app/hooks'
import {selectAirlines} from '../../../../store/airportReducer'
import {SaveSearchTrackingThunk} from '../../../../store/userSearchReducer'
import moment from 'moment'

const NextLocationForm: React.FC<NextLocationFormPropTypes> = ({formData, getResultsLink, travelType}) => {
    const dispatch = useAppDispatch()
    const airlines = useAppSelector(selectAirlines)
    const navigate = useNavigate()
    const initialLocationData = {startCity: {name: '', placeId: ''}, endCity: {name: '', placeId: ''}}

    const [formValues, setFormValues] = useState(initialLocationData)

    const handleAddressChange = async (field: 'startCity' | 'endCity', value: string) => {
        if (value?.length) {
            const addressData = await geocodeByAddress(value)
            const placeId = addressData[0]?.place_id
            setFormValues({...formValues, [field]: {name: value, placeId}})
        } else {
            setFormValues({...formValues, [field]: {name: '', placeId: ''}})
        }
    }

    const goToResults = () => {
        const url = getResultsLink(formData, formValues)
        trackSearch(url)
    }

    const trackSearch = (redirectUrl: string) => {
        const airlineData = airlines?.find(airline => airline.code === formData?.airlineCode)
        const trackingData = {
            searched_start_destination_city: formValues?.startCity?.name || '',
            searched_final_destination_city: formValues?.endCity?.name || '',
            ...(travelType === 'flight'
                    ? {
                        searched_airline: `${airlineData?.name} (${airlineData?.code})`,
                        searched_flight_number: formData.number,
                        searched_flight_date: moment(formData.date).format('DD-MM-yyyy'),
                        searched_type: 'Flight' as 'Flight',
                    } : {
                        searched_cruise_line: formData.cruiseLine.label,
                        searched_cruise_name: formData?.cruiseName?.label[0] || '',
                        searched_cruise_first_date: moment(formData.date).format('DD-MM-yyyy'),
                        searched_type: 'Cruise' as 'Cruise',
                    }
            )
        }
        dispatch(SaveSearchTrackingThunk(trackingData))
        navigate(redirectUrl)
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{width: '100%', marginBottom: '35px'}}>
                Select the city from where you plan to get to the starting point of the {travelType}
                <InputGoogleAPI
                    value={formValues.startCity.name}
                    onChange={(value: string) => handleAddressChange('startCity', value)}
                    placeholder='Enter location'
                    suggestionsPosition='vertical'
                    style={{maxWidth: 'none', height: 'auto'}}
                    searchOptions={{types: ['(cities)']}}
                />
            </div>
            <div style={{width: '100%', zIndex: 1}}>
                Select the city from where you will go after the {travelType}
                <InputGoogleAPI
                    value={formValues.endCity.name}
                    onChange={(value: string) => handleAddressChange('endCity', value)}
                    placeholder='Enter location'
                    suggestionsPosition='vertical'
                    style={{maxWidth: 'none', height: 'auto'}}
                    searchOptions={{types: ['(cities)']}}
                />
            </div>
            <Button
                type='primary'
                onClick={goToResults}
                style={{
                    marginTop: '40px',
                    width: '160px'
                }}
            >
                Search
            </Button>
            <div
                onClick={() => trackSearch(getResultsLink(formData, initialLocationData))}
                style={{marginTop: '15px', textDecoration: 'underline', color: 'black', cursor: 'pointer'}}
            >
                Skip
            </div>
        </div>
    )
}

interface NextLocationFormPropTypes {
    travelType: 'flight' | 'cruise'
    formData: any
    getResultsLink: (data: any, additionalData: { startCity: BeforeAfterLocationsType, endCity: BeforeAfterLocationsType }) => string
}

export default NextLocationForm
