import { AdvertisingType } from '../../../types/advertisingTypes'
import classes from './AdvertisingBanner.module.css'

const AdvertisingBanner: React.FC<{adData: AdvertisingType, style: object}> = ({adData, style}) => {
  return (
    <a
      href={adData.link.includes('https') || adData.link.includes('http') ? adData.link : '//' + adData.link}
      target='_blank'
      rel='noreferrer'
      className={classes.adsBanner}
      style={style}
    >
      {(adData.selected_banner_type === 'uploaded' || !adData.selected_banner_type) &&
        <img
          src={adData.banner_url}
          alt='banner'
          style={{width: '100%', height: '100%', objectFit: 'scale-down', borderRadius: '9px', backgroundColor: '#f2f2f2'}}
        />
      }
      {adData?.selected_banner_type === 'generated' &&
        <GeneratedBanner adData={adData}/>
      }
      {adData.selected_banner_type === 'html' && (
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            borderRadius: '9px',
          }}
          dangerouslySetInnerHTML={{__html: adData?.banner_html!}}
        ></div>
      )}
    </a>
  )
}

const GeneratedBanner: React.FC<{adData: AdvertisingType}> = ({adData}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: adData.banner_bg_color,
        color: adData.banner_color,
        borderRadius: '8px',
        fontSize: '14px',
        display: 'grid',
        gridTemplateColumns: adData.banner_comment?.length ? '60px 1fr' : '1fr',
        gridTemplateRows: '100%',
        overflow: 'hidden',
      }}
    >
      <img
        src={adData?.banner_url}
        style={{
          height: '100%',
          objectFit: 'scale-down',
          ...(!!adData.banner_comment?.length ? {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          } : {
            borderRadius: '8px',
          }),
          width: '100%',
        }}
        alt='banner'
      />
      {!!adData.banner_comment?.length &&
        <div
          style={{
            padding: '8px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
          }}
        >
          {adData.banner_comment}
        </div>
      }
    </div>
  )
}

export default AdvertisingBanner
