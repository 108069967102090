import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import MainPage from './MainPage/MainPage'
import { getLocationsFromLink } from '../../helpers/linksHelper'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getWidgetLocationData, selectActiveTab, setLocation } from '../../store/widgetReducer'
import Results from './Results/Results'


const Widget = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const activeTab = useAppSelector(selectActiveTab)

  useEffect(() => {
    const locationName = getLocationsFromLink(location.search)?.[0]
    getWidgetLocationData(locationName)
      .then((resp) => dispatch(setLocation(resp)))
  }, [dispatch, location])

  return (
    <>
      {activeTab === 'main' && <MainPage />}
      {activeTab !== 'main' && <Results />}
    </>
  )
}

export default Widget
