import { Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { selectActiveResultCategories, setActiveResultCategories, setIsTerminalListOpen, setNodeServiceDetails, setSelectedTransportStop, setSelectedTransportStops } from '../../../../../store/searchResultsReducer'
import classes from './ResultCategories.module.css'
import { setSelectedNodeTerminals } from '../../../../../store/terminalReducer'

const ResultCategories: React.FC<ResultCategoriesPropTypes> = ({resultCategories, isSmallScreen, className}) => {
  const dispatch = useAppDispatch()
  const activeResultCategories = useAppSelector(selectActiveResultCategories)

  const selectType = (type: ResultCategoryType) => {
    if (type.value === 'near') {
      dispatch(setActiveResultCategories(['near']))
    } else {
      dispatch(setSelectedTransportStop(null))
      dispatch(setSelectedTransportStops([]))
      const removedNearType = activeResultCategories.filter(s => s !== 'near')
      if (activeResultCategories.includes(type.value)) {
        const newActiveResultCategories = removedNearType.filter(s => s !== type.value)
        dispatch(setActiveResultCategories(newActiveResultCategories?.length ? newActiveResultCategories : ['near']))
      } else {
        dispatch(setActiveResultCategories([...removedNearType, type.value]))
      }
      dispatch(dispatch(setSelectedNodeTerminals(null)))
    }
    dispatch(setNodeServiceDetails(null))
    dispatch(setIsTerminalListOpen(false))
  }

  return (
    <div className={`${classes.resultCategories} ${isSmallScreen ? classes.smallScreen : ''} ${className}`}>
      {resultCategories.map(type => type.name === 'Rail' || type.name === 'Bus' ? (
        <Tooltip title='Coming soon' key={type.name}>
          <div
            onClick={() => selectType(type)}
            className={`
              ${classes.resultType}
              ${activeResultCategories.includes(type.value) && classes.active}
            `}
          >
            {type.name}
          </div>
        </Tooltip>
      ) : (
        <div
          key={type.name}
          onClick={() => selectType(type)}
          className={`
            ${classes.resultType}
            ${activeResultCategories.includes(type.value) && classes.active}
          `}
        >
          {type.name}
        </div>
      ))}
    </div>
  )
}

interface ResultCategoryType {
  [key: string]: string
}

interface ResultCategoriesPropTypes {
  resultCategories: ResultCategoryType[]
  isSmallScreen?: boolean
  className?: string
}

export default ResultCategories
