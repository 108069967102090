import { Upload, message } from "antd"

export const dummyRequest = ({ onSuccess }:any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const getBase64 = (file:any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const getImageUrl = async(file:any): Promise<string> => {
  let imageUrl = await getBase64(file.originFileObj) as string
  return imageUrl
}

export const beforeUpload = (file:any, sizeLimit?: number) => {
  const fileSizeLimit = sizeLimit || 2
  const isValidSize = file.size / 1024 / 1024 < fileSizeLimit
  if (!isValidSize) message.error(`File must be less than ${fileSizeLimit} mb`)
  return isValidSize ? true : Upload.LIST_IGNORE
}

export const normFile = (e: any, addPhoto: (e: any) => void) => {
  addPhoto(e.file)
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}
