import classes from './SortBtn.module.css'
import { ReactComponent as SortIcon } from './../../../img/icons/sortIcon.svg'
import { ReactComponent as LeftArrow } from './../../../img/icons/leftArrow.svg'

const SortBtn: React.FC<{
  isSortOptionsOpen: boolean,
  setIsSortOptionsOpen: (isOpen: boolean) => void,
  sortBy: string | null
  setSortBy?: (sortBy: string | null) => void
  sortType: 'asc' | 'desc' | false
  setSortType?: (type: 'asc' | 'desc' | false) => void
  sortOptions: {name: string, value: string}[]
  applySort?: (sortType: 'asc' | 'desc' | false, sortBy: string | null) => void
}> = ({
  isSortOptionsOpen,
  setIsSortOptionsOpen,
  sortBy,
  setSortBy,
  sortType,
  setSortType,
  sortOptions,
  applySort
}) => {
  const onOptionClick = (sortBy: string) => {
    if (!!applySort) {
      if (sortType === 'desc') {
        applySort(false, null)
      } else {
        applySort(sortType === false ? 'asc' : 'desc', sortBy)
      }
    } else {
      if (sortType === 'desc') {
        setSortType!(false)
        setSortBy!(null)
      } else {
        setSortType!(sortType === false ? 'asc' : 'desc')
        setSortBy!(sortBy)
      }
    }
  }

  return (
    <div
      className={`${classes.sortBtn} ${isSortOptionsOpen ? classes.active : ''}`}
      onClick={(e) => {e.stopPropagation(); setIsSortOptionsOpen(!isSortOptionsOpen)}}>
      <SortIcon />
      Sort by
      {isSortOptionsOpen &&
        <div className={classes.sortOptions} onClick={e => e.stopPropagation()}>
          {sortOptions.map((option) => (
            <div
              className={`${classes.sortOption} ${sortBy === option.value ? classes.active : ''}`}
              onClick={() => onOptionClick(option.value)}
              key={option.value}
            >
              {option.name}
              {sortType === 'desc' && sortBy === option.value &&
                <LeftArrow style={{transform: 'rotate(270deg)', width: '8px'}}/>
              }
              {sortType === 'asc' && sortBy === option.value &&
                <LeftArrow style={{transform: 'rotate(90deg)', width: '8px'}}/>
              }
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default SortBtn
