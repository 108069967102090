import moment from 'moment'
import classes from './../EventCard.module.css'

const EventTime: React.FC<{start_date: string, end_date: string}> = ({start_date, end_date}) => {
  return (
    <div className={classes.dateDetails} style={{marginTop: '7px'}}>
      {moment(start_date).format('hh:mm a')} - {moment(end_date).format('hh:mm a')}
    </div>
  )
}

export default EventTime
