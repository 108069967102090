import { Helmet } from 'react-helmet-async'
import classes from './AboutPage.module.css'
import Header from '../common/Header/Header'
import GoToTopBtn from '../common/GoToTopBtn/GoToTopBtn'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GetAboutPageThunk, selectAboutPage } from '../../store/blogReducer'
import React, { useEffect } from 'react'
import { Spin } from 'antd'

const AboutPage = () => {
  const dispatch = useAppDispatch()
  const pageData = useAppSelector(selectAboutPage)

  useEffect(() => {
    if (pageData === null) {
      dispatch(GetAboutPageThunk())
    }
  }, [dispatch, pageData])

  if (pageData === null) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Relavanti | About</title>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
        <Header showMenu={true}/>
        <GoToTopBtn />
        <div className={classes.contentWrapper}>
          <div
            className={classes.title}
            dangerouslySetInnerHTML={{__html: pageData?.content}}
          >
          </div>
          {!!pageData?.image_list?.[0]?.image_url && 
            <img
              src={pageData?.image_list?.[0]?.image_url}
              alt={pageData?.image_list?.[0]?.comment}
              className={classes.cover}
            />
          }
          {pageData?.image_list?.[0]?.image_title &&
            <div className={classes.imageDetails}>
              {pageData?.image_list?.[0]?.image_title}
            </div>
          }
          {pageData?.child_sections?.map(section => (
            <React.Fragment key={section.id}>
              <div dangerouslySetInnerHTML={{__html: section?.content}} className={classes.sectionContent}>
              </div>
              {!!section?.image_list?.[0]?.image_url && 
                <img
                  src={section?.image_list?.[0]?.image_url}
                  alt={section?.image_list?.[0]?.comment}
                  className={classes.cover}
                />
              }
              {section?.image_list?.[0]?.image_title &&
                <div className={classes.imageDetails}>
                  {section?.image_list?.[0]?.image_title}
                </div>
              }
            </React.Fragment>
          ))}
        </div>
    </div>
  )
}

export default AboutPage
