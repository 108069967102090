import { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { navMenuItems, selectActiveTab, selectActivities, selectAirports, selectLocation, selectPorts, selectTransportStops, setActiveTab } from '../../../store/widgetReducer'
import classes from './Results.module.css'
import { GetAirportsByCityThunk } from '../../../store/airportReducer'
import {ReactComponent as GoBackIcon} from './../../../img/icons/goBack.svg'
import { ActivitiesResult, NodesResult, TransportStopResult } from './ResultItems/ResultItems'
import { GetPortsByCityThunk } from '../../../store/portReducer'
import Map from './Map/Map'
import { GetNearTransportStopsThunk } from '../../../store/transportStopsReducer'
import { GetViatorAttractionsFilteredThunk, selectAttractionFilters } from '../../../store/viatorReducer'

const Results = () => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectActiveTab)
  const widgetLocationData = useAppSelector(selectLocation)
  const airports = useAppSelector(selectAirports)
  const ports = useAppSelector(selectPorts)
  const transportStops = useAppSelector(selectTransportStops)
  const activities = useAppSelector(selectActivities)
  const activitiesFilters = useAppSelector(selectAttractionFilters)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (activeTab !== 'main') {
      const getResultsData = async() => {
        switch (activeTab) {
          case 'airports':
            return airports === null && dispatch(GetAirportsByCityThunk({widgetLocation: widgetLocationData!}))
          case 'ports':
            return ports === null && dispatch(GetPortsByCityThunk({widgetLocation: widgetLocationData!}))
          case 'transports':
            // @ts-ignore
            return transportStops === null && dispatch(GetNearTransportStopsThunk({latitude: widgetLocationData?.coordinates[0]!, longitude: widgetLocationData?.coordinates[1]!, radius: 5000, isWidget: true}))
          case 'activities':
            return activities === null && dispatch(GetViatorAttractionsFilteredThunk({
              coordinates: {
                latitude: widgetLocationData?.coordinates?.[0]!,
                longitude: widgetLocationData?.coordinates?.[1]!,
              },
              filters: activitiesFilters,
              attractionAdditionalFilters: {},
              isWidget: true
            }))
          default:
            return false
        }
      }
      setIsLoading(true)
      getResultsData()
        .then(() => setIsLoading(false))
    }
  }, [dispatch, activeTab, widgetLocationData, airports, ports, transportStops, activities, activitiesFilters])

  const tabData = navMenuItems.find(item => item.tab === activeTab)

  return (
    <div className={classes.wrapper}>
      <div className={classes.infoBlock}>
        <div className={classes.logo}>
          Logo
        </div>
        <div className={classes.resultsWrapper}>
          <div className={classes.goBack} onClick={() => dispatch(setActiveTab('main'))}>
            <GoBackIcon /> Back
          </div>
          <div className={classes.tabIconWrapper}>
            <img src={tabData?.icon} alt={tabData?.name}/>
            <div>
              {tabData?.name}
            </div>
          </div>

          {isLoading ? (
            <Spin style={{width: '100%', marginTop: '30px'}} />
          ) : (
            <>
              {activeTab === 'airports' && airports !== null &&
                <NodesResult
                  nodes={airports}
                  type='airport'
                />
              }

              {activeTab === 'ports' && ports !== null &&
                <NodesResult
                  nodes={ports}
                  type='port'
                />
              }

              {activeTab === 'transports' &&
                <TransportStopResult />
              }

              {activeTab === 'activities' &&
                <ActivitiesResult />
              }
            </>
          )}
        </div>
      </div>
      <div style={{position: 'relative'}}>
        <Map center={widgetLocationData?.coordinates!} />
      </div>
    </div>
  )
}

export default Results
