import { useState } from 'react'
import moment from 'moment'
import { Button, Calendar, Input, Spin, message } from 'antd'
import classes from './CalendarBlock.module.css'
import { ReactComponent as LeftArrowShort } from './../../../img/icons/leftArrowShort.svg'
import { ReactComponent as RightArrowShort } from './../../../img/icons/rightArrowShort.svg'
import { ReactComponent as SearchIcon } from './../../../img/icons/search.svg'
import { ReactComponent as ArrowIcon } from './../../../img/icons/rightArrow.svg'
import { ReactComponent as WelcomeBlockCalendar } from './../../../img/events/welcomeBlockCalendar.svg'
import welcomeBlockBgMap from './../../../img/events/welcomeBlockBgMap.svg'
import EventFormModal from '../EventFormModal/EventFormModal'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetEventByCodeThunk } from '../../../store/eventReducer'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { selectIsLoggedIn } from '../../../store/userReducer'
import ShowVideo from '../../common/ShowVideo/ShowVideo'

const CalendarBlock = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})
  const [selectedDate, setSelectedDate] = useState(moment())
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [eventCode, setEventCode] = useState('')
  const [isSearchingEvent, setIsSearchingEvent] = useState(false)

  const openCreateModal = () => {
    if (isLoggedIn) {
      setIsCreateModalOpen(true)
    } else {
      message.warning('Sign in to create Event', 2)
    }
  }

  const getEventByCode = () => {
    setIsSearchingEvent(true)
    dispatch(GetEventByCodeThunk(eventCode))
      .then((resp) => {
        setIsSearchingEvent(false)
        if (!resp.type.includes('rejected')) {
          navigate('/events/details/' + eventCode)
        }
      })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <h2>
          Events 
        </h2>
        <ShowVideo code='event'/>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.welcomeBlockWrapper}>
          <Input
            placeholder='Search event by code'
            prefix={<SearchIcon style={{marginRight: '10px'}}/>}
            className={classes.search}
            value={eventCode}
            onChange={(e) => setEventCode(e.target.value)}
            suffix={
              <div className={classes.searchCodeIconWrapper} onClick={getEventByCode}>
                {!!isSearchingEvent ? <Spin className={classes.searchSpin}/> : <ArrowIcon/>}
              </div>
            }
          />
          <div className={classes.welcomeBlock}>
            <img src={welcomeBlockBgMap} alt='' className={classes.bgDecoration}/>
            <div>
              <div className={classes.title}>
                Welcome
              </div>
              <div className={classes.description}>
                Create and share events with family,<br/>friends, co-workers or clients
              </div>
              {!isSmallScreen &&
                <Button type='primary' onClick={openCreateModal}>
                  + Create
                </Button>
              }
            </div>
            <WelcomeBlockCalendar className={classes.calendarImg} />
            {!!isSmallScreen &&
              <Button
                type='primary'
                style={{width: '100%'}}
                onClick={openCreateModal}
              >
                + Create
              </Button>
            }
          </div>
        </div>
        <Calendar
          fullscreen={false}
          dateFullCellRender={(date: any) => (
            <div style={{cursor: 'default'}}>
              {moment(date).format('DD')}
            </div>
          )}
          headerRender={() => {
            return (
              <div className={classes.calendarHeader}>
                {selectedDate.format('MMM')}
                <div>
                  <LeftArrowShort
                    style={{marginRight: '15px'}}
                    onClick={() => setSelectedDate(moment(selectedDate).subtract(1, 'month'))
                    }
                  />
                  <RightArrowShort
                    onClick={() => setSelectedDate(moment(selectedDate).add(1, 'month'))}
                  />
                </div>
              </div>
            )
          }}
          onPanelChange={val => setSelectedDate(val)}
          style={{backgroundColor: 'transparent', userSelect: 'none'}}
          onChange={val => setSelectedDate(val)}
          value={selectedDate}
          className={classes.calendar}
        />
      </div>
      {isCreateModalOpen &&
        <EventFormModal
          isOpen={isCreateModalOpen}
          onCancel={() => setIsCreateModalOpen(false)}
        />
      }
    </div>
  )
}

export default CalendarBlock
