import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { blogAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { CancelTokenSource } from 'axios'
import { AboutPageBlockType, BlogEntryType, BlogFetchParamsType } from '../types/blogTypes'
import { SignOutThunk } from './userReducer'

const blogFetchParamsDefaultValue = {
  pagination_request: {page: 1, size: 10, sort: [{field: 'created', direction: 'DESC' as 'DESC'}]},
  blog_entity_request: {
    search: ''
  }
}

interface InitialStateType {
  blogEntries: null | BlogEntryType[]
  blogEntriesTotalCount: number
  blogFetchParams: BlogFetchParamsType

  selectedBlogEntry: null | BlogEntryType
  aboutPage: null | AboutPageBlockType
}

const initialState: InitialStateType = {
  blogEntries: null,
  blogEntriesTotalCount: 0,
  blogFetchParams: blogFetchParamsDefaultValue,

  selectedBlogEntry: null,
  aboutPage: null,
}

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogEntries: (state, action: PayloadAction<null | BlogEntryType[]>) => {state.blogEntries = action.payload},
    setBlogEntriesTotalCount: (state, action: PayloadAction<number>) => {state.blogEntriesTotalCount = action.payload},
    setBlogFetchParams: (state, action: PayloadAction<BlogFetchParamsType>) => {state.blogFetchParams = action.payload},
    setSelectedBlogEntry: (state, action: PayloadAction<BlogEntryType | null>) => {state.selectedBlogEntry = action.payload},
    setAboutPage: (state, action: PayloadAction<null | AboutPageBlockType>) => {state.aboutPage = action.payload}
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetBlogEntriesThunk.fulfilled, (state, action) => {
        if (action.payload.page === 1) {
          state.blogEntries = action.payload.blog_entries
        } else {
          state.blogEntries = [...state.blogEntries || [], ...action.payload.blog_entries]
        }

        state.blogEntriesTotalCount = action.payload.total_count
      })
      .addCase(GetBlogEntryByIdThunk.fulfilled, (state, action) => {
        state.selectedBlogEntry = action.payload
      })
      .addCase(GetBlogEntryByTitleThunk.fulfilled, (state, action) => {
        state.selectedBlogEntry = action.payload
      })
      .addCase(GetAboutPageThunk.fulfilled, (state, action) => {
        state.aboutPage = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.blogEntries = null
        state.blogEntriesTotalCount = 0
        state.blogFetchParams = blogFetchParamsDefaultValue
        state.selectedBlogEntry = null
      })
  }
})

export const {
  setBlogEntries,
  setBlogEntriesTotalCount,
  setBlogFetchParams,
  setSelectedBlogEntry,
  setAboutPage,
} = blogSlice.actions

export const selectBlogEntries = (state: RootState): null | BlogEntryType[] => state.blog.blogEntries
export const selectBlogEntriesTotalCount = (state: RootState): number => state.blog.blogEntriesTotalCount
export const selectBlogFetchParams = (state: RootState): BlogFetchParamsType => state.blog.blogFetchParams
export const selectSelectedBlogEntry = (state: RootState): BlogEntryType | null => state.blog.selectedBlogEntry
export const selectAboutPage = (state: RootState): AboutPageBlockType | null => state.blog.aboutPage

export const GetBlogEntriesThunk = createAsyncThunk<{blog_entries: BlogEntryType[], total_count: number, page: number}, {requestParams: BlogFetchParamsType, source?: CancelTokenSource}, AsyncThunkConfig>(
  'blog/getBlogEntries',
  async ({requestParams, source}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('pagination_request', new Blob([JSON.stringify(requestParams.pagination_request, null, 2)], {type: 'application/json'}))
      formData.append('blog_entity_request', new Blob([JSON.stringify(requestParams.blog_entity_request, null, 2)], {type: 'application/json'}))
      const {data, status} = await blogAPI.getBlogEntries(formData, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({...data, page: requestParams.pagination_request.page}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetBlogEntryByIdThunk = createAsyncThunk<BlogEntryType, number, AsyncThunkConfig>(
  'blog/getBlogEntryById',
  async (id, thunkAPI) => {
    try {
      const {data, status} = await blogAPI.getBlogEntryById(id)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetBlogEntryByTitleThunk = createAsyncThunk<BlogEntryType, string, AsyncThunkConfig>(
  'blog/getBlogEntryByTitle',
  async (title, thunkAPI) => {
    try {
      const {data, status} = await blogAPI.getBlogEntryByTitle(title)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data?.blog_entries?.[0], {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAboutPageThunk = createAsyncThunk<AboutPageBlockType, void, AsyncThunkConfig>(
  'blog/getAboutPage',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await blogAPI.getAboutPage()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.page_sections?.[0], {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default blogSlice.reducer
 