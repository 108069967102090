import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { viatorApi } from '../app/api'
import { ViatorAttractionAdditionalFilterTypes, ViatorAttractionFilteredTypes, ViatorAttractionFilterTypes, ViatorAttractionLocationType, ViatorAttractionsType } from '../types/viatorTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { GetUserIPThunk } from './userReducer'
import { AdvertisingType } from '../types/advertisingTypes'
import { getRequestHeaders } from '../helpers/axiosHelper'
import { NodeServiceDetails } from './searchResultsReducer'

interface InitialStateType {
  attractions: ViatorAttractionFilteredTypes[] | null
  attractionFilters: ViatorAttractionFilterTypes
  attractionAdditionalFilters: ViatorAttractionAdditionalFilterTypes
  attractionLocationCoordinates: NodeServiceDetails | null
  selectedAttractionLocation: ViatorAttractionLocationType | null
}

const initialState: InitialStateType = {
  attractions: null,
  attractionFilters: {
    lowestPrice: 0,
    highestPrice: 500,
    rating: {from: 1, to: 5},
    durationInMinutes: {from: 1, to: 6000}
  },
  attractionAdditionalFilters: {},
  attractionLocationCoordinates: null,
  selectedAttractionLocation: null
}

export const viatorSlice = createSlice({
  name: 'viator',
  initialState,
  reducers: {
    setAttractions: (state, action: PayloadAction<ViatorAttractionFilteredTypes[] | null>) => {state.attractions = action.payload},
    setAttractionFilters: (state, action: PayloadAction<ViatorAttractionFilterTypes>) => {state.attractionFilters = action.payload},
    setAttractionAdditionalFilters: (state, action: PayloadAction<ViatorAttractionAdditionalFilterTypes>) => {state.attractionAdditionalFilters = action.payload},
    setAttractionLocationCoordinates: (state, action: PayloadAction<NodeServiceDetails | null>) => {state.attractionLocationCoordinates = action.payload},
    setSelectedAttractionLocation: (state, action: PayloadAction<ViatorAttractionLocationType | null>) => {state.selectedAttractionLocation = action.payload},
  },
  extraReducers: (builder) => {
    builder
      // .addCase(GetViatorAttractionsThunk.fulfilled, (state, action) => {
      //   state.attractions = action.payload
      // })
      .addCase(GetViatorAttractionsFilteredThunk.fulfilled, (state, action) => {
        state.attractions = action.payload.products
      })
      .addCase(GetViatorAttractionLocationThunk.fulfilled, (state, action) => {
        state.selectedAttractionLocation = action.payload
      })
  }
})

export const {
  setAttractions,
  setAttractionFilters,
  setAttractionAdditionalFilters,
  setAttractionLocationCoordinates,
  setSelectedAttractionLocation
} = viatorSlice.actions

export const selectAttractions = (state: RootState): ViatorAttractionFilteredTypes[] | null => state.viator.attractions
export const selectAttractionFilters = (state: RootState): ViatorAttractionFilterTypes => state.viator.attractionFilters
export const selectAttractionAdditionalFilters = (state: RootState): ViatorAttractionAdditionalFilterTypes => state.viator.attractionAdditionalFilters
export const selectAttractionLocationCoordinates = (state: RootState): NodeServiceDetails | null => state.viator.attractionLocationCoordinates
export const selectSelectedAttractionLocation = (state: RootState): ViatorAttractionLocationType | null => state.viator.selectedAttractionLocation

export const GetViatorAttractionsThunk = createAsyncThunk<ViatorAttractionsType[], {coordinates: {latitude: number, longitude: number}, range: string}, AsyncThunkConfig>(
  'viator/getViatorAttractions',
  async (requestData, thunkAPI) => {
    const request = {
      nearest_request: {
        radius: 20,
        latitude: requestData.coordinates.latitude,
        longitude: requestData.coordinates.longitude,
        unit: 'KM'
      },
      top_x: requestData.range || '1-20',
      sort_order: 'RECOMMENDED' as 'RECOMMENDED'
    }
    try {
      const {data, status} = await viatorApi.getViatorAttractions(request)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetViatorAttractionsFilteredThunk = createAsyncThunk<{products: ViatorAttractionFilteredTypes[], advertising_list: AdvertisingType[]}, {coordinates: {latitude: number, longitude: number}, filters: ViatorAttractionFilterTypes, attractionAdditionalFilters: ViatorAttractionAdditionalFilterTypes, isWidget?: boolean}, AsyncThunkConfig>(
  'viator/getViatorAttractionsFiltered',
  async (requestData, thunkAPI) => {
    const request = {
      ...requestData.attractionAdditionalFilters,
      nearest_request: {
        radius: 20,
        latitude: requestData.coordinates.latitude,
        longitude: requestData.coordinates.longitude,
        unit: 'KM'
      },
      filtering: {
        tags: [],
        flags: [],
        confirmationType: 'INSTANT',
        rating: requestData.filters.rating,
        durationInMinutes: requestData.filters.durationInMinutes,
        includeAutomaticTranslations: true,
        lowestPrice: requestData.filters.lowestPrice,
        highestPrice: requestData.filters.highestPrice === 500 ? undefined : requestData.filters.highestPrice,
        // startDate: '',
        // endDate: '',
      },
      pagination: {
        start: 1,
        count: 50
      },
      currency: 'USD'
    }
    try {
      const userLocationHeader = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const requestHeaders = {
        ...userLocationHeader,
        ...(requestData?.isWidget ? {Authorization: false} : {})
      }
      const {data, status} = await viatorApi.getViatorAttractionsFiltered(request, requestHeaders)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetViatorAttractionLocationThunk = createAsyncThunk<ViatorAttractionLocationType, {code: string, latitude:number, longitude:number}, AsyncThunkConfig>(
  'viator/getViatorAttractionLocation',
  async ({code, latitude, longitude}, thunkAPI) => {
    try {
      const {data, status} = await viatorApi.getViatorAttractionLocation(code, latitude, longitude)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default viatorSlice.reducer
 