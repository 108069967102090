import classes from './SelectedLocation.module.css'
import { ReactComponent as SearchLocationIcon } from './../../../../../../img/icons/searchLocationIcon.svg'

const SelectedLocation: React.FC<{location: {name: string, url: string}, handleLocationDeselect?: (location: {name: string, url: string}) => void}> = ({location, handleLocationDeselect}) => {
  return (
    <div className={classes.selectedLocationItem}>
      <div style={{alignItems: 'center', display: 'flex'}}>
        <SearchLocationIcon style={{width: '26px', marginRight: '6px'}}/>
        {location.name}
      </div>
      {!!handleLocationDeselect &&
        <div
          className={classes.removeLocation}
          onClick={() => handleLocationDeselect(location)}
        >
          Remove
        </div>
      }
    </div>
  )
}

export default SelectedLocation
