import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SharedFlightCruiseDetailsType } from '../types/appTypes'
import { CruiseType, CurrentActiveTabDataType } from '../types/cruiseTypes'
import { FlightDetailsType } from '../types/flightTypes'
import { AppStatusType } from './appStatusReducer'
import { GetCruiseByLineAndDateThunk, GetCruisesByAdvancedSearchThunk } from './cruiseReducer'
import { GetFlightByNumberAndDateThunk } from './flightReducer'
import { NodeServiceDetails, setActiveResultBlock, setActiveResultCategories, setActiveServices, setActiveTab, setNodeServiceDetails, setSelectedTransportStop, setSelectedTransportStops } from './searchResultsReducer'
import { setNodeServices } from './serviceReducer'
import { AppThunk, fulfilledMetaType, RootState } from './../types/appTypes'
import { geocodeByAddress } from 'react-places-autocomplete'
import { TerminalType } from '../types/searchResultTypes'
import { setIsTransportStopsListOpen } from './transportStopsReducer'

export type SelectedCategoryTabKeyTypes = 'general' | 'stops' | 'additionalLocations'

interface InitialStateType {
  activeResultsTabKey: string
  isStopTabSelected: boolean
  isCityResultsOpen: boolean
  isAdditionalLocationOpen: boolean
  selectedCategoryTabKey: SelectedCategoryTabKeyTypes
  selectedResultItemId: string
  startEndPortSelected: 'departure' | 'arrival' | null
  selectedStopTerminals: TerminalType[] | null

  currentActiveTabData: CurrentActiveTabDataType | null
}

const initialState: InitialStateType = {
  activeResultsTabKey: '1',
  isStopTabSelected: false,
  isCityResultsOpen: false,
  isAdditionalLocationOpen: false,
  selectedCategoryTabKey: 'general',
  selectedResultItemId: '',
  startEndPortSelected: null,
  selectedStopTerminals: null,
  
  currentActiveTabData: null
}

export const travelSearchSlice = createSlice({
  name: 'travelSearch',
  initialState,
  reducers: {
    setActiveResultsTabKey: (state, action: PayloadAction<string>) => {state.activeResultsTabKey = action.payload},
    setIsStopTabSelected: (state, action: PayloadAction<boolean>) => {state.isStopTabSelected = action.payload},
    setIsCityResultsOpen: (state, action: PayloadAction<boolean>) => {state.isCityResultsOpen = action.payload},
    setIsAdditionalLocationOpen: (state, action: PayloadAction<boolean>) => {state.isAdditionalLocationOpen = action.payload},
    setSelectedCategoryTabKey: (state, action: PayloadAction<SelectedCategoryTabKeyTypes>) => {state.selectedCategoryTabKey = action.payload},
    setSelectedResultItemId: (state, action: PayloadAction<string>) => {state.selectedResultItemId = action.payload},
    setStartEndPortSelected: (state, action: PayloadAction<'departure' | 'arrival' | null>) => {state.startEndPortSelected = action.payload},
    setSelectedStopTerminals: (state, action: PayloadAction<TerminalType[] | null>) => {state.selectedStopTerminals = action.payload},
    setCurrentActiveTabData: (state, action: PayloadAction<CurrentActiveTabDataType | null>) => {state.currentActiveTabData = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetFlightByNumberAndDateThunk.fulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType 
        const data = meta.appStatus === AppStatusType.info ? {flights: []} : action.payload as {flights: FlightDetailsType[], sharedDetails?: SharedFlightCruiseDetailsType}   
        const sharedDetails = (action.payload as {flights: FlightDetailsType[], sharedDetails?: SharedFlightCruiseDetailsType}).sharedDetails 
        if (meta.appStatus !== AppStatusType.info && Object.keys(sharedDetails || {}).length) {
          state.activeResultsTabKey = sharedDetails?.activeTabKey!
          state.isCityResultsOpen = sharedDetails?.isCityResultsOpen!
          state.isStopTabSelected = sharedDetails?.isStopTabSelected!
          state.selectedResultItemId = sharedDetails?.selectedResultItemId!
          state.selectedCategoryTabKey = sharedDetails?.selectedCategoryTabKey! as SelectedCategoryTabKeyTypes
        }
        if (data?.flights?.length === 1) {
          state.selectedResultItemId = data?.flights[0]?.id
        }
      })
      .addCase(GetCruiseByLineAndDateThunk.fulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType    
        const data = meta.appStatus === AppStatusType.info ? {cruises: []} : action.payload as {cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType} 
        const sharedDetails = (action.payload as {cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType}).sharedDetails 
        if (meta.appStatus !== AppStatusType.info && Object.keys(sharedDetails || {}).length) {
          state.activeResultsTabKey = sharedDetails?.activeTabKey!
          state.isCityResultsOpen = sharedDetails?.isCityResultsOpen!
          state.isStopTabSelected = sharedDetails?.isStopTabSelected!
          state.selectedResultItemId = sharedDetails?.selectedResultItemId!
        }
        if (data?.cruises?.length === 1) {
          state.selectedResultItemId = String(data?.cruises[0]?.id)
        }
      })
      .addCase(GetCruisesByAdvancedSearchThunk.fulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType    
        const data = meta.appStatus === AppStatusType.info ? {cruises: []} : action.payload as {cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType} 
        const sharedDetails = (action.payload as {cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType}).sharedDetails 
        if (meta.appStatus !== AppStatusType.info && Object.keys(sharedDetails || {}).length) {
          state.activeResultsTabKey = sharedDetails?.activeTabKey!
          state.isCityResultsOpen = sharedDetails?.isCityResultsOpen!
          state.isStopTabSelected = sharedDetails?.isStopTabSelected!
          state.selectedResultItemId = sharedDetails?.selectedResultItemId!
        }
        if (data?.cruises?.length === 1) {
          state.selectedResultItemId = String(data?.cruises[0]?.id)
        }
      })
  }
})

export const { 
  setActiveResultsTabKey,
  setIsStopTabSelected,
  setIsCityResultsOpen,
  setIsAdditionalLocationOpen,
  setSelectedCategoryTabKey,
  setSelectedResultItemId,
  setStartEndPortSelected,
  setSelectedStopTerminals,
  setCurrentActiveTabData,
} = travelSearchSlice.actions

export const selectActiveResultsTabKey = (state: RootState): string => state.travelSearch.activeResultsTabKey
export const selectIsStopTabSelected = (state: RootState): boolean => state.travelSearch.isStopTabSelected
export const selectIsCityResultsOpen = (state: RootState): boolean => state.travelSearch.isCityResultsOpen
export const selectIsAdditionalLocationOpen = (state: RootState): boolean => state.travelSearch.isAdditionalLocationOpen
export const selectSelectedCategoryTabKey = (state: RootState): SelectedCategoryTabKeyTypes => state.travelSearch.selectedCategoryTabKey
export const selectSelectedResultItemId= (state: RootState): string => state.travelSearch.selectedResultItemId
export const selectStartEndPortSelected = (state: RootState): 'departure' | 'arrival' | null => state.travelSearch.startEndPortSelected
export const selectSelectedStopTerminals = (state: RootState): TerminalType[] | null => state.travelSearch.selectedStopTerminals
export const selectCurrentActiveTabData = (state: RootState): CurrentActiveTabDataType | null => state.travelSearch.currentActiveTabData

export const SelectTabThunk = (key: string, getNodeData: (key: string) => NodeServiceDetails, searchData: {date: string, from?: string, to?: string}, nodeDetailsSettings?: {nodeId?: number, newActiveResultCategories?: string[], isFromToLocation: boolean}): AppThunk => async(dispatch, getState) => {
  const activeTabKey = getState().travelSearch.activeResultsTabKey
  const tabs = getState().searchResults.tabs
  const currentActiveTabData = getState().travelSearch.currentActiveTabData
  const activeResultCategories = getState().searchResults.activeResultCategories

  if (key !== activeTabKey) {
    dispatch(setActiveResultsTabKey(key))
    const tabData = tabs.find(tab => tab.name === key)
    if (Object.keys(tabData || {})?.length) {
      const isFromToLocation = nodeDetailsSettings?.isFromToLocation
      dispatch(setActiveTab(tabData!))
      dispatch(setNodeServiceDetails(null))
      dispatch(setSelectedTransportStop(null))
      dispatch(setSelectedTransportStops([]))
      dispatch(setIsCityResultsOpen(true))
      dispatch(activeResultCategories[0] !== 'near' && !nodeDetailsSettings?.newActiveResultCategories?.includes('near') ? setActiveResultBlock('Nodes') : setActiveResultBlock('Services'))
      dispatch(setIsStopTabSelected(!isFromToLocation))
      if (!isFromToLocation) {
        const nodeData = getNodeData(String(nodeDetailsSettings?.nodeId)!)
        dispatch(setIsAdditionalLocationOpen(false))
        if (currentActiveTabData?.id !== nodeData.id) {
          const addressData = await geocodeByAddress(nodeData?.name)
          const placeId = addressData[0]?.place_id
          dispatch(setCurrentActiveTabData({...nodeData!, placeId}))
        }
      } else {
        dispatch(setIsAdditionalLocationOpen(true))
        dispatch(setCurrentActiveTabData(null))
      }
    } else if (key !== '1') {
      dispatch(setActiveServices([]))
      dispatch(setIsTransportStopsListOpen(false))
      dispatch(setNodeServices([]))
      dispatch(setIsCityResultsOpen(false))
      dispatch(setIsStopTabSelected(true))
      const nodeData = getNodeData(key)
      if (currentActiveTabData?.id !== nodeData.id) {
        const addressData = await geocodeByAddress(nodeData?.name)
        const placeId = addressData[0]?.place_id
        dispatch(setCurrentActiveTabData({...nodeData!, placeId}))
      }
    } else {
      dispatch(setIsAdditionalLocationOpen(false))
      dispatch(setIsCityResultsOpen(false))
      dispatch(setIsStopTabSelected(false))
    }
    dispatch(setActiveResultCategories(nodeDetailsSettings?.newActiveResultCategories || ['near']))
  }
}

export default travelSearchSlice.reducer
 