import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flightApi } from '../app/api'
import { AirportDataType } from '../types/airportTypes'
import { SharedFlightCruiseDetailsType } from '../types/appTypes'
import { AdvancedFlightSearchAirportsRequestDataType, FlightAvailableDatesTypes, FlightDetailsType, FlightSearchTypeType, KayakRequestDataType } from '../types/flightTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, fulfilledMetaType, RootState } from './../types/appTypes'

type ActiveInfoBlockTypes = 'flights' | 'flightDetails'

const advancedSearchRequestDefaultValue = {
  type: 'oneway' as 'oneway',
  from: '',
  to: '',
  startDate: '',
  endDate: ''
}

interface InitialStateType {
  flights: FlightDetailsType[] | null
  flight: FlightDetailsType | null
  flightAvailableDates: FlightAvailableDatesTypes[] | null
  activeInfoBlock: ActiveInfoBlockTypes
  selectedStops: AirportDataType[]

  flightSearchType: FlightSearchTypeType
  advancedSearchRequest: AdvancedFlightSearchAirportsRequestDataType,
  kayakLink: string | null
}

const initialState: InitialStateType = {
  flights: null,
  flight: {} as FlightDetailsType,
  flightAvailableDates: null,
  activeInfoBlock: 'flights',
  selectedStops: [],

  flightSearchType: 'default',
  advancedSearchRequest: advancedSearchRequestDefaultValue,
  kayakLink: null
}

export const flightSlice = createSlice({
  name: 'flight',
  initialState,
  reducers: {
    setFlights: (state, action: PayloadAction<FlightDetailsType[] | null>) => {state.flights = action.payload},
    setFlight: (state, action: PayloadAction<FlightDetailsType | null>) => {
      state.flight = action.payload
      if (action.payload === null) {
        state.activeInfoBlock = 'flights'
      } else {
        state.activeInfoBlock = 'flightDetails'
      }
    },
    setFlightAvailableDates: (state, action: PayloadAction<FlightAvailableDatesTypes[] | null>) => {state.flightAvailableDates = action.payload},
    setActiveInfoBlock: (state, action: PayloadAction<ActiveInfoBlockTypes>) => {state.activeInfoBlock = action.payload},
    setSelectedStops: (state, action: PayloadAction<number>) => {
      if (state.selectedStops.some(stop => stop?.id === action.payload)) {
        state.selectedStops = state.selectedStops.filter(s => s.id !== action.payload)
      } else {
        const stopData = state?.flight?.airport_stops?.find(r => r.id === action.payload)
        state.selectedStops = [...state.selectedStops, stopData!]
      }
    },
    clearSelectedStops: (state) => {state.selectedStops = []},
    setFlightSearchType: (state, action: PayloadAction<FlightSearchTypeType>) => {state.flightSearchType = action.payload},
    setAdvancedSearchRequest: (state, action: PayloadAction<AdvancedFlightSearchAirportsRequestDataType>) => {state.advancedSearchRequest = action.payload},
    clearAdvancedSearchRequest: (state) => {state.advancedSearchRequest = advancedSearchRequestDefaultValue},
    setKayakLink: (state, action: PayloadAction<null | string>) => {state.kayakLink = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetFlightByNumberAndDateThunk.fulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType      
        if (meta.appStatus === AppStatusType.info) {
          state.flights = []
          return 
        } else {
          const data = action.payload as {flights: FlightDetailsType[], sharedDetails?: SharedFlightCruiseDetailsType}
          state.flights = data?.flights as FlightDetailsType[]
          state.activeInfoBlock = 'flights'
          // if (data?.flights?.length > 1 && !data.sharedDetails?.selectedResultItemId) {
          //   state.activeInfoBlock = 'flights'
          // } else {
          //   if (!!data.sharedDetails?.selectedResultItemId) {
          //     state.flight = data?.flights.find(f => f.id === data.sharedDetails?.selectedResultItemId) as FlightDetailsType
          //   } else {
          //     state.flight = data?.flights[0] as FlightDetailsType
          //   }
          //   state.activeInfoBlock = 'flightDetails'
          // }
        }
      })
      .addCase(GetFlightByNumberAndDateThunk.rejected, (state) => {
        state.flights = []
      })
      .addCase(GetFlightAvailableDatesThunk.fulfilled, (state, action) => {state.flightAvailableDates = action.payload})
      .addCase(GetKayakLinkThunk.fulfilled, (state, action) => {
        state.kayakLink = action.payload
      })
    }
})

export const {
  setFlights,
  setFlight,
  setActiveInfoBlock,
  setFlightAvailableDates,
  setSelectedStops,
  clearSelectedStops,
  setFlightSearchType,
  setAdvancedSearchRequest,
  clearAdvancedSearchRequest,
  setKayakLink,
 } = flightSlice.actions

export const selectFlights = (state: RootState): FlightDetailsType[] | null => state.flight.flights
export const selectFlight = (state: RootState): FlightDetailsType | null => state.flight.flight
export const selectFlightAvailableDates = (state: RootState): FlightAvailableDatesTypes[] | null => state.flight.flightAvailableDates
export const selectActiveInfoBlock = (state: RootState): ActiveInfoBlockTypes => state.flight.activeInfoBlock
export const selectSelectedStops = (state: RootState): AirportDataType[] => state.flight.selectedStops
export const selectFlightSearchType = (state: RootState): FlightSearchTypeType => state.flight.flightSearchType
export const selectAdvancedSearchRequest = (state: RootState): AdvancedFlightSearchAirportsRequestDataType => state.flight.advancedSearchRequest
export const selectKayakLink = (state: RootState): null | string => state.flight.kayakLink

export const GetFlightByNumberAndDateThunk = createAsyncThunk<{flights: FlightDetailsType[], sharedDetails?: SharedFlightCruiseDetailsType} | string, {flightInfo: {number: string, date: string, airlineCode?: string}, sharedDetails?: SharedFlightCruiseDetailsType}, AsyncThunkConfig>(
  'flight/getFlightByNumberAndDate',
  async ({flightInfo, sharedDetails}, thunkAPI) => {
    try {
      const {data, status} = await flightApi.getFlightByNumberAndDate(flightInfo.number, flightInfo.date, flightInfo?.airlineCode)
      if (status === 200 && data) {
        if (data.flights.length > 0) {
          return thunkAPI.fulfillWithValue({flights: data.flights, sharedDetails}, {appStatus: AppStatusType.idle})
        } else {
          return thunkAPI.fulfillWithValue('No flights found', {appStatus: AppStatusType.info})
        }
      } 
      else {
        return thunkAPI.rejectWithValue({data})
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetFlightAvailableDatesThunk = createAsyncThunk<FlightAvailableDatesTypes[], {flightNo: string, source: any, airlineCode?: string}, AsyncThunkConfig>(
  'flight/getFlightAvailableDates',
    // @ts-ignore
  async ({flightNo, source, airlineCode}, thunkAPI) => {
    try {
      const {data, status} = await flightApi.getAvailableDatesForFlight(flightNo, source, airlineCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.available_days, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue('canceled')
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return thunkAPI.fulfillWithValue({cruises: []}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue('canceled')
      }
    }
  }
)

export const GetKayakLinkThunk = createAsyncThunk<string, KayakRequestDataType, AsyncThunkConfig>(
  'flight/getKayakLink',
  async (requestData, thunkAPI) => {
    try {
      const {data, status} = await flightApi.getKayakLink(requestData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.message, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default flightSlice.reducer
 