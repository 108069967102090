import { Button, Divider, Input, message, Modal, Select } from 'antd'
import classes from './SaveSearch.module.css'
import {ReactComponent as SaveIcon} from '../../../../../img/icons/save.svg'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { CreateUserSearchListThunk, GetUserSearchListsThunk, SaveUserSearchThunk, selectUserSearchLists } from '../../../../../store/userSearchReducer'
import { Dispatch, useEffect, useState } from 'react'
import { selectIsLoggedIn, selectUserData } from '../../../../../store/userReducer'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { decodeSearchResultsExportLink, getShareLink } from '../../../../../helpers/linksHelper'
import { sortBy } from 'lodash'
import saveImg from '../../../../../img/saveImg.webp'
import SelectLocations from '../common/SelectLocations/SelectLocations'
import SelectedLocation from '../common/SelectedLocation/SelectedLocation'
import UserSearchListModal from '../../../../Settings/UserSearchList/UserSearchListModal/UserSearchListModal'

const Save:React.FC<{children?: any}>= ({children}) => {
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  
  const onSaveBtnClick = () => {
    isLoggedIn ? setIsSaveModalOpen(true) : navigate('/sign-in')
  }
  return (
    <>
      {children ? (
        <div onClick={onSaveBtnClick}>
          {children}
        </div>
      ) : (
        <SaveBtn onClick={onSaveBtnClick}/>
      )}
      {isSaveModalOpen &&
        <SaveModal onCancel={() => setIsSaveModalOpen(false)}/>
      }
    </>
  )
}

export const SaveBtn:React.FC<{onClick: () => void}> = ({onClick}) => {
  return (
    <div className={classes.saveIcon} onClick={onClick}>
      <SaveIcon /> Save
    </div>
  )
}

const SaveModal: React.FC<{onCancel: () => void}> = ({onCancel}) => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const userSearchLists = useAppSelector(selectUserSearchLists)
  const search = window.location.search
  const isGeneralSearch = !window?.location?.pathname?.includes('/travel/by-cruise')
    && !window?.location?.pathname?.includes('/travel/by-flight')
    && !window?.location?.pathname?.includes('/travel/by-cruise-advanced')
    && !window?.location?.pathname?.includes('/travel/by-flight-advanced')

  const options = decodeSearchResultsExportLink(search.split('-shared')[0]).locations
    .map(searchItem => ({
      name: decodeURIComponent(searchItem.split('-from')[0]),
      url: searchItem
    }))

  const [selectedLocations, setSelectedLocations] = useState<LocationOptionType[]>([])
  const [availableLocations, setAvailableLocations] = useState<LocationOptionType[]>(options)
  const [searchData, setSearchData] = useState<{name: string, listId: undefined | number}>({name: '', listId: undefined})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (options.length === 1 && isGeneralSearch) {
      setAvailableLocations([])
      setSelectedLocations(options)
    }
  }, [options, isGeneralSearch])

  useEffect(() => {
    if (userSearchLists === null) {
      dispatch(GetUserSearchListsThunk(userData.user_id))
    }
  }, [dispatch, userData, userSearchLists])

  const handleLocationSelect = (location: string) => {
    const selectedLocation = options.find(option => option.name === location)!
    const updatedSelectedLocations = sortBy(
      [...selectedLocations, selectedLocation],
      location => options.findIndex(option => option.name === location.name)
    )
    setSelectedLocations(updatedSelectedLocations)
    setAvailableLocations(availableLocations.filter(l => l.name !== location))
  }

  const handleLocationDeselect = (location: LocationOptionType) => {
    setAvailableLocations([...availableLocations, location])
    setSelectedLocations(selectedLocations.filter(l => l.name !== location.name))
  }

  const saveSearch = () => {
    setIsLoading(true)
    const newSearchData = {
      search_name: searchData.name!,
      search_url: getShareLink(selectedLocations),
      latitude: 0,
      longitude: 0,
      search_time: moment().format('YYYY-MM-DD HH:mm:ss'),
      user_id: userData.user_id,
      user_search_list_id: searchData.listId!
    }
    dispatch(SaveUserSearchThunk(newSearchData))
      .then((resp) => {
        setIsLoading(false)
        if (!resp.type.includes('rejected')) {
          message.success('Search has been saved successfully')
          onCancel()
        }
      })
  }

  return (
    <Modal
      visible
      onCancel={onCancel}
      footer={null}
      width='340px'
    >
      <div className={classes.modalContent}>
        <img src={saveImg} alt='share' style={{width: '120px'}}/>
        <h3>
          Save your search
        </h3>

        {!!availableLocations.length && !!isGeneralSearch &&
          <>
            <div className={classes.description}>
              {'Select from the list of locations\nyou want to save'}
            </div>
            <SelectLocations
              handleLocationSelect={handleLocationSelect}
              availableLocations={availableLocations}
              showLabel={false}
            />
          </>
        }

        <div>
          {selectedLocations.map(location => (
            <SelectedLocation
              location={location}
              handleLocationDeselect={options.length === 1 ? undefined : handleLocationDeselect}
              key={location.name}
            />
          ))}
        </div>

        <div className={classes.description}>
          Search details
        </div>
        <Input
          placeholder='Search name'
          style={{marginBottom: '20px'}}
          value={searchData.name}
          onChange={e => setSearchData({...searchData, name: e.target.value})}
        />

        <SelectUserSearchList
          setSearchData={setSearchData}
          searchData={searchData}
        />
        
        <Button
          type='primary'
          onClick={saveSearch}
          style={{marginTop: '20px'}}
          disabled={
            !searchData.name?.length
            || !searchData.listId
            || (options.length > 1 && !selectedLocations?.length && !!isGeneralSearch)
          }
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

const SelectUserSearchList: React.FC<SelectUserSearchListPropTypes> = ({setSearchData, searchData}) => {
  const dispatch = useAppDispatch()
  const userSearchLists = useAppSelector(selectUserSearchLists)
  const userData = useAppSelector(selectUserData)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const getModalSubmitAction = async(name?:string) => {
    return dispatch(CreateUserSearchListThunk({
      list_name: name!,
      date_created: moment().format('YYYY-MM-DD HH:mm:ss'),
      user_id: userData.user_id
    }))
  }

  return (
    <>
      <Select
        onSelect={(val: number) => setSearchData({...searchData, listId: val})}
        style={{width: '100%'}}
        options={userSearchLists?.map(list => ({value: list.user_search_list_id, label: list.list_name}))}
        optionFilterProp='label'
        placeholder='Select search list'
        value={searchData.listId}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Button type='text' style={{width: '100%'}} onClick={() => setIsModalOpen(true)}>
              + Create new list
            </Button>
          </>
        )}
      />
      <UserSearchListModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formType='create'
        getSubmitAction={getModalSubmitAction}
        modalName='Search list'
      />
    </>
  )
}

type LocationOptionType = {
  name: string //example: "New York, NY, USA"
  url: string //example: "New%20York,%20NY,%20USA-from-20230301-to-20230302-adults-1-children-0"
}

interface SelectUserSearchListPropTypes {
  setSearchData: Dispatch<React.SetStateAction<{name: string, listId: undefined | number}>>
  searchData: {name: string, listId: undefined | number}
}

export default Save
