import { Link } from 'react-router-dom'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import routes from '../../../app/routes'
import classes from './Breadcrumbs.module.css'
import Share from './searchTools/Share/Share'
import SaveSearch from './searchTools/SaveSearch/SaveSearch'
import { ReactComponent as LeftArrowShort } from './../../../img/icons/leftArrowShort.svg'

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({className, showShare, showSave}) => {
  // @ts-ignore
  const breadcrumbs = useReactRouterBreadcrumbs(routes)

  const noBgColorPathnames = [
    '/travel/by-cruise',
    '/travel/by-flight',
    '/travel/by-cruise-advanced',
    '/travel/by-flight-advanced',
    '/travel/by-flight-advanced/summary'
  ]

  return (
    <div className={`${classes.wrapper} ${className}`} id='breadcrumbs'>
      <div
        className={`
          ${classes.linksChain}
          ${noBgColorPathnames.some(path => breadcrumbs[breadcrumbs.length - 1].key === path) ? '' : classes.highlightBg}
        `}
      >
        {breadcrumbs.map(({
          match,
          breadcrumb
        }, index) => (
          <span key={match?.pathname}>
            {index + 1 < breadcrumbs.length ? (
              <Link
                to={match?.pathname}
                className={classes.crumb}
                style={noBgColorPathnames.some(path => breadcrumbs[breadcrumbs.length - 1].key === path)
                  ? {color: '#D0D6E4'}
                  : {}
                }
              >
                {breadcrumb} <span className={classes.separator}>/</span>
              </Link>
            ) : (
              <span
                className={classes.currentCrumb}
                style={noBgColorPathnames.some(path => breadcrumbs[breadcrumbs.length - 1].key === path)
                  ? {color: '#F9F8FE'}
                  : {}
                }
              >
                {breadcrumb}
              </span>
            )}
          </span>
        ))}
      </div>
      <div
        className={`
          ${classes.goToPrev}
          ${noBgColorPathnames.some(path => breadcrumbs[breadcrumbs.length - 1].key === path) ? '' : classes.highlightBg}
        `}
      >
        <Link
          to={breadcrumbs?.[breadcrumbs.length - 2]?.match?.pathname}
        >
          <LeftArrowShort /> {breadcrumbs?.[breadcrumbs.length - 2]?.breadcrumb}
        </Link>
      </div>
      <div className={classes.tools}>
        {showShare && <Share />}
        {showSave && <SaveSearch />}
      </div>  
    </div>
  )
}

interface BreadcrumbsProps {
  className?: string
  showShare?: boolean
  showSave?: boolean
}

export default Breadcrumbs
