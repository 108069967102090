import { useEffect, useState } from 'react'
import { pickBy, sortBy } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import classes from './Results.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { resultCategories, selectActiveResultBlock, selectActiveResultCategories, selectActiveTab, selectNodeServiceDetails, selectSearchResults, selectSelectedTerminal, selectSelectedTransportStops, selectTabs, setActiveResultBlock, setIsTerminalListOpen, setNodeServiceDetails, setSelectedTransportStop, setTabs } from '../../../../store/searchResultsReducer'
import { MultipleExternalLinksDataType, SearchAdditionalDataType } from '../../../../types/searchResultTypes'
import Filters from '../../Filters/Filters'
import SearchAdditionalFields from '../../../common/SearchAdditionalFields/SearchAdditionalFields'
import ServiceResults from '../../ServiceResults/ServiceResults'
import GoogleMap from '../../Map/GoogleMap'
import { modifyUrl } from '../../../../helpers/linksHelper'
import {ReactComponent as GoBackIcon} from './../../../../img/icons/goBack.svg'
import {ReactComponent as ShowMapIcon} from './../../../../img/icons/showMapIcon.svg'
import { GetNearServicesThunk, GetServicesForNodeThunk } from '../../../../store/serviceReducer'
import { ResultCategoryItem } from '../../ResultComponents/ResultComponents'
import ResultCategories from './ResultCategories/ResultCategories'
import ExternalLinksBlock from '../../ServiceResults/ExternalLinksBlock/ExternalLinksBlock'
import TerminalResults, { TerminalDetails } from '../../TerminalResults/TerminalResults'
import AirlinesForAirport from '../../ResultComponents/AirlinesForAirport/AirlinesForAirport'
import { TransportStopDetails } from '../../../common/TransportStopDetails/TransportStopDetails'
import Activities, { ActivitiesBtn } from '../../Activities/Activities'
import ServiceOptions from '../../ServiceResults/ServiceOptions/ServiceOptions'
import { setAdditionalMarkersData } from '../../../../store/mapReducer'
import { selectIsStopTabSelected } from '../../../../store/travelSearchReducer'
import MultipleExternalLinksBlock from './components/MultipleExternalLinksBlock/MultipleExternalLinksBlock'
import CruisesForPort from '../../ResultComponents/CruisesForPort/CruisesForPort'
import { setSelectedNodeTerminals } from '../../../../store/terminalReducer'
import PublicTransportStops from '../../../common/PublicTransportStops/PublicTransportStops'
import ServicesAvailableString from '../../../common/ServicesAvailableString/ServicesAvailableString'

const Results: React.FC<ResultPropsType> = ({
  mapCenter,
  showOnlyResults,
  showCityResults,
  handleGoBack,
  onTransportStopCategorySelect,
  multipleExternalLinks,
  isServicesLoading
}) => {
  const dispatch = useAppDispatch()
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)
  const searchResults = useAppSelector(selectSearchResults)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const selectedTerminal = useAppSelector(selectSelectedTerminal)
  const selectedTransportStops = useAppSelector(selectSelectedTransportStops)
  const activeResultBlock = useAppSelector(selectActiveResultBlock)
  const isStopTabSelected = useAppSelector(selectIsStopTabSelected)

  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  const [openMapMobileView, setOpenMapMobileView] = useState(false)
  const [isServiceDataLoading, setIsServiceDataLoading] = useState(false)

  useEffect(() => {
    if (!!selectedTransportStops.length) {
      const stopData = selectedTransportStops[selectedTransportStops.length - 1]
      dispatch(GetNearServicesThunk({
        requestData: {
          latitude: stopData.location?.latitude!,
          longitude: stopData.location?.longitude!,
          radius: 2,
          unit: 'KM',
          place_id_list: [
            ...(!!activeTab?.placeId ? [activeTab?.placeId] : []),
            ...(activeTab?.placeId! !== activeTab?.countryPlaceId && !!activeTab?.countryPlaceId ? [activeTab?.countryPlaceId!] : [])
          ]
        }
      }))
    } else if (selectedTerminal) {
      dispatch(GetServicesForNodeThunk({
        requestData: {nodeId: selectedTerminal?.id, latitude: selectedTerminal.location.latitude, longitude: selectedTerminal.location.longitude},
      }))
    } else if (nodeServiceDetails?.id && nodeServiceDetails?.location) {
      dispatch(GetServicesForNodeThunk({
        requestData: {nodeId: nodeServiceDetails?.id, latitude: nodeServiceDetails.location.latitude, longitude: nodeServiceDetails.location.longitude},
      }))
    }
  }, [nodeServiceDetails, dispatch, selectedTerminal, selectedTransportStops, activeTab])

  const resultsForActiveCategories = pickBy(searchResults, function(_, key) {
    return activeResultCategories.some(category => category.toLowerCase() === key.toLowerCase())
  })

  const filtersAreAvailable = () => {
    return !!Object.keys(resultsForActiveCategories)?.length
      || (activeTab?.activeFilters && Object?.keys(activeTab?.activeFilters)?.some(key => activeResultCategories?.includes(key)))
  }

  const handleAdditionalDataChange = (data: SearchAdditionalDataType) => {
    dispatch(setTabs(tabs.map(tab => tab.name === activeTab?.name ? {...activeTab, ...data} : tab)))
    window.history.replaceState(null, '', modifyUrl(window.location.search, activeTab?.name, data))
  }

  return (
    <div className={classes.results}>
      {!showOnlyResults && <ResultCategories resultCategories={resultCategories} isSmallScreen={true} />}
      <div className={openMapMobileView ? classes.hiddenResults : classes.bigScreenResults}>
        {!showOnlyResults &&
          <SearchAdditionalFields
            values={{travelers: tabs.find(t => t.name === activeTab?.name)?.travelers!, dates: tabs.find(t => t.name === activeTab?.name)?.dates!}}
            handleChange={handleAdditionalDataChange}
            className={classes.searchAdditionalFields}
          />
        }
        {(activeResultBlock === 'Services' || activeResultBlock === 'NodeDetails') && showCityResults !== 'activitiesOnly' &&
          <ServiceOptions className={classes.serviceOptions} />  
        }
        {((isStopTabSelected && showCityResults !== 'fullResults') || !showOnlyResults || showCityResults === 'fullResults') &&
          <>
            {!!multipleExternalLinks?.length ? (
                activeResultBlock !== 'Activities' &&
                  <>
                    {showCityResults === 'fullResults' &&
                      <Filters filtersAreAvailable={filtersAreAvailable()} className={classes.filters}/>
                    }
                    {activeResultBlock === 'Services' &&
                      <MultipleExternalLinksBlock blocksData={multipleExternalLinks} />
                    }
                  </>
              ) : (
                <>        
                  {activeResultBlock !== 'Activities' && (!isStopTabSelected || (!!isStopTabSelected && showCityResults === 'fullResults')) &&
                    <Filters filtersAreAvailable={filtersAreAvailable()} className={classes.filters}/>
                  }
                  {!multipleExternalLinks && activeResultBlock === 'Services' &&
                    <div className={classes.resultInner}>
                      <ExternalLinksBlock nodeData={nodeServiceDetails}/>
                    </div>
                  }
                </>
              )
            }
          </>
        }
        {activeResultBlock === 'Activities' && <Activities />}
        {!!nodeServiceDetails?.terminals?.length && showCityResults === 'fullResults' && activeResultBlock !== 'NodeDetails' &&
          <TerminalResults />
        }
        {(activeResultBlock === 'NodeDetails') &&
          <>
            <div className={classes.resultInner}>
              <div
                className={classes.nodeDetailsGoBack}
                onClick={!!handleGoBack 
                  ? () => handleGoBack()
                  : () => {
                    dispatch(setNodeServiceDetails(null))
                    dispatch(setSelectedTransportStop(null))
                    dispatch(setActiveResultBlock('Nodes'))
                    dispatch(setAdditionalMarkersData([]))
                    dispatch(setIsTerminalListOpen(false))
                    dispatch(setSelectedNodeTerminals(null))
                  }
                }
                
              >
                <GoBackIcon /> Back to list
              </div>
              <div className={classes.openServicesNodeName}>
                {nodeServiceDetails?.name}
              </div>
              <ServicesAvailableString name={nodeServiceDetails?.name || 'this node'}/>
              <ExternalLinksBlock nodeData={nodeServiceDetails}/>
            </div>
            {!!nodeServiceDetails?.terminals?.length && 
              <TerminalResults />
            }
            {!!nodeServiceDetails?.airlines?.length && 
              <AirlinesForAirport />  
            }
            {!!nodeServiceDetails?.cruise_count &&
              <CruisesForPort />
            }
          </>
        }
        {(activeResultBlock === 'Services' || activeResultBlock === 'NodeDetails') && !multipleExternalLinks &&
          <ActivitiesBtn />
        }
        {(activeResultBlock ===  'Services' || activeResultBlock === 'NodeDetails') && showCityResults !== 'activitiesOnly' &&
          <PublicTransportStops
            nodeId={nodeServiceDetails?.id}
            onTransportStopListOpen={onTransportStopCategorySelect}
          />
        }
        {(!!selectedTransportStops.length || (!!selectedTerminal && !!selectedTransportStops.length))
          && activeResultBlock !== 'Activities' && 
          <TransportStopDetails />
        }
        {!!selectedTerminal && !selectedTransportStops.length &&
          <TerminalDetails
            onGoBack={showCityResults === 'fullResults' && activeResultCategories[0] === 'near'
              ? () => dispatch(setActiveResultBlock('Services'))
              : undefined
            }
          />
        }   
        {(activeResultBlock ===  'Services' || activeResultBlock === 'NodeDetails') && showCityResults !== 'activitiesOnly' &&
          <ServiceResults
            nodeId={nodeServiceDetails?.id}
            nodeLocation={nodeServiceDetails?.location}
            isServiceDataLoading={isServiceDataLoading || !!isServicesLoading}
            setIsServiceDataLoading={setIsServiceDataLoading}
          />
        }
         {activeResultBlock === 'Nodes' &&
          <div className={classes.resultList}>
            {sortBy(activeResultCategories, (category) => resultCategories.findIndex(c => c.value === category))
            .map(category => {
              const resultsForCategory = searchResults?.[category as 'ports' | 'airports']
              return (
                <ResultCategoryItem
                  category={category}
                  key={category}
                  showNodeServices={val => nodeServiceDetails === null ? dispatch(setNodeServiceDetails(val)) : () => {}}
                  resultsForCategory={resultsForCategory!}
                />
              )
            })}
          </div>
        }
      </div>
      {!showOnlyResults &&
        <>
          <div className={classes.mapWrapper}>
            <ResultCategories resultCategories={resultCategories} />
            {!isSmallScreen && <GoogleMap showCenter center={mapCenter} isNodeDetailsOpen={!!nodeServiceDetails?.id}/>}
          </div>
          {isSmallScreen &&
            <GoogleMap
              showCenter
              center={mapCenter}
              isNodeDetailsOpen={!!nodeServiceDetails?.id}
              smallScreenHandleShowList={() => setOpenMapMobileView(false)}
              isSmallScreenOpen={openMapMobileView}
            />
          }
          {!openMapMobileView && 
            <div 
              className={classes.showMapSmallScreenBtn}
              onClick={() => setOpenMapMobileView(true)}
            >
              <ShowMapIcon />View map
            </div>
          }
        </>
      }
    </div>
  )
}

interface ResultPropsType {
  mapCenter: [number, number]
  showOnlyResults?: boolean
  showCityResults?: 'fullResults' | 'nearbyOnly' | 'activitiesOnly'
  handleGoBack?: () => void
  onTransportStopCategorySelect?: () => Promise<any>
  multipleExternalLinks?: MultipleExternalLinksDataType[]
  isServicesLoading?: boolean
}

export default Results
