import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userSearchApi } from '../app/api'
import { NewUserSearchListType, SearchByCruiseTrackingType, SearchByFlightTrackingType, SearchGeneralTrackingType, UserSearchListType, UserSearchType } from '../types/userSearchTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { GetUserIPThunk } from './userReducer'
import { getRequestHeaders } from '../helpers/axiosHelper'

interface InitialStateType {
  userSearchLists: UserSearchListType[] | null
}

const initialState: InitialStateType = {
  userSearchLists: null,
}

export const userSearchSlice = createSlice({
  name: 'travelSearch',
  initialState,
  reducers: {
    setUserSearchLists: (state, action: PayloadAction<UserSearchListType[] | null>) => {state.userSearchLists = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserSearchListsThunk.fulfilled, (state, action) => {
        state.userSearchLists = action.payload
      })
      .addCase(SaveUserSearchThunk.fulfilled, (state, action) => {
        state.userSearchLists = state.userSearchLists?.map(list => {
          if (list.user_search_list_id === action.payload.user_search_list_id) {
            return {...list, user_searches: [...(list?.user_searches || []), action.payload]}
          } else {
            return list
          }
        })!
      })
      .addCase(DeleteUserSearchThunk.fulfilled, (state, action) => {
        state.userSearchLists = state.userSearchLists?.map(list => {
          if (list.user_search_list_id === action.payload.listId) {
            return {...list, user_searches: (list?.user_searches || []).filter(search => search.search_id !== action.payload.searchId)}
          } else {
            return list
          }
        })!
      })
      .addCase(CreateUserSearchListThunk.fulfilled, (state, action) => {
        state.userSearchLists = [...(state.userSearchLists || []), action.payload]
      })
      .addCase(EditUserSearchListThunk.fulfilled, (state, action) => {
        state.userSearchLists = state.userSearchLists?.map(list => {
          if (list.user_search_list_id === action.payload.user_search_list_id) {
            return action.payload
          }
          else {
            return list
          }
        })!
      })
      .addCase(DeleteUserSearchListThunk.fulfilled, (state, action) => {
        state.userSearchLists = state.userSearchLists?.filter(list => list.user_search_list_id !== action.payload)!
      })    
  }
})

export const { setUserSearchLists } = userSearchSlice.actions

export const selectUserSearchLists = (state: RootState): UserSearchListType[] | null => state.userSearch.userSearchLists

export const GetUserSearchListsThunk = createAsyncThunk<UserSearchListType[], number , AsyncThunkConfig>(
  'userSearch/getUserSearchLists',
  async (userId, thunkAPI) => {
    try {
      const {data, status} = await userSearchApi.getUserSearchList(userId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.user_search_lists, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return thunkAPI.fulfillWithValue([], {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(error?.response?.data?.message)
      }
    }
  }
)

export const SaveUserSearchThunk = createAsyncThunk<UserSearchType, UserSearchType , AsyncThunkConfig>(
  'userSearch/saveUserSearch',
  async (searchData, thunkAPI) => {
    try {
      const {data, status} = await userSearchApi.saveUserSearch(searchData)
      if (status === 201 &&  data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteUserSearchThunk = createAsyncThunk<{listId: number, searchId: number}, {listId: number, searchId: number} , AsyncThunkConfig>(
  'userSearch/deleteUserSearch',
  async ({listId, searchId}, thunkAPI) => {
    try {
      const {data, status} = await userSearchApi.deleteUserSearch(searchId)
      if (status === 204) {
        return thunkAPI.fulfillWithValue({listId, searchId}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateUserSearchListThunk = createAsyncThunk<UserSearchListType, NewUserSearchListType , AsyncThunkConfig>(
  'userSearch/createUserSearchList',
  async (listData, thunkAPI) => {
    try {
      const {data, status} = await userSearchApi.createUserSearchList(listData)
      if (status === 201 &&  data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditUserSearchListThunk = createAsyncThunk<UserSearchListType, {listId: number, listData: NewUserSearchListType} , AsyncThunkConfig>(
  'userSearch/editUserSearchList',
  async ({listId, listData}, thunkAPI) => {
    try {
      const {data, status} = await userSearchApi.editUserSearchList(listId, listData)
      if (status === 200 &&  data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteUserSearchListThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'userSearch/deleteUserSearchList',
  async (listId, thunkAPI) => {
    try {
      const {data, status} = await userSearchApi.deleteUserSearchList(listId)
      if (status === 204) {
        return thunkAPI.fulfillWithValue(listId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SaveSearchTrackingThunk = createAsyncThunk<number, SearchGeneralTrackingType | SearchByFlightTrackingType | SearchByCruiseTrackingType, AsyncThunkConfig>(
  'userSearch/saveSearchTracking',
  async (trackingData, thunkAPI) => {
    const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
    const {status} = await userSearchApi.saveSearchTracking(trackingData, requestHeaders)
    return thunkAPI.fulfillWithValue(status, {appStatus: AppStatusType.idle})
  }
)

export default userSearchSlice.reducer
 