import { Divider, Select } from 'antd'
import { useState } from 'react'
import Footer from '../common/Footer/Footer'
import Header from '../common/Header/Header'
import Account from './Account/Account'
import Password from './Password/Password'
import SavedList from './UserSearchList/UserSearchList'
import classes from './Settings.module.css'
import CustomizedCruises from './CustomizedCruises/CustomizedCruises'
import GenerateWidget from './GenerateWidget/GenerateWidget'

const Settings = () => {
  const settingTabs = [
    'My details',
    'Password',
    'Saved list',
    'Customized cruises',
    'Generate widget',
  ]

  const [activeTab, setActiveTab] = useState(settingTabs[0])

  return (
    <div className={classes.wrapper}>
      <Header showMenu />
      <Divider style={{margin: '0px', borderTop: '2px solid rgba(0, 0, 0, 0.06)'}}/>
      <div className={classes.contentWrapper}>
        <div className={classes.pageTitle}>
          <h1>
            Profile
          </h1>
          <Divider style={{margin: '0px'}}/>
        </div>
        <div className={classes.settingsArea}>
          <Select
            value={activeTab}
            className={classes.settingsTabsMobile}
            onSelect={(val:any) => setActiveTab(val)}
          >
            {settingTabs?.map(tab => (
              <Select.Option key={tab}>
                {tab}
              </Select.Option>
            ))}
          </Select>
          <ul className={classes.settingsTabs}>
            {settingTabs.map(tab =>(
              <li
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={activeTab === tab ? classes.active : ''}
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className={classes.formArea}>
            {activeTab === 'My details' && <Account /> }
            {activeTab === 'Password' && <Password /> }
            {activeTab === 'Saved list' && <SavedList />}
            {activeTab === 'Customized cruises' && <CustomizedCruises />}
            {activeTab === 'Generate widget' && <GenerateWidget />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Settings
