import { Helmet } from 'react-helmet-async'
import { Input, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import classes from './Blog.module.css'
import Footer from '../common/Footer/Footer'
import Header from '../common/Header/Header'
import GoToTopBtn from '../common/GoToTopBtn/GoToTopBtn'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import {ReactComponent as DownArrowLong} from './../../img/icons/downArrowLong.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GetBlogEntriesThunk, selectBlogEntries, selectBlogEntriesTotalCount, selectBlogFetchParams, setBlogFetchParams } from '../../store/blogReducer'
import BlogEntryPreview from './BlogEntryPreview/BlogEntryPreview'
import { debounce } from 'lodash'

const Blog = () => {
  const dispatch = useAppDispatch()
  const fetchParams = useAppSelector(selectBlogFetchParams)
  const entriesList = useAppSelector(selectBlogEntries)
  const entriesListTotalCount = useAppSelector(selectBlogEntriesTotalCount)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    dispatch(GetBlogEntriesThunk({requestParams: fetchParams}))
      .then(() => setIsLoading(false))
  }, [dispatch, fetchParams])

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      dispatch(setBlogFetchParams({
        ...fetchParams,
        pagination_request: {...fetchParams.pagination_request, page: 1},
        blog_entity_request: {...fetchParams.blog_entity_request, search: searchRequest}
      }))
    }, 350),
    [fetchParams]
  )

  const loadMore = () => {
    dispatch(setBlogFetchParams({
      ...fetchParams,
      pagination_request: {...fetchParams.pagination_request, page: fetchParams.pagination_request.page + 1},
    }))
  }

  return (
    <>
      <div className={classes.wrapper}>
        <Helmet>
          <title>Relavanti | Blog</title>
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header showMenu={true}/>
        <GoToTopBtn />
        <div className={classes.contentWrapper}>
          <h1>
            Blog
          </h1>
          {entriesList === null ? (
            <Spin style={{width: '100%'}} />
          ) : (
            <>
              <div className={classes.infoBlock}>
                <h2>
                  Resources and insights from Relavanti
                </h2>
                <Input
                  placeholder='Search'
                  suffix={<SearchIcon />}
                  defaultValue={decodeURIComponent(fetchParams.blog_entity_request.search as string)}
                  onChange={(e) => handleSearchDebounce(e.target.value)}
                  className={classes.searchInput}
                />
              </div>
              <div className={classes.previewWrapper}>
                {entriesList.map(entry => (
                  <BlogEntryPreview
                    key={entry.id}
                    entry={entry}
                  />
                ))}
              </div>
              {entriesListTotalCount > entriesList.length &&
                <div className={classes.loadMore} onClick={loadMore}>
                  {isLoading ? <Spin /> : <DownArrowLong />} Load more
                </div> 
              }
             
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Blog
