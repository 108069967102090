import { Button, Modal } from 'antd'

const TermsAndConditions:React.FC<{
  isOpen: boolean,
  onCancel: () => void,
  onAccept: () => void,
}> = ({isOpen, onCancel, onAccept}) => {

  const points = [
    {text: 'By using this service, you confirm that you, the event creator, are at least 18 years of age.'},
    {text: 'Use of the Events platform on Relavanti.com also confirms agreement to the Terms and Conditions of the site.'},
    {text: 'You will need to open a profile on Relavanti.com to create events. Please be sure to enter your exact and full name and email address. Your information will not be shared with, or sold to, any person or company, except if required by law. '},
    {text: 'Scopia LLC (owner of this website) will share with you commissions earned on bookings through the event website for car rentals, hotels or activities. '},
    {text: 'Only commissions paid to Scopia LLC by the linked service suppliers will be considered. Bookings that do not produce any commission or that are booked outside of this Event page, will not be considered for sharing.'},
    {text: 'To learn more about how Events work on Scopia LLC, please visit this blog post.'},
    {text: 'Your share of the commission will be paid to you in the form of a virtual gift card from Amazon.com or any gift card of your choice which can be sent to you via email.'},
    {text: 'You can be a resident of any country to create and share an event, but you must be a resident of the United States to receive shared commissions.'},
    {
      text: 'Commissions will be paid to you according to the following scale: ',
      subPoints: [
        'If the total commission earned is less than US$10, no commission will be shared.',
        'If the total commission earned is between US$10 and US$49.99, you will receive a share of the commission equivalent to 25% of the total commission amount.',
        'If the total commission earned is US$50 or higher, you will receive a share of the commission equivalent to 50% of the total commission.',
        'If the total commission paid to you is above $600, you will be contacted to securely provide your full name, social security number and residential address for the purpose of sending you IRS form 1099-NEC.'
      ]
    },
    {text: 'The email address provided when you registered to Relavanti.com, is the only email address to which the virtual gift card will be sent (meaning, you cannot register with one email address and request that the gift card be sent to a different email address).'},
    {text: 'Should more than one Event be created on Relavanti.com, by two different persons with different email addresses, for the same event (location and date), then each such Event created on Relavanti.com will be treated separately for the commission calculation (as an example: If the bride and the groom both create Events on Relavanti.com for the same wedding and with different email addresses, then each of those Events will be calculated separately according to the scale mentioned above in item 5.'},
    {text: 'It can take 3-6 months for commissions earned to show up on the supplier reports. Please be patient.'},
    {text: 'In case you have any questions, please email events@relavanti.com.'},
  ]
  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h2 style={{textAlign: 'center'}}>
          Terms and Conditions for Events – Please Read Carefully
        </h2>
        <ol>
          {points?.map((point) => (
            <li key={point.text} style={{marginBottom: '8px'}}>
              {point.text}
              {!!point?.subPoints?.length &&
                <ol type='a'>
                  {point?.subPoints?.map((subPoint) => (
                    <li key={subPoint}>
                      {subPoint}
                    </li>
                  ))}
                </ol>
              }
            </li>
          ))}
        </ol>
        <Button
          type='primary'
          onClick={onAccept}
          style={{marginTop: '15px'}}
        >
          Accept
        </Button>
      </div>
    </Modal>
  )
}

export default TermsAndConditions
