import moment from 'moment'
import { Link } from 'react-router-dom'
import { BlogEntryType } from '../../../types/blogTypes'
import classes from './BlogEntryPreview.module.css'
import { ReactComponent as LinkArrow } from './../../../img/icons/linkArrow.svg'
import { getContentFromHTMLString, slugify } from '../../../helpers/funcHelper'
import blogDefaultCover from './../../../img/blogDefaultCover.webp'

const BlogEntryPreview: React.FC<BlogEntryPreviewPropTypes> = ({entry}) => {
  const getContentForPreview = () => {
    return getContentFromHTMLString(entry.content).slice(0, 100) + '...'
  }

  return (
    <div className={classes.wrapper}>
      <img
        src={entry.blog_images?.find(i => i.is_cover)?.image_url || blogDefaultCover}
        alt={entry.blog_images?.find(i => i.is_cover)?.image_html || 'blog default cover'}
        className={classes.cover}
      />
      <div className={classes.date}>
        {moment(entry.created).format('DD MMM YYYY')}
      </div>
      <Link to={`/blog/post/${entry.url}`} className={classes.title}>
        {entry.title} <LinkArrow />
      </Link>
      <div className={classes.textPreview}>
        {getContentForPreview()}
      </div>
      <Link to={`/blog/post/${slugify(entry.url)}`} className={classes.entryLink}>
        Read post <LinkArrow />
      </Link>
    </div>
  )
}

interface BlogEntryPreviewPropTypes {
  entry: BlogEntryType
}

export default BlogEntryPreview
