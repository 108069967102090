import { AppSettingsHelperType, TokenDataHelperType, UserDataHelperType } from '../types/localStorageDataTypes'

export const tokenDataHelper: TokenDataHelperType = {
  setTokenData(data) {
    localStorage.setItem('tokenData', JSON.stringify(data))
  },
  getTokenData() {
    const tokenData = localStorage.getItem('tokenData')
    return !!tokenData && JSON.parse(tokenData)
  },
  removeTokenData() {
    return localStorage.removeItem('tokenData')
  },
}

export const userDataHelper: UserDataHelperType = {
  setUserData(data) {
    localStorage.setItem('userData', JSON.stringify(data))
  },
  setUserAvatar(avatarUrl) {
    const userData = localStorage.getItem('userData')
    if (!!userData && !!avatarUrl) {
      localStorage.setItem('userData', JSON.stringify({photo_url: avatarUrl, ...JSON.parse(userData)}))
    }
  },
  getUserData() {
    const userData = localStorage.getItem('userData')
    return !!userData && JSON.parse(userData)
  },
  removeUserData() {
    return localStorage.removeItem('userData')
  },
}

export const appSettingsHelper: AppSettingsHelperType = {
  setAppSettings(data) {
    localStorage.setItem('appSettings', JSON.stringify(data))
  },
  getAppSettings() {
    const appSettingsData = localStorage.getItem('appSettings')
    return !!appSettingsData && JSON.parse(appSettingsData)
  },
  removeAppSettings() {
    return localStorage.removeItem('appSettings')
  },

  setBetaModalLastShownTime(time: string) {
    localStorage.setItem('betaModalLastShownTime', JSON.stringify(time))
  },
  getBetaModalLastShownTime() {
    const timeData = localStorage.getItem('betaModalLastShownTime')
    return !!timeData && JSON.parse(timeData)
  },
}
