import Auth, { AuthPage } from '../components/Auth/Auth'
import MainPage from '../components/MainPage/MainPage'
import SearchResults from '../components/SearchResults/SearchResults'
import ByFlight from '../components/Travel/ByFlight/ByFlight'
import Search from '../components/Travel/Search/Search'
import Travel from '../components/Travel/Travel'
import ByFlightSearchResults from '../components/Travel/ByFlight/SearchResults/SearchResults'
import ByCruiseSearchResults from '../components/Travel/ByCruise/SearchResults/SearchResults'
import ByCruise from '../components/Travel/ByCruise/ByCruise'
import Settings from '../components/Settings/Settings'
import Events from '../components/Events/Events'
import EventDetailsPage from '../components/Events/EventDetailsPage/EventDetailsPage'
import AdvertisingPage from '../components/AdvertisingPage/AdvertisingPage'
import B2B from '../components/B2B/B2B'
import CruiseAdvancedSearch from '../components/Travel/ByCruise/CruiseAdvancedSearch/CruiseAdvancedSearch'
import Blog from '../components/Blog/Blog'
import BlogEntry from '../components/Blog/BlogEntry/BlogEntry'
import Widget from '../components/Widget/Widget'
import FlightAdvancedSearch from '../components/Travel/ByFlight/FlightAdvancedSearch/FlightAdvancedSearch'
import AirportList from '../components/Travel/ByFlight/FlightAdvancedSearch/AirportList/AirportList'
import AboutPage from '../components/AboutPage/AboutPage'

const routes: RouteType[] = [
  {path: '/', component: MainPage, auth: false, breadcrumb: 'Home'},
  {path: '/travel', component: Travel, auth: false, breadcrumb: 'Travel'},
  {path: '/travel/search', component: Search, auth: false, breadcrumb: 'By Locations'},
  {path: '/travel/results', component: SearchResults, auth: false, breadcrumb: 'By Locations'},
  {path: '/travel/by-flight', component: ByFlight, auth: false, breadcrumb: 'By Flight'},
  {path: '/travel/by-flight-advanced', component: FlightAdvancedSearch, auth: false, breadcrumb: 'By Flight Advanced Search'},
  {path: '/travel/by-flight-advanced/airports', component: AirportList, auth: false, breadcrumb: 'Airports'},
  {path: '/travel/by-flight-advanced/summary', component: FlightAdvancedSearch, props: {showSummary: true}, auth: false, breadcrumb: 'Summary'},
  {path: '/travel/by-flight/results', component: ByFlightSearchResults, auth: false, breadcrumb: 'Results'},
  {path: '/travel/by-cruise', component: ByCruise, auth: false, breadcrumb: 'By Cruise'},
  {path: '/travel/by-cruise-advanced', component: CruiseAdvancedSearch, auth: false, breadcrumb: 'By Cruise Advanced Search'},
  {path: '/travel/by-cruise/results', component: ByCruiseSearchResults, auth: false, breadcrumb: 'Results'},
  {path: '/travel/by-cruise-advanced/results', component: ByCruiseSearchResults, auth: false, breadcrumb: 'Results'},
  {path: '/profile', component: Settings, auth: true, breadcrumb: null},
  {path: '/advertising', component: AdvertisingPage, auth: false, breadcrumb: null},
  {path: '/b-2-b', component: B2B, auth: false, breadcrumb: null},
  {path: '/blog', component: Blog, auth: false, breadcrumb: null},
  {path: '/blog/post/:title', component: BlogEntry, auth: false, breadcrumb: null},
  {path: '/about', component: AboutPage, auth: false, breadcrumb: null},
  {path: '/events', component: Events, auth: false, breadcrumb: null},
  {path: '/events/details/:code', component: EventDetailsPage, auth: false, breadcrumb: null},
  {path: '/sign-in', component: Auth, props: {page: AuthPage.SignIn}, auth: false, breadcrumb: null},
  {path: '/sign-up', component: Auth, props: {page: AuthPage.SignUp}, auth: false, breadcrumb: null},
  {path: '/forgot-password', component: Auth, props: {page: AuthPage.ForgotPassword}, auth: false, breadcrumb: null},
  {path: '/login/create-password', component: Auth, props: {page: AuthPage.CreatePassword}, auth: false, breadcrumb: null},
  {path: '/reset-password', component: Auth, props: {page: AuthPage.ResetPassword}, auth: false, breadcrumb: null},

  {path: '/widget', component: Widget, auth: false, breadcrumb: null},
]

interface RouteType {
  path: string
  component: React.FC<any>
  auth: boolean
  props?: object
  breadcrumb?: string | null
}

export default routes
