import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { getFlightDataFromSearchString } from '../../../../../helpers/linksHelper'
import { selectFlight, selectSelectedStops, setFlight, setSelectedStops } from '../../../../../store/flightReducer'
import { NodeServiceDetails, setActiveResultCategories } from '../../../../../store/searchResultsReducer'
import { GetServiceCategoriesThunk } from '../../../../../store/serviceReducer'
import { setSelectedResultItemId } from '../../../../../store/travelSearchReducer'
import ItemDetailsTabs from '../../../common/ItemDetailsTabs/ItemDetailsTabs'
import TripNumber from '../../../common/TripNumber/TripNumber'
import FlightItem from '../FlightItem/FlightItem'

const FlightDetails: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const flight = useAppSelector(selectFlight)
  const searchData = getFlightDataFromSearchString(search)
  const selectedStops = useAppSelector(selectSelectedStops)

  useEffect(() => {
    dispatch(GetServiceCategoriesThunk())
    dispatch(setActiveResultCategories(['airports']))
  }, [dispatch])

  const getNodeData = (key: string):NodeServiceDetails => {
    const stops = [flight?.departure_airport, flight?.arrival_airport, ...(flight?.airport_stops || [])]
    const nodeData = stops?.find(stop => String(stop?.id) === key)!
    return {
      id: nodeData?.id,
      name: nodeData?.name,
      type: 'airport',
      location: {
        latitude: nodeData?.latitude,
        longitude: nodeData?.longitude
      },
      terminals: nodeData?.terminals,
      airlines: nodeData?.airlines
    }
  }

  const updateStopCoordinatesAccordingToGoogle = (tabsData: any[]) => {
    !!flight && dispatch(setFlight(
      {...flight,
        departure_airport: {
          ...flight.departure_airport,
          latitude: tabsData.find(tab => tab.name === flight.departure_airport.name)?.coordinates[0],
          longitude: tabsData.find(tab => tab.name === flight.departure_airport.name)?.coordinates[1]
        },
        arrival_airport: {
          ...flight.arrival_airport,
          latitude: tabsData.find(tab => tab.name === flight.arrival_airport.name)?.coordinates[0],
          longitude: tabsData.find(tab => tab.name === flight.arrival_airport.name)?.coordinates[1]
        },
        airport_stops: flight?.airport_stops?.map(stop => {
          const stopDataFromResp = tabsData.find(tab => tab.name === stop.name)
          if (Object.keys(stopDataFromResp || {})?.length) {
            return {
              ...stop, 
              latitude: stopDataFromResp.coordinates[0],
              longitude: stopDataFromResp.coordinated[1]
            }
          } else {
            return stop
          }
        }) || []
      }
    ))
    !!flight && dispatch(setSelectedResultItemId(flight.id))
  }

  return (
    <ItemDetailsTabs
      resultItem={{
        tripNumber: searchData.number.toUpperCase(),
        stops: [flight?.departure_airport, flight?.arrival_airport, ...(flight?.airport_stops || [])] || []
      }}
      getNodeData={getNodeData}
      requiredTabs={[
        ...(flight?.departure_airport?.name === flight?.arrival_airport?.name
          ? [{...flight?.departure_airport, departure_date: flight?.departure_date + ' ' + flight?.departure_time}]
          : [
              {...flight?.departure_airport, departure_date: flight?.departure_date + ' ' + flight?.departure_time},
              {...flight?.arrival_airport, arrival_date: flight?.arrival_date + ' ' + flight?.arrival_time}
            ]
        )
      ]}
      optionalTabs={selectedStops}
      searchData={searchData}
      setSelectedStops={(key: number) => dispatch(setSelectedStops(key))}
      type='byFlight'
      updateStopCoordinatesAccordingToGoogle={updateStopCoordinatesAccordingToGoogle}
    >
      <TripNumber
        tripNumber={`Flight number: ${searchData.number.toUpperCase()}`}
        secondaryInfo='Airline'
        primaryInfo={flight?.airline?.name || ''}
        imageType='plane'
        handleGoBack={() => {
          dispatch(setFlight(null))
          dispatch(setSelectedResultItemId(''))
        }}
      />
      <FlightItem
        showStops
        flight={flight!}
        onClick={() => {}}
        searchData={searchData}
        getNodeData={getNodeData}
      />
    </ItemDetailsTabs>
  )
}

export default FlightDetails
