import { Select } from 'antd'
import classes from './../CruiseAdvancedSearch.module.css'
import { useAppSelector } from '../../../../../app/hooks'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { selectRegions } from '../../../../../store/additionalDataReducer'
import { sortBy } from 'lodash'
import { RegionType } from '../../../../../types/additionalDataTypes'

const RegionsLocationField: React.FC<{setFormValues: Dispatch<SetStateAction<any>>, formValues: any}> = ({setFormValues, formValues}) => {
  const regions = useAppSelector(selectRegions)

  const [searchString, setSearchString] = useState('')
  const [regionOptions, setRegionOptions] = useState<RegionType[]>(regions || [])

  useEffect(() => {
    if (!!searchString?.length) {
      setRegionOptions(regions?.filter(r => r.name.toLowerCase().includes(searchString.toLowerCase())) || [])
    } else {
      setRegionOptions(regions || [])
    }
  }, [searchString, regions])

  const handleSelect = (id: number) => {
    setFormValues({...formValues, regions: [...(formValues?.regions || []), id]})
    setSearchString('')
  }
  
  const handleDeselect = (id: number) => {
    setFormValues({...formValues, regions: formValues?.regions.filter((c:number) => c !== id)})
  }

  return (
    <div className={classes.formItemWrapper}>
      <div className={classes.formItemLabel}>
        <span>Region: Where would you like to go?</span>
      </div>
      <div id={'regionSelect'}>
        <Select
          showSearch
          style={{width: '100%', marginBottom: '25px'}}
          placeholder='Start typing region name'
          onSearch={searchQuery => setSearchString(searchQuery)}
          onSelect={(val:any) => handleSelect(val.value)}
          onDeselect={(val:any) => handleDeselect(val.value)}
          searchValue={searchString}
          labelInValue
          filterOption={false}
          mode='multiple'
          getPopupContainer={() => document.getElementById('regionSelect')!}
          value={formValues?.regions}
          options={sortBy(regionOptions?.map(c => ({value: c.region_id, label: c.name})), c => c.label.toLowerCase())}
        >  
        </Select>
      </div>
    </div>
  )
}

export default RegionsLocationField
