import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectNearServices, selectNodeServices, selectServiceCategories, setNodeServices, setServiceCategoryOptions } from '../../../store/serviceReducer'
import { NodeServiceResultType } from '../../../types/serviceTypes'
import { selectActiveResultBlock, selectActiveResultCategories, selectActiveServices, selectNodeServiceDetails, selectSelectedTransportStops, setActiveServices } from '../../../store/searchResultsReducer'
import { setIsTransportStopsListOpen, setNodeTransportStops } from '../../../store/transportStopsReducer'
import { Spin } from 'antd'
import { orderBy } from 'lodash'
import { selectDistanceType } from '../../../store/appStatusReducer'
import ServiceBanner from './ServiceBanner/ServiceBanner'
import { selectAdvertisementServicesComponent } from '../../../store/advertisementReducer'
import AdvertisingBanner from '../../common/AdvertisingBanner/AdvertisingBanner'

const ServiceResults: React.FC<ServiceResultsPropTypes> = ({nodeId, nodeLocation, isServiceDataLoading, setIsServiceDataLoading}) => {
  const dispatch = useAppDispatch()
  const nearServices = useAppSelector(selectNearServices)
  const nodeServices = useAppSelector(selectNodeServices)
  const activeServices = useAppSelector(selectActiveServices)
  const serviceCategories = useAppSelector(selectServiceCategories)
  // const nearTransportStops = useAppSelector(selectNearTransportStops)
  // const nodeTransportStops = useAppSelector(selectNodeTransportStops)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const selectedTransportStops = useAppSelector(selectSelectedTransportStops)
  const activeResultBlock = useAppSelector(selectActiveResultBlock)
  const distanceType = useAppSelector(selectDistanceType)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const advertisement = useAppSelector(selectAdvertisementServicesComponent)

  const [servicesForDisplay, setServicesForDisplay] = useState<{[key: string]: NodeServiceResultType[]}>({})

  useEffect(() => {    
    return () => {
      activeResultBlock !== 'NodeDetails' && dispatch(setNodeServices([]))
      activeResultBlock !== 'NodeDetails' && dispatch(setNodeTransportStops(null))
      dispatch(setActiveServices([]))
      dispatch(setIsTransportStopsListOpen(false))
      dispatch(setServiceCategoryOptions([]))
    }
    // eslint-disable-next-line
  }, [dispatch, nodeId, nodeLocation])

  useEffect(() => {
    setIsServiceDataLoading(true)
    let services = !!nodeId ? nodeServices : nearServices
    if (!!serviceCategories.length) {
      const options = serviceCategories
        ?.filter(category => services.some(s => s.category_id === category.service_category_id))
        ?.map(category => category.name)
      const serviceOptions = [
        // 'Public Transportation Stops',
        ...(nodeServiceDetails?.terminals?.length && activeResultBlock !== 'TerminalDetails' ? ['Terminals'] : []),
        ...options
      ]
      dispatch(setServiceCategoryOptions(serviceOptions))
      setIsServiceDataLoading(false)
    }

    const onlyActiveServices = services
      .filter(service => {
        const categoryName = serviceCategories.find(c => c.service_category_id === service.category_id)?.name
        return activeServices.includes(categoryName!)
      })
      .map(service => ({
        name: service.name,
        id: service.service_id,
        details: service?.comment,
        webLink: service?.website,
        serviceType: 'service',
        phones: service?.phones,
        icon: service?.logo_url,
        banner_background_color: service?.banner_background_color,
        categoryId: service?.category_id,
        banner_html: service.active_banner_type === 'html' ? service?.banner_html : undefined,
      }))
    setServicesForDisplay({services: onlyActiveServices})
  }, [nodeServices, nearServices, nodeId, activeServices, serviceCategories, dispatch, activeResultCategories, isServiceDataLoading, setIsServiceDataLoading, selectedTransportStops, distanceType, nodeServiceDetails, activeResultBlock])

  if (isServiceDataLoading) {
    return (
      <Spin />
    )
  }

  return (
    <>
      {advertisement?.some(ad => ad.place_type?.toUpperCase()?.includes('BEFORE_SERVICES')) &&
        <AdvertisingBanner
          adData={advertisement?.find(ad => ad.place_type?.toUpperCase()?.includes('BEFORE_SERVICES'))!}
          style={{marginTop: '15px'}}
        />
      }
      <div style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        marginTop: '20px',
        padding: '10px 0px',
      }}>
        {Object.values(servicesForDisplay).some(s => s.length > 0) ?
          Object.keys(servicesForDisplay).map((key) =>
            !!servicesForDisplay[key]?.length ? (
              <div key={key}>
                <div
                  style={{ 
                    fontSize: '18px',
                    fontWeight: 700,
                    margin: '15px 0px 10px 0px',
                    textAlign: 'start',
                    textTransform: 'capitalize',
                    padding: '0px 15px'
                }}>
                  {key}
                </div>
                {servicesForDisplay[key].map(item =>  (
                  <ServiceBanner
                    key={item.id}
                    serviceData={{
                      name: item.name,
                      id: item.id,
                      details: item.details,
                      webLink: item.webLink,
                      logo: item?.icon,
                      phones: item?.phones,
                      banner_background_color: item?.banner_background_color,
                      // @ts-ignore
                      categoryId: item?.categoryId,
                      // @ts-ignore
                      banner_html: item?.banner_html
                    }}
                  />
                ))}
              </div>
            ) : (<></>)
          ) : (
            <div style={{padding: '10px',}}>
              No services found
            </div>
          )}
        {orderBy(advertisement?.filter(ad => ad.place_type?.toUpperCase()?.includes('AFTER_SERVICES')), ad => ad.place_index)?.map(ad => (
          <AdvertisingBanner
            adData={ad}
            style={{
              marginTop: '15px',
              marginLeft: '10px',
              width: 'auto',
              paddingRight: '10px',
            }}
          />
        ))}
      </div>
    </>
  )
}

interface ServiceResultsPropTypes {
  nodeId?: number
  nodeLocation?: {latitude: number, longitude: number}
  setIsServiceDataLoading: Dispatch<SetStateAction<boolean>>
  isServiceDataLoading: boolean
}

export default ServiceResults
