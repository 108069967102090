import { Spin } from 'antd'
import { groupBy } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectDistanceType } from '../../../../store/appStatusReducer'
import { selectNodeServiceDetails, selectSelectedTerminal, selectSelectedTransportStops } from '../../../../store/searchResultsReducer'
import { getStopsForNodeRadius, selectIsTransportStopsListOpen, selectIsTransportStopsLoading, selectNearTransportStops, selectNodeTransportStops, selectTransportStopFilters, setTransportStopFilters } from '../../../../store/transportStopsReducer'
import { StopDataType } from '../../../../types/transportStopsTypes'
import { ResultItem } from '../../../SearchResults/ResultComponents/ResultComponents'
import classes from './TransportStopList.module.css'
import { ReactComponent as ServiceBusStopIcon } from './../../../../img/icons/serviceBusStop.svg'
import { ReactComponent as ServiceTrainStopIcon } from './../../../../img/icons/serviceTrainStop.svg'
import { ReactComponent as ServiceFerryStopIcon } from './../../../../img/icons/serviceFerryStop.svg'
import { ReactComponent as ServiceTramStopIcon } from './../../../../img/icons/serviceTramStop.svg'
import { ReactComponent as ServiceMetroStopIcon } from './../../../../img/icons/serviceMetroStop.svg'
import { useEffect } from 'react'

export const stopTypeIcons: {[key: string]: any} = {
  'bus': <ServiceBusStopIcon />,
  'rail': <ServiceTrainStopIcon />,
  'ferry': <ServiceFerryStopIcon />,
  'metro': <ServiceMetroStopIcon />,
  'tram': <ServiceTramStopIcon />,
}

const TransportStopList: React.FC<{relatedToNode?: boolean, isLoading?: boolean, isOpen?: boolean}> = ({relatedToNode, isLoading, isOpen}) => {
  const dispatch = useAppDispatch()
  const distanceType = useAppSelector(selectDistanceType)
  const isListOpen = useAppSelector(selectIsTransportStopsListOpen)
  const isTransportStopsLoading = useAppSelector(selectIsTransportStopsLoading)
  const nearTransportStops = useAppSelector(selectNearTransportStops)
  const nodeTransportStops = useAppSelector(selectNodeTransportStops)
  const selectedTransportStops = useAppSelector(selectSelectedTransportStops)
  const transportStopFilters = useAppSelector(selectTransportStopFilters)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const selectedTerminal = useAppSelector(selectSelectedTerminal)

  const transportStops = !!relatedToNode 
    ? nodeTransportStops
    : (!!selectedTransportStops.length
      ? nearTransportStops?.filter(stop => stop.id !== selectedTransportStops[selectedTransportStops.length - 1].id)
      : nearTransportStops
    )

  const stopsForDisplay = Object.entries({...groupBy(transportStops, 'type')})
    .map(([key, stops]) => ({
      [key]: stops.map((stop: StopDataType) => ({
        name: stop.stop_name,
        id: stop.id,
        details: `Distance from search point: ${distanceType === 'km' ? (stop?.distance * 1000).toFixed(0) : (stop?.distance / 1.609).toFixed(1)} ${distanceType === 'km' ? 'meters' : 'miles'}`,
        webLink: stop?.agency_web,
        additionalInfo: stop?.agency_name,
        serviceType: 'stop',
        location: stop.location,
        icon: stopTypeIcons[stop.route_types[0]?.route_type_name?.toLowerCase() as string || 'bus']
      }))
    }))
    .reduce((item,acc) => acc = {...acc, ...item}, {})

    const getStopDistance = () => {
      if (transportStopFilters === null) {
        const nodeCategory = selectedTerminal?.id ? 0 : nodeServiceDetails?.category || 0
        const distance = getStopsForNodeRadius(nodeCategory, distanceType)
        const formattedDistance = nodeServiceDetails?.id || selectedTerminal?.id
          ? (distanceType === 'km' ? distance / 1000 : distance) + ' ' + distanceType
          : (distanceType === 'km' ? 1 : 0.62) + ' ' + distanceType
          return `Stops within ${formattedDistance} radius`
        } else {
          const formattedDistance = transportStopFilters?.departure_location?.radius + ' ' + transportStopFilters?.departure_location?.unit.toLowerCase()
          return `Stops within ${formattedDistance} radius for direct routes to ${transportStopFilters?.arrival_location?.name}`
      }              
    }

    useEffect(() => {
      return () => {dispatch(setTransportStopFilters(null))}
    }, [dispatch])

  return (
    <>
      {(!!isListOpen || !!isOpen) && (isTransportStopsLoading || isLoading) &&
        <Spin style={{width: '100%', marginTop: '10px'}}/>
      }
      {(!!isListOpen || !!isOpen) && !isTransportStopsLoading && !isLoading && (
        !transportStops?.length ? (//!nodeTransportStops?.length && !nearTransportStops?.length ? (
          <div className={classes.noResultsMessage}>
            No stops found
            <span className={classes.radiusInfo}>
              {getStopDistance()}
            </span>
          </div>
        ) : (
          <>
            {Object.keys(stopsForDisplay)
              .filter(key => !!stopsForDisplay[key]?.length)
              .map((key) => (
                <div key={key}>
                  <div className={classes.stopType}>
                    {key}
                    <span className={classes.radiusInfo}>
                      {getStopDistance()}
                    </span>
                  </div>
                  {stopsForDisplay[key].map((item) => (
                    <ResultItem
                      item={{
                        name: item.name,
                        id: item.id,
                        details: item.details,
                        additionalInfo: item?.additionalInfo,
                        webLink: item.webLink,
                        type: item.serviceType,
                        location: item?.location,
                        icon: key.toLowerCase() !== 'services' ? item?.icon : '',
                        // terminals: item?.terminals || []
                      }}
                      isNodeDetails={!!relatedToNode}
                      key={item.id}
                      wrapperStyle={{padding: '0px'}}
                      disableItemDetails={!!isOpen}
                    />
                  ))}
                </div>
              ))
            }
          </>
        )
      )}
    </>
  )
}

export default TransportStopList
