import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './../types/appTypes'
import { GetNearServicesThunk } from './serviceReducer'
import { AdvertisingType } from '../types/advertisingTypes'
import { GetViatorAttractionsFilteredThunk } from './viatorReducer'
import { GetCruiseByLineAndDateThunk, GetCruisesByAdvancedSearchThunk } from './cruiseReducer'
import { CruiseType } from '../types/cruiseTypes'
import { SharedFlightCruiseDetailsType } from '../types/appTypes'
import { GetEventByCodeThunk } from './eventReducer'

const adsLocationsForMap = [
  'NEARBY_MAP',
  'NODE_DETAILS_MAP',
  'ACTIVITIES_LIST_MAP',
  'NEARBY_VIP_MAP',
  'CRUISE_PROGRAM_MAP',
  'CRUISE_DESCRIPTION_MAP',
  'CRUISE_VIP_MAP',
  'EVENT_DETAILS_MAP'
]

const adsLocationsForServices = [
  'NEARBY_AFTER_SERVICES',
  'NODE_DETAILS_BEFORE_SERVICES',
  'NODE_DETAILS_AFTER_SERVICES',
]

// const adsLocationsForActivities = [
//   'NEARBY_BEFORE_ACTIVITIES',
//   'NODE_DETAILS_BEFORE_ACTIVITIES',
//   'ACTIVITIES_LIST',
// ]

// const adsLocationsForNodes = [
//   'NODE_LIST'
// ]

const adsLocationsVIPOnly = [
  'NEARBY_VIP_HEADER',
  'NEARBY_VIP_AFTER_FILTERS',
  'NEARBY_VIP_MAP',
]

// const adsLocationsForEvents = [
//   'EVENT_DETAILS_BEFORE_INFO',
//   'EVENT_DETAILS_MAP',
//   'EVENT_DETAILS_AFTER_INFO',
// ]

interface InitialStateType {
  nearbyAdvertisement: AdvertisingType[]
  activityAdvertisement: AdvertisingType[]
  nodeAdvertisement: AdvertisingType[]
  VIPAdvertisement: AdvertisingType[]

  cruiseAdvertisement: AdvertisingType[]
  eventAdvertisement: AdvertisingType[]
}

const initialState: InitialStateType = {
  nearbyAdvertisement: [],
  activityAdvertisement: [],
  nodeAdvertisement: [],
  VIPAdvertisement: [],

  cruiseAdvertisement: [],
  eventAdvertisement: []
}

export const advertisementSlice = createSlice({
  name: 'advertisement',
  initialState,
  reducers: {
    setNodeAdvertisement: (state, action: PayloadAction<AdvertisingType[]>) => {state.nodeAdvertisement = action.payload},
    
    setCruiseAdvertisement: (state, action: PayloadAction<AdvertisingType[] | undefined>) => {state.cruiseAdvertisement = action.payload || []},

    clearAdvertisement: (state) => {
      state.nearbyAdvertisement = []
      state.activityAdvertisement = []
      state.nodeAdvertisement = []
      state.VIPAdvertisement = []
      state.cruiseAdvertisement = []
      state.eventAdvertisement = []
    },    
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetNearServicesThunk.fulfilled, (state, action) => {
        state.nearbyAdvertisement = action.payload.advertising_list?.filter(
          ad => !adsLocationsVIPOnly.includes(ad.place_type)
        ) || []
        state.VIPAdvertisement = action.payload.advertising_list?.filter(
          ad => adsLocationsVIPOnly.includes(ad.place_type)
        ) || []
      })
      .addCase(GetViatorAttractionsFilteredThunk.fulfilled, (state, action) => {
        state.activityAdvertisement = action.payload.advertising_list || []
      })
      .addCase(GetCruiseByLineAndDateThunk.fulfilled, (state, action) => {
        const data = action.payload as {cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType}
        if (data.cruises?.length > 1) {
          state.cruiseAdvertisement = data.cruises[0]?.advertising_list || []
        }
      })
      .addCase(GetCruisesByAdvancedSearchThunk.fulfilled, (state, action) => {
        const data = action.payload as {cruises: CruiseType[], sharedDetails?: SharedFlightCruiseDetailsType}
        if (data.cruises?.length > 1) {
          state.cruiseAdvertisement = data.cruises[0]?.advertising_list || []
        }
      })
      .addCase(GetEventByCodeThunk.fulfilled, (state, action) => {
        state.eventAdvertisement = action.payload.advertising_list || []
      })
  }
})

export const {
  setNodeAdvertisement,
  setCruiseAdvertisement,
  clearAdvertisement
} = advertisementSlice.actions

export const selectAdvertisement = (state: RootState): AdvertisingType[] => {
  let currentAds: AdvertisingType[] = []
   if (state.searchResults.activeResultCategories.includes('near')) {
    currentAds = state.advertisement.nearbyAdvertisement.filter(
      ad => state.searchResults.activeResultBlock === 'Activities'
        ? ad.place_type.toUpperCase().includes('ACTIVITIES_LIST')
        : !ad.place_type.toUpperCase().includes('ACTIVITIES_LIST')
    )
  } else if (
    state.searchResults.activeResultBlock === 'NodeDetails'
    || (state.searchResults.activeResultBlock === 'Activities' && !state.searchResults.activeResultCategories.includes('near'))
  ) {
    currentAds = state.advertisement.nodeAdvertisement.filter(
      ad => state.searchResults.activeResultBlock === 'Activities'
        ? ad.place_type.toUpperCase().includes('ACTIVITIES_LIST')
        : !ad.place_type.toUpperCase().includes('ACTIVITIES_LIST')
    )
  }
  return [...currentAds, ...state.advertisement.VIPAdvertisement]
}

export const selectAdvertisementMapComponent = (state: RootState): AdvertisingType[] => {
  const ads = selectAdvertisement(state)
  const cruiseAds = state.cruise.activeInfoBlock === 'cruiseDetails'
    ? state.advertisement.cruiseAdvertisement.filter(ads => {
      return state.cruise.cruiseInfoActiveTab === 'program'
        ? ads.place_type !== 'CRUISE_DESCRIPTION_MAP'
        : ads.place_type !== 'CRUISE_PROGRAM_MAP'
      })
    : []
  const eventAds = !!state.events.selectedEventByCode
    ? state.advertisement.eventAdvertisement.filter(ads => ads.place_type === 'EVENT_DETAILS_MAP')
    : []
  return [...ads, ...cruiseAds, ...eventAds].filter(a => adsLocationsForMap.includes(a.place_type))
}

export const selectAdvertisementActivitiesComponent = (state: RootState): AdvertisingType[] => {
  const ads = selectAdvertisement(state)
  return ads.filter(a => a.place_type.toUpperCase().includes('ACTIVITIES_LIST'))
}

export const selectAdvertisementActivitiesBtnComponent = (state: RootState): AdvertisingType[] => {
  const ads = selectAdvertisement(state)
  return ads.filter(a => a.place_type.toUpperCase().includes('BEFORE_ACTIVITIES'))
}

export const selectAdvertisementServicesComponent = (state: RootState): AdvertisingType[] => {
  const ads = selectAdvertisement(state)
  return ads.filter(a => adsLocationsForServices.includes(a.place_type))
}

export const selectAdvertisementVIP = (state: RootState): AdvertisingType[] => state.advertisement.VIPAdvertisement
export const selectVIPAdvertisementHeader = (state: RootState): AdvertisingType | undefined => {
  const adData: AdvertisingType | undefined = state.advertisement.VIPAdvertisement?.find(a => a.place_type.toUpperCase() === 'NEARBY_VIP_HEADER')
  return adData
}
export const selectVIPAdvertisementNearFilters = (state: RootState): AdvertisingType | undefined => {
  const adData: AdvertisingType | undefined = state.advertisement.VIPAdvertisement?.find(a => a.place_type.toUpperCase() === 'NEARBY_VIP_AFTER_FILTERS')
  return adData
}

export const selectCruiseAdvertisement = (state: RootState): AdvertisingType[] | undefined => {
  return state.advertisement.cruiseAdvertisement
}

export const selectEventAdvertisement = (state: RootState): AdvertisingType[] | undefined => {
  return state.advertisement.eventAdvertisement
}

export default advertisementSlice.reducer
 