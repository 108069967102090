import { Spin, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector, usePrevious } from '../../../../app/hooks'
import { decodeFlightCruiseSearchResultsExportLink, getFlightDataFromSearchString, validateSearchString } from '../../../../helpers/linksHelper'
import { GetAirportsByCityThunk } from '../../../../store/airportReducer'
import { GetBusesByCityThunk } from '../../../../store/busReducer'
import { clearSelectedStops as clearSelectedStopsCruise } from '../../../../store/cruiseReducer'
import { clearSelectedStops as clearSelectedStopsFlight} from '../../../../store/flightReducer'
import { GetPortsByCityThunk } from '../../../../store/portReducer'
import { GetRailwaysByCityThunk } from '../../../../store/railwayReducer'
import { clearSearchData, GetDataForResultTabsFromSharedLinkThunk, GetDataForResultTabsThunk, NodeServiceDetails, selectActiveTab, selectTabs, setActiveResultCategories, setActiveTab, setIsTerminalListOpen } from '../../../../store/searchResultsReducer'
import { GetNearServicesThunk } from '../../../../store/serviceReducer'
import { GetNodeTransportStopsThunk, setIsTransportStopsListOpen, setNodeTransportStops } from '../../../../store/transportStopsReducer'
import { selectActiveResultsTabKey, selectCurrentActiveTabData, SelectedCategoryTabKeyTypes, selectIsAdditionalLocationOpen, selectSelectedCategoryTabKey, selectStartEndPortSelected, SelectTabThunk, setCurrentActiveTabData, setSelectedCategoryTabKey, setSelectedStopTerminals, setStartEndPortSelected } from '../../../../store/travelSearchReducer'
import { SharedDetailsType } from '../../../../types/appTypes'
import { ResultTabTypes } from '../../../../types/searchResultTypes'
import Results from '../../../SearchResults/ResultTabs/Results/Results'
import { ReactComponent as CloseIcon } from './../../../../img/icons/close.svg'
import moment from 'moment'
import axios from './../../../../helpers/axiosHelper'
import { AdditionalSearchDataType } from '../../../../types/cruiseTypes'
import { setSelectedNodeTerminals } from '../../../../store/terminalReducer'

const ItemDetailsTabs: React.FC<ItemDetailsTabsPropTypes> = ({
  resultItem,
  children,
  getNodeData,
  requiredTabs,
  optionalTabs,
  searchData,
  setSelectedStops,
  type,
  updateStopCoordinatesAccordingToGoogle
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const tabs = useAppSelector(selectTabs)
  const activeTabKey = useAppSelector(selectActiveResultsTabKey)
  const activeCategoryTabKey = useAppSelector(selectSelectedCategoryTabKey)
  const activeTab = useAppSelector(selectActiveTab)
  const currentActiveTabData = useAppSelector(selectCurrentActiveTabData)
  const startEndPortSelected = useAppSelector(selectStartEndPortSelected)
  const isAdditionalLocationOpen = useAppSelector(selectIsAdditionalLocationOpen)

  const prevActiveTabName = usePrevious(activeTab?.name)

  const [isTabsLoading, setIsTabsLoading] = useState(false)
  const [isServicesLoading, setIsServicesLoading] = useState(false)

  const checkIfPlaceIdValid = (placeIds: string[], resp: any) => {
    let newLink
    if (placeIds.length === 2) {
      newLink = validateSearchString(type, search, {from: resp[0].placeId, to: resp[1].placeId})
    } else if (placeIds.length === 1) {
      newLink = validateSearchString(type, search, {[!!searchData?.from ? 'from'  : 'to']: resp[0].placeId})
    }
   
    if (search !== newLink) {
      navigate(`/travel/${type === 'byCruise' ? 'by-cruise' : 'by-flight'}/results` + newLink)
    }
  }

  const getDataForPlaceIds = async(placeIds: string[]) => {
    await Promise.all(placeIds?.map(placeId => geocodeByPlaceId(placeId)))
      .then(resp => {
        const getDataForTabs = async() => {
          if (search.includes('shared')) {
            const sharedData = decodeFlightCruiseSearchResultsExportLink(getFlightDataFromSearchString).sharedDetails
            const additionalInfo: SharedDetailsType = {
              activeTabName: sharedData?.activeTabKey!,
              activeResultCategories: sharedData?.activeResultCategories!,
              activeResultBlock: sharedData?.activeResultBlock!,
              selectedNodeId: sharedData?.selectedNodeId!,
              selectedTransportStop: sharedData?.selectedTransportStop!,
            }
            return dispatch(GetDataForResultTabsFromSharedLinkThunk({locations: [...resp.flat(1).map(item => item.formatted_address), ...requiredTabs.map(tab => tab.name)], additionalInfo}))
          } else {
            return dispatch(GetDataForResultTabsThunk({searchRequests: [...resp.flat(1).map(item => item.formatted_address), ...requiredTabs.map(tab => tab.name)], newActiveTab: {} as ResultTabTypes}))
          }
        }
        getDataForTabs()
          .then((resp) => {
            const tabs = (resp.payload as {tabs: ResultTabTypes[]})?.tabs
            updateStopCoordinatesAccordingToGoogle(tabs)
            if (!!searchData?.from || !!searchData?.to) {
              checkIfPlaceIdValid(placeIds, tabs)
            }
          })
    })
  }

  useEffect(() => {
    if (!tabs?.length && !isTabsLoading) {
      setIsTabsLoading(true)
      getDataForPlaceIds([
        ...(searchData?.from ? [searchData?.from] : []),
        ...(searchData?.to ? [searchData?.to] : []),
      ]).then(() => setIsTabsLoading(false))
    }
  // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if ((!!startEndPortSelected && startEndPortSelected !== null && currentActiveTabData !== null) || (isAdditionalLocationOpen && activeTab?.name !== prevActiveTabName)) {
      setIsServicesLoading(true)
      dispatch(GetNearServicesThunk({
        requestData: {
          latitude: activeTab.coordinates?.[0],
          longitude:activeTab.coordinates?.[1],
          radius: 2,
          unit: 'KM',
          place_id_list: [
            ...(!!activeTab?.placeId ? [activeTab?.placeId] : []),
            ...(activeTab?.placeId! !== activeTab?.countryPlaceId && !!activeTab?.countryPlaceId ? [activeTab?.countryPlaceId!] : [])
          ]
        },
        source
      })).then(() => setIsServicesLoading(false)) 
        .then(() => {
          Promise.all([
            dispatch(GetAirportsByCityThunk({activeTabData: activeTab, departure_date: searchData?.date, source})),
            dispatch(GetPortsByCityThunk({activeTabData: activeTab, source})),
            dispatch(GetBusesByCityThunk({activeTabData: activeTab, source})),
            dispatch(GetRailwaysByCityThunk({activeTabData: activeTab, source})),
          ])
        }) 
    } else if (startEndPortSelected === null && currentActiveTabData !== null) {
      setIsServicesLoading(true)
      dispatch(GetNearServicesThunk({
        requestData: {
          latitude: currentActiveTabData?.location?.latitude!,
          longitude:currentActiveTabData?.location?.longitude!,
          radius: 2,
          unit: 'KM',
          place_id_list: [...(!!currentActiveTabData?.placeId ? [currentActiveTabData?.placeId] : []),]
        },
        source
      })).then(() => setIsServicesLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, currentActiveTabData, activeTab])

  useEffect(() => {
    return () => {
      dispatch(clearSearchData())
      dispatch(setSelectedCategoryTabKey('general'))
      dispatch(clearSelectedStopsCruise())
      dispatch(clearSelectedStopsFlight())
      dispatch(setCurrentActiveTabData(null))
    }
  }, [dispatch])

  const selectTab = (key: string, isFromToLocation?: boolean) => {
    const requiredTabData = requiredTabs.find(tab => tab.name === key)
    const optionalTabData = optionalTabs?.find(tab => tab.id === key)
    const isDeparture = moment(requiredTabData?.departure_time).isBefore(requiredTabData?.arrival_time) || (requiredTabs[0]?.name === key && requiredTabs?.length > 1)
    const isArrival = requiredTabs[requiredTabs.length - 1]?.name === key
    if (isDeparture) {
      dispatch(setStartEndPortSelected('departure'))
    } else if (isArrival) {
      dispatch(setStartEndPortSelected('arrival'))
    } else {
      dispatch(setStartEndPortSelected(null))
    }
    dispatch(setIsTransportStopsListOpen(false))
    dispatch(setNodeTransportStops(null))
    dispatch(setSelectedStopTerminals(null)) 
    dispatch(setSelectedNodeTerminals(null))
    dispatch(setIsTerminalListOpen(false))
    dispatch(SelectTabThunk(key, getNodeData, searchData!, {nodeId: requiredTabData?.id || optionalTabData?.port?.id, newActiveResultCategories: undefined, isFromToLocation: !!isFromToLocation}))
  }

  const handleEditTab = (key: string, action: 'remove' | 'add') => {
    if (action === 'remove') {
      selectTab(requiredTabs[0].name)
    }
    setSelectedStops(+key)
  }

  const onTransportStopCategorySelect = (key: string, isOptionalTab: boolean) => {
    let nodeData
    if (isOptionalTab) {
      nodeData = getNodeData(String(key))!
    } else {
      const tabData = requiredTabs.find(tab => tab.name === key)
      nodeData = getNodeData(String(tabData?.id)!)
    }
    return dispatch(GetNodeTransportStopsThunk({location: {latitude: nodeData?.location?.latitude!, longitude: nodeData?.location?.longitude!}, radius: 1000, isPort: isOptionalTab}))//nodeData?.type === 'port'}))
  }

  const tabsData = requiredTabs

  if (isTabsLoading) {
    return (
      <Spin/>
    )
  }

  return (
    <div>
      <Tabs
        activeKey={activeCategoryTabKey}
        onChange={(key) => {
          dispatch(setSelectedCategoryTabKey(key as SelectedCategoryTabKeyTypes))
          dispatch(setSelectedStopTerminals(null)) 
          dispatch(setSelectedNodeTerminals(null))
          dispatch(setIsTerminalListOpen(false))
          dispatch(setIsTransportStopsListOpen(false))
          dispatch(setNodeTransportStops(null))
          if (key === 'general') {
            dispatch(setActiveResultCategories(['near']))
            dispatch(setActiveTab({} as ResultTabTypes))
            dispatch(setCurrentActiveTabData(null))
          }
          const tabKey = key === 'general'
            ? '1'
            : key === 'stops' 
              ? tabsData[0].name
              : searchData?.from
                ? tabs.find(tab => tab.placeId === searchData?.from)?.name
                : tabs.find(tab => tab.placeId === searchData?.to)?.name
          selectTab(tabKey, key === 'additionalLocations')
        }}
        type='editable-card'
        hideAdd
      >
        <Tabs.TabPane tab={resultItem?.tripNumber} key='general' closable={false}>
          {children}
        </Tabs.TabPane>

        <Tabs.TabPane tab={type === 'byCruise' ? 'Cruise Ports' : 'Airports'} key='stops' closable={false}>
          <Tabs
            activeKey={activeTabKey}
            onChange={(key) => selectTab(key)}
          >
            {tabsData?.map((stop:any) => (
              <Tabs.TabPane tab={stop.name} key={stop.name} closable={false}>
                <NodeLocationTabs
                  onTransportStopCategorySelect={() => onTransportStopCategorySelect(stop.name, false)}
                  stop={stop}
                  isServicesLoading={isServicesLoading}
                />
              </Tabs.TabPane>
            ))}
            {optionalTabs?.map((stop:any) => {
              return (
              <Tabs.TabPane
                tab={
                  <div>
                    {stop?.name}
                    <CloseIcon
                      onClick={(e) => {
                        e.stopPropagation()
                        handleEditTab(stop.id, 'remove')
                      }}
                    />
                  </div>
                }
                key={stop?.id}
              >
                <Results
                  mapCenter={[0, 0]}
                  showOnlyResults
                  onTransportStopCategorySelect={() => onTransportStopCategorySelect(stop?.id, true)}
                  showCityResults={
                    stop?.port?.port_type === 'WAYPOINT'
                      ? 'activitiesOnly'
                      : 'nearbyOnly'
                  }
                  handleGoBack={() => dispatch(setSelectedCategoryTabKey('general'))}
                  multipleExternalLinks={[{
                    title: '',
                    terminals: stop?.port?.terminals,
                    dataForLinks: {
                      id: stop?.port?.id,
                      name: stop?.port?.name,
                      type: 'port',
                      location: {
                        latitude: stop?.port?.latitude,
                        longitude: stop?.port?.longitude,
                      },
                    },
                    dates: startEndPortSelected === null
                      ? [moment(stop?.arrival_time) || '', moment(stop?.departure_time).isSame(moment(stop?.arrival_time), 'day') ? moment(stop?.arrival_time).add(1, 'days') : moment(stop?.departure_time) || '']
                      : startEndPortSelected === 'departure'
                        ? [moment(stop?.departure_time).subtract(1, 'days'), moment(stop?.departure_time)]
                        : [moment(stop?.arrival_time), moment(stop?.arrival_time).add(1, 'days')],
                    showLinks: false
                  }]}
                  isServicesLoading={isServicesLoading}
                />
              </Tabs.TabPane>
            )})}
          </Tabs>
        </Tabs.TabPane>

        {(!!searchData?.from || !!searchData?.to) && 
          <Tabs.TabPane tab={'Additional locations'} key='additionalLocations' closable={false}>
            <Tabs
              activeKey={activeTabKey}
              onChange={(key) => selectTab(key, true)}
              onEdit={(key, action) => handleEditTab(key as string, action)}
              hideAdd
            >
              {searchData?.from &&
                <Tabs.TabPane tab={tabs.find(tab => tab.placeId === searchData?.from)?.name} key={tabs.find(tab => tab.placeId === searchData?.from)?.name} closable={false}>
                  <NodeLocationTabs isServicesLoading={isServicesLoading}/>
                </Tabs.TabPane>
              }
              {!!searchData?.to && searchData?.to !== searchData?.from &&
                <Tabs.TabPane tab={tabs.find(tab => tab.placeId === searchData?.to)?.name || ''} key={tabs.find(tab => tab.placeId === searchData?.to)?.name || ''} closable={false}>
                  <NodeLocationTabs isServicesLoading={isServicesLoading}/>
                </Tabs.TabPane>
              }
            </Tabs>
          </Tabs.TabPane>
        }
      </Tabs>
    </div>
  )
}

const NodeLocationTabs: React.FC<{onTransportStopCategorySelect?: () => Promise<any>, stop?: any, isServicesLoading: boolean}> = ({
  onTransportStopCategorySelect,
  stop,
  isServicesLoading
}) => {
  const startEndPortSelected = useAppSelector(selectStartEndPortSelected)
  return (
    <Results
      mapCenter={[0, 0]}
      showOnlyResults
      showCityResults='fullResults'
      onTransportStopCategorySelect={onTransportStopCategorySelect}
      isServicesLoading={isServicesLoading}
      multipleExternalLinks={stop?.port ? [
        {
          title: 'NEAR THE PORT',
          dataForLinks: {
            id: stop?.port?.id,
            name: stop?.port?.name,
            type: 'port',
            location: {
              latitude: stop?.port?.latitude,
              longitude: stop?.port?.longitude,
            }
          },
          dates: startEndPortSelected === null
            ? [moment(stop?.arrival_time) || '', moment(stop?.departure_time).isSame(moment(stop?.arrival_time), 'day') ? moment(stop?.arrival_time).add(1, 'days') : moment(stop?.departure_time) || '']
            : startEndPortSelected === 'departure'
              ? [moment(stop?.departure_time).subtract(1, 'days'), moment(stop?.departure_time)]
              : [moment(stop?.arrival_time), moment(stop?.arrival_time).add(1, 'days')],
          showLinks: true,
          terminals: stop?.port?.terminals || [],
        },
        {
          title: 'IN THE CITY',
          dataForLinks: {
            id: stop?.port?.city?.id,
            name: stop?.port?.city?.name,
            type: 'port',
            location: {
              latitude: stop?.port?.city?.latitude,
              longitude: stop?.port?.city?.longitude,
            }
          },
          dates: startEndPortSelected === null
            ? [moment(stop?.arrival_time) || '', moment(stop?.departure_time).isSame(moment(stop?.arrival_time), 'day') ? moment(stop?.arrival_time).add(1, 'days') : moment(stop?.departure_time) || '']
            : startEndPortSelected === 'departure'
              ? [moment(stop?.departure_time).subtract(1, 'days'), moment(stop?.departure_time)]
              : [moment(stop?.arrival_time), moment(stop?.arrival_time).add(1, 'days')],
          showLinks: true
        }
      ] : [
        {
          title: '',
          dataForLinks: {
            id: stop?.id,
            name: stop?.name,
            type: 'airport',
            location: {
              latitude: stop?.latitude,
              longitude: stop?.longitude,
            }
          },
          dates: stop?.departure_date
            ? [moment(stop?.departure_date).subtract(1, 'days'), moment(stop?.departure_date)]
            : [moment(stop?.arrival_date), moment(stop?.arrival_date).add(1, 'days')],
          showLinks: true
        }
      ]}
    />
  )
}

interface ItemDetailsTabsPropTypes {
  resultItem: {
    tripNumber: string
    stops: any[]
  }
  children: any
  getNodeData: (key: string) => NodeServiceDetails
  requiredTabs: any[]
  optionalTabs?: any[]
  searchData?: AdditionalSearchDataType
  setSelectedStops: (key: number) => void
  type: 'byFlight' | 'byCruise'
  updateStopCoordinatesAccordingToGoogle: (tabsData: any[]) => void
}

export default ItemDetailsTabs
