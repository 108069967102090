import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Button, Divider, Modal, Select, Spin, Tag } from 'antd'
import { ReactComponent as FiltersIcon } from './../../../../../img/icons/filters.svg'
import classes from './CruiseForPortFilters.module.css'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { GetPortsByNameOrCodeThunk, selectPortCruisesFilters, selectPortOptions, setPortCruisesFiltersData, setPortOptions } from '../../../../../store/portReducer'
import axios from './../../../../../helpers/axiosHelper'
import { InputGoogleAPI } from '../../../../common/InputGoogleAPI/InputGoogleAPI'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { selectDistanceType } from '../../../../../store/appStatusReducer'

const CruiseForPortFilters = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className={classes.filtersBtnWrapper} onClick={() => setIsModalOpen(true)}>
        <FiltersIcon /> Filters
      </div>
      <FiltersModal
        isOpen={isModalOpen}
        handleClose={handleClose}
      />
    </>
  )
}

const FiltersModal: React.FC<{isOpen: boolean, handleClose: () => void}> = ({isOpen, handleClose}) => {
  const dispatch = useAppDispatch()
  const portCruisesFilters = useAppSelector(selectPortCruisesFilters)
  const distanceType = useAppSelector(selectDistanceType)

  const [selectedFilters, setSelectedFilters] = useState(portCruisesFilters.filter)

  const handleClearAll = () => {
    setSelectedFilters({
      visited_port_id_list: [],
      avoid_port_id_list: [],
      locations: []
    })
  }

  const handleDone = () => {
    dispatch(setPortCruisesFiltersData({
      filter: {
        ...portCruisesFilters.filter,
        ...selectedFilters
      },
      pagination: {...portCruisesFilters.pagination, page: 1}
    }))
    handleClose()
  }

  const handleChange = (updatedValue: any) => {
    setSelectedFilters({...selectedFilters, ...updatedValue})
  }

  const onLocationChange = async(locationName: string) => {
    if (selectedFilters.locations?.some(loc => loc.name === locationName)) {
      return
    }
    const addressData = await geocodeByAddress(locationName)
    const coordinates = await getLatLng(addressData[0])
    handleChange({locations: [
      ...(selectedFilters.locations || []),
      {latitude: coordinates.lat, longitude: coordinates.lng, name: locationName}
    ]})
  }
  
  return (
    <Modal
      visible={isOpen}
      onCancel={handleClose}
      footer={null}
      bodyStyle={{
        padding:'0px',
        overflow: 'auto',
        minHeight: '200px',
      }}
      centered
      className={classes.filtersWindow}
      title={<div style={{fontWeight: 600, textAlign: 'center'}}>Filters</div>}
    >
      <div className={classes.filterWrapper} style={{marginTop: '20px'}}>
        <div className={classes.label}>
          Port visited
        </div>
        <FiltersMultipleSelect
          onChange={(val: {value: number, label: string}[]) => handleChange({visited_port_id_list: val})}
          value={selectedFilters.visited_port_id_list || []}
          avoidOptions={selectedFilters?.avoid_port_id_list || []}
        />
      </div>

      <Divider style={{margin: '0px 10px 20px 10px', width: 'auto'}}/>

      <div className={classes.filterWrapper}>
        <div className={classes.label}>
          Avoid ports
        </div>
        <FiltersMultipleSelect
          onChange={(val: {value: number, label: string}[]) => handleChange({avoid_port_id_list: val})}
          value={selectedFilters.avoid_port_id_list || []}
          avoidOptions={selectedFilters?.visited_port_id_list || []}
        />
      </div>

      <Divider style={{margin: '0px 10px 20px 10px', width: 'auto'}}/>

      <div className={classes.filterWrapper} style={{marginBottom: '20px'}}>
        <div className={classes.label}>
          Location visited
          <span style={{color: '#667085', fontSize: '14px', fontWeight: 600, marginLeft: '6px'}}>
            (Local port search within {distanceType === 'km' ? '100 km' : '62.1 miles'} radius)
          </span>
        </div>
        <InputGoogleAPI
          placeholder='Start typing location'
          onChange={(val) => val?.length && onLocationChange(val)}
          clearAfterSelect
        />
        <div style={{marginTop: '10px'}}>
          {selectedFilters?.locations?.map(l => (
            <Tag
              closable
              onClose={() => handleChange({locations: selectedFilters.locations?.filter(loc => loc.name !== l.name)})}
            >
              {l.name}
            </Tag>
          ))}
        </div>
      </div>

      <div className={classes.filtersFooter}>
        <Button onClick={handleClearAll} type='text'>
          Clear all
        </Button>
        <Button type='primary' onClick={handleDone}>
          Done
        </Button>
      </div>
    </Modal>
  )
}

const FiltersMultipleSelect: React.FC<FiltersMultipleSelectPropTypes> = ({onChange, value, avoidOptions}) => {
  const dispatch = useAppDispatch()
  const portOptions = useAppSelector(selectPortOptions)

  const [isLoading, setIsLoading] = useState(false)
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if (!!searchString.length) {
      setIsLoading(true)
      dispatch(GetPortsByNameOrCodeThunk({name: searchString, source}))
        .then((resp) => {
          !resp.type.includes('rejected') && setIsLoading(false)
        })
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, searchString])

  // eslint-disable-next-line
  const onSearch = useCallback(
    debounce((val) => {
      setSearchString(val)
    }, 400), []
  )

  const onSelect = (selectedValue: {value: number, label: string}) => {
    onChange([...value, selectedValue])
    setSearchString('')
    dispatch(setPortOptions(null))
  }

  const onDeselect = (deselectedId: number) => {
    onChange(value.filter(opt => opt.value !== deselectedId))
  }

  return (
    <div>
      <Select
        showSearch
        style={{width: '100%', marginBottom: '25px'}}
        placeholder='Start typing port name or code'
        onSearch={searchQuery => onSearch(searchQuery)}
        onSelect={(val:{value: number, label: string}) => onSelect({value: val.value, label: val.label})}
        onDeselect={(val:{value: number}) => onDeselect(val.value)}
        // searchValue={searchString}
        labelInValue
        placement='bottomLeft'
        // onFocus={onInputFocus}
        filterOption={false}
        dropdownStyle={{bottom: '10px'}}
        value={value}
        onBlur={() => {
          setSearchString('')
          dispatch(setPortOptions(null))
        }}
        mode='multiple'
        // getPopupContainer={() => document.getElementById('select')!}
        notFoundContent={isLoading ? (
          <Spin size='small' />
        ) : (
          <>
            {!!searchString.length && !portOptions?.filter(opt => !avoidOptions?.some(avoidOpt => avoidOpt.value === opt.id))?.length && 'No results found'}
            {!searchString.length && portOptions === null && 'Start typing line name'}
          </>
        )}
      >
        {portOptions?.filter(opt => !avoidOptions?.some(avoidOpt => avoidOpt.value === opt.id))?.map(opt => (
          <Select.Option value={opt.id} key={opt.id}>
            {opt.name} ({opt.code})
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

interface FiltersMultipleSelectPropTypes {
  onChange: (val: {value: number, label: string}[]) => void
  value: {label: string, value: number}[]
  avoidOptions: {label: string, value: number}[]
}

export default CruiseForPortFilters
