import classes from './ServiceBanner.module.css'
import {ReactComponent as ServiceBannerDecoration} from './../../../../img/serviceBannerDecoration.svg'
import { useAppSelector } from '../../../../app/hooks'
import { selectServiceCategories } from '../../../../store/serviceReducer'

const ServiceBanner: React.FC<{serviceData: ServiceDataType}> = ({serviceData}) => {
  if (serviceData.banner_html) {
    return  <div className={classes.bannerFromCodeWrapper}  dangerouslySetInnerHTML={{__html: serviceData.banner_html}}></div>
  }
  return (
    serviceData.webLink ? (
      <a
        href={serviceData.webLink}
        target='_blank'
        rel='noreferrer'
      >
        <BannerInnerContent serviceData={serviceData}/>
      </a>
    ) : (
      <BannerInnerContent serviceData={serviceData}/>
    )
  )
}

const BannerInnerContent: React.FC<{serviceData: ServiceDataType}> = ({serviceData}) => {
  const serviceCategories = useAppSelector(selectServiceCategories)
  const backgroundColor = serviceData.banner_background_color ? serviceData.banner_background_color : 'rgba(87,190,192,0.1)'

  return (
    <div
      className={classes.wrapper}
      style={{backgroundColor}}
    >
      <div>
        {serviceData.logo
          ? <img src={serviceData.logo} alt={serviceData.name} className={classes.logo}/>
          : <div className={classes.logo}></div>
        }
      </div>
      <div className={classes.mainDataWrapper}>
        <div className={classes.name}>
          {serviceData.name}
          <span className={classes.category}>
            {serviceCategories.find(c => c.service_category_id === serviceData.categoryId)?.name}
          </span>
        </div>
        <div className={classes.description}>
          {serviceData.details}
        </div>
        <div className={classes.contacts}>
          {serviceData?.phones?.join(', ')}
        </div>
        <ServiceBannerDecoration className={classes.decoration1} />
        <ServiceBannerDecoration className={classes.decoration2} />
      </div>
    </div>
  )
}

interface ServiceDataType {
  id: number
  name: string
  details?: string
  webLink?: string
  logo?: string
  phones?: string[]
  banner_background_color?: string | null
  banner_html?: string
  categoryId: number
}

export default ServiceBanner
