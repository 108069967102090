import { Button, Checkbox, DatePicker, Spin, Tabs } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment, { Moment } from 'moment'
import Breadcrumbs from '../../../../common/Breadcrumbs/Breadcrumbs'
import Footer from '../../../../common/Footer/Footer'
import Header from '../../../../common/Header/Header'
import classes from './AirportList.module.css'
import { useAppDispatch, useAppSelector, usePrevious } from '../../../../../app/hooks'
import { clearAdvancedSearchRequest, selectAdvancedSearchRequest, setAdvancedSearchRequest } from '../../../../../store/flightReducer'
import { getAdvanceFlightSearchUrl, getDataFromAdvanceFlightSearchUrl } from '../../../../../helpers/linksHelper'
import { GetAirportsByCityThunk } from '../../../../../store/airportReducer'
import { GetDataForResultTabsThunk, resetTabsData, selectActiveTab, selectSearchResults, selectTabs, setActiveResultBlock, setActiveResultCategories, setActiveTab} from '../../../../../store/searchResultsReducer'
import GoogleMap from '../../../../SearchResults/Map/GoogleMap'
import { ReactComponent as ShowMapIcon } from './../../../../../img/icons/showMapIcon.svg'
import { ReactComponent as DatePickerCalendar } from './../../../../../img/icons/datePickerCalendar.svg'
import { getMarkerSvgIconGoogleMap, markerSizes } from '../../../../../helpers/mapMarkerHelper'
import { selectDistanceType } from '../../../../../store/appStatusReducer'

const AirportList = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()
  const advancedSearchRequest = useAppSelector(selectAdvancedSearchRequest)
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)?.name

  const [isLoading, setIsLoading] = useState(true)
  const [selectedAirportIds, setSelectedAirportIds] = useState<{[key: string]: number[]}>({1: [], 2: []})

  const prevActiveTab = usePrevious(activeTab) || ''

  useEffect(() => {
    if (!advancedSearchRequest?.from) {
      // example: ?New%20York%2C%20NY%2C%20USA-oneway-flight-to-New%20York%2C%20NY%2C%20USA-flight-date-2024-02-28
      const data = getDataFromAdvanceFlightSearchUrl(search)
      dispatch(setAdvancedSearchRequest(data))
      if (!!data?.airports) {
        setSelectedAirportIds(data?.airports)
      }
    } else if (!tabs?.length) {
      dispatch(GetDataForResultTabsThunk({
        searchRequests: [advancedSearchRequest.from, `${advancedSearchRequest.to}-from-${advancedSearchRequest.startDate.replaceAll('-', '')}-to-${advancedSearchRequest.endDate.replaceAll('-', '')}-adults-1-children-0`],
        saveSearchTracking: false
      }))
      dispatch(setActiveResultBlock('Nodes'))
      dispatch(setActiveResultCategories(['airports']))
    }
  }, [dispatch, advancedSearchRequest, search, tabs])

  useEffect(() => {
    if ((activeTab && prevActiveTab && activeTab !== prevActiveTab) || (!!activeTab && !prevActiveTab)) {
      setIsLoading(true)
      dispatch(GetAirportsByCityThunk({activeTabData: tabs?.find(t => t.name === activeTab)}))
        .then(() => setIsLoading(false))
    }
    // eslint-disable-next-line
  }, [activeTab])

  useEffect(() => {
    return () => {
      dispatch(clearAdvancedSearchRequest())
      dispatch(resetTabsData())
      dispatch(setActiveResultBlock('Services'))
      dispatch(setActiveResultCategories(['near']))
    }
  }, [dispatch])

  const onSelectedChange = (locationOrder: string, id: number) => {
    let updatedValue
    if (selectedAirportIds?.[locationOrder]?.includes(id)) {
      updatedValue = selectedAirportIds?.[locationOrder]?.filter(aId => aId !== id)
    } else {
      updatedValue =[...(selectedAirportIds?.[locationOrder] || []), id]
    }
    setSelectedAirportIds({
      ...selectedAirportIds,
      [locationOrder]: updatedValue
    })
  }

  const goNextStep = () => {
    if (activeTab === advancedSearchRequest.from) {
      dispatch(setActiveTab(tabs?.find(t => t.name === advancedSearchRequest.to)!))
    } else {
      navigate(getAdvanceFlightSearchUrl('summary' ,{...advancedSearchRequest, airports: selectedAirportIds}))
    }
  }
  
  return (
    <>
      <div className={classes.wrapper}>
        <Header showMenu={true} className={classes.header}/>
        <div className={classes.breadcrumbsWrapper}>
          <Breadcrumbs />
        </div>

        {!!activeTab &&
          <Tabs
            activeKey={activeTab || ''}
            onTabClick={(key) => dispatch(setActiveTab(tabs?.find(t => t.name === key)!))}
            type='editable-card'
            className={classes.tabs}
            hideAdd={true}
            renderTabBar={(tabBarProps) => (
              <div className={classes.tabsWrapper} style={{ display: 'flex' }}>
                <div
                  style={{cursor: 'default'}}
                  className={`${classes.tab} ${activeTab === advancedSearchRequest?.from && classes.active}`}
                  onClick={(e) => tabBarProps.onTabClick(advancedSearchRequest?.from, e)}
                >
                  <h2>{advancedSearchRequest.from}</h2>
                </div>
                <div
                  style={{
                    cursor: !selectedAirportIds?.[String(tabs?.findIndex(t => t.name === advancedSearchRequest.from) + 1)]?.length
                      ? 'not-allowed'
                      : 'default'
                  }}
                  className={`${classes.tab} ${activeTab === advancedSearchRequest?.to && classes.active}`}
                  onClick={(e) => !selectedAirportIds?.[String(tabs?.findIndex(t => t.name === advancedSearchRequest.from) + 1)]?.length
                    ? {}
                    : tabBarProps.onTabClick(advancedSearchRequest?.to, e)
                  }
                >
                  <h2>{advancedSearchRequest.to}</h2>
                </div>
              </div>
            )}
          >
            <Tabs.TabPane tab={advancedSearchRequest.from} key={advancedSearchRequest.from} closable={false}>
              {isLoading
                ? <Spin style={{width: '100%'}}/>
                : <Results
                  locationOrder={String(tabs?.findIndex(t => t.name === advancedSearchRequest.from) + 1)}
                  selectedAirportIds={selectedAirportIds?.[String(tabs?.findIndex(t => t.name === advancedSearchRequest.from) + 1)] || []}
                  onSelectedChange={onSelectedChange}
                  goNextStep={goNextStep}
                />
              }
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={advancedSearchRequest.to}
              key={advancedSearchRequest.to}
              closable={false}
              disabled={!selectedAirportIds?.[String(tabs?.findIndex(t => t.name === advancedSearchRequest.from) + 1)]?.length}
            >
              {isLoading
                ? <Spin style={{width: '100%'}}/>
                : <Results
                  locationOrder={String(tabs?.findIndex(t => t.name === advancedSearchRequest.to) + 1)}
                  selectedAirportIds={selectedAirportIds?.[String(tabs?.findIndex(t => t.name === advancedSearchRequest.to) + 1)] || []}
                  onSelectedChange={onSelectedChange}
                  goNextStep={goNextStep}
                />
              }
            </Tabs.TabPane>
          </Tabs>
        }
      </div>
      <Footer />
    </>
  )
}

const Results:React.FC<{
  locationOrder: string,
  selectedAirportIds: number[],
  onSelectedChange: (locationOrder: string, id: number) => void
  goNextStep: () => void
}> = ({locationOrder, selectedAirportIds, onSelectedChange, goNextStep}) => {
  const dispatch = useAppDispatch()
  const distanceType = useAppSelector(selectDistanceType)
  const searchResults = useAppSelector(selectSearchResults)
  const activeTab = useAppSelector(selectActiveTab)
  const advancedSearchRequest = useAppSelector(selectAdvancedSearchRequest)
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  const [openMapMobileView, setOpenMapMobileView] = useState(false)

  const handleDateChange = (val: [Moment, Moment]) => {
    const updatedData = {
      ...advancedSearchRequest,
      startDate: val[0]?.format('YYYY-MM-DD'),
      endDate: val[1]?.format('YYYY-MM-DD')
    }
    dispatch(setAdvancedSearchRequest(updatedData))
    window.history.replaceState(null, '', getAdvanceFlightSearchUrl('airports', updatedData))
  }

  return (
    <>   
      <div className={classes.results}>
        <div>
          {advancedSearchRequest?.type === 'oneway' ? (
            <DatePicker
              value={moment(advancedSearchRequest?.startDate)}
              style={{width: '100%', marginBottom: '20px'}}
              allowClear={false}
              suffixIcon={<DatePickerCalendar/>}
              onChange={(val) => handleDateChange([val!, val!])}
              disabledDate={(current) => current.startOf('day').isBefore(moment().startOf('day'))}
            />
          ) : (
            <DatePicker.RangePicker
              value={[moment(advancedSearchRequest?.startDate), moment(advancedSearchRequest?.endDate)]}
              style={{width: '100%', marginBottom: '20px'}}
              allowClear={false}
              suffixIcon={<DatePickerCalendar/>}
              onChange={(val) => handleDateChange(val! as [Moment, Moment])}
              disabledDate={(current) => current.startOf('day').isBefore(moment().startOf('day'))}
            />
          )}
          <div className={openMapMobileView ? classes.hiddenResults : classes.blockWrapper}>
            <div style={{color: '#191559', fontSize: '18px', fontWeight: 600, marginBottom: '10px'}}>
              Check each airport to add to the list of airports for price comparison
              <div style={{fontSize: '12px'}}>
                (Limit: 3 airports)
              </div>
            </div>
            
            <div style={{paddingBottom: '10px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <Button
                type='primary'
                style={{width: '100%'}}
                disabled={!selectedAirportIds?.length}
                onClick={goNextStep}
              >
                Next
              </Button>
              {!selectedAirportIds?.length &&
                <div style={{fontSize: '12px', color: 'gray', marginTop: '6px'}}>
                  You need to select at least one airport for each location
                </div>
              }
            </div>
            
            <div style={{color: '#0068FF', fontSize: '18px', fontWeight: 700}}>
              Airports
            </div>
            {searchResults?.airports?.map((airport, index) => (
              <div
                key={airport?.details?.id}
                className={`${classes.airportCard} ${airport?.details?.id}`} 
                id={String(airport?.details.id)}
              >
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <div className={classes.title}>
                    <div>
                      <span style={{marginRight: '10px'}}>
                        {airport?.details?.name}
                        {!!airport?.details?.code &&
                          <span style={{marginLeft: '6px'}}>
                            ({airport?.details?.code})
                          </span>
                        }
                      </span>
                      {!!airport?.details?.terminals?.length &&
                        <span className={classes.terminals}>
                          {airport?.details?.terminals?.length} {airport?.details?.terminals?.length > 1 ? 'Terminals' : 'Terminal'}
                        </span>
                      }
                    </div>
                  </div>
                  <div style={{fontSize: '14px'}}>
                    {`${airport?.details?.city?.name}${airport?.details?.city?.state_name ? ', '+airport?.details?.city?.state_name + (airport?.details?.city?.country_name ? ', ' : '') : ''} ${airport?.details?.city?.country_name || ''} ${airport?.details?.distance ? '(' + (distanceType === 'km' ? airport?.details?.distance.toFixed(0) : (airport?.details?.distance / 1.609).toFixed(0)) + distanceType + ')' : ''}`}
                  </div>
                </div>
                <img
                  src={getMarkerSvgIconGoogleMap({...airport, type: 'airport'}, index! + 1)}
                  alt=''
                  style={{
                    height: markerSizes[airport?.details?.category || 0][0],
                    width: markerSizes[airport?.details?.category || 0][1],
                    justifySelf: 'center'
                  }}
                />
                <Checkbox
                  style={{justifySelf: 'end'}}
                  checked={selectedAirportIds?.some(id => id === airport?.details?.id)}
                  onChange={() => onSelectedChange(locationOrder, airport?.details?.id)}
                  disabled={!selectedAirportIds?.some(id => id === airport?.details?.id) && selectedAirportIds?.length === 3}
                />
              </div>
            ))}
          </div>
        </div>
        <>
          <div className={classes.mapWrapper}>
            {!isSmallScreen && activeTab?.coordinates && 
              <GoogleMap
                showCenter
                center={activeTab?.coordinates as [number, number]}
                isNodeDetailsOpen={false}
                ignoreCenterChange
              />
            }
          </div>
          {isSmallScreen && activeTab?.coordinates &&
            <GoogleMap
              showCenter
              center={activeTab?.coordinates as [number, number]}
              isNodeDetailsOpen={false}
              smallScreenHandleShowList={() => setOpenMapMobileView(false)}
              isSmallScreenOpen={openMapMobileView}
              ignoreCenterChange
            />
          }
          {!openMapMobileView && 
            <div 
              className={classes.showMapSmallScreenBtn}
              onClick={() => setOpenMapMobileView(true)}
            >
              <ShowMapIcon />View map
            </div>
          }
        </>
      </div>
      {/* <Link to='/' style={{display: 'flex', justifyContent: 'center'}}>
        <Button
          type='primary'
          style={{marginBottom: '50px', width: '160px'}}
          disabled={!selectedAirportIds?.length}
        >
          Next
        </Button>
      </Link> */}
    </>
  )
}

export default AirportList
