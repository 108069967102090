import { Button, Checkbox, Form, Input, message, Modal, Rate } from 'antd'
import { useState } from 'react'
import classes from './Feedback.module.css'
import { UserFeedbackType } from '../../../types/userTypes'
import { omit } from 'lodash'
import { SendUserFeedbackThunk } from '../../../store/userReducer'
import { useAppDispatch } from '../../../app/hooks'

const Feedback = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  return (
    <>
     <div className={classes.feedbackBtn} onClick={() => setIsFeedbackModalOpen(true)}>
        Send Feedback
      </div>
      {isFeedbackModalOpen &&
        <FeedbackModal onCancel={() => setIsFeedbackModalOpen(false)}/>
      }
    </>
  )
}

const FeedbackModal: React.FC<{onCancel: () => void}> = ({onCancel}) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  
  const sendFeedback = (data: FormDataType) => {
    setIsLoading(true)
    dispatch(SendUserFeedbackThunk(omit(data, ['privacy_policy'])))
      .then((resp) => {
        setIsLoading(false)
        if (!resp.type.includes('rejected')) {
          message.success('Thank you for your feedback!')
          onCancel()
        }  
      })
  }

  return (
    <Modal
      visible
      onCancel={onCancel}
      footer={null}
      width='600px'
    >
      <Form
        name='feedback'
        onFinish={sendFeedback}
        autoComplete='off'
        validateTrigger='onBlur'
        className={classes.form}
      >
        <h2>
          Feedback
        </h2>

        <div className={classes.label}>
          Full Name
        </div>
        <Form.Item
          name='sender_name'
          rules={[
            { required: true, message: 'Please enter your name!' },
          ]}
        >
          <Input placeholder='Enter your name'/>
        </Form.Item>

        <div className={classes.label}>
          Email Address
        </div>
        <Form.Item
          name='sender_email'
          rules={[
            { required: true, message: 'Please enter your email!' },
            { type: 'email', message: 'Invalid e-mail!' },
          ]}
        >
          <Input placeholder='Enter your email'/>
        </Form.Item>

        <div className={classes.additionalLabel}>
          Your service rating
        </div>
        <Form.Item name='service_rating' rules={[{ required: true, message: 'Please rate!' }]}>
          <Rate style={{ fontSize: 36 }} allowClear={false}/>
        </Form.Item>
     
        <div className={classes.additionalLabel}>
          Additional feedback
        </div>
        <Form.Item name='additional'>
          <Input.TextArea
            placeholder='If you have any additional feedback, please type it in here...'
            rows={3}
          />
        </Form.Item>

        <div style={{display: 'flex', alignItems: 'center'}}>
          <Form.Item
            name='privacy_policy'
            valuePropName='checked'
            rules={[{ required: true, message: 'You need to accept the Privacy Policy' }]}
            style={{ minHeight: 'none'}}
          >
            <Checkbox>
              <span className={classes.policyMessage}>I have read and accept the Privacy Policy.</span>
            </Checkbox>
          </Form.Item>
        </div>

        <div className={classes.btnAreaWrapper}>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Form.Item style={{width: '100%'}}>
            <Button type='primary' htmlType='submit' loading={isLoading} style={{width: '100%'}}>
              Send
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

interface FormDataType extends UserFeedbackType {
  privacy_policy: boolean
}

export default Feedback
