import React, { useEffect, useState } from 'react'
import { Button, Divider, InputNumber, Modal, Radio, Rate, Slider, Spin } from 'antd'
import classes from './Activities.module.css'
import activitiesIcon from './../../../img/icons/activitiesIcon.svg'
import collapseArrow from './../../../img/icons/collapseArrow.svg'
import { useAppDispatch, useAppSelector, usePrevious } from '../../../app/hooks'
import { selectActiveResultCategories, selectActiveTab, selectNodeServiceDetails, selectSelectedTransportStops, setActiveResultBlock, setSelectedTransportStop } from '../../../store/searchResultsReducer'
import { ReactComponent as GoBackIcon } from './../../../img/icons/goBack.svg'
import { GetViatorAttractionLocationThunk, GetViatorAttractionsFilteredThunk, selectAttractionAdditionalFilters, selectAttractionFilters, selectAttractionLocationCoordinates, selectAttractions, setAttractionAdditionalFilters, setAttractionFilters, setAttractionLocationCoordinates, setAttractions } from '../../../store/viatorReducer'
import { ReactComponent as FiltersSmallIcon } from './../../../img/icons/filtersSmall.svg'
import { selectAdvertisementActivitiesBtnComponent, selectAdvertisementActivitiesComponent } from '../../../store/advertisementReducer'
import AdvertisingBanner from '../../common/AdvertisingBanner/AdvertisingBanner'
import { useMediaQuery } from 'react-responsive'
import { GetNearTransportStopsThunk } from '../../../store/transportStopsReducer'
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete'
import { ViatorAttractionLocationType } from '../../../types/viatorTypes'
import TransportStopList from '../../common/PublicTransportStops/TransportStopList/TransportStopList'

const Activities = () => {
  const dispatch = useAppDispatch()
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const activeTab = useAppSelector(selectActiveTab)
  const activities = useAppSelector(selectAttractions)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const filterValues = useAppSelector(selectAttractionFilters)
  const attractionAdditionalFilters = useAppSelector(selectAttractionAdditionalFilters)
  const locationCoordinates = useAppSelector(selectAttractionLocationCoordinates)
  const advertisement = useAppSelector(selectAdvertisementActivitiesComponent)
  const selectedTransportStops = useAppSelector(selectSelectedTransportStops)

  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  const prevFilterValues = usePrevious(filterValues)

  const [isLoading, setIsLoading] = useState(false)
  const [isStopsLoading, setIsStopsLoading] = useState(false)
  const [openStopsListActivityCode, setOpenStopsListActivityCode] = useState<null | string>(null)

  const getCoordinates = () => {
    return (!!selectedTransportStops?.length && selectedTransportStops?.[selectedTransportStops?.length - 1]?.location)
      || (!!locationCoordinates?.location.latitude && locationCoordinates.location)
      || nodeServiceDetails?.location
      || {latitude: activeTab?.coordinates[0], longitude: activeTab?.coordinates[1]}
  }

  useEffect(() => {
    if (!!selectedTransportStops?.length || !!nodeServiceDetails?.id || locationCoordinates?.location.latitude || (
      !!activeResultCategories.includes('near') && (
        activities === null || (!!Object.keys(prevFilterValues || {})?.length && JSON.stringify(prevFilterValues) !== JSON.stringify(filterValues))
      )
    )) {
      setIsLoading(true)
      const coordinates = getCoordinates()
      dispatch(GetViatorAttractionsFilteredThunk({coordinates: coordinates, filters: filterValues, attractionAdditionalFilters}))
        .then(() => setIsLoading(false))
    }
    // eslint-disable-next-line
  }, [dispatch, nodeServiceDetails, activeTab, activeResultCategories, filterValues, locationCoordinates, attractionAdditionalFilters])

  useEffect(() => {
    return () => {
      dispatch(setAttractions(null))
      dispatch(setAttractionAdditionalFilters({}))
      dispatch(setAttractionLocationCoordinates(null))
    }
    // eslint-disable-next-line
  }, [dispatch])

  const goBack = () => {
    if (!selectedTransportStops.length) {
      dispatch(setSelectedTransportStop(null))
    }
    if (!!selectedTransportStops.length) {
      dispatch(setActiveResultBlock('TransportStopDetails'))
    } else if (activeResultCategories[0] === 'near') {
      dispatch(setActiveResultBlock('Services'))
    } else {
      dispatch(setActiveResultBlock('NodeDetails'))
    }
  }

  const getAdsPlaceIndex = (totalLength: number, index: number) => {
    if (
      (totalLength < 2 && index === totalLength - 1) ||
      (index === 2)
    ) {
      return 1
    } else if (
      (index === 4) ||
      (totalLength  <= 5 && index === totalLength - 1)
    ) {
      return 2
    } else {
      return null
    }
  }

  const getTransportStopsForActivity = async(e: any, itemCode: string) => {
    e.preventDefault()
    if (itemCode !== openStopsListActivityCode) {
      setIsStopsLoading(true)
      setOpenStopsListActivityCode(itemCode === openStopsListActivityCode ? null : itemCode)
      const coordinates = getCoordinates()
      const location = await dispatch(GetViatorAttractionLocationThunk({code: itemCode, latitude: coordinates.latitude, longitude: coordinates.longitude})) as {payload: ViatorAttractionLocationType}
      if (location?.payload?.latitude) {
        dispatch(GetNearTransportStopsThunk({latitude: location?.payload?.latitude!, longitude: location?.payload?.longitude!, radius: 1000}))
        .then(() => setIsStopsLoading(false))
        // @ts-ignore
      } else if (!!location?.error?.message || location?.payload?.latitude === null) {
        setIsStopsLoading(false)
      } else {
        const addressData = await geocodeByPlaceId(location?.payload?.reference!)
        const coordinates = await getLatLng(addressData[0])
        dispatch(GetNearTransportStopsThunk({latitude: coordinates?.lat!, longitude: coordinates?.lng!, radius: 1000}))
        .then(() => setIsStopsLoading(false))
      }
    } else {
      setOpenStopsListActivityCode(itemCode === openStopsListActivityCode ? null : itemCode)
    }
  }

  if (isLoading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
        <Spin />
      </div>
    )
  }
  
  return (
    <div className={classes.activitiesWrapper}>
      <div className={classes.goBackBtnWrapper}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div onClick={goBack} style={{fontSize: '16px', fontWeight: 400}} className={classes.goBackBtn}>
            <GoBackIcon /> Back to {activeResultCategories.includes('near') ? 'results' : nodeServiceDetails?.type}
          </div>
          <h2 style={{marginTop: '7px'}}>
            Activities
          </h2>
        </div>
        <ActivitiesFilters />
      </div>
      <div>
        {activities?.length ? (
          <>
            {activities?.map((item, index) => {
              const adDataPlacementIndex = getAdsPlaceIndex(activities?.length, index)
              const adData = advertisement.find(ad => ad.place_index === adDataPlacementIndex)
              return (
                <React.Fragment key={item?.productCode}>
                  {!!adData?.place_type &&
                    <AdvertisingBanner
                      adData={adData}
                      style={{marginBottom: '5px'}}
                    />
                  }
                  <div className={classes.activityItemWrapper}>
                    <a
                      key={item.productCode}
                      className={classes.activityItem}
                      href={item.productUrl.replace('pid=P00071570', 'pid=P00070998')}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={item.images[0].variants.find(v => v.height > 400)?.url || item.images[0].variants[0]?.url}
                        alt={item.title}
                      />
                      <div className={classes.activityItemInfo}>
                        <div className={classes.mainDataWrapper}>
                          <div className={classes.activityTitle}>
                            {item.title}
                          </div>
                          {!!item?.pricing?.summary?.fromPrice && !isSmallScreen &&
                            <div className={classes.price}>
                              <div>
                                from
                              </div>
                              {item?.pricing?.summary?.fromPrice} {item?.pricing?.currency}
                            </div>
                          }
                        </div>
                        <div className={classes.activityLocationData}>
                          {/* <ActivitiesLocationIcon /> */}
                          {/* {!!item?.attractionStreetAddress && item?.attractionStreetAddress}
                          {!!item?.primaryDestinationName && ', ' + item?.primaryDestinationName}
                          {!!item?.attractionState && ', ' + item?.attractionState} */}
                        </div>
                        <div className={classes.rateWrapper}>
                          <Rate disabled value={Math.round(item.reviews.combinedAverageRating*2)/2} allowHalf/>
                          <div
                            className={classes.transportStopsBtn}
                            onClick={(e) => getTransportStopsForActivity(e, item.productCode)}
                          >
                            {openStopsListActivityCode === item.productCode
                              ? 'Hide Public transport'
                              : 'Show Public transport'
                            }
                          </div>
                          {!!item?.pricing?.summary?.fromPrice && isSmallScreen &&
                            <div
                              className={classes.price}
                              style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px'
                              }}
                            >
                              <div>
                                from
                              </div>
                              {item?.pricing?.summary?.fromPrice} {item?.pricing?.currency}
                            </div>
                          }
                        </div>
                      </div>
                    </a>
                    <TransportStopList isOpen={openStopsListActivityCode === item.productCode} isLoading={isStopsLoading}/>
                  </div>
                </React.Fragment>
              )
            })}
          </> 
        ) : (
          <>No Activities for this location</>
        )}
      </div>
    </div>
  )
}

export default Activities

export const ActivitiesBtn: React.FC<{style?: object, onClick?: () => void}> = ({style, onClick}) => {
  const dispatch = useAppDispatch()
  const advertisement = useAppSelector(selectAdvertisementActivitiesBtnComponent)

  return (
    <>
      {!!advertisement?.length &&
        <AdvertisingBanner
          adData={advertisement[0]}
          style={{marginTop: '15px'}}
        />
      }
      <div
        className={classes.activitiesBtnWrapper} 
        onClick={() => {
          onClick && onClick()
          dispatch(setActiveResultBlock('Activities'))
          dispatch(setSelectedTransportStop(null))
        }}
        style={style}
      >
        <div className={classes.activitiesBtnTitle}>
          <img src={activitiesIcon} alt='activities'/>
          <div className={classes.resultInnerLinkText}>Activities</div>
        </div>
        <img src={collapseArrow} alt='activities'/>
      </div>
    </>
  )
}

const ActivitiesFilters = () => {
  const dispatch = useAppDispatch()
  const attractionFilters = useAppSelector(selectAttractionFilters)
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  const initialFilters = {
    lowestPrice: 0,
    highestPrice: 500,
    rating: {from: 1, to: 5},
    durationInMinutes: {from: 1, to: 6000}
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filterValues, setFilterValues] = useState(attractionFilters)

  const rateOptions = [1, 2, 3 , 4, 5]

  const durationOptions = [
    {title: 'Any', value: {from: 1, to: 6000}},
    {title: 'Up to 1 hour', value: {from: 0, to: 60}},
    {title: '1 to 4 hours', value: {from: 60, to: 240}},
    {title: '4 hours to 1 day', value: {from: 240, to: 1440}},
    {title: '1 to 3 days', value: {from: 1440, to: 4320}},
    {title: '3+ days', value: {from: 4320, to: 6000}},
  ]

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const handleClearAll = () => {
    setFilterValues(initialFilters)
  }

  const onFinish = () => {
    dispatch(setAttractionFilters(filterValues))
    handleClose()
  }

  return (
    <>
      <div className={classes.filtersBtn} onClick={() => setIsModalOpen(true)}>
        <FiltersSmallIcon /> Filters
      </div>
      <Modal
        visible={isModalOpen}
        onCancel={handleClose}
        footer={null}
        bodyStyle={{
          padding:'0px',
          overflow: 'auto',
          // height: 'calc(100vh - 35px)',
          minHeight: '200px',
          ...(isSmallScreen ? {} : {height: 'calc(100vh - 35px)'})
        }}
        style={{top: '12px'}}
        className={classes.filtersWindow}
      >
        <div className={classes.filtersTitle}>
          Activity filters
        </div>
        <div className={classes.filterBlockWrapper}>
          <div className={classes.filterBlockTitle}>
            Price
          </div>
          <Slider
            range
            max={500}
            value={[filterValues.lowestPrice, filterValues.highestPrice]}
            onChange={(val) => setFilterValues({...filterValues, lowestPrice: val[0], highestPrice: val[1]})}
          />
          <div className={classes.priceFieldsWrapper}>
            <div>
              <div style={{color: '#717171', fontSize: '12px', textAlign: 'start'}}>
                min price
              </div>
              <InputNumber
                prefix='$'
                controls={false}
                style={{width: '100%'}}
                value={filterValues.lowestPrice}
                onChange={val => setFilterValues({...filterValues, lowestPrice: val || 0})}
              />
            </div>
            <Divider style={{borderTop: '1px solid #717171'}}/>
            <div>
              <div style={{color: '#717171', fontSize: '12px', textAlign: 'start'}}>
                max price
              </div>
              <InputNumber
                prefix='$'
                controls={false}
                style={{width: '100%'}}
                value={filterValues.highestPrice}
                onChange={val => setFilterValues({...filterValues, highestPrice: val || 0})}
                formatter={(value) => filterValues.highestPrice === 500 ? `${value}+` : `${value}`}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.filterBlockWrapper}>
          <div className={classes.filterBlockTitle}>
            Rate
          </div>
          <div className={classes.rateOptionsWrapper}>
            <Radio.Group
              value={filterValues.rating.from}
              onChange={(e) => setFilterValues({...filterValues, rating: {...filterValues.rating, from: e.target.value}})}
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {rateOptions.map(opt => (
                <Radio value={opt} key={opt}>
                  <Rate disabled value={opt}/> {opt !== 5 ? '& up' : ''}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
        <Divider />
        <div className={classes.filterBlockWrapper}>
          <div className={classes.filterBlockTitle}>
            Duration
          </div>
          <div className={classes.durationOptionsWrapper}>
            <Radio.Group
              value={filterValues.durationInMinutes.from}
              onChange={(e) => {
                setFilterValues({
                  ...filterValues,
                  durationInMinutes: {
                    from: e.target.value,
                    to: durationOptions.find(option => option.value.from === e.target.value)?.value.to!
                  }
                })
              }}
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {durationOptions.map(opt => (
                <Radio value={opt.value.from} key={opt.value.from}>
                  {opt.title}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
        <div className={classes.filtersFooter}>
          <Button onClick={handleClearAll} type='text'>
            Clear all
          </Button>
          <Button type='primary' onClick={onFinish}>
            Done
          </Button>
        </div>
      </Modal>
    </>
  )
}
