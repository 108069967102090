import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CountryType, RegionType, TutorialVideoType } from '../types/additionalDataTypes'
import { AsyncThunkConfig, RootState } from '../types/appTypes'
import { additionalDataApi } from '../app/api'
import { AppStatusType } from './appStatusReducer'

interface InitialStateType {
  countries: null | CountryType[]
  regions: null | RegionType[]
  tutorialVideo: null | TutorialVideoType
}

const initialState: InitialStateType = {
  countries: null,
  regions: null,
  tutorialVideo: null
}

export const additionalDataSlice = createSlice({
  name: 'additionalData',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<null | CountryType[]>) => {state.countries = action.payload},
    setRegions: (state, action: PayloadAction<null | RegionType[]>) => {state.regions = action.payload},
    setTutorialVideo: (state, action: PayloadAction<null | TutorialVideoType>) => {state.tutorialVideo = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCountryListThunk.fulfilled, (state, action) => {
        state.countries = action.payload
      })
      .addCase(GetRegionListThunk.fulfilled, (state, action) => {
        state.regions = action.payload
      })
      .addCase(GetTutorialVideoThunk.fulfilled, (state, action) => {
        state.tutorialVideo = action.payload
      })
  }
})

export const {
  setCountries,
  setRegions,
  setTutorialVideo
} = additionalDataSlice.actions

export const selectCountries = (state: RootState): null | CountryType[] => state.additionalData.countries
export const selectRegions = (state: RootState): null | RegionType[] => state.additionalData.regions
export const selectTutorialVideo = (state: RootState): null | TutorialVideoType => state.additionalData.tutorialVideo

export const GetCountryListThunk = createAsyncThunk<CountryType[], void, AsyncThunkConfig>(
  'additionalData/getCountryList',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await additionalDataApi.getCountryList()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.countries, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetRegionListThunk = createAsyncThunk<RegionType[], void, AsyncThunkConfig>(
  'additionalData/getRegionList',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await additionalDataApi.getRegionList()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.regions, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetTutorialVideoThunk = createAsyncThunk<TutorialVideoType, string, AsyncThunkConfig>(
  'additionalData/getTutorialVideo',
  async (tutorialCode, thunkAPI) => {
    try {
      const {data, status} = await additionalDataApi.getTutorialVideo(tutorialCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default additionalDataSlice.reducer
 