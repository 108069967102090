import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { Carousel, Spin } from 'antd'
import moment from 'moment'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import classes from './BlogEntry.module.css'
import BackBtn from '../../common/BackBtn/BackBtn'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetBlogEntryByTitleThunk, selectSelectedBlogEntry } from '../../../store/blogReducer'
import { ReactComponent as CopyIcon } from './../../../img/icons/copy.svg'
import { ReactComponent as TwitterMinimal } from './../../../img/icons/twitterMinimal.svg'
import { ReactComponent as FacebookMinimal } from './../../../img/icons/facebookMinimal.svg'
import { ReactComponent as LinkedInMinimal } from './../../../img/icons/linkedInMinimal.svg'

import { copyToClipboard } from '../../../helpers/linksHelper'

const BlogEntry = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const selectedEntry = useAppSelector(selectSelectedBlogEntry)

  useEffect(() => {
  //pathname example: blog/post/111111
    const url = pathname.split('post/')[1] 
    dispatch(GetBlogEntryByTitleThunk(url))
  }, [dispatch, pathname])

  return (
    <>
      <div className={classes.wrapper}>
        <Helmet>
          {selectedEntry?.title?.length &&
            <title>Relavanti | {selectedEntry?.title}</title>
          }
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header showMenu className={classes.header}/>
        <BackBtn type='button' className={classes.header} onClick={() => navigate('/blog')}/>
        {selectedEntry === null ? (
          <Spin style={{width: '100%'}}/>
        ) : (
          <div className={classes.entry}>
            <div className={classes.date}>
              Published {moment(selectedEntry.created).format('DD MMM YYYY')}
            </div>
            <h1>
              {selectedEntry.title}
            </h1>
            {!!selectedEntry.blog_images?.find(i => i.is_cover)?.image_url &&
              <img
                src={selectedEntry.blog_images?.find(i => i.is_cover)?.image_url}
                alt={selectedEntry.blog_images?.find(i => i.is_cover)?.image_html}
                className={classes.cover}
              />
            }
            <div className={classes.contentWrapper}>
              <div dangerouslySetInnerHTML={{__html: selectedEntry.content}}></div>
            </div>
            {selectedEntry.blog_images?.length > 1 &&
              <Carousel dots={{className: classes.carouselDots}} autoplay>
                {selectedEntry.blog_images?.filter(i => !i.is_cover)?.map(i => (
                  <img src={i.image_url} alt={i?.image_html} key={i.id} className={classes.carouselImg}/>
                ))}
              </Carousel>
            }
            <div className={classes.shareBlock}>
              <div
                className={classes.copyLink}
                onClick={() => copyToClipboard(window.location.href, 'Link copied successfully')}
              >
                <CopyIcon /> Copy link
              </div>
              <TwitterShareButton url={window.location.href} title=''>
                <div className={classes.socialOption}>
                  <TwitterMinimal />
                </div>
              </TwitterShareButton>
              <FacebookShareButton url={window.location.href}>
                <div className={classes.socialOption}>
                  <FacebookMinimal />
                </div>
              </FacebookShareButton>
              <LinkedinShareButton url={window.location.href}>
                <div className={classes.socialOption}>
                  <LinkedInMinimal />
                </div>
              </LinkedinShareButton>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}

export default BlogEntry
