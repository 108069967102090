import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Checkbox, Input, Popover, Select, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetCruiseByIdThunk, GetCruiseLineOptionsThunk, GetCruiseOptionsThunk, GetCruisesByLineIdThunk, selectCruiseLineOptions, selectCruiseOptions, setCruiseLineOptions, setCruiseOptions } from '../../../../store/cruiseReducer'
import { selectActiveTab } from '../../../../store/searchResultsReducer'
import { ActiveFiltersType } from '../../../../types/searchResultTypes'
import { InputGoogleAPI } from '../../../common/InputGoogleAPI/InputGoogleAPI'
import classes from './../Filters.module.css'
import { ReactComponent as InfoIcon } from './../../../../img/icons/info.svg'
import ExcludeCountryFilter from '../common/ExcludeCountryFilter'
import axios from './../../../../helpers/axiosHelper'
import { CruiseType } from '../../../../types/cruiseTypes'

interface PortFiltersPropTypes {
  selectedFilters: ActiveFiltersType
  setSelectedFilters: (filters: ActiveFiltersType) => void
}

const PortFilters:React.FC<PortFiltersPropTypes> = ({selectedFilters, setSelectedFilters}) => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectActiveTab)
  const cruiseLineOptions = useAppSelector(selectCruiseLineOptions)
  const cruiseOptions = useAppSelector(selectCruiseOptions)

  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  const [lineName, setLineName] = useState('')
  const [cruiseName, setCruiseName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!selectedFilters?.ports?.cruise_line_id) {
      dispatch(setCruiseLineOptions(null))
    }
    if (!selectedFilters?.ports?.cruise_id) {
      dispatch(setCruiseOptions(null))
    }
  // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    if (!!lineName.length) {
      setIsLoading(true)
      dispatch(GetCruiseLineOptionsThunk({name: lineName, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, lineName])

  useEffect(() => {
    if (!!cruiseName.length) {
      setIsLoading(true)
      if (selectedFilters?.ports?.cruise_line_id) {
        dispatch(GetCruisesByLineIdThunk({
          lineId: selectedFilters?.ports?.cruise_line_id,
          data: {cruise_name: cruiseName, with_cruise_routes: false},
          source
        })).then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
      } else {
        dispatch(GetCruiseOptionsThunk({name: cruiseName, without_routes: true, source}))
          .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
      }
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, cruiseName])

  useEffect(() => {
    if (!!selectedFilters?.ports?.cruise_id) {
      dispatch(GetCruiseByIdThunk(selectedFilters?.ports?.cruise_id))
        .then(r => dispatch(setCruiseOptions([r.payload] as CruiseType[])))
    }
  }, [dispatch, selectedFilters])

  const handleChange = (fields: {[key: string]: any}) => {
    const updatedFilters = {
      ...selectedFilters,
      ports: {...(selectedFilters?.ports || {}), ...fields}
    }
    setSelectedFilters(updatedFilters)
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((name: string, type: 'cruise' | 'line') => {
      type === 'cruise' ? setCruiseName(name) : setLineName(name)
    }, 350),
    []
  )

  const handleLocationChange = async(value: string) => {
    if (value.length) {
      const addressData = await geocodeByAddress(value)
      const coordinates = await getLatLng(addressData[0])
      handleChange({
        destination_location: {latitude: coordinates.lat, longitude: coordinates.lng},
        destination_location_name: value,
        destination_country_code: addressData[0]?.address_components?.find(component => component?.types?.includes('country'))?.short_name,
        radius: selectedFilters?.airports?.radius || 200,
        unit: selectedFilters?.airports?.unit || 'KM',
        departure_date: activeTab.dates[0].format('YYYY-MM-DD')
      })
    } else {
      handleChange({
        destination_location: null,
        destination_country_code: null,
        radius: null,
        unit: null
      })
    }
  }

  return (
    <>
      <div className={classes.filterCategoryTitle}>
        Port Filters:
      </div>

      {/* <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
            Include other countries
            <Popover
              content={
                <span style={{padding: '0px 10px', fontSize: '12px'}}>
                  Show nearest ports in other countries (if there are any available)
                </span>
              }
            >
              <InfoIcon className={classes.infoIcon} />
            </Popover>
        </div>
        <Checkbox
          onChange={e => handleChange({show_all_countries: e.target.checked})}
          checked={selectedFilters?.ports?.show_all_countries}
        >
          Include
        </Checkbox>
      </div> */}

      <ExcludeCountryFilter
        onChange={(val: null | string[]) => handleChange({excluded_countries: val})}
        value={selectedFilters?.ports?.excluded_countries || []}
      />

      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
          Only ports with cruises
        </div>
        <Checkbox
          onChange={e => handleChange({has_cruises: e.target.checked})}
          checked={selectedFilters?.ports?.has_cruises}
        >
          Only with cruises
        </Checkbox>
      </div>

      <div className={classes.filterSubCategory} id='cruiseLineFilterSelect'>
        <div className={classes.filterSubCategoryTitle}>
          Ports served by a specific cruise line
        </div>
        <Select
          placeholder='Enter the cruise line'
          showSearch
          value={selectedFilters?.ports?.cruise_line_id ? {value: selectedFilters?.ports?.cruise_line_id, label: cruiseLineOptions?.find(o => o.id === selectedFilters?.ports?.cruise_line_id)?.name} : undefined}
          onSelect={(lineData: any) => {
            handleChange({
              cruise_line_id: lineData.value,
              ...(selectedFilters?.ports?.cruise_id && cruiseOptions?.find(o => o.id === selectedFilters?.ports?.cruise_id)?.cruise_line_id !== lineData.value
                ? {cruise_id: undefined}
                : {}
              )
            })
            dispatch(setCruiseOptions(null))
          }}
          onSearch={(val) => handleSearchDebounce(val, 'line')}
          allowClear
          onClear={() => handleChange({cruise_line_id: null})}
          style={{width: '100%'}}
          filterOption={false}
          labelInValue
          getPopupContainer={() => document.getElementById('cruiseLineFilterSelect')!}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!lineName.length && !cruiseLineOptions?.length && 'No results found'}
              {!lineName.length && !cruiseLineOptions?.length && 'Start typing line name'}
            </>
          )}
          options={cruiseLineOptions?.map(o => ({label: o?.name, value: o.id}))}
        ></Select>
      </div>

      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
          Ports served by cruises
          {!!selectedFilters?.ports?.cruise_line_id &&
            <span style={{color: 'gray', fontSize: '12px', fontWeight: 400, marginLeft: '7px'}}>
              (Search among selected line cruises)
            </span>
          }
        </div>
        <Select
          placeholder='Enter the cruise name'
          showSearch
          value={selectedFilters?.ports?.cruise_id}
          onSelect={(lineData: any) => handleChange({cruise_id: lineData.value})}
          onSearch={(val) => handleSearchDebounce(val, 'cruise')}
          allowClear
          onClear={() => handleChange({cruise_id: null})}
          style={{width: '100%'}}
          filterOption={false}
          labelInValue
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!cruiseName.length && !cruiseOptions?.length && 'No results found'}
              {!cruiseName.length && !cruiseOptions?.length && 'Start typing cruise name'}
            </>
          )}
        >
          {cruiseOptions?.map((cruise) => (
            <Select.Option value={cruise.id} key={cruise.id}>
              <div
                style={{
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word'
                }}
              > 
                {cruise.name} <span style={{color: 'gray', fontSize: '14px', marginLeft: '7px'}}>{cruise.display_info}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={classes.filterSubCategory}>
        <div className={classes.filterSubCategoryTitle}>
          Ports served by cruises that depart from a specific port or city or radius around a location
          <Popover
            content={
              <span style={{padding: '0px 10px', fontSize: '12px', whiteSpace: 'pre-wrap'}}>
                {'Enter the location from where the cruise will start, and allowable search radius for ports in this area.'}
              </span>
            }
          >
            <InfoIcon className={classes.infoIcon} />
          </Popover>
        </div>
        <InputGoogleAPI
          placeholder='Enter Location'
          value={selectedFilters?.ports?.destination_location_name || ''}
          onChange={(value) => handleLocationChange(value)}
        />
        <div className={classes.doubleInput}>
          <Input
            type='number'
            value={selectedFilters?.ports?.radius || undefined}
            onChange={e => handleChange({radius: e.target.value})}
            placeholder='Radius'
            min={1}
            disabled={!selectedFilters?.ports?.destination_location_name?.length}
          />
          <Select
            value={selectedFilters?.ports?.unit}
            onChange={value => handleChange({unit: value})}
            placeholder='Units'
            disabled={!selectedFilters?.ports?.destination_location_name?.length}
          >
            <Select.Option value='KM'>km</Select.Option>
            <Select.Option value='MILES'>miles</Select.Option>
            <Select.Option value='NAUTICAL'>nautical</Select.Option>
          </Select>
        </div>
      </div>
    </>
  )
}

export default PortFilters
