import moment from 'moment'
import { NodeServiceDetails, setIsTerminalListOpen } from '../../../../store/searchResultsReducer'
import { SelectTabThunk, setSelectedCategoryTabKey, setSelectedStopTerminals, setStartEndPortSelected } from '../../../../store/travelSearchReducer'
import { useAppDispatch } from '../../../../app/hooks'
import { ReactComponent as ArrivalLocationLogo } from './../../../../img/travel/byFlight/arrivalLocationLogo.svg'
import { ReactComponent as TransferLocationLogo } from './../../../../img/travel/byFlight/transferLocationLogo.svg'
import { ReactComponent as DeleteIcon } from './../../../../img/icons/delete.svg'
import { ReactComponent as TopArrowLongIcon } from './../../../../img/icons/topArrowLong.svg'
import mapIconExampleAirportNoNumber from './../../../../img/icons/mapIconExampleAirportNoNumber.svg'
import classes from './TripStopItem.module.css'
import { getMarkerSvgIconGoogleMap, markerSizes, portExcursionIcon, portGenericIcon, portWaypointIcon } from '../../../../helpers/mapMarkerHelper'
import { PortTypeType } from '../../../../types/portTypes'
import { TerminalType } from '../../../../types/searchResultTypes'
import { setSelectedNodeTerminals } from '../../../../store/terminalReducer'
import { useEffect, useState } from 'react'
import { InputGoogleAPI } from '../../../common/InputGoogleAPI/InputGoogleAPI'
import { CustomizedCruiseRouteType } from '../../../../types/cruiseTypes'
import { orderBy } from 'lodash'
import { message } from 'antd'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

const TripStopItem: React.FC<TripStopItemPropTypes> = ({
  id,
  location,
  nodeName,
  nodeDetails,
  arrivalDate,
  departureDate,
  type,
  getNodeData,
  searchData,
  setSelectedStops,
  tripType,
  stopOrder,
  terminals,
  previewInfoOnly,
  isEditingRoutes,
  customizedRoutes,
  setCustomizedRoutes,
}) => {
  const dispatch  = useAppDispatch()

  const notForDisplayOnMap = nodeDetails?.port?.port_type === 'EXCURSION' || nodeDetails?.port?.port_type === 'GENERIC' || !nodeDetails?.port?.id

  const addStopTab = () => {
    if (notForDisplayOnMap) {
      return
    } else {
      setSelectedStops!(id!)
      dispatch(setSelectedCategoryTabKey('stops'))
      dispatch(SelectTabThunk(String(id)!, getNodeData, searchData))
      dispatch(setStartEndPortSelected(null))
    }
  }

  const goToStartEndPortTab = () => {
    dispatch(setSelectedCategoryTabKey('stops'))
    dispatch(setStartEndPortSelected(type === 'transfer' ? null : type))
    dispatch(SelectTabThunk(location, getNodeData, searchData!, {nodeId: id, newActiveResultCategories: undefined, isFromToLocation: false}))
  }

  const getStopIconForCruiseStop = (portType: PortTypeType, isFlight?: boolean) => {
    if (portType === 'EXCURSION') {
      return portExcursionIcon
    } else if (portType === 'GENERIC') {
      return portGenericIcon
    } else if (portType === 'WAYPOINT') {
      return portWaypointIcon
    } else if (!!isFlight) {
      return mapIconExampleAirportNoNumber
    } else {
      return getMarkerSvgIconGoogleMap({
        // @ts-ignore
        details: {category: 1, with_schedule_flights: true},
        type: 'port'
      }, stopOrder)
    }
  }

  return (
    <div style={type === 'departure' ? {marginBottom: '10px'} : {}}>
      <div
        className={`${classes.stopItemWrapper} ${String(id)} ${isEditingRoutes || customizedRoutes?.find(r => r.route_id === id)?.locations?.length ? classes.editingRoutes : ''}`}
        onClick={() => previewInfoOnly || isEditingRoutes ? {} : type === 'transfer'
          ? addStopTab()
          : tripType === 'byCruise'
            ? goToStartEndPortTab()
            : {}
        }
        style={{cursor : ((tripType === 'byCruise' && !notForDisplayOnMap) || (type === 'transfer' && (!notForDisplayOnMap || tripType === 'byFlight'))) && !previewInfoOnly ? 'pointer' : 'default'}}
      >
        <div>
          {type === 'departure' &&
            // <DepartureLocationLogo style={tripType === 'byCruise' ? {height: '50px'} : {}} />
            <StartEndIcon style={{marginLeft: '-4px', position: 'relative', zIndex: 100}}/>
          }
          {type === 'transfer' && (
            tripType === 'byFlight' 
              ? <TransferLocationLogo style={{marginLeft: '11px'}}/>
              : <img
                  src={getStopIconForCruiseStop(nodeDetails?.port?.port_type, nodeDetails?.name?.includes('Fly to'))}
                  alt=''
                  style={{
                    height: markerSizes[1][0],
                    width: markerSizes[1][1],
                    position: 'relative',
                    zIndex: 100
                  }}
                />
            )
          }
          {type === 'arrival' && (
            tripType === 'byFlight'
              ? <ArrivalLocationLogo style={{position: 'relative', zIndex: 100}} />
              : <StartEndIcon style={{marginLeft: '-4px',  position: 'relative', zIndex: 100}}/>
            )
          }
        </div>
        <div>
          <div className={classes.location}>
            {location}
          </div>
          <div className={classes.additionalInfo}>
            {nodeName}
            {!!terminals?.length &&
              <span
                onClick={() => {
                  if (previewInfoOnly) {
                    return
                  } else {
                    dispatch(setIsTerminalListOpen(true))
                    dispatch(setSelectedStopTerminals(terminals || []))
                    dispatch(setSelectedNodeTerminals(terminals || []))
                  }
                }}
                className={`${classes.terminalsCount} ${classes[tripType]}`}
                style={{cursor: previewInfoOnly ? 'default' : 'pointer'}}
              >
                {terminals?.length} {terminals?.length > 1 ? 'Terminals' : 'Terminal'}
              </span>
            }
          </div>
          {!!setCustomizedRoutes &&
            <AddLocationField
              routeId={id!}
              onClick={(e) => e.stopPropagation()}
              isEditingRoutes={isEditingRoutes}
              customizedRoutes={customizedRoutes!}
              setCustomizedRoutes={setCustomizedRoutes}
            />
          }
        </div>
        <div>
          {!!arrivalDate && <DateInfo date={arrivalDate} type='Arrival' />}
          {!!departureDate && !nodeDetails?.name?.includes('Fly to') && <DateInfo date={departureDate} type='Departure' />}
        </div>
      </div>
    </div>
  )
}

const DateInfo: React.FC<{date: string, type: 'Arrival' | 'Departure'}> = ({date, type}) => {
  return (
    <div>
      <div className={classes.flightStopInfo}>
        {moment(date, 'YYYY-MM-DD').format('dd DD MMM YYYY')}
      </div>
      <div className={classes.additionalInfo}>
        {moment(date.split(' ')[1], 'HH:mm:ss').format('HH:mm A')} ({type})   
      </div>
    </div>
  )
}

const StartEndIcon: React.FC<{style?: object}> = ({style}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width='50px' height='50px' style={style} viewBox="0 0 45.25 69.28">
      <g>
        <path className="cls-2" fill='#9f9eba' d="M22.73,29.63a9.48,9.48,0,1,1,9.48-9.48A9.5,9.5,0,0,1,22.73,29.63Zm21.42-7.31a.17.17,0,0,0,0-.07v-.34C44.16,22.05,44.16,22.19,44.15,22.32Z"/>
        <path className="cls-3" fill='#191559' d="M44.16,21.91c0,.14,0,.28,0,.41a21.17,21.17,0,0,1-3,9.24l-.05.08L40.23,33l-.07.1c-1.95,3-4,5.95-4,5.95L27,53.32a5.08,5.08,0,0,1-1.42,1.47,4.74,4.74,0,0,1-1,.54,5.13,5.13,0,0,1-3.6,0,5.21,5.21,0,0,1-1.05-.54,5.08,5.08,0,0,1-1.42-1.47L9.33,39.05s-2.08-3-4-5.95L5.23,33l-.89-1.36,0-.08a21.17,21.17,0,0,1-3-9.24c0-.13,0-.27,0-.41v-.3A20.85,20.85,0,0,1,7.52,6.4,20.86,20.86,0,0,1,22.67.12h.12a20.6,20.6,0,0,1,15,6.45A21.11,21.11,0,0,1,44.16,21.91Z"/>
        <line className="cls-4" fill='none' stroke='#fff' strokeLinecap='round' strokeWidth='2px' strokeMiterlimit='10' x1="16.68" y1="9.2" x2="16.68" y2="44.73"/>
        <path className="cls-5" fill='white' stroke='#191559' strokeWidth='1.13px' strokeMiterlimit='10' d="M35.5,11.21c-.29-1.74-3.56,1.08-8.95,0,0,0-5.51-1.94-9-.88a1.28,1.28,0,0,0-.91,1.43l.18,8.7c0,1.05.89.78,1.78.55,3.29-.85,8.39.22,8.39.22A11.36,11.36,0,0,0,36.36,19a.44.44,0,0,0,0-.61l-2.95-2.61S35.51,11.27,35.5,11.21Z"/>
        <path className="cls-6" fill='#f31711' d="M22.73,42.18V65a1,1,0,0,1-.91-.56L5.32,33.52c-.48-.94,1.19-2.39,2-1.79l3.34,2.32,11.45,8A1,1,0,0,0,22.73,42.18Z"/>
        <path className="cls-6" fill='#f31711' d="M22.73,42.18V65a1,1,0,0,0,.9-.56l16.5-30.87c.48-.94-1.19-2.39-2-1.79l-3.34,2.32L23.3,42A1,1,0,0,1,22.73,42.18Z"/>
        <path className="cls-7" fill='white' d="M38.55,31.59c.87,0,2,1.15,1.58,1.92L23.63,64.39a1,1,0,0,1-.91.55,1,1,0,0,1-.9-.55L5.32,33.51c-.41-.77.69-1.92,1.56-1.92a.9.9,0,0,1,.48.14L10.69,34l11.46,8a1,1,0,0,0,.57.18A1,1,0,0,0,23.3,42l11.45-8,3.34-2.31a.83.83,0,0,1,.46-.14m0-.75h0a1.57,1.57,0,0,0-.9.28l-3.33,2.31-11.45,8a.26.26,0,0,1-.15,0,.2.2,0,0,1-.14,0l-11.47-8L7.78,31.12a1.55,1.55,0,0,0-.9-.28,2.63,2.63,0,0,0-2.09,1.29,1.73,1.73,0,0,0-.14,1.73l16.5,30.89a1.78,1.78,0,0,0,3.13,0L40.79,33.87a1.72,1.72,0,0,0-.14-1.73,2.62,2.62,0,0,0-2.1-1.3Z"/>
      </g>
    </svg>
  )
}

const AddLocationField:React.FC<AddLocationFieldPropTypes>  = ({routeId, isEditingRoutes, onClick, customizedRoutes, setCustomizedRoutes}) => {
  const [isAddingLocation, setIsAddingLocation] = useState(false)
  const routeLocations = customizedRoutes?.find(r => r.route_id === routeId)?.locations || []

  useEffect(() => {
    if (!isEditingRoutes && !!isAddingLocation) {
      setIsAddingLocation(false)
    }
  }, [isEditingRoutes, isAddingLocation])

  const onLocationSelect = async(location: string) => {
    if (routeLocations.some(l => l.name === location)) {
      message.warning('Location with this name is already added!', 2)
    } else {
      const addressData = await geocodeByAddress(location)
      const coordinates = await getLatLng(addressData[0])
      const updatedLocations = [
        ...routeLocations,
        {
          name: location,
          order: routeLocations.length + 1,
          latitude: coordinates.lat,
          longitude: coordinates.lng
        }
      ]
      const hasRouteLocations = customizedRoutes?.some(r => r.route_id === routeId)
      let updatedRoutes
      if (hasRouteLocations) {
        updatedRoutes = customizedRoutes.map(r => r.route_id === routeId ? {...r, locations: updatedLocations} : r)
      } else {
        updatedRoutes = [...customizedRoutes, {route_id: routeId, locations: updatedLocations}]
      }
      setCustomizedRoutes(updatedRoutes)
    }
    setIsAddingLocation(false)
  }

  const onOrderChange = (move: 'up' | 'down', location: string) => {
    const locationCurrentOrder = routeLocations.find(l => l.name === location)?.order!
    const nearestLocation = routeLocations.find(l => l.order === (move === 'up' ? locationCurrentOrder - 1 : locationCurrentOrder + 1))
    setCustomizedRoutes(customizedRoutes.map(r => {
      if (r.route_id === routeId) {
        return {
          ...r,
          locations: r.locations.map(l => {
            if (l.name === location) {
              return {...l, order: move === 'up' ? l.order - 1 : l.order + 1}
            } else if (l.name === nearestLocation?.name) {
              return {...l, order: move === 'up' ? l.order + 1 : l.order - 1}
            } else {
              return l
            }
          })
        }
      } else {
        return r
      }
    }))
  }

  const onDelete = (location: string) => {
    const deletedLocation = customizedRoutes.find(r => r.route_id === routeId)?.locations.find(l => l.name === location)
    const updatedRoutes = customizedRoutes.map(r => r.route_id === routeId
        ? {
            ...r,
            locations: r.locations
              .filter(l => l.name !== location)
              .map(l => l.order > deletedLocation?.order! ? {...l, order: l.order - 1} : l)
          }
        : r)
    setCustomizedRoutes(updatedRoutes)
  }

  return (
    <div onClick={onClick} style={isEditingRoutes ? {} : {marginBottom: '10px'}}>
      {orderBy(routeLocations, 'order', 'asc')?.map((r, i) => (
        <div className={classes.addedLocationWrapper} key={r.name}>
          <div>{r.name}</div>
          {isEditingRoutes &&
            <div className={classes.addedLocationActions}>
              {i > 0
                ? <TopArrowLongIcon className={classes.arrow} onClick={() => onOrderChange('up', r.name)}/>
                : <div></div>
              }
              {i < routeLocations?.length! - 1
                ? <TopArrowLongIcon
                    style={{transform: 'rotate(180deg)'}}
                    className={classes.arrow}
                    onClick={() => onOrderChange('down', r.name)}
                  />
                : <div></div>
              }
              <DeleteIcon className={classes.delete} onClick={() => onDelete(r.name)}/>
            </div>
          }
        </div>
      ))} 
      {isEditingRoutes &&
        <div style={{margin: '10px 0px'}}>
          {isAddingLocation ? (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <InputGoogleAPI
                placeholder='Enter location'
                onChange={(value) => onLocationSelect(value)}
                clearAfterSelect
                style={{height: '30px', lineHeight: '30px'}}
                wrapperStyle={{width: '100%'}}
              />
              <div
                style={{fontSize: '12px', cursor: 'pointer', margin: '0px 10px'}}
                onClick={() => setIsAddingLocation(false)}
              >
                Cancel
              </div>
            </div>
          ) : (
            <div className={classes.addLocationBtn} onClick={() => setIsAddingLocation(true)}>
              + Add City/Port
            </div>
          )}
        </div>
      }     
    </div>
  )
}

interface TripStopItemPropTypes {
  location: string
  nodeName: string
  nodeDetails?: any
  type: 'departure' | 'arrival' | 'transfer'
  departureDate?: string
  arrivalDate?: string
  id?: number
  searchData: {
    date: string
    from?: string
    to?: string
  }
  getNodeData: (key: string) => NodeServiceDetails
  setSelectedStops?: (id: number) => void
  tripType: 'byFlight' | 'byCruise'
  stopOrder?: number
  terminals?: TerminalType[]
  previewInfoOnly?: boolean
  isEditingRoutes?: boolean
  customizedRoutes?: CustomizedCruiseRouteType[]
  setCustomizedRoutes?: (routes: CustomizedCruiseRouteType[]) => void
}

interface AddLocationFieldPropTypes {
  routeId: number
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  isEditingRoutes?: boolean
  customizedRoutes: CustomizedCruiseRouteType[]
  setCustomizedRoutes: (routes: CustomizedCruiseRouteType[]) => void
}

export default TripStopItem
