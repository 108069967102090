import { copyToClipboard } from '../../../../../helpers/linksHelper'
import classes from './../EventCard.module.css'
import { ReactComponent as LinkChainIcon } from './../../../../../img/icons/linkChainIcon.svg'
import EventDetailsTag from './EventDetailsTag'

const CopyLink: React.FC<{code: string, type: string}> = ({code, type}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div
        className={classes.copyLink}
        onClick={() => copyToClipboard(window.location.href + '/details/' + code, 'Link copied successfully')}
      >
        <LinkChainIcon /> Copy link
      </div>
      <EventDetailsTag tag={type}/>
    </div>
  )
}

export default CopyLink
