import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  // <React.StrictMode>
    <Provider store={store}> 
      <BrowserRouter>
        <HelmetProvider context={{}}>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
)

if ('serviceWorker' in navigator) {
  // console.log('navigator', navigator)
  navigator.serviceWorker.ready.then(registration => {
    // registration.unregister()
    if (caches) {
      caches.keys().then(async (names) => {
        await Promise.all(names.map(name => caches.delete(name)));
      }).then(() => window.location.reload())  
    }
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
