  import {Button, Spin} from 'antd'
  import { omit } from 'lodash'
  import React, { useState } from 'react'
  import { useLocation, useNavigate } from 'react-router-dom'
  import { useAppDispatch, useAppSelector } from '../../../app/hooks'
  import { getDatesForUrl, getPeopleCountForUrl } from '../../../helpers/linksHelper'
  import {getMarkerSvgIconGoogleMap, markerSizes} from '../../../helpers/mapMarkerHelper'
  import { selectDistanceType } from '../../../store/appStatusReducer'
  import { setAdditionalMarkersData } from '../../../store/mapReducer'
  import { AddResultTabThunk, NodeServiceDetails, addTerminalsToActiveServices, selectActiveTab, selectSelectedTerminal, selectSelectedTransportStop, selectSelectedTransportStops, setActiveTab, setIsTerminalListOpen, setSelectedTerminal, setSelectedTransportStop, setSelectedTransportStops } from '../../../store/searchResultsReducer'
  import {AirlineAirportType, AirlineType, AirportDataType, AirportSearchResultType} from '../../../types/airportTypes'
  import { PortSearchResultType, PortType } from '../../../types/portTypes'
  import { ResultTabTypes, TerminalType } from '../../../types/searchResultTypes'
  import { NodeServiceResultType } from '../../../types/serviceTypes'
  import {ReactComponent as LinkIcon} from './../../../img/icons/linkIcon.svg'
  import {ReactComponent as ArchedArrow} from './../../../img/icons/archedArrow.svg'
  import classes from './ResultComponents.module.css'
  import { AdvertisingType } from '../../../types/advertisingTypes'
  import AdvertisingBanner from '../../common/AdvertisingBanner/AdvertisingBanner'
  import { setNodeAdvertisement } from '../../../store/advertisementReducer'
  import { CruiseType } from '../../../types/cruiseTypes'
  import { setSelectedNodeTerminals } from '../../../store/terminalReducer'
  import { setIsTransportStopsListOpen, setNearTransportStops } from '../../../store/transportStopsReducer'
  import { selectSelectedTransportStop as selectWidgetSelectedTransportStop } from './../../../store/widgetReducer'

  export const ResultCategoryTitle: React.FC<ResultCategoryTitlePropTypes> = ({title}) => {
  return (
      <h2 className={classes.resultCategoryTitle}>
          {title}
      </h2>
  )
  }

  export const ResultSubCategoryTitle: React.FC<ResultCategoryTitlePropTypes> = ({title}) => {
  return (
      <h3 className={classes.resultSubCategoryTitle}>
          {title}
      </h3>
  )
  }

  export const ResultItem: React.FC<ResultItemPropTypes> = ({
    item,
    index,
    showNodeServices,
    isNodeDetails,
    directAirlinesFrom,
    wrapperStyle,
    disableItemDetails,
    customSelectItem,
  }) => {
  const dispatch = useAppDispatch()
  const selectedStop = useAppSelector(selectSelectedTransportStop)
  const widgetSelectedStop = useAppSelector(selectWidgetSelectedTransportStop)
  const selectedStops = useAppSelector(selectSelectedTransportStops)
  const selectedTerminal = useAppSelector(selectSelectedTerminal)

  const selectItem = (e: any) => {
      if (item?.type === 'stop') {
          e.stopPropagation()
          const element = document.getElementById('breadcrumbs')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
          dispatch(setSelectedTransportStop(omit(item, ['type']) as NodeServiceResultType))
      } else if (item?.type === 'terminal' && !!item.location?.latitude) {
          dispatch(setSelectedTerminal({id: item.id, location: item.location!, name: item.name}))
      }
  }

  const openNodeDetails = () => {
    showNodeServices!({
      id: item.id,
      name: item.name,
      type: item.mapMarkerData!.type!,
      location: {
          latitude: item?.mapMarkerData?.details?.latitude!,
          longitude: item?.mapMarkerData?.details?.longitude!
      },
      terminals: item?.mapMarkerData?.details?.terminals || [],
      airlines: item?.airlines,
      cruises: item?.cruises,
      cruise_count: item?.cruise_count,
      advertising_list: item?.advertising_list,
      category: item?.mapMarkerData?.details?.category
    })
    dispatch(setNodeAdvertisement(item?.advertising_list || []))
  }

  return (
    <>
      <div
        className={`
          ${classes.resultItem}
          ${!!isNodeDetails ? classes.nodeDetails : ''}
          ${item?.type === 'service' ? classes.serviceItem : ''}
          ${item?.type === 'stop' ? classes.stopItem : ''}
          ${!!item?.type && item?.type === 'terminal' && !!item.location?.latitude ? classes.stopItem : !!item?.type ? classes.serviceItem : ''}
          ${item?.type === 'stop' && (selectedStop?.id === item.id || widgetSelectedStop?.id === item.id) ? classes.selectedStopItem : ''}
          ${item?.type === 'terminal' && selectedTerminal?.id === item.id ? classes.selectedStopItem : ''}
          ${item.id}
        `}
        key={item.id}
        onClick={customSelectItem || selectItem}
        style={{
          cursor: item?.type === 'stop' || (item?.type === 'terminal' && !!item.location?.latitude) 
            ? 'pointer'
            : 'default',
          ...wrapperStyle || {}
        }}
        id={String(item.id)}
      >     
        <div>
          <div
            className={`
              ${classes.resultTitle}
              ${(item?.type === 'stop' && !disableItemDetails) ? classes.stopTitle : ''}
            `}
            onClick={
              item?.type === 'stop' && !disableItemDetails ? () => {
                dispatch(setIsTransportStopsListOpen(false))
                dispatch(setNearTransportStops(null))
                dispatch(setSelectedTransportStops([...selectedStops, {...item, serviceType: 'stop'}]))
              } : () => {}
            }
          >
            {!!item.logo && <img src={item.logo} alt='' className={classes.logo}/>}
            <div>
              <bdo style={{marginRight: '5px'}} dir='ltr'>{item.name}</bdo>
              {!!item?.terminals?.length &&
                <span
                  className={`${classes.terminalInfo} ${classes[item?.mapMarkerData?.type + '-terminals']}`}
                  id={'nodeDetailsTerminalsBtn'+item.id}
                  onClick={() => {
                    dispatch(setIsTerminalListOpen(true))
                    dispatch(addTerminalsToActiveServices())
                    dispatch(setSelectedNodeTerminals(item?.terminals || []))
                    openNodeDetails()
                  }}
                >
                  {item?.terminals?.length} {item?.terminals?.length > 1 ? 'Terminals' : 'Terminal'}
                </span>
              }
            </div>
          </div>
            {item?.type === 'stop' && (item?.additionalInfo || item.webLink) &&
              <div onClick={e => e.stopPropagation()} className={classes.stopAgency}>
                {item.webLink ? (
                  <a href={item.webLink} target='_blank' rel='noreferrer'>
                    {item?.additionalInfo}
                    <ArchedArrow className={classes.archedArrow}/>
                  </a>
                ) : (
                  <>{item?.additionalInfo}</>
                )}
              </div>
            }
          <div className={classes.resultInfo}>
            {item?.city}
          </div>
          <div className={classes.resultInfo}>
            {item?.details}
          </div>
          <div className={classes.resultInfo}>
            {item?.type !== 'stop' && item?.additionalInfo}
          </div>
          <DirectFlightAirportInfo
            airport_airlines={item?.airport_airlines}
            directAirlinesFrom={directAirlinesFrom}
            showNodeServices={showNodeServices!}
          />
        </div>
        {!!item?.icon && item.icon}
        {!!item?.webLink?.length && item?.type !== 'stop' &&
          <a href={item.webLink} target='_blank' rel='noreferrer' className={classes.linkIcon}>
            <LinkIcon/>
          </a>
        }
        {!!item?.mapMarkerData && !!Object.keys(item?.mapMarkerData)?.length &&
          <div className={classes.additionalInfoWrapper}>
            <img
              src={getMarkerSvgIconGoogleMap(item?.mapMarkerData, index! + 1)}
              alt=''
              style={{
                height: markerSizes[item?.mapMarkerData?.details?.category || 0][0],
                width: markerSizes[item?.mapMarkerData?.details?.category || 0][1]
              }}
            />
            {!!item?.advertising_list?.some((ad => ad.place_type === 'NODE_LIST'))
              ? <AdvertisingBanner
                  adData={item?.advertising_list.find(ad => ad.place_type === 'NODE_LIST')!}
                  style={{width: '50px', height: '50px', minWidth: '50px', maxWidth: '50px', margin: '0 auto'}}
                />
              : <div></div>
            }
            <Button
              type='primary'
              id={'nodeDetailsBtn'+item.id}
              onClick={() => openNodeDetails()}
            >
              Details
            </Button>
          </div>
        }
      </div>
    </>
  )
}

const DirectFlightAirportInfo: React.FC<DirectFlightAirportInfoPropTypes> = ({airport_airlines, directAirlinesFrom, showNodeServices}) => {
  const [airportListExpanded, setIdAirportListExpanded] = useState(false)

  const directFlightOptions = airport_airlines?.reduce((acc, option) => {
    if (acc.some(opt => opt.airport.id === option.airport.id)) {
      return acc = acc.map(opt => opt.airport.id === option.airport.id ? {...opt, airlines: [...opt.airlines, option.airline]} : opt) 
    } else {
      return acc = [...acc, {airport: option.airport, airlines: [option.airline]}]
    }
  }, [] as {airport: AirportDataType, airlines: AirlineType[]}[])

  if (directAirlinesFrom && !!directFlightOptions?.length) {
    return (
      <div className={classes.resultInfo} style={{marginTop: '6px'}}>
        <DirectFlightAirportInfoBlock
          airportData={directFlightOptions[0]}
          directAirlinesFrom={directAirlinesFrom}
          showNodeServices={showNodeServices}
        />
        {directFlightOptions.length > 1 && !airportListExpanded &&
          <span
            className={classes.directAirlinesShowMore}
            onClick={() => setIdAirportListExpanded(true)}
          >
            +{directFlightOptions.length - 1} more airports
          </span>
        }
        {directFlightOptions.length > 1 && !!airportListExpanded &&
          <span>
            {directFlightOptions.slice(1).map(option => (
              <DirectFlightAirportInfoBlock
                airportData={option}
                directAirlinesFrom={directAirlinesFrom}
                showNodeServices={showNodeServices}
                key={option.airport?.id}
              />
            ))}
          </span>
        }
      </div>
    )
  } else {
    return <></>
  }
}

const DirectFlightAirportInfoBlock: React.FC<DirectFlightAirportInfoBlockPropTypes> = ({airportData, directAirlinesFrom, showNodeServices}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const distanceType = useAppSelector(selectDistanceType)
  const [airlineListExpanded, setIdAirlineListExpanded] = useState(false)

  const addCityToTabs = () => {
    const [mainData, sharedData] = `/travel/results${decodeURIComponent(location.search)}`.split('-shared')
    if (!mainData.includes(directAirlinesFrom)) {
      const newLink = mainData + `&${directAirlinesFrom}${getDatesForUrl()}${getPeopleCountForUrl()}` + (sharedData?.length ? '-shared' + sharedData : '')
      navigate(newLink)
      dispatch(AddResultTabThunk(directAirlinesFrom)).then(res => dispatch(setActiveTab(res.payload as ResultTabTypes)))
    }
  }

  return (
    <div>
      Non-Stop flights on {airportData?.airlines[0]?.name + ' '}
      {airportData?.airlines?.length > 1 && !airlineListExpanded &&
        <span
          className={classes.directAirlinesShowMore}
          style={{marginRight: '3px'}}
          onClick={() => setIdAirlineListExpanded(true)}
        >
          +{airportData?.airlines?.length - 1} more airlines
        </span>
      }
      {!!airlineListExpanded && ', ' + airportData?.airlines.splice(1).map(airline => airline.name).join(', ') + '  '} 
      from
      <span
        onClick={() => {
          showNodeServices!({
            id: airportData?.airport.id,
            name: airportData?.airport.name,
            type: 'airport',
            location: {
                latitude: airportData?.airport?.latitude!,
                longitude: airportData?.airport?.longitude!
            },
            terminals: airportData?.airport?.terminals || [],
          // @ts-ignore
            airlines: airportData?.airport?.directAirlines
          })
          dispatch(setAdditionalMarkersData([{
            type: 'airport',
            coordinates: [airportData?.airport?.latitude!, airportData?.airport?.longitude!],
            nodeDetails: {type: 'airport', details: airportData?.airport, unit: distanceType, distance: airportData?.airport?.distance, advertising_list: airportData?.airport?.advertising_list || []}
          }]))
        }}
        style={{color: '#0068FF', cursor: 'pointer', marginLeft: '5px'}}
      >
          {airportData?.airport?.name}
        </span> ({distanceType === 'km' ? airportData?.airport?.distance?.toFixed(0) : (airportData?.airport?.distance / 1.609).toFixed(0)}{distanceType} from <span onClick={addCityToTabs} style={{color: '#0068FF', cursor: 'pointer'}}>{directAirlinesFrom}</span>).
    </div>
  )
}

export const ResultCategoryItem: React.FC<ResultItemProps> = ({category, resultsForCategory, showNodeServices}) => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectActiveTab)
  const distanceType = useAppSelector(selectDistanceType)

  const extendAirportSearch = () => {
    dispatch(setActiveTab({
      ...activeTab,
      activeFilters: {
        ...activeTab.activeFilters,
        airports: {...activeTab.activeFilters?.airports, show_all_countries: true}
      }
    }))
  }

  return (
    <div className={classes.resultCategory}>
        <ResultCategoryTitle title={category}/>
        {resultsForCategory?.length ? (
            resultsForCategory.map((item: any, index: any) => {
                const itemData: AirportDataType | PortType = item.details
                    return (
                        <ResultItem
                            item={{
                                id: itemData?.id,
                                name: `${itemData?.name}${item.type !== 'bus' && item.type !== 'rail' && !!itemData?.code ? ' ('+itemData?.code+')' : ''}`,
                                mapMarkerData: item,
                                city: `${itemData?.city?.name}${itemData?.city?.state_name ? ', '+itemData?.city?.state_name + (itemData?.city?.country_name ? ', ' : '') : ''} ${itemData?.city?.country_name || ''} ${itemData?.distance ? '(' + (distanceType === 'km' ? itemData?.distance.toFixed(0) : (itemData?.distance / 1.609).toFixed(0)) + distanceType + ')' : ''}`,
                                terminals: itemData.terminals,
                                // @ts-ignore
                                airlines: itemData?.airlines || [],
                                // @ts-ignore
                                cruises: itemData?.cruises || [],
                                // @ts-ignore
                                cruise_count: itemData?.cruise_count || 0,
                                // @ts-ignore
                                airport_airlines: itemData?.airport_airlines || [],
                                // additionalInfo: itemData?.airlines
                                advertising_list: itemData?.advertising_list || []
                            }}
                            index={index}
                            key={itemData?.id || itemData?.name}
                            showNodeServices={showNodeServices}
                            directAirlinesFrom={
                                // @ts-ignore
                                !!itemData?.airlines?.length && !!activeTab.activeFilters.airports.destination_location_name
                                    ? activeTab?.activeFilters?.airports?.destination_location_name
                                    : undefined
                            }
                        />
                    )
                }
            )
        ) : (
          resultsForCategory === null ? (
            <Spin style={{marginLeft: '20px'}}/>
          ) : (
            <div className={classes.noResultsForCategory}>
              {category === 'airports' ? (
                <>
                  No airports with scheduled service found within default radius. Try extending the search to include other countries
                  <Button type='primary' onClick={extendAirportSearch}>
                    Extended search
                  </Button>
                </>
              ) : (
                <>No results found</>
              )}
            </div>
          )          
        )}
    </div>
  )
}

interface ResultCategoryTitlePropTypes {
  title: string
}

interface ResultItemPropTypes {
  item: {
    id: number
    name: string
    details?: string
    webLink?: string
    type?: string
    mapMarkerData?: AirportSearchResultType | PortSearchResultType
    distance?: string
    location?: {
      latitude: number
      longitude: number
    }
    city?: string
    additionalInfo?: string
    address?: string
    icon?: string
    logo?: string
    terminals?: TerminalType[]
    airlines?: AirlineType[]
    cruise_count?: number
    cruises?: CruiseType[]
    airport_airlines?: AirlineAirportType[]
    advertising_list?: AdvertisingType[]
  }
  index?: number
  showNodeServices?: (val: NodeServiceDetails | null) => void
  isNodeDetails?: boolean
  directAirlinesFrom?: string
  wrapperStyle?: object
  disableItemDetails?: boolean
  customSelectItem?: (e: any) => void
}

interface DirectFlightAirportInfoPropTypes {
  directAirlinesFrom?: string
  airport_airlines?: AirlineAirportType[]
  showNodeServices: (val: NodeServiceDetails | null) => void
}

interface DirectFlightAirportInfoBlockPropTypes {
  airportData: {airport: AirportDataType, airlines: AirlineType[]}
  directAirlinesFrom: string
  showNodeServices: (val: NodeServiceDetails | null) => void
}

interface ResultItemProps {
  resultsForCategory: AirportSearchResultType[] | PortSearchResultType[] | null
  category: string
  showNodeServices: (val: NodeServiceDetails | null) => void
}
