import { Button, DatePicker, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useState } from 'react'
import classes from './AdvancedSearchForm.module.css'
import { InputGoogleAPI } from '../../../../common/InputGoogleAPI/InputGoogleAPI'
import { ReactComponent as SwitchPlacesIcon } from './../../../../../img/icons/switchPlaces.svg'
import { ReactComponent as DatePickerCalendar } from './../../../../../img/icons/datePickerCalendar.svg'
import { getAdvanceFlightSearchUrl } from '../../../../../helpers/linksHelper'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { selectAdvancedSearchRequest, setAdvancedSearchRequest } from '../../../../../store/flightReducer'

const AdvancedSearchForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const formValues = useAppSelector(selectAdvancedSearchRequest)

  const [validationError, setValidationError] = useState({message: ''})
  
  const setFormValue = (updatedFieldValue: any) => {
    const updatedData = {
      ...formValues,
      ...updatedFieldValue
    }
    if (updatedData.from === updatedData.to) {
      setValidationError({message: 'From and To locations can\'t match!'})
    } else {
      setValidationError({message: ''})
    }
    dispatch(setAdvancedSearchRequest(updatedData))
  }

  const airportListLink = getAdvanceFlightSearchUrl('airports', formValues)

  return (
    <div className={classes.form}>
      <div className={classes.label}>
        Select flight type
      </div>
      <div id='searchTypeSelect' style={{width: '100%'}}>
        <Select
          style={{width: '100%', marginBottom: '15px'}}
          options={[
            {value: 'oneway', label: 'One Way'},
            {value: 'round', label: 'Round Trip'}
          ]}
          optionFilterProp='label'
          placeholder='Please select flight type'
          getPopupContainer={() => document.getElementById('searchTypeSelect')!}
          value={formValues?.type}
          onSelect={(value: 'oneway' | 'round') => setFormValue({
            type: value,
            ...(value === 'oneway' ? {endDate: formValues?.startDate || ''} : {})
          })}
        />
      </div>

      <div className={classes.label}>
        From
      </div>
      <InputGoogleAPI
        placeholder='Enter any Place (A)'
        wrapperStyle={{width: '100%', marginBottom: '15px'}}
        value={formValues.from || ''}
        onSelect={(value) => setFormValue({from: value})}
      />
      {!!validationError?.message?.length && !!formValues.from &&
        <div style={{fontSize: '12px', color: 'red', marginTop: '-15px', marginBottom: '15px', alignSelf: 'left'}}>
          {validationError.message}
        </div>
      }

      <SwitchPlacesIcon
        className={classes.switchIcon}
        onClick={() => setFormValue({from: formValues.to, to: formValues.from})}
      />

      <div className={classes.label}>
        To
      </div>
      <InputGoogleAPI
        placeholder='Enter any Place (B)'
        wrapperStyle={{width: '100%', marginBottom: '15px'}}
        value={formValues.to || ''}
        onSelect={(value) => setFormValue({to: value})}
      />
      {!!validationError?.message?.length && !!formValues.to &&
        <div style={{fontSize: '12px', color: 'red', marginTop: '-15px', marginBottom: '15px', alignSelf: 'left'}}>
          {validationError.message}
        </div>
      }

      <div className={classes.label}>
        Select Date
      </div>
      {formValues.type === 'oneway' ? (
        <DatePicker
          placeholder='Please select date'
          style={{width: '100%'}}
          value={!!formValues.startDate?.length ? moment(formValues.startDate, 'YYYY-MM-DD') : undefined}
          suffixIcon={<DatePickerCalendar/>}
          onChange={(val) => setFormValue({startDate: val?.format('YYYY-MM-DD'), endDate: val?.format('YYYY-MM-DD')})}
          disabledDate={(current) => current.startOf('day').isBefore(moment().startOf('day'))}
          allowClear={false}
        />
      ) : (
        <DatePicker.RangePicker
          value={formValues?.endDate
            ? [moment(formValues?.startDate), moment(formValues?.endDate)]
            : formValues?.startDate ? [moment(formValues?.startDate), moment(formValues?.startDate)] : undefined
          }
          style={{width: '100%', marginBottom: '20px'}}
          allowClear={false}
          suffixIcon={<DatePickerCalendar/>}
          onChange={(val) => setFormValue({startDate: val![0]?.format('YYYY-MM-DD')!, endDate: val![1]?.format('YYYY-MM-DD')!})}
          disabledDate={(current) => current.startOf('day').isBefore(moment().startOf('day'))}
        />
      )}

      <Button
        type='primary'
        htmlType='submit'
        className={classes.searchButton}
        disabled={!formValues?.from?.length
          || !formValues?.to?.length
          || !formValues?.startDate?.length
          || (!!formValues?.to && !!formValues?.from && formValues?.to === formValues?.from)
        }
        onClick={() => navigate(airportListLink)}
      >
        Search
      </Button>
      {(!formValues?.from?.length || !formValues?.to?.length || !formValues?.startDate?.length) &&
        <div style={{fontSize: '12px', color: 'gray', marginTop: '6px'}}>
          Make sure all fields are filled in
        </div>
      }
    </div>
  )
}

export default AdvancedSearchForm
