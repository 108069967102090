import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdditionalMarkerDataType } from '../types/mapTypes'
import { RootState } from './../types/appTypes'

interface InitialStateType {
  map: any,
  maps: any,

  markers: any[],
  infoWindows: any[],

  additionalMarkersData: AdditionalMarkerDataType[]
}

const initialState: InitialStateType = {
  map: null,
  maps: null,

  markers: [],
  infoWindows: [],
  
  additionalMarkersData: [],
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMap: (state, action: PayloadAction<any>) => {state.map = action.payload},
    setMaps: (state, action: PayloadAction<any>) => {state.maps = action.payload},

    setMarkers: (state, action: PayloadAction<any[]>) => {state.markers = action.payload},
    setInfoWindows: (state, action: PayloadAction<any[]>) => {state.infoWindows = action.payload},

    setAdditionalMarkersData: (state, action: PayloadAction<AdditionalMarkerDataType[]>) => {state.additionalMarkersData = action.payload},
  },
})

export const { 
  setMap,
  setMaps,
  setMarkers,
  setInfoWindows,
  setAdditionalMarkersData
} = mapSlice.actions

export const selectMap = (state: RootState): any => state.map.map
export const selectMaps = (state: RootState): any => state.map.maps
export const selectMarkers = (state: RootState): any[] => state.map.markers
export const selectInfoWindows = (state: RootState): any[] => state.map.infoWindows
export const selectAdditionalMarkersData = (state: RootState): AdditionalMarkerDataType[] => state.map.additionalMarkersData


export default mapSlice.reducer
 