import moment from 'moment'
import classes from './../EventCard.module.css'

const EventDate: React.FC<{date: string, showDay: boolean}> = ({date, showDay}) => {
  return (
    <div>
      <span className={classes.date}>
        {moment(date).format('DD')}
      </span>
      <span className={classes.dateDetails}>
        {moment(date).format('MMMM, YYYY')}
      </span>
      {showDay &&
        <span className={classes.day}>
          {moment(date).format('dddd')}
        </span>
      }
    </div>
  )
}

export default EventDate
