import { Divider } from 'antd'
import moment from 'moment'
import classes from './FlightItem.module.css'
import { ReactComponent as PlaneIcon } from './../../../../../img/travel/byFlight/planeIcon.svg'
import { FlightDetailsType } from '../../../../../types/flightTypes'
import { AirportDataType } from '../../../../../types/airportTypes'
import { CityType } from '../../../../../types/locationTypes'
import TripStopItem from '../../../common/TripStopItem/TripStopItem'
import { AdditionalSearchDataType } from '../../../../../types/cruiseTypes'
import { NodeServiceDetails } from '../../../../../store/searchResultsReducer'
import { useAppDispatch } from '../../../../../app/hooks'
import { setSelectedStops } from '../../../../../store/flightReducer'

const FlightItem: React.FC<FlightItemPropTypes> = ({flight, showStops, onClick, searchData, getNodeData}) => {
  return (
    <div
      className={`${classes.wrapper} ${showStops ? classes.flightDetails : ''}`}
      onClick={() => onClick(flight)}
    >
      <div className={classes.airlineInfo}>
        {showStops ? (
          <div className={classes.flightSummaryTitle}>
            Flight summary
          </div>
        ) : (
          <div className={classes.airline}>
            {flight?.airline?.name}
          </div>
        )}
        <div className={classes.stops}>
          {flight?.stops > 0 ? flight?.stops + (flight?.stops > 1 ? ' Stops' : ' Stop') : 'Nonstop'}
        </div>
      </div>
      <div className={classes.infoWrapper}>
        <LocationData
          airport={flight?.departure_airport}
          country={flight?.departure_country}
          date={flight?.departure_date}
          time={flight?.departure_time}
          type='departure'
          showStops={showStops}
        />
        <div>
          <PlaneIcon />
          {/* <div className={classes.tripTime}>
            trip time
          </div> */}
        </div>
        <LocationData
          airport={flight?.arrival_airport}
          country={flight?.arrival_country}
          date={flight?.arrival_date}
          time={flight?.arrival_time}
          type='arrival'
          showStops={showStops}
        />
      </div>
      {showStops &&
        <TripLocationDetails
          flight={flight}
          searchData={searchData!}
          getNodeData={getNodeData!}
        />
      }
    </div>
  )
}

const LocationData: React.FC<LocationDataPropTypes> = ({airport, country, date, time, type, showStops}) => {
  return (
    <div className={classes.locationDataWrapper}>
      {showStops ? (
        <>
          <div className={classes.flightAdditionalInfo}>
            {type === 'arrival' ? 'Airport arrival' : 'Airport departure'}
          </div>
          <div className={classes.flightSummaryInfo}>
            {airport?.name} {!!airport?.code && '(' + airport?.code }) 
          </div>
          <div className={classes.flightAdditionalInfo}>
            {type === 'arrival' ? 'Journey End Date' : 'Journey Start Date'}
          </div>
          <div className={classes.flightSummaryInfo}>
            {moment(date, 'YYYY-MM-DD').format('dd DD MMM YYYY')} ({moment(time, 'HH:mm:ss').format('HH:mm A')})
          </div>
        </>
      ) : (
        <>
          <div className={classes.flightLocation}>
            {country?.cities[0]?.name} / {country?.name}
          </div>
          <div className={classes.flightAdditionalInfo}>
            {airport?.name} ({airport?.code})
          </div>
          <div className={classes.flightDate}>
            {moment(date, 'YYYY-MM-DD').format('dd DD MMM YYYY')} ({moment(time, 'HH:mm:ss').format('HH:mm')})
          </div>
        </>
      )}
    </div>
  )
}

const TripLocationDetails: React.FC<TripLocationDetailsPropTypes> = ({flight, searchData, getNodeData}) => {
  const dispatch = useAppDispatch()

  return (
    <div>
      <Divider />
      <TripStopItem
        location={flight?.departure_country?.cities[0]?.name + ' / ' + flight?.departure_country?.name}
        nodeName={flight?.departure_airport?.name}
        departureDate={flight?.departure_date + ' ' + flight?.departure_time}
        type='departure'
        id={flight?.departure_airport.id}
        searchData={searchData}
        getNodeData={getNodeData}
        tripType='byFlight'
      />
      {flight?.airport_stops?.map(stop => (
        <TripStopItem
          location={stop.city.name + (stop.city.state_name ? ' / ' +  stop.city.state_name : '')}
          nodeName={stop.name}
          key={stop.name}
          type='transfer'
          searchData={searchData}
          getNodeData={getNodeData}
          setSelectedStops={(id: number) => dispatch(setSelectedStops(id))}
          tripType='byFlight'
          id={stop.id}
        /> 
      ))}
      <TripStopItem
        location={flight?.arrival_country?.cities[0]?.name + ' / ' + flight?.arrival_country?.name}
        nodeName={flight?.arrival_airport?.name}
        arrivalDate={flight?.arrival_date + ' ' + flight?.arrival_time}
        type='arrival'
        id={flight?.arrival_airport.id}
        searchData={searchData}
        getNodeData={getNodeData}
        tripType='byFlight'
      />
    </div>
  )
}

interface FlightItemPropTypes {
  flight: FlightDetailsType
  showStops?: boolean
  onClick: (flight: FlightDetailsType) => void
  searchData?: AdditionalSearchDataType
  getNodeData?: (key: string) => NodeServiceDetails
}

interface LocationDataPropTypes {
  airport: AirportDataType
  country: {code: string, name: string, cities: CityType[]}
  date: string
  time: string
  type: 'departure' | 'arrival'
  showStops?: boolean
}

interface TripLocationDetailsPropTypes {
  flight: FlightDetailsType
  searchData: AdditionalSearchDataType
  getNodeData: (key: string) => NodeServiceDetails
}

export default FlightItem
