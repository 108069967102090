import { Spin, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import GoogleMap from '../../SearchResults/Map/GoogleMap'
import Footer from '../../common/Footer/Footer'
import Header from '../../common/Header/Header'
import classes from './EventDetailsPage.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetEventByCodeThunk, GetEventUserRelationThunk, SaveEventTrackingThunk, selectEventUserRelation, selectSelectedEventByCode, setEventUserRelation, setMyEventSelectedKind, setSelectedEventByCode } from '../../../store/eventReducer'
import { selectIsLoggedIn } from '../../../store/userReducer'
import { ReactComponent as ShowMapIcon } from './../../../img/icons/showMapIcon.svg'
import { NodeServiceDetails, resultCategories, selectActiveTab, selectNodeServiceDetails, setActiveTab } from '../../../store/searchResultsReducer'
import { GetDataForResultTabsThunk } from '../../../store/searchResultsReducer'
import Results from '../../SearchResults/ResultTabs/Results/Results'
import { GetNearServicesThunk, GetServiceCategoriesThunk } from '../../../store/serviceReducer'
import { GetAirportsByCityThunk } from '../../../store/airportReducer'
import { GetPortsByCityThunk } from '../../../store/portReducer'
import { GetBusesByCityThunk } from '../../../store/busReducer'
import { GetRailwaysByCityThunk } from '../../../store/railwayReducer'
import ResultCategories from '../../SearchResults/ResultTabs/Results/ResultCategories/ResultCategories'
import { useMediaQuery } from 'react-responsive'
import BackBtn from '../../common/BackBtn/BackBtn'
import { Link } from 'react-router-dom'
import { setAttractionAdditionalFilters } from '../../../store/viatorReducer'
import { Helmet } from 'react-helmet-async'
import { GetCruiseByLineAndDateThunk, GetCruiseLineByIdThunk, getCruiseArrivalLocation, selectCruiseLine, selectCruises, setCruise, setCruises } from '../../../store/cruiseReducer'
import { DetailsBlock } from '../../Travel/ByCruise/SearchResults/CruiseDetails/CruiseDetails'
import TripNumber from '../../Travel/common/TripNumber/TripNumber'
import { setSelectedResultItemId } from '../../../store/travelSearchReducer'
import { getMapIconHoverInfo } from '../../Travel/ByCruise/SearchResults/SearchResults'
import DetailsTab from './DetailsTab/DetailsTab'
import { ResultTabTypes } from '../../../types/searchResultTypes'

const EventDetailsPage = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  const selectedEventByCode = useAppSelector(selectSelectedEventByCode)
  const eventUserRelation = useAppSelector(selectEventUserRelation)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const activeTab = useAppSelector(selectActiveTab)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const cruises = useAppSelector(selectCruises)

  const [activeTabKey, setActiveTabKey] = useState('event')
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [isTrackingSaved, setIsTrackingSaved] = useState(false)
  const [openMapMobileView, setOpenMapMobileView] = useState(false)

  useEffect(() => {
    // pathname example: events/details/111111
    const code = pathname.split('details/')[1] 
    dispatch(setAttractionAdditionalFilters({event_code: code}))
    Promise.all([
      ...(!eventUserRelation && !!isLoggedIn
        ? [dispatch(GetEventUserRelationThunk(code))]
        : []
      ),
      ...(!selectedEventByCode 
        ? [dispatch(GetEventByCodeThunk(code))]
        : []
      )
    ])
  }, [dispatch, pathname, selectedEventByCode, eventUserRelation, isLoggedIn])

  useEffect(() => {
    if (!activeTab?.name && !!selectedEventByCode) {
      Promise.all([
        ...(selectedEventByCode?.event_kind === 'Location'
          ? [
            dispatch(GetDataForResultTabsThunk({
              searchRequests: (moment(selectedEventByCode.start_date).startOf('day').isBefore(moment().startOf('day'))
                ? [selectedEventByCode.event_location!.name]
                : [`${selectedEventByCode.event_location!.name}-from-${moment(selectedEventByCode.start_date).format('YYYYMMDD')}-to-${moment(selectedEventByCode.end_date).format('YYYYMMDD')}-adults-1-children-0`]
              ), 
              saveSearchTracking: false
            }))
          ]
          : []
        ),
        ...(selectedEventByCode?.event_kind !== 'Location'
          ? [
            dispatch(GetCruiseByLineAndDateThunk({cruiseInfo: {
              lineId: selectedEventByCode?.event_cruise?.cruise_line_id!,
              date: selectedEventByCode?.event_cruise?.cruise_start_date?.split(' ')[0]!,// moment(selectedEventByCode?.event_cruise?.cruise_start_date).format('YYYY-MM-DDThh:mm:ss')!,
              name: selectedEventByCode?.event_cruise?.cruise_name,
              with_cruise_routes: true
            }})).then((r:any) => {
              dispatch(setCruise(r.payload.cruises?.[0]))
              dispatch(setSelectedResultItemId(String(r.payload.cruises?.[0]?.id)))
            }),
            dispatch(GetCruiseLineByIdThunk({lineId: selectedEventByCode?.event_cruise?.cruise_line_id!, withCruises: false}))
          ]
          : []
        ),
      ])
      .then(() => setIsDataLoading(false))
    }
    // eslint-disable-next-line
  }, [dispatch, selectedEventByCode, activeTab?.name])

  useEffect(() => {
    return () => {
      dispatch(setEventUserRelation(null))
      dispatch(setAttractionAdditionalFilters({}))
      dispatch(setCruises(null))
      dispatch(setSelectedEventByCode(null))
      dispatch(setActiveTab({} as ResultTabTypes))
    }
  }, [dispatch])

  useEffect(() => {
    if (!!selectedEventByCode && !isTrackingSaved) {
      dispatch(SaveEventTrackingThunk(selectedEventByCode.code))
      setIsTrackingSaved(true)
    }
  }, [dispatch, selectedEventByCode, isTrackingSaved])

  useEffect(() => {
    if (!!Object.keys(activeTab).length) {
      dispatch(GetServiceCategoriesThunk())
      dispatch(GetNearServicesThunk({
        requestData: {
          latitude: activeTab?.coordinates?.[0],
          longitude: activeTab?.coordinates?.[1],
          radius: 2,
          unit: 'KM',
          place_id_list: [
            ...(!!activeTab?.placeId ? [activeTab?.placeId] : []),
            ...(activeTab?.placeId! !== activeTab?.countryPlaceId && !!activeTab?.countryPlaceId ? [activeTab?.countryPlaceId!] : [])
          ]
        }
      })).then(() => Promise.all([
        dispatch(GetAirportsByCityThunk({activeTabData: activeTab})),
        dispatch(GetPortsByCityThunk({activeTabData: activeTab})),
        dispatch(GetBusesByCityThunk({activeTabData: activeTab})),
        dispatch(GetRailwaysByCityThunk({activeTabData: activeTab})),
      ]))
    }
  }, [dispatch, activeTab, selectedEventByCode])

  const getCruisePath = () => {
    const locations = cruises?.[0]?.cruise_routes
    if (!!locations?.length) {
      const departureLocation = locations?.[0]
      const arrivalLocation = getCruiseArrivalLocation(locations, 1)
      const stops = locations.slice(1, locations.findIndex((l) => l.id === arrivalLocation.id))
      if (!!departureLocation?.id) {
        return [
          getCruisePathItem(departureLocation),
          ...stops.filter(l => !!l.port?.latitude).map(l => getCruisePathItem(l)),
          getCruisePathItem(arrivalLocation)
        ]
      }
    }
    return []
  }

  const getCruisePathItem = (stop:any) => {
    return ({ 
      id: stop?.id || 0,
      info: getMapIconHoverInfo(stop),
      lat: stop?.port?.latitude,
      lng: stop?.port?.longitude,
      stopData: stop
    })
  }

  const goBack = () => {
    if (activeTabKey === 'event') {
      dispatch(setMyEventSelectedKind(selectedEventByCode?.event_kind || 'Location'))
      navigate('/events')
    } else {
      setActiveTabKey('event')
    }
  }

  if (isDataLoading) {
    return (
      <>
        <div className={classes.wrapper}>
          <Header showMenu className={classes.header}/>
          <Spin style={{margin: '0 auto', padding: '40px'}}/>
        </div>
        <Footer />
      </>
    )
  }

  return (
    <>
      <div className={classes.wrapper}>
        <Helmet>
          {selectedEventByCode?.name?.length &&
            <title>Relavanti | {selectedEventByCode?.name}</title>
          }
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header showMenu className={classes.header}/>
        <BackBtn type='button' className={classes.header} onClick={goBack}/>
        {selectedEventByCode?.event_type === 'Private' && !isLoggedIn ? (
          <div className={classes.loginMessage}>
            <div className={classes.emptyListText}>
              {'This event is private.\nOnly authorized users can see this event.'}
            </div>
            <Link
              style={{fontSize: '20px', textDecoration: 'underline'}}
              to='/sign-in'
              state={{navigateLink: '/events/details/' + selectedEventByCode.code}}
            >
              Sign in to your account
            </Link>
          </div>
        ) : (
          <div className={classes.contentWrapper}>
            <div className={openMapMobileView ? classes.eventInfoWrapperHidden : classes.eventInfoWrapper}>
              <Tabs
                activeKey={activeTabKey}
                onTabClick={(key) => setActiveTabKey(key)}
                className={classes.tabs}
                type='card'
              >
                <Tabs.TabPane tab={'Event Details'} key='event'>
                  <DetailsTab />
                </Tabs.TabPane>

                {(selectedEventByCode?.event_kind === 'Location'
                  || (selectedEventByCode?.event_kind === 'Cruise' && (cruises === null || !!cruises?.length))
                ) && <Tabs.TabPane
                  // tab={selectedEventByCode?.event_kind === 'Location'
                  //   ? selectedEventByCode?.event_location?.name || ''
                  //   : selectedEventByCode?.event_cruise?.cruise_name || ''
                  // }
                  tab={'Book Nearby Services'}
                  key='location'
                >
                  {selectedEventByCode?.event_kind === 'Location' &&
                    <LocationTab
                      openMapMobileView={openMapMobileView}
                      activeTabKey={activeTabKey}
                    />
                  }
                  {selectedEventByCode?.event_kind === 'Cruise' &&
                    <CruiseTab />
                  }
                </Tabs.TabPane>}
              </Tabs>
              {!openMapMobileView &&
                <>
                  {((selectedEventByCode?.event_kind === 'Cruise' && !!cruises?.length) || selectedEventByCode?.event_kind !== 'Cruise') ? (
                    <div 
                      className={classes.showMapSmallScreenBtn}
                      onClick={() => setOpenMapMobileView(true)}
                    >
                      <ShowMapIcon />View map
                    </div>
                  ) : (
                    <div></div>
                  )}
                </>
              }
            </div>
            {(!isSmallScreen || !!openMapMobileView) && 
              <>
                {((selectedEventByCode?.event_kind === 'Cruise' && !!cruises?.length) || selectedEventByCode?.event_kind !== 'Cruise') ? (
                  <div style={{height: activeTabKey === 'location'&& !isSmallScreen ? 'calc(100% - 50px)' : '100%'}}>
                    {activeTabKey === 'location' && selectedEventByCode?.event_kind === 'Location' &&
                      <ResultCategories
                        resultCategories={resultCategories}
                        className={classes.resultCategories}
                      />
                    }
                    <div className={classes.mapWrapper}>
                      <GoogleMap
                        showCenter
                        center={activeTab.coordinates || [getCruisePath()?.[0]?.lat, getCruisePath()?.[0]?.lng]}
                        isNodeDetailsOpen={!!nodeServiceDetails?.id}
                        smallScreenHandleShowList={() => setOpenMapMobileView(false)}
                        pathCoordinates={getCruisePath()}
                        pathIconsType={selectedEventByCode?.event_kind === 'Cruise' ? 'byCruise' : undefined}
                      />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            }
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}

const LocationTab: React.FC<{openMapMobileView: boolean, activeTabKey: string}> = ({openMapMobileView, activeTabKey}) => {
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})
  
  return (
    <>
      {!!isSmallScreen && !openMapMobileView && activeTabKey === 'location' &&
        <ResultCategories
          resultCategories={resultCategories}
          className={classes.resultCategories}
        />
      }
      <Results
        showOnlyResults
        showCityResults='fullResults'
        mapCenter={[0,0]}
        // multipleExternalLinks=[{title: '', dataForLinks: NodeServiceDetails}]
      />
    </>
  )
}

const CruiseTab = () => {
  const cruises = useAppSelector(selectCruises)
  const cruiseLine = useAppSelector(selectCruiseLine)
  const cruise = cruises?.[0]

  const getNodeData = (key: string):NodeServiceDetails => {
    const nodeData = cruise?.cruise_routes?.find(stop => String(stop.id) === key)!
    return {
      id: nodeData?.port?.id || nodeData?.id,
      name: nodeData?.name,
      type: 'port',
      location: {
        latitude: nodeData?.port?.latitude,
        longitude: nodeData?.port?.longitude
      }
    }
  }
  return (
    <>
      <TripNumber
        tripNumber={cruise?.cruise_line_name || ''}
        secondaryInfo='Cruise name'
        primaryInfo={cruise?.name!}
        additionalInfo={{
          cruiseNumber: cruise?.code,
          ship: cruise?.display_info?.split(' Ship: ')[1]?.split('|')[0]
        }}
        imageType='ship'
        logo={cruiseLine?.logo_url}
        logoLink={cruiseLine?.website}
        hideGoBack
      />
      <DetailsBlock getNodeData={getNodeData} previewInfoOnly/>
    </>
  )
}

export default EventDetailsPage
