import { Button, Modal, Tooltip } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { Lottie } from '@crello/react-lottie'
import classes from './MainPage.module.css'
import Footer from '../common/Footer/Footer'
import Header from '../common/Header/Header'
import globe from './../../img/mainPage/globe.webp'
import mapAnimation from './../../img/mainPage/mapAnimation.json'
import howToUseImg1 from './../../img/mainPage/howToUseImg1.webp'
import howToUseImg2 from './../../img/mainPage/howToUseImg2.webp'
import howToUseImg3 from './../../img/mainPage/howToUseImg3.webp'
import howToUseImg4 from './../../img/mainPage/howToUseImg4.webp'
import howToUseImg5 from './../../img/mainPage/howToUseImg5.webp'
import howToUseImg6 from './../../img/mainPage/howToUseImg6.webp'
import howToUseImg7 from './../../img/mainPage/howToUseImg7.webp'
import howToUseImg8 from './../../img/mainPage/howToUseImg8.webp'
import betaModalImg from './../../img/mainPage/betaModalImg.webp'
import { ReactComponent as PlusIcon } from './../../img/icons/plus.svg'
import { ReactComponent as RightArrow } from './../../img/icons/rightArrow.svg'
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectBetaModalLastShownTime, selectIsBetaModalOpen, setBetaModalLastShownTime, setIsBetaModalOpen, setUserPlaceId } from '../../store/appStatusReducer'
import { appSettingsHelper } from '../../helpers/localStorageHelper'
import moment from 'moment'
import GoToTopBtn from '../common/GoToTopBtn/GoToTopBtn'
import { Helmet } from 'react-helmet-async'

const MainPage = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isBetaModalOpen = useAppSelector(selectIsBetaModalOpen)
  const betaModalLastShownTime = useAppSelector(selectBetaModalLastShownTime)
  
  const modalWasShownRecently = moment().diff(moment(betaModalLastShownTime), 'hours') < 24

  useEffect(() => {
    if (location.hash === '#howTo') {
      window.scrollTo(0, 500);
    } else if (location.search?.includes('?place_id')) { // example: ?place_id=ChIJdd4hrwug2EcRmSrV3Vo6llI
      const placeId = location.search?.split('?place_id=')[1]
      dispatch(setUserPlaceId(placeId))
    }
  }, [dispatch, location])

  const closeBetaModal = () => {
    dispatch(setIsBetaModalOpen(false))
    dispatch(setBetaModalLastShownTime(moment().format()))
    appSettingsHelper.setBetaModalLastShownTime(moment().format())
  }

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <link rel='canonical' href='https://www.relavanti.com/' />
      </Helmet>
      <div className={classes.welcomeBlock}>
        <Lottie config={{animationData: mapAnimation}} className={classes.animation} style={{position:'absolute', top:'-5%', right:'-10%', width: '40%', height: '70%'}}/>
        <Header showMenu={true}/>
        <h1>
          Relevance in Travel
        </h1>
        <div className={classes.subtitle}>
          Travel and tourism solutions worldwide
        </div>
        <div className={classes.appNavigationBtnWrapper}>
          <Link to='/travel'>
            <Button type='primary'>
              Travel
            </Button>
          </Link>
          <Tooltip title='Coming soon'>
            <Link to='/'>
              <Button type='primary'>
                Shipping
              </Button>
            </Link>
          </Tooltip>
        </div>
        <div className={classes.globeImgWrapper}>
          <img src={globe} alt='globe' className={classes.globe} width='100%' height='100%'/>
        </div>
      </div>
      <HowToUse />
      <BetaModal isOpen={isBetaModalOpen && !modalWasShownRecently} onStart={closeBetaModal}/>
      <GoToTopBtn />
      <Footer />
    </div>
  )
}

const HowToUse = () => {
  const steps = [
    {
      step: '01',
      title: 'Searching by Single or Multiple Locations',
      description: 'To search by single or multiple locations, click on the “Search” button and then enter one or more locations to search. A location can be the name of a city, attraction, address or any other place available on the list. To add a location to the search, click on the “+” sign next to the location name, and enter another location. You can have up to 4 locations in one search.',
      image: howToUseImg1,
    },
    {
      step: '02',
      title: 'Searching by Flight',
      description: 'To search by flight you have booked, click on the “By Flight” button on the home page. Choose your airline from the dropdown list, and then enter the flight number. For example: Choose “QANTAS” as the airline and then enter “123” for the flight number. Then, choose the date of the flight.\n\nOnce you have entered the flight details, click on “Next”. You can then optionally enter two locations: (1) The location from which you will be coming TO the flight prior to departure, and the location to which you will be going FROM the flight after arrival. This means you will have four locations searched: point of origin, departure airport, arrival airport and destination.',
      image: howToUseImg2,
    },
    {
      step: '03',
      title: 'Searching by Cruise',
      description: 'To search by cruise you have booked, click on the “By Cruise” button on the home page. Choose your cruise line from the dropdown list, and then the cruise name. Then, choose the date of the cruise (note: for now, only a few sample cruises are available from Celestyal Cruises, with more data coming soon).\n\nOnce you have entered the cruise details, click on “Next”. You can then optionally enter two locations: (1) The location from which you will be coming TO the cruise, and the location to which you will be going FROM the cruise.',
      image: howToUseImg3,
    },
    {
      step: '04',
      title: 'Search Results - Nearby',
      description: 'When you search for a location (address, city, attraction, etc.), the Nearby tab will show you information about services (hotels, car rentals, activities, public transportation) in the area surrounding the searched location.',
      image: howToUseImg4,
    },
    {
      step: '05',
      title: 'Search Results – Nodes',
      description: 'When you search for a location (address, city, attraction, etc.), you will note above the map that there are buttons to see nearest nodes of transportation (Air - Airports, Sea – Seaports, Rail – Rail Stations and Bus – Bus Stations). Clicking on one of the buttons will show you the nearby nodes of that type, to the location searched. In the list of nodes displayed to the left of the map, click on Details to see services (hotels, car rentals, activities, public transportation) at or near that node.',
      image: howToUseImg5,
    },
    {
      step: '06',
      title: 'Nodes - Filtering Airports',
      description: 'When searching for a location and clicking on the Air button, you can see the nearest airports to the location, sorted by distance. By default, airports shown include only those with scheduled service in the same country as the searched location. The size of the airport icon indicates whether the airport is large, medium or small, depending on several factors such as the number of flights.\n\nYou can filter the airport search in several ways, such as showing airports without scheduled services, showing airports served by a specific airline or alliance, or you can increase the search radius to nearby countries.\n\nFinally, you can filter airports to show only those that have non-stop service from an airport within a certain radius from another location. For example, after searching for location “A” you can then filter the airports to show only those with non-stop flights from any airport within 200 kilometers from location “B”.',
      image: howToUseImg6,
    },
    {
      step: '07',
      title: 'Nodes – Digging Deeper',
      description: 'At each node, when clicking on the Details button, you will be able to see services available at or near the node (hotels, car rentals, activities, public transportation). But you can also dig deeper by clicking, where available, on Terminals at airports and seaports to see services at or near the Terminals.',
      image: howToUseImg7,
    },
    {
      step: '08',
      title: 'Opening a Profile, Saving and Sharing Search Results',
      description: 'You can sign up for a profile, which will allow you to save searches for future reference. After signing up you can perform a search for one or more locations, by flight or cruise, and save that search to your profile. When saving a search, you will first be asked to create a list and you can keep adding searches to each list. For example: you can create a list called “Honeymoon Cruise” and save as many searches as you like to that list. You can then also create a list called “Trip to Japan” and save other searches to that list as well. You can then return to your profile and view the list under the Settings tab.\n\nThere is also the possibility of sharing a single search, or multiple searches. After performing the search(es), simply click on the Share icon and then share the link to social media or by email.',
      image: howToUseImg8,
    },
  ]

  return (
    <div className={classes.howToUseBlock} id='howTo'>
      <div className={classes.additionalTitle}>
        Features
      </div>
      <h2>
        How to use?
      </h2>
      {steps.map((step, index) => (
        <HowToUseOption
          key={step.step}
          step={step}
          index={index}
        />
      ))}
    </div>
  )
}

const HowToUseOption: React.FC<{index: number, step: HowToStepType}> = ({step, index}) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      {/* <Divider
        className={classes.howToUseOptionDivider}
        style={{
          minWidth: 'auto',
          margin: '0 auto',
          marginTop: '30px',
        }}
      /> */}
      {!isOpen &&
        <motion.header
          onClick={() => setIsOpen(true)}
          className={classes.howToUseOption}
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 }
          }}
          transition={{ duration: 1 }}
        >
          <div>
            <PlusIcon className={classes.plusMinusIcon}/> {step.title}
          </div>
        </motion.header>
      }
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div
              key={step.step}
              className={`${classes.step} ${index % 2 === 0 ? classes.rightImage : classes.leftImage}`}
            >
              <img src={step.image} alt='' width='100%' height='100%' style={{objectFit: 'scale-down'}}/>
              <div className={classes.contentArea}>
                {/* <MinusIcon 
                  className={classes.plusMinusIcon}
                  onClick={() => setIsOpen(false)}
                  style={{marginTop: '8px'}}
                /> */}
                <div>
                  <div className={classes.title}>
                    {step.title}
                  </div>
                  <div className={classes.description}>
                    {step.description}
                  </div>
                </div>
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}

const BetaModal: React.FC<BetaModalPropTypes> = ({isOpen, onStart}) => {
  return (
    <Modal
      visible={isOpen}
      footer={false}
      closable={false}
      centered
      className={'betaModal'}
      style={window.innerHeight < 1045 ? { top: 20 } : {}}
      bodyStyle={{display: 'flex', flexDirection: 'column'}}
    >
      <img src={betaModalImg} alt='betaInfo' className={classes.betaModalImg} width='100%' height='100%'/>
      <div className={classes.betaModalTitle}>
        Welcome to Relavanti
      </div>
      <div className={classes.betaModalDescription}>
        <p>
          Welcome to the beta version of Relavanti! Please be aware that there may be some inaccuracies and bugs as we develop the software. Your feedback is crucial in making improvements. Thank you for being part of our beta testing community!
        </p>
      </div>
      <Button
        type='primary'
        onClick={onStart}
        style={{width: '100%'}}
        className={classes.betaModalStartBtn}
      >
        Start <RightArrow />
      </Button>
    </Modal>
  )
}

interface HowToStepType {
  step: string
  title: string
  description: string
  image: string
}

interface BetaModalPropTypes {
  isOpen: boolean
  onStart: () => void
}

export default MainPage
