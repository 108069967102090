import { Button, Carousel } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import moment from 'moment'
import { ReactComponent as LocationIcon } from './../../../../img/icons/location.svg'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { AddEventToUserListThunk, DeleteEventFromUserListThunk, selectEventUserRelation, selectSelectedEventByCode } from '../../../../store/eventReducer'
import { selectIsLoggedIn } from '../../../../store/userReducer'
import classes from './../EventDetailsPage.module.css'
import { selectEventAdvertisement } from '../../../../store/advertisementReducer'
import AdvertisingBanner from '../../../common/AdvertisingBanner/AdvertisingBanner'
import { sortBy } from 'lodash'

const DetailsTab = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const selectedEventByCode = useAppSelector(selectSelectedEventByCode)
  const eventUserRelation = useAppSelector(selectEventUserRelation)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const advertisement = useAppSelector(selectEventAdvertisement)

  const [isBtnLoading, setIsBtnLoading] = useState(false)

  const addEventToUserList = () => {
    if (isLoggedIn) {
      setIsBtnLoading(true)
      dispatch(AddEventToUserListThunk(pathname.split('details/')[1]))
        .then(() => setIsBtnLoading(false))
    } else {
      navigate('/sign-in')
    }
  }

  const deleteEventFromUserList = () => {
    setIsBtnLoading(true)
    dispatch(DeleteEventFromUserListThunk(pathname.split('details/')[1]))
      .then(() => setIsBtnLoading(false))
  }

  return (
    <>
      {!!advertisement?.some(ad => ad.place_type === 'EVENT_DETAILS_BEFORE_INFO') &&
        <AdvertisingBanner
          adData={advertisement.find(ad => ad.place_type === 'EVENT_DETAILS_BEFORE_INFO')!}
          style={{marginBottom: '15px'}}
        />
      }
      <div className={classes.eventInfo}>
        {!!selectedEventByCode?.photo_urls?.length &&
          <Carousel dots={{className: classes.carouselDots}} autoplay>
            {selectedEventByCode.photo_urls?.map(url => (
              <img src={url} alt={selectedEventByCode?.name} key={url}/>
            ))}
          </Carousel>
        }
        <div className={classes.date}>
          {moment(selectedEventByCode?.start_date).format('DD MMMM, hh:mm A')}
          {' - '}
          {moment(selectedEventByCode?.end_date).format('DD MMMM, hh:mm A')}
        </div>
        <h1>
          {selectedEventByCode?.name}
        </h1>
        {selectedEventByCode?.event_kind === 'Location' &&
          <div className={classes.location}>
            <LocationIcon /> {selectedEventByCode?.event_location?.name}
          </div>
        }
        <div className={classes.description}>
          {selectedEventByCode?.description}
        </div>
        {!eventUserRelation?.is_creator &&
          <Button
            type='primary'
            onClick={eventUserRelation?.is_member ? deleteEventFromUserList : addEventToUserList}
            loading={isBtnLoading}
          >
            {eventUserRelation?.is_member && 'Remove from my list'}
            {!isLoggedIn && 'Sign in to add Event'}
            {!!isLoggedIn && !eventUserRelation?.is_member && 'Add to my list'}
          </Button>
        }
      </div>
      {sortBy(advertisement?.filter(ad => ad.place_type === 'EVENT_DETAILS_AFTER_INFO') || [], ad => ad?.place_index)?.map(ad =>
        <AdvertisingBanner
          key={ad.place_index}
          adData={ad}
          style={{marginTop: '15px'}}
        />
      )}
    </>
  )
}

export default DetailsTab
