import React, { useEffect, useState } from 'react'
import { Button, Divider, Input, InputNumber, Modal, Pagination, Select, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectActiveTab, selectNodeServiceDetails } from '../../../../store/searchResultsReducer'
import classes from './AirlinesForAirport.module.css'
import { ReactComponent as AirlinesIcon } from './../../../../img/icons/airlinesIcon.svg'
import { ReactComponent as SearchIcon } from './../../../../img/icons/search.svg'
import { AirlineType } from '../../../../types/airportTypes'
import {ReactComponent as GoBackIcon} from './../../../../img/icons/goBack.svg'
import { ReactComponent as FiltersIcon } from './../../../../img/icons/filters.svg'
import { orderBy } from 'lodash'
import SortBtn from '../../../common/SortBtn/SortBtn'
import { GetAirportsWithFlightToLocationThunk, selectAirportsWithFlightToLocation } from '../../../../store/airportReducer'

const AirlinesForAirport = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className={classes.blockWrapper}>
      <AirlinesBtn onClick={() => setIsModalOpen(true)}/>

      <AirlinesModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  )
}

export const AirlinesBtn: React.FC<{style?: object, onClick?: () => void}> = ({style, onClick}) => {
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)

  return (
    <>
      <div
        className={classes.airlinesBtnWrapper} 
        onClick={() => {
          onClick && onClick()
        }}
        style={style}
      >
        <div className={classes.airlinesBtnTitle}>
          <AirlinesIcon style={{marginRight: '7px'}}/>
          <div className={classes.resultInnerLinkText}>
            Airlines ({nodeServiceDetails?.airlines?.length} {nodeServiceDetails?.airlines?.length === 1 ? 'Airline' : 'Airlines'})
          </div>
        </div>
        <div style={{fontSize: '14px', cursor: 'pointer', color: '#0068FF', fontWeight: 700}}>
          View
        </div>
      </div>
    </>
  )
}

const AirlinesModal: React.FC<AirlinesModalPropTypes> = ({isOpen, closeModal}) => {
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)

  const [airlinesForDisplay, setAirlinesForDisplay] = useState<AirlineType[]>([])
  const [searchParams, setSearchParams] = useState<{type: 'NAME' | 'CODE' | 'ALLIANCE', requestStr: string}>({type: 'NAME', requestStr: ''})
  const [sortBy, setSortBy] = useState<'name' | 'code' | 'alliance' | null>('name')
  const [sortType, setSortType] = useState<'asc' | 'desc' | false>('asc')
  const [isSortOptionsOpen, setIsSortOptionsOpen] = useState(false)
  const [selectedAirlineId, setSelectedAirlineId] = useState<number | null>(null)
  const [pagination, setPagination] = useState({page:  1, size: 10})

  const getAirlinesForDisplay = () => {
    let dataForDisplay
    if (!searchParams.requestStr.length) {
      dataForDisplay = nodeServiceDetails?.airlines || []
    } else {
      dataForDisplay = (nodeServiceDetails?.airlines || []).filter(airline => {
        if (searchParams.type === 'NAME') {
          return airline.name.toLowerCase().includes(searchParams.requestStr.toLowerCase())
        } else if (searchParams.type === 'CODE') {
          return airline.code.toLowerCase().includes(searchParams.requestStr.toLowerCase())
        } else {
          return airline?.alliance?.name?.toLowerCase()?.includes(searchParams.requestStr.toLowerCase())
        }
      })
    }
    if (sortType === false || sortBy === null) {
      return dataForDisplay
    } else {
      return orderBy(dataForDisplay, sortBy === 'alliance' ? item => item?.alliance?.name : [sortBy], [sortType])
    }
  }

  const paginate = (array: any[]) => {
    return array.slice((pagination.page - 1) * pagination.size, pagination.page * pagination.size)
  }

  useEffect(() => {
    setPagination({...pagination, page: 1})
    setAirlinesForDisplay(getAirlinesForDisplay())
    // eslint-disable-next-line
  }, [searchParams, nodeServiceDetails, sortBy, sortType])

  return (
    <Modal
      visible={isOpen}
      onCancel={() => {
        setSelectedAirlineId(null)
        closeModal()
      }}
      footer={null}
      className={classes.wrapper}
      closable={true}
      width={660}
      destroyOnClose
    >
      <div onClick={() => setIsSortOptionsOpen(false)}>
        <div className={classes.modalTitle}>
          {!!selectedAirlineId ? (
            <>
              {airlinesForDisplay?.find(a => a.id === selectedAirlineId)?.name} ({airlinesForDisplay?.find(a => a.id === selectedAirlineId)?.code})
            </>
          ) : (
            <>
              Airlines
              <span>
                ({airlinesForDisplay?.length} {airlinesForDisplay?.length === 1 ? 'Airline' : 'Airlines'})
              </span>
            </>
          )}
        </div>
        <Divider />
        {selectedAirlineId === null ? (
          <>
            {!!nodeServiceDetails?.airlines?.length &&
              <>
                <div
                  onClick={(e) => {e.stopPropagation(); setIsSortOptionsOpen(false)}}
                  onKeyDown={(e) => e.stopPropagation()}
                  className={classes.searchBarWrapper}
                >
                  <div>
                    <Input.Group compact style={{display:'flex'}}>
                      <Select
                        style={{ width: '150px' }}
                        value={searchParams.type}
                        onChange={(val) => setSearchParams({...searchParams, type: val as 'NAME' | 'CODE'})}
                      >
                        <Select.Option value={'NAME'}>By name</Select.Option>
                        <Select.Option value={'CODE'}>By code</Select.Option>
                        <Select.Option value={'ALLIANCE'}>By Alliance</Select.Option>
                      </Select>
                      <Input
                        placeholder='Search'
                        suffix={<SearchIcon />}
                        value={searchParams.requestStr}
                        onChange={(e) => setSearchParams({...searchParams, requestStr: e.target.value})}
                        className={classes.searchInput}
                      />
                    </Input.Group>
                  </div>
                  <SortBtn
                    isSortOptionsOpen={isSortOptionsOpen}
                    setIsSortOptionsOpen={(isOpen: boolean) => setIsSortOptionsOpen(isOpen)}
                    setSortBy={(sortBy: string | null) => setSortBy(sortBy as 'name' | 'code' | 'alliance' | null)}
                    setSortType={(type: 'asc' | 'desc' | false) => setSortType(type)}
                    sortBy={sortBy}
                    sortType={sortType}
                    sortOptions={[
                      {name: 'Name', value: 'name'},
                      {name: 'Code', value: 'code'},
                      {name: 'Alliance', value: 'alliance'},
                    ]}
                  />
                </div>
                <div>
                  {!!nodeServiceDetails?.airlines?.length && !!airlinesForDisplay.length &&
                  <>
                    {paginate(airlinesForDisplay)?.map((airline, index) => (
                      <div
                        key={airline.id}
                        className={classes.airlineWrapper}
                        onClick={() => setSelectedAirlineId(airline.id)}
                      >
                        <div className={classes.airlineColumn}>
                          {airline.name}
                        </div>
                        <div className={classes.codeColumn}>
                          {airline?.code}
                        </div>
                        <div className={classes.airlineColumn}>
                          {airline?.alliance?.name}
                          {/* {!!airline?.alliance?.name &&
                            <span className={classes.additionalData}>Alliance name</span>
                          } */}
                        </div>
                      </div>
                    ))}
                    <Pagination
                      current={pagination?.page}
                      pageSize={pagination?.size}
                      total={airlinesForDisplay?.length}
                      onChange={page => setPagination({...pagination, page})}
                      style={{paddingTop: '20px', display: 'flex', justifyContent: 'center'}}
                      showSizeChanger={false}
                    />
                  </>
                  }
                  {!!nodeServiceDetails?.airlines?.length && !airlinesForDisplay.length &&
                    <div style={{textAlign:'center', marginTop: '20px'}}>
                      No results for your search
                    </div>
                  }
                </div>
              </>
            }
          </>
        ) : (
          <AirportsWithFlightToLocation
            selectedAirlineId={selectedAirlineId}
            goBack={() => setSelectedAirlineId(null)}
          />
        )}
      </div>
    </Modal>
  )
}

const AirportsWithFlightToLocation: React.FC<{selectedAirlineId: number, goBack: () => void}> = ({selectedAirlineId, goBack}) => {
  const dispatch = useAppDispatch()
  const activeSearchResultTab = useAppSelector(selectActiveTab)
  const airportsWithFlightToLocation = useAppSelector(selectAirportsWithFlightToLocation)

  const [searchRadiusData, setSearchRadiusData] = useState({radius: 30, unit: 'KM'})
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [dispatch, activeSearchResultTab, selectedAirlineId])

  const getData = async() => {
    setIsLoading(true)
    dispatch(GetAirportsWithFlightToLocationThunk({
      airline_id: selectedAirlineId,
      nearest_request: {
        latitude: activeSearchResultTab.coordinates[0],
        longitude: activeSearchResultTab.coordinates[1],
        radius: searchRadiusData.radius,
        unit: searchRadiusData.unit as 'KM' | 'MILES'
      }
    })).then(() => setIsLoading(false))
  }

  if (isLoading) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <> 
      <div onClick={() => goBack()} className={classes.goBack}>
        <GoBackIcon /> <span>Back to Airlines</span>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px'}}>
        <div style={{fontSize: '18px'}}>
          List of Airports
        </div>
        <div className={classes.filtersWrapper}>
          <div className={classes.filtersBtn} onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
            <FiltersIcon /> Filters
          </div>
          {isFiltersOpen &&
            <div className={classes.radiusFilter}>
              <div>
                Set the radius of the search.
              </div>
                <Divider style={{margin: '8px 0px 6px 0px'}} />
                <Select
                  style={{ width: '100%', marginBottom: '6px' }}
                  value={searchRadiusData.unit}
                  onChange={(val) => setSearchRadiusData({...searchRadiusData, unit: val as 'KM' | 'MILES'})}
                >
                  <Select.Option value={'KM'}>KM</Select.Option>
                  <Select.Option value={'MILES'}>MILES</Select.Option>
                </Select>
                <InputNumber
                  value={searchRadiusData.radius}
                  onChange={(value) => setSearchRadiusData({...searchRadiusData, radius: value ? +value : 0})}
                  controls={false}
                  style={{ width: '100%', marginBottom: '12px' }}
                />
                <div style={{ width: '100%', display: 'flex' }}>
                  <Button
                    style={{ marginRight: '10px', width: '100%' }}
                    onClick={() => setIsFiltersOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='primary'
                    style={{ width: '100%' }}
                    onClick={() => {
                      setIsFiltersOpen(false)
                      getData()
                    }}
                  >
                      Set
                    </Button>
                </div>
            </div>
          }
        </div>
      </div>
      <div className={classes.additionalData} style={{marginBottom: '10px'}}>
        View list of airports from where the airline flies to the searched city.
      </div>
      {airportsWithFlightToLocation !== null && !!airportsWithFlightToLocation?.length &&
        airportsWithFlightToLocation?.map((airport, index) => (
          <div key={airport.id} className={classes.airlineAdditionalAirportData}>
            {index + 1}. {airport.name} {!!airport.code && '(' + airport.code + ')'} {!!airport?.city?.name && ' - ' + airport.city.name}{!!airport?.city?.country_name && ', ' + airport?.city?.country_name}
            <Divider style={{margin: '0px', marginTop: '10px'}}/>
          </div>
        ))
      }
      {airportsWithFlightToLocation !== null && !airportsWithFlightToLocation?.length &&
        <div style={{textAlign: 'center'}}>No airports found</div>
      }
    </>
  )
}

interface AirlinesModalPropTypes {
  isOpen: boolean
  closeModal: () => void
}

export default AirlinesForAirport
