import { message } from 'antd'
import { useEffect } from 'react'
import {Helmet} from 'react-helmet-async'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { AppStatusType, selectAppStatus, selectErrorMessage, selectInfoMessage } from '../store/appStatusReducer'
import { selectIsLoggedIn, selectTokenData } from '../store/userReducer'
import './App.less'
import { useAppSelector } from './hooks'
import routes from './routes'
import { refreshToken } from '../helpers/axiosHelper'

function App() {
  const { pathname } = useLocation()
  const tokenData = useAppSelector(selectTokenData)
  const appStatus = useAppSelector(selectAppStatus)
  const error = useAppSelector(selectErrorMessage)
  const infoMessage = useAppSelector(selectInfoMessage)

  useEffect(() => {
    appStatus === AppStatusType.failed && !!error?.length && message.error(error, 2)
    appStatus === AppStatusType.succeeded && !!infoMessage?.length && message.success(infoMessage, 2)
    appStatus === AppStatusType.info && message.info(infoMessage, 2)
  }, [appStatus, infoMessage, error])

  useEffect(() => {
    if (!!tokenData?.token && pathname !== '/widget') {
      refreshToken(tokenData.token)
    }
  // eslint-disable-next-line
  }, [tokenData])

  return (
    <div className={pathname === '/widget' ? '' : 'app'}>
      <Helmet>
        <title>Relavanti</title>
        <meta name='description' content='Travel and tourism solutions worldwide'/>
      </Helmet>
      <Routes>
        {routes.map((route) => (
          route.auth ? (
            <Route key={route.path} path={route.path} element={<PrivateRoute/>}>
              <Route path={route.path} element={<route.component {...route.props}/>}/>
            </Route>
          ) : (
            <Route key={route.path} element={<route.component {...route.props}/>} path={route.path}/>
          )
        ))}
      </Routes>
    </div>
  )
}

const PrivateRoute = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return isLoggedIn ? (
    <div className='appContent'>
      <Outlet />
    </div>
  ) : (
    <Navigate to='/sign-in' />
  )
}

export default App
