import { Select, Spin } from 'antd'
import classes from './../CruiseAdvancedSearch.module.css'
import { GetCruiseStartLocationsThunk, selectCruiseStartLocations, setCruiseStartLocations } from '../../../../../store/cruiseReducer'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import axios from './../../../../../helpers/axiosHelper'

const DepartureLocationsField: React.FC<{setFormValues: Dispatch<SetStateAction<any>>, formValues: any}> = ({setFormValues, formValues}) => {
  const dispatch = useAppDispatch()
  const departureOptions = useAppSelector(selectCruiseStartLocations)
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  const [isLoading, setIsLoading] = useState(false)
  const [departureSearchString, setDepartureSearchString] = useState('')

  useEffect(() => {
    if (!!departureSearchString.length) {
      dispatch(setCruiseStartLocations({locations: []}))
      setIsLoading(true)
      dispatch(GetCruiseStartLocationsThunk({name: departureSearchString, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, departureSearchString])

  const handleDepartureSelect = async(location: string, locationId: number) => {
    // if (location.includes('regionName')) {
    //   const name = location.split('regionName+')[1]
    //   setFormValues({...formValues, regions: [...(formValues?.regions || []), {region_id: locationId, name}]})
    // } else 
    if (location.includes('cityName')) {
      const name = location.split('cityName+')[1] 
      setFormValues({...formValues, cities: [...(formValues?.cities || []), {city_id: locationId, name}]})
    } else {
      const name = location.split('countryName+')[1]
      setFormValues({...formValues, countries: [...(formValues?.countries || []), {country_id: locationId, name}]})
    }
    setDepartureSearchString('')
  }
  
  const handleLocationDeselect = async(location: string, locationId: number) => {
    if (location.includes('region')) {
      setFormValues({...formValues, regions: formValues.regions?.filter((loc:any) => loc.region_id !== locationId)})
      dispatch(setCruiseStartLocations({...departureOptions, regions: departureOptions?.regions?.filter(r => r.region_id !== locationId)}))
    } else if (location.includes('city')) {
      setFormValues({...formValues, cities: formValues.cities?.filter((loc:any) => loc.city_id !== locationId)})
      dispatch(setCruiseStartLocations({...departureOptions, locations: departureOptions?.locations?.filter(loc => loc.city_id !== locationId)}))
    } else if (location.includes('country')) {
      setFormValues({...formValues, countries: formValues.countries?.filter((loc:any) => loc.country_id !== locationId)})
      dispatch(setCruiseStartLocations({...departureOptions, locations: departureOptions?.locations?.filter(loc => loc.country_id !== locationId)}))
    } else {
      setFormValues({
        ...formValues,
        // regions: formValues.regions?.filter((loc:any) => loc.region_id !== locationId),
        cities: formValues.cities?.filter((loc:any) => loc.city_id !== locationId),
        countries: formValues.countries?.filter((loc:any) => loc.country_id !== locationId)
      })
      dispatch(setCruiseStartLocations({
        locations: departureOptions?.locations?.filter(o => o.country_id !== locationId && o.city_id !== locationId), 
        // regions:  departureOptions?.regions?.filter(r => r.region_id !== locationId),
      }))
    }
  }

  return (
    <div className={classes.formItemWrapper}>
      <div className={classes.formItemLabel}>
        <span>Departure:</span> Ports within 100 km of the location
      </div>
      <div id='departureSelect'>
        <Select
          showSearch
          style={{width: '100%', marginBottom: '25px'}}
          placeholder='Start typing location'
          onSearch={searchQuery => setDepartureSearchString(searchQuery)}
          onSelect={(val:any) => handleDepartureSelect(val.key, val.value)}
          onDeselect={(val:any) => handleLocationDeselect(val.key, val.value)}
          searchValue={departureSearchString}
          labelInValue
          filterOption={false}
          mode='multiple'
          getPopupContainer={() => document.getElementById('departureSelect')!}
          value={[
            // ...(formValues?.regions?.map((c:any) => ({value: c.region_id, label: c.name})) || []),
            ...(formValues?.cities?.map((c:any) => ({value: c.city_id, label: c.name})) || []),
            ...(formValues?.countries?.map((c:any) => ({value: c.country_id, label: c.name})) || []),
            ]}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!departureSearchString.length && !departureOptions?.locations?.length && 'No results found'}
              {!departureSearchString.length && !departureOptions?.locations?.length && 'Start typing location'}
            </>
          )}
        >
          {/* {!!departureOptions?.regions?.length &&
            <Select.OptGroup label={'Regions'}>
              {departureOptions?.regions?.map(opt => (
                <Select.Option value={opt.region_id} key={opt.region_id + 'regionName+' + opt.name}>
                  {opt.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          } */}
          {!!departureOptions?.locations?.length &&
            <Select.OptGroup label={'Locations'}>
              {departureOptions?.locations?.map(opt => (
                <Select.Option
                  value={opt.city_id || opt.country_id}
                  key={opt.city_id
                    ? opt.city_id + 'cityName+' + opt.country_name + ', ' + opt.city_name
                    : opt.country_id + 'countryName+' + opt.country_name
                  }
                >
                  {/* @ts-ignore */}
                  {opt.name || opt.country_name + (opt.city_name ? ', ' + opt.city_name : '')}
                </Select.Option>
              ))}
            </Select.OptGroup>
          }
        </Select>
      </div>
    </div>
  )
}

export default DepartureLocationsField
