import { Checkbox, Select, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { GetCruiseLineOptionsThunk, selectCruiseLineOptions, selectCruiseLineTypes, setCruiseLineOptions } from '../../../../../store/cruiseReducer'
import classes from './../CruiseAdvancedSearch.module.css'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import axios from './../../../../../helpers/axiosHelper'
import { AdvancedCruiseSearchData } from '../../../../../types/cruiseTypes'

const SpecificCruiseLinesField: React.FC<{setFormValues: Dispatch<SetStateAction<AdvancedCruiseSearchData>>, formValues: AdvancedCruiseSearchData}> = (props) => {
  const cruiseLineTypes = useAppSelector(selectCruiseLineTypes)

  return (
    <div className={classes.doubleInputWrapper}>
      {cruiseLineTypes?.map(type => (
        <LineField 
          key={type.id}
          {...props}
          type={type}
        />
      ))}
    </div>
  )
}

const LineField: React.FC<{setFormValues: Dispatch<SetStateAction<AdvancedCruiseSearchData>>, formValues: AdvancedCruiseSearchData, type: {name: string, id: number}}> = ({setFormValues, formValues, type}) => {
  const dispatch = useAppDispatch()
  const cruiseLines = useAppSelector(selectCruiseLineOptions)

  const [isLoading, setIsLoading] = useState(false)
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if (!!searchString.length) {
      setIsLoading(true)
      dispatch(setCruiseLineOptions(null))
      dispatch(GetCruiseLineOptionsThunk({name: searchString, type: type.id, source}))
        .then((resp) => {
          !resp.type.includes('rejected') && setIsLoading(false)
        })
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, searchString])

  const onCruiseLineTypeChange = (checked: boolean, type_id: number) => {
    if (checked) {
      setFormValues({...formValues, cruise_line_types: [...(formValues?.cruise_line_types || []), type_id]})
    } else {
      setFormValues({
        ...formValues,
        cruise_lines: formValues?.cruise_lines?.filter(l => l.type_id !== type_id),
        cruise_line_types: formValues?.cruise_line_types?.filter((type:number) => type !== type_id)
      })
    }
  }

  const onCruiseLineSelect = (name: string, id: number, type_id: number) => {
    setFormValues({
      ...formValues,
      cruise_lines: [...(formValues.cruise_lines || []), {id: +id, name, type_id}],
      cruise_line_types: formValues.cruise_line_types?.filter((type:number) => type !== type_id)
    })
    setSearchString('')
    dispatch(setCruiseLineOptions(null))
  }

  const onCruiseLineDeselect = (id: number) => {
    setFormValues({
      ...formValues,
      cruise_lines: formValues?.cruise_lines?.filter(l => l.id !== id),
    })
    setSearchString('')
    dispatch(setCruiseLineOptions(null))
  }

  const onInputFocus = () => {
    setSearchString('')
    if (cruiseLines?.length && cruiseLines?.[0]?.type_id !== type.id) {
      dispatch(setCruiseLineOptions(null))
    }
  }

  return (
    <div className={classes.formItemWrapper}>
      <div className={classes.formItemLabel}>
        <Checkbox
          onChange={e => onCruiseLineTypeChange(e.target.checked, type.id)}
          checked={formValues?.cruise_line_types?.includes(type.id) || formValues?.cruise_lines?.some(l => l.type_id === type.id)}
        >
          <div className={classes.formItemLabel}>{type.name}</div>
        </Checkbox>
      </div>
      <div id='select'>
        <Select
          showSearch
          style={{width: '100%', marginBottom: '25px'}}
          placeholder='Start typing line name'
          onSearch={searchQuery => setSearchString(searchQuery)}
          onSelect={(val:any) => onCruiseLineSelect(val.label, val.value, +val.key)}
          onDeselect={(val:any) => onCruiseLineDeselect(val.value)}
          searchValue={searchString}
          labelInValue
          onFocus={onInputFocus}
          filterOption={false}
          value={formValues.cruise_lines?.filter(l => l.type_id === type.id).map(l => ({label: l.name, value: l.id}))}
          mode='multiple'
          getPopupContainer={() => document.getElementById('select')!}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!searchString.length && !cruiseLines?.length && 'No results found'}
              {!searchString.length && !cruiseLines?.length && 'Start typing line name'}
            </>
          )}
        >
          {cruiseLines?.map(opt => (
            <Select.Option value={opt.id} key={opt.type_id}>
              {opt.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default SpecificCruiseLinesField
