import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { decodeFlightCruiseSearchResultsExportLink } from '../../../../helpers/linksHelper'
import { resultCategories, selectActiveTab } from '../../../../store/searchResultsReducer'
import { selectIsCityResultsOpen, selectIsStopTabSelected } from '../../../../store/travelSearchReducer'
import { SharedFlightCruiseDetailsType } from '../../../../types/appTypes'
import Breadcrumbs from '../../../common/Breadcrumbs/Breadcrumbs'
import Footer from '../../../common/Footer/Footer'
import Header from '../../../common/Header/Header'
import GoogleMap from '../../../SearchResults/Map/GoogleMap'
import ResultCategories from '../../../SearchResults/ResultTabs/Results/ResultCategories/ResultCategories'
import classes from './SearchResultPage.module.css'
import { ReactComponent as ShowMapIcon } from './../../../../img/icons/showMapIcon.svg'

const SearchResultPage:React.FC<SearchResultPagePropTypes> = ({
  dataList,
  dataItem,
  getData,
  getSearchDataFromUrl,
  children,
  isDetailsPageOpen,
  itemMapCenter,
  departureLocation,
  stopLocations,
  arrivalLocation,
  handleStopMarkerClick,
  searchType,
  activeInfoBlock
}) => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const isCityResultsOpen = useAppSelector(selectIsCityResultsOpen)
  const activeTab = useAppSelector(selectActiveTab)
  const isStopTabSelected = useAppSelector(selectIsStopTabSelected)

  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})

  const [isLoading, setIsLoading] = useState(false)
  const [mapCenter, setMapCenter] = useState<[number, number]>([0, 0])
  const [openMapMobileView, setOpenMapMobileView] = useState(false)

  useEffect(() => {
    if (dataList === null && !!search && !!getData) {
      const searchData = decodeFlightCruiseSearchResultsExportLink(getSearchDataFromUrl)
      setIsLoading(true)
      getData(searchData.searchData, searchData.sharedDetails)
        .then(() => setIsLoading(false))
    }
    // eslint-disable-next-line
  }, [dispatch, dataList, search])

  useEffect(() => {
    if (!!dataItem && !!isDetailsPageOpen && itemMapCenter?.lat !== 0 && itemMapCenter?.lng !== 0) {
      setMapCenter([itemMapCenter?.lat, itemMapCenter?.lng])
    }
    // eslint-disable-next-line
  }, [dispatch, dataItem, isDetailsPageOpen, itemMapCenter])

  return (
    <>
      <div className={classes.wrapper}>
        <Header showMenu={true} className={classes.header}/>
        <div className={classes.breadcrumbsWrapper}>
          <Breadcrumbs showShare showSave/>
        </div>
        <div className={classes.blocksWrapper}>
          {!!isCityResultsOpen && !!isSmallScreen &&
            <ResultCategories
              resultCategories={resultCategories}
              className={classes.resultCategories}
            />
          }
          <InfoBlock
            isLoading={isLoading}
            style={{display: openMapMobileView ? 'none' : 'block'}}
          >
            {children}
          </InfoBlock>
          {!!isSmallScreen && !openMapMobileView && activeInfoBlock.includes('Details') && 
            <div 
              className={classes.showMapSmallScreenBtn}
              onClick={() => setOpenMapMobileView(true)}
            >
              <ShowMapIcon /> View map
            </div>
          }

          <div style={{height: !!isCityResultsOpen && !isSmallScreen ? 'calc(100% - 50px)' : '100%'}}>
            {!!isCityResultsOpen && !isSmallScreen &&
              <ResultCategories
                resultCategories={resultCategories}
                className={classes.resultCategories}
              />
            }
            <div
              className={classes.mapWrapper}
              style={{
                visibility: !isSmallScreen || openMapMobileView ? 'visible' : 'hidden',
                ...(isSmallScreen && !openMapMobileView ? {height: '10px', overflow: 'hidden'} : {})
              }}
            >
            <GoogleMap
                showCenter={isDetailsPageOpen}
                center={isCityResultsOpen ? activeTab?.coordinates : mapCenter}
                isNodeDetailsOpen={isStopTabSelected}
                pathCoordinates={
                  dataItem !== null && !!Object.keys(dataItem)?.length && !!departureLocation?.id ? [
                    departureLocation,
                    ...(stopLocations?.length ? stopLocations : []),
                    arrivalLocation
                  ] : []
                }
                pathIconsType={searchType}
                handleTripStopMarkerClick={handleStopMarkerClick}
                smallScreenHandleShowList={() => setOpenMapMobileView(false)}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const InfoBlock: React.FC<{isLoading: boolean, children:any, style?: object}> = ({isLoading, children, style}) => {
  if (isLoading) {
    return <Spin />
  } else {
    return (
      <div className={classes.informationWrapper} style={style}>
        <div>
          {children}
        </div>
      </div>
    )
  }
}

interface SearchResultPagePropTypes {
  dataList: any[] | null
  dataItem: any
  getData?: (data:any, sharedDetails?: SharedFlightCruiseDetailsType) => Promise<any>
  getSearchDataFromUrl: (data:any) => object
  children: any
  isDetailsPageOpen: boolean
  itemMapCenter: {lat: number, lng: number}
  departureLocation: {id: number, info: string, lat: number, lng: number}
  stopLocations: {id: number, info: string, lat: number, lng: number, stopData?: any}[]
  arrivalLocation: {id: number, info: string, lat: number, lng: number}
  searchType: 'byFlight' | 'byCruise'
  handleStopMarkerClick?: (id: number) => void
  activeInfoBlock: 'cruises' | 'cruiseDetails' | 'flights' | 'flightDetails'
}

export default SearchResultPage
