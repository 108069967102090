import { Link } from 'react-router-dom'
import { Input, Pagination, Radio, Spin, Table } from 'antd'
import moment from 'moment'
import { debounce } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectIsLoggedIn } from '../../../store/userReducer'
import classes from './YourEventsBlock.module.css'
import EventCard from './EventCard/EventCard'
import { ReactComponent as SearchIcon } from './../../../img/icons/search.svg'
import { ReactComponent as ListIconList } from './../../../img/icons/listIconList.svg'
import { ReactComponent as ListIconBlocks } from './../../../img/icons/listIconBlocks.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/delete.svg'
import {ReactComponent as EditIcon} from './../../../img/icons/edit.svg'
import { useCallback, useEffect, useState } from 'react'
import { GetUserEventsThunk, selectMyEventSelectedKind, selectUserEvents, selectUserEventsPagination, selectUserEventsTotalCount, setMyEventSelectedKind, setSelectedEvent, setUserEventsPagination } from '../../../store/eventReducer'
import { EventType } from '../../../types/eventTypes'
import EventDate from './EventCard/cardElements/EventDate'
import EventTime from './EventCard/cardElements/EventTime'
import Code from './EventCard/cardElements/Code'
import EventFormModal from '../EventFormModal/EventFormModal'
import { ColumnGroupType, ColumnType } from 'antd/lib/table'
import CopyLink from './EventCard/cardElements/CopyLink'
import { useMediaQuery } from 'react-responsive'
import EventDetailsTag from './EventCard/cardElements/EventDetailsTag'

const YourEventsBlock = () => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const userEvents = useAppSelector(selectUserEvents)
  const pagination = useAppSelector(selectUserEventsPagination)
  const eventKind = useAppSelector(selectMyEventSelectedKind)
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 1112px)'})

  const [listType, setListType] = useState<'list' | 'card'>(isSmallScreen ? 'card' : 'list')
  const [searchQuery, setSearchQuery] = useState('')
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    if (isLoggedIn) {
      setIsDataLoading(true)
      dispatch(GetUserEventsThunk({pagination, params: {searchQuery, eventKind}}))
        .then(() => setIsDataLoading(false))
    }
  }, [dispatch, isLoggedIn, pagination, searchQuery, eventKind])

    // eslint-disable-next-line
    const handleSearchDebounce = useCallback(
      debounce((query: string) => {
        setSearchQuery(query)
      }, 350),
      []
    )

  return (
    <div className={classes.wrapper}>
      <h2>
        Your Events
      </h2>
      <div className={classes.toolbar}>
        <Radio.Group
          className={classes.eventKindTool}
          onChange={(e) => dispatch(setMyEventSelectedKind(e.target.value))}
          value={eventKind}
          optionType='button'
        >
          <Radio value={'Location'}>By Location</Radio>
          <Radio value={'Cruise'}>By Cruise</Radio>
        </Radio.Group>
        <div className={classes.searchBar}>
          <Input
            placeholder='Search your events...'
            prefix={<SearchIcon style={{marginRight: '10px'}}/>}
            className={classes.search}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
          />
          <ListIconList
            style={{marginRight: '10px'}}
            className={`${classes.toolbarIcon} ${listType === 'list' ? classes.activeListType : ''}`}
            onClick={() => setListType('list')}
          />
          <ListIconBlocks
            onClick={() => setListType('card')}
            className={`${classes.toolbarIcon} ${listType === 'card' ? classes.activeListType : ''}`}
          />
        </div>
      </div>
      {!isDataLoading && (!!userEvents?.filter(e => e.event_kind === eventKind)?.length ? (
          <EventList listType={listType} eventKind={eventKind}/>
        ): (
          <EmptyEventList />
        )
      )}
      {!!isDataLoading && <Spin />}
    </div>
  )
}

const EventList: React.FC<EventListPropTypes>= ({listType, eventKind}) => {
  const dispatch = useAppDispatch()
  const userEvents = useAppSelector(selectUserEvents)
  const userEventsTotalCount = useAppSelector(selectUserEventsTotalCount)
  const pagination = useAppSelector(selectUserEventsPagination)

  const [eventFormType, setEventFormType] = useState<'' | 'edit' | 'delete'>('')

  const onActionIconClick = (event: EventType, btnType: 'edit' | 'delete') => {
    dispatch(setSelectedEvent(event))
    setEventFormType(btnType)
  }

  const onPaginationChange = (pageNumber: number) => {
    dispatch(setUserEventsPagination({...pagination, page: pageNumber}))
  }

  return (
    <>
      {listType === 'card' &&
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className={classes.cardViewWrapper}>
            {userEvents?.filter(e => e.event_kind === eventKind)?.map(e => (
              <EventCard event={e} key={e.id} onActionIconClick={onActionIconClick}/>
            ))}
          </div>
          {userEventsTotalCount > pagination.size &&
            <Pagination
              current={pagination.page}
              pageSize={pagination.size}
              total={userEventsTotalCount}
              onChange={page => onPaginationChange(page)}
              style={{alignSelf: 'center', paddingTop: '20px'}}
            />
          }
        </div>
      }
      {listType === 'list' &&
        <Table
          columns={getListColumns(onActionIconClick)}
          dataSource={userEvents || []}
          rowKey={(event: EventType) => event?.id}
          pagination={{
            position: ['bottomCenter'],
            pageSize: pagination.size,
            current: pagination.page,
            total: userEventsTotalCount,
            onChange: page => onPaginationChange(page)
          }}
          className={classes.eventTable}
        />
      }
      {eventFormType !== '' &&
        <EventFormModal
          isOpen={!!eventFormType.length}
          onCancel={() => {
            setEventFormType('')
            dispatch(setSelectedEvent(null))
          }}
          isDeleteConformation={eventFormType === 'delete'}
        />
      }
    </>
  )
}

const EmptyEventList = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return (
    <div className={classes.emptyListWrapper}>
      <div className={classes.emptyListText}>
        The list is empty
      </div>
      {!isLoggedIn &&
        <Link to='/sign-in'>
          Sign in to your account
        </Link>
      }
    </div>
  )
}

const getListColumns = (onActionIconClick: (event: EventType, btnType: 'edit' | 'delete') => void): (ColumnGroupType<EventType> | ColumnType<EventType>)[] => [
  {
    title: 'Event Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'Data/Time',
    dataIndex: 'start_date',
    key: 'start_date',
    ellipsis: true,
    render: (_: string, row: EventType) => (
      <>
        <EventDate date={row.start_date} showDay/>
        {!moment(row.end_date).isSame(moment(row.start_date),'day') &&
          <EventDate date={row.end_date} showDay/>
        }
        <EventTime start_date={row.start_date} end_date={row.end_date}/>
      </>
    )
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    ellipsis: true,
    render: (code: string, event: EventType) => (
      <div style={{display: 'flex', alignItems: 'center', whiteSpace: 'pre-wrap'}}>
        <Code code={code} />
        {event.is_creator && <EventDetailsTag tag='My event'/>}
      </div>
    )
  },
  {
    title: 'Link',
    dataIndex: 'code',
    key: 'code',
    ellipsis: true,
    render: (code: string, event: EventType) => <CopyLink code={code} type={event.event_type} />
  },
  {
    title: 'About',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    render: (description: string, event: EventType) => (
      <div style={{display: 'grid', gridTemplateColumns: '1fr 70px', alignItems: 'center', whiteSpace: 'pre-wrap'}}>
        <div>
          {description}
        </div>
        <div style={{marginBottom: '-10px'}}>
          <DeleteIcon
            onClick={() => onActionIconClick(event, 'delete')}
            style={{marginRight: '10px', cursor: 'pointer'}}
          />
          {!!event.is_creator && <EditIcon
            onClick={() => onActionIconClick(event, 'edit')}
            style={{cursor: 'pointer'}}
          />}
        </div>
      </div>
    )
  },
]

interface EventListPropTypes {
  listType: 'list' | 'card'
  eventKind: 'Location' | 'Cruise'
}

export default YourEventsBlock
