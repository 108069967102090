import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './../types/appTypes'
import { TerminalType } from '../types/searchResultTypes'

interface InitialStateType {
  selectedNodeTerminals: TerminalType[] | null
}

const initialState: InitialStateType = {
  selectedNodeTerminals: null,
}

export const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    setSelectedNodeTerminals: (state, action: PayloadAction<TerminalType[] | null>) => {state.selectedNodeTerminals = action.payload},
  },
})

export const { 
  setSelectedNodeTerminals,
} = terminalSlice.actions

export const selectSelectedNodeTerminals = (state: RootState): TerminalType[] | null => state.terminal.selectedNodeTerminals

export default terminalSlice.reducer
 