import GoogleMapReact from 'google-map-react'
import React, { useEffect, useRef, useState } from 'react'
import classes from './GoogleMap.module.css'
import { AirportSearchResultType } from '../../../types/airportTypes'
import { getCenterIcon, getCruiseStopIcon, getDestinationIcon, getDashedPath, getFlightTransferIcon, getMapMarker, getMapZoom, getZoomForPath, showTransportStopMarker } from '../../../helpers/mapMarkerHelper'
import {ReactComponent as MapLegendIcon} from './../../../img/icons/mapLegend.svg'
import MapLegend from './MapLegend'
import {ReactComponent as ShowListIcon} from './../../../img/icons/showListIcon.svg'
import { PortSearchResultType } from '../../../types/portTypes'
import { useAppDispatch, useAppSelector, usePrevious } from '../../../app/hooks'
import { selectActiveResultCategories, selectActiveResultBlock, selectSearchResults, selectSelectedTerminal, selectSelectedTransportStop, selectNodeServiceDetails, selectIsTerminalListOpen } from '../../../store/searchResultsReducer'
import { pickBy } from 'lodash'
import { SearchResultType } from '../../../types/searchResultTypes'
import { selectDistanceType } from '../../../store/appStatusReducer'
import {
  setInfoWindows,
  setMap as setMapMap,
  setMaps as setMapMaps,
  selectAdditionalMarkersData,
} from '../../../store/mapReducer'
import { selectAdvertisementMapComponent } from '../../../store/advertisementReducer'
import AdvertisingBanner from '../../common/AdvertisingBanner/AdvertisingBanner'
import { selectCurrentActiveTabData, selectSelectedStopTerminals } from '../../../store/travelSearchReducer'
import { selectSelectedNodeTerminals } from '../../../store/terminalReducer'

const GoogleMap: React.FC<MapProps> = ({center, smallScreenHandleShowList, isSmallScreenOpen, isNodeDetailsOpen, pathCoordinates, pathIconsType, handleTripStopMarkerClick, showCenter, ignoreCenterChange}) => {
  const dispatch = useAppDispatch()
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const searchResults = useAppSelector(selectSearchResults)
  const selectedTransportStop = useAppSelector(selectSelectedTransportStop)
  const selectedTerminal  = useAppSelector(selectSelectedTerminal)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const travelSearchActiveTabData = useAppSelector(selectCurrentActiveTabData)
  const distanceType = useAppSelector(selectDistanceType)
  const activeResultBlock = useAppSelector(selectActiveResultBlock)
  const additionalMarkersData = useAppSelector(selectAdditionalMarkersData)
  const advertisements = useAppSelector(selectAdvertisementMapComponent)
  const selectedNodeTerminals = useAppSelector(selectSelectedNodeTerminals)
  const isTerminalListOpen = useAppSelector(selectIsTerminalListOpen)
  const selectedStopTerminals = useAppSelector(selectSelectedStopTerminals)
  const prevActiveResultBlock = usePrevious(activeResultBlock)
  const prevAdditionalMarkersData = usePrevious(additionalMarkersData)

  console.log('map rerender')

  const [points, setPoints] = useState<Array<AirportSearchResultType| PortSearchResultType>>([])
  const [path, setPath] = useState<any>([])
  const [pathPoints, setPathPoints] = useState<any>([])
  const myMap = useRef(null)
  const mapCenter = center || [0, 0]

  const ref = useRef(null)
  const prevCenter = usePrevious(mapCenter)
  const prevPathCoordinates = usePrevious(pathCoordinates)

  const location = {
    lat: mapCenter[0],
    lng: mapCenter[1],
  }

  const [isLegendIconVisible, setIsLegendIconVisible] = useState(false)
  const [isLegendOpen, setIsLegendOpen] = useState(false)
  const [mapSettings, setMapSettings] = useState<{center: LocationType, zoom: number}>(
      {
        center: location,
        zoom: JSON.stringify(center) === '[0, 0]' ? 1 : getMapZoom(location, points.length)
      }
  )
  const [map, setMap] = useState()
  const [maps, setMaps] = useState<any>()
  const [markers, setMarkers] = useState<any[]>([])
  const [additionalMarkers, setAdditionalMarkers] = useState<any[]>([])
  const [transportStopMarker, setTransportStopMarker] = useState<any>()
  const [terminalMarker, setTerminalMarker] = useState<any>()
  const [terminalMarkers, setTerminalMarkers] = useState<any>([])
  const [searchLocationMarker, setSearchLocationMarker] = useState<any>()
  const prevMaps = usePrevious(maps)
  const handleChangeMap = (a: {center: LocationType, zoom: number}) => {
    setMapSettings({center: a.center, zoom: a.zoom})
  }

  // adding transport stop marker
  useEffect(() => {
    const hasSelectedStopChanged = !!transportStopMarker && !!selectedTransportStop?.location && selectedTransportStop?.id !== transportStopMarker.id
    const isStopDeselected = !selectedTransportStop?.location && !!transportStopMarker
    const isStopSelected = !!selectedTransportStop?.location && !!maps && !transportStopMarker
    if (isStopDeselected || hasSelectedStopChanged) {
      !!transportStopMarker?.setMap && transportStopMarker.setMap(null)
      setTransportStopMarker(null)
      setMapSettings({
        ...mapSettings,
         zoom: 11
       })
    }
    if (isStopSelected || hasSelectedStopChanged) {
      const marker = showTransportStopMarker(map, maps, {id: selectedTransportStop?.id!, location: selectedTransportStop?.location!, name: selectedTransportStop?.name!})
      ref.current = marker
      setTransportStopMarker(marker)
      setMapSettings({
        center: {
          lat: selectedTransportStop?.location?.latitude!,
          lng: selectedTransportStop?.location?.longitude!
        },
        zoom: 15
      })
    }
    // eslint-disable-next-line
  }, [selectedTransportStop, map, maps, transportStopMarker, isNodeDetailsOpen])


  const checkIfStopOverlayStartEndPoint = (start: {lat: number, lng: number}, end: {lat: number, lng: number}, stop: {lat: number, lng: number}) => {
    return (stop.lat === start.lat && stop.lng === start.lng) || (stop.lat === end.lat && stop.lng === end.lng)
  }
  // adding path
  useEffect(() => {
    if (!!maps && !!pathCoordinates?.length && !pathPoints?.length && (JSON.stringify(pathCoordinates) !== JSON.stringify(prevPathCoordinates) || (prevMaps === undefined))) {
      const flightPath = getDashedPath(maps, pathCoordinates, pathCoordinates?.length <= 2 && pathIconsType === 'byFlight')
      const startPoint = getCenterIcon(maps, map, pathCoordinates?.[0]?.lat!, pathCoordinates?.[0]?.lng!, pathCoordinates?.[0]?.info!, pathCoordinates?.[0].id, pathIconsType === 'byCruise' ? 2 : undefined)
      const destinationPoint = pathIconsType === 'byFlight' 
        ? getDestinationIcon(maps, map, pathCoordinates?.[pathCoordinates?.length - 1]?.lat!, pathCoordinates?.[pathCoordinates?.length - 1]?.lng!, pathCoordinates?.[pathCoordinates?.length - 1]?.id, pathCoordinates?.[pathCoordinates?.length - 1]?.info!)
        : getCenterIcon(maps, map, pathCoordinates?.[pathCoordinates?.length - 1]?.lat!, pathCoordinates?.[pathCoordinates?.length - 1]?.lng!, pathCoordinates?.[pathCoordinates?.length - 1]?.info!, pathCoordinates?.[pathCoordinates?.length - 1].id, 2)
      const stopCoordinates = pathCoordinates?.slice(1, -1)
      const stopsPoint = stopCoordinates?.length 
        ? stopCoordinates
          .map((stop, index) => {
            const isStartEndDuplicate = checkIfStopOverlayStartEndPoint({lat: pathCoordinates[0].lat, lng: pathCoordinates[0].lng}, {lat: pathCoordinates?.[pathCoordinates?.length - 1].lat, lng: pathCoordinates?.[pathCoordinates?.length - 1].lng}, {lat: stop.lat, lng: stop.lng})
            const isNotForDisplay = stop?.stopData?.port?.port_type === 'EXCURSION' || stop?.stopData?.port?.port_type === 'GENERIC' || !stop?.lat
            return pathIconsType === 'byFlight'
              ? getFlightTransferIcon(maps, map, stop?.lat, stop?.lng, stop?.id, stop?.info, handleTripStopMarkerClick)
              : getCruiseStopIcon(maps, map, stop?.lat, stop?.lng, stop?.stopData, index + 2, stop?.info, handleTripStopMarkerClick, isStartEndDuplicate || isNotForDisplay)
          })
        : []
      setPath(flightPath)
      setPathPoints([
        startPoint, 
        destinationPoint, ...stopsPoint])
      startPoint?.setMap(map!)
      destinationPoint?.setMap(map!)
      flightPath?.forEach(segment => segment?.setMap(map!))
    } else if (!pathCoordinates?.length && !!pathPoints?.length) {
      path?.forEach((segment:any) => segment?.setMap(null))
      setPath(null)
      pathPoints?.forEach((point: any) => point?.setMap(null))
      setPathPoints([])
    }
    // eslint-disable-next-line
  }, [pathCoordinates, map, maps, pathIconsType, pathPoints, path])

  // adding selected terminal marker
  useEffect(() => {
    const hasSelectedTerminalChanged = !!terminalMarker && !!selectedTerminal?.location && selectedTerminal?.id !== terminalMarker.id
    const isTerminalDeselected = !selectedTerminal?.location && !!terminalMarker
    const isTerminalSelected = !!selectedTerminal?.location && !!maps && !terminalMarker
    if (isTerminalDeselected || hasSelectedTerminalChanged) {
      !!terminalMarker?.setMap && terminalMarker.setMap(null)
      setTerminalMarker(null)
      setMapSettings({
       ...mapSettings,
        zoom: 10
      })
    }
    if (isTerminalSelected || hasSelectedTerminalChanged) {
      const marker = showTransportStopMarker(map, maps, {id: selectedTerminal?.id!, location: selectedTerminal?.location!, name: selectedTerminal?.name!})
      ref.current = marker
      setTerminalMarker(marker)
      setMapSettings({
        center: {
          lat: selectedTerminal?.location?.latitude!,
          lng: selectedTerminal?.location?.longitude!
        },
        zoom: 15
      })
    }
    // eslint-disable-next-line
  }, [selectedTerminal, map, maps, terminalMarker, isNodeDetailsOpen])

    // adding all node terminals markers
    useEffect(() => {
      if (selectedNodeTerminals !== null && !!selectedNodeTerminals?.length && (isNodeDetailsOpen || !!selectedStopTerminals?.length)) {
        !!terminalMarker?.setMap && terminalMarker.setMap(null)
        setTerminalMarker(null)
        const newMarkers = (selectedNodeTerminals || selectedStopTerminals || []).map((terminal) => {
          const marker = showTransportStopMarker(map, maps, {id: terminal?.id!, location: {latitude: terminal?.latitude, longitude: terminal?.longitude}, name: terminal?.name!}, false)
          ref.current = marker
          return marker
        })
        setTerminalMarkers(newMarkers)
        setMapSettings({
          ...mapSettings,
          zoom: 11
        })
      } else if (selectedNodeTerminals === null) {
        terminalMarkers.forEach((marker: any) => !!marker?.setMap && marker.setMap(null))
        setTerminalMarkers([])
      }
      // eslint-disable-next-line
    }, [selectedNodeTerminals, map, maps, isNodeDetailsOpen, selectedStopTerminals])

  // adding additional markers
  useEffect(() => {
    if (!!additionalMarkersData.length && !(prevAdditionalMarkersData||[]).length) {
      additionalMarkers.forEach((marker:any) => marker?.setMap && marker?.setMap(null))      
      const newMarkers = additionalMarkersData.map((markerData, index) => {
        const {marker, infoWindow} = getMapMarker(map, maps, {lat: markerData.coordinates[0], lng: markerData.coordinates[1]}, markerData.nodeDetails!, index + 1, markerData.nodeDetails?.details?.id || index, distanceType)
        ref.current = marker
        return {marker, infoWindow}
      })
      setAdditionalMarkers(newMarkers)
    } else if (!additionalMarkersData.length && !!additionalMarkers.length) {
      additionalMarkers.forEach(marker => marker?.setMap && marker.setMap(null))
      setAdditionalMarkers([])
    }
  }, [additionalMarkersData, prevAdditionalMarkersData, additionalMarkers, distanceType, map, maps])

  useEffect(() => {
    const isTerminalSelected = !!selectedTerminal?.location && !!maps && !terminalMarker
    if (((!isNodeDetailsOpen && !travelSearchActiveTabData?.id) || (prevActiveResultCategories[0] === 'near' && activeResultCategories[0] !== 'near')) && !isTerminalListOpen && !isTerminalSelected) {
      !!transportStopMarker?.setMap && transportStopMarker.setMap(null)
      setTransportStopMarker(null)
      setMapSettings({
        ...mapSettings,
        center: location,
        zoom: pathCoordinates?.length && myMap
          ? getZoomForPath(pathCoordinates.map(p => ({lat: p?.lat, lng: p?.lng})), myMap)?.zoom 
          : !!pathIconsType ? 1 : 7
      })
    } else if (!!isNodeDetailsOpen || !!travelSearchActiveTabData?.id) { 
      setMapSettings({
        center: {
          lat: (nodeServiceDetails?.location?.latitude || travelSearchActiveTabData?.location?.latitude) as number,
          lng: (nodeServiceDetails?.location?.longitude || travelSearchActiveTabData?.location?.longitude) as number},
        zoom: 11
      })
    }
    // eslint-disable-next-line
  }, [isNodeDetailsOpen, activeResultCategories, nodeServiceDetails, pathCoordinates, travelSearchActiveTabData])

   // adding center or start point
   useEffect(() => {
    const centerHasChanged = (prevCenter?.[0] !== mapCenter?.[0] || prevCenter?.[1] !== mapCenter?.[1]) && JSON.stringify(center) !== '[0,0]'
    const hasNoCenterYet = !!Object.keys(mapSettings?.center)?.length && !searchLocationMarker && JSON.stringify(center) !== '[0,0]'
    const isIncludedToPathPoints = pathCoordinates?.some(point => point.lat === mapCenter[0] && point.lng === mapCenter[1])
    if (hasNoCenterYet || centerHasChanged) {
      if (!isIncludedToPathPoints) {
        const marker = getCenterIcon(maps, map, mapCenter[0], mapCenter[1], '', pathCoordinates?.[0]?.id || 0)
        if (!!pathCoordinates?.length) {
          setMapSettings({...mapSettings, center: {lat: mapCenter[0], lng: mapCenter[1]}})
        } else if (!ignoreCenterChange) {
          setMapSettings({...mapSettings, center: {lat: mapCenter[0], lng: mapCenter[1]}})
        }
        ref.current = marker
        setSearchLocationMarker(marker)
      }

      !!searchLocationMarker && !!searchLocationMarker?.setMap && searchLocationMarker.setMap(null)

    } else if ((!showCenter && searchLocationMarker?.setMap) || (mapCenter[0] !== prevCenter?.[0] && mapCenter[1] !== prevCenter?.[1] && !!Object.keys(searchLocationMarker || {}).length)) {
      !!searchLocationMarker?.setMap && searchLocationMarker?.setMap(null)
      setSearchLocationMarker({})
      setMapSettings({...mapSettings, zoom: 0})
    }
    // eslint-disable-next-line
  }, [mapSettings, map, maps, searchLocationMarker, center, mapCenter])

  const prevSearchResults = usePrevious(searchResults)
  const prevActiveResultCategories = usePrevious(activeResultCategories) || []
  const prevMarkers = usePrevious(markers || [])
  const prevDistanceType = usePrevious(distanceType)

  // adding node markers
  useEffect(() => {
    if (!Object.values(searchResults)?.some(val => val !== null) && markers?.length) {
      markers.forEach((marker:any) => marker?.setMap && marker?.setMap(null))
    }
    // if (Object.values(searchResults)?.some(val => val === null)) {return}
    if (Object.entries(searchResults).some(([key, value]) => activeResultCategories.includes(key) && value === null)) {return}
    const activeCategoriesResults: SearchResultType = pickBy(searchResults, function(_, key) {
      return activeResultCategories.some(category => category.toLowerCase() === key.toLowerCase())
    })
    const prevActiveCategoriesResults: SearchResultType = pickBy(prevSearchResults, function(_, key) {
      return prevActiveResultCategories?.some((category:any) => category.toLowerCase() === key.toLowerCase())
    })
    const points = Object.values(activeCategoriesResults).flat(1) as (AirportSearchResultType | PortSearchResultType)[]
    const prevPoints = Object.values(prevActiveCategoriesResults).flat(1) as (AirportSearchResultType | PortSearchResultType)[]
    setPoints(points)
    if (!!Object.keys(maps || {})?.length && (
      (points.length && !markers.length)
      || (JSON.stringify(prevPoints) !== JSON.stringify(points) && !!maps)
      || (activeResultBlock === 'Nodes' && prevActiveResultBlock !== 'Nodes' && markers?.length)
      || (prevDistanceType !== distanceType)
      || (markers?.length !== points?.length)
    )) {
      markers.forEach((marker:any) => marker?.setMap && marker?.setMap(null))
      const activeResultLists = Object.keys(activeCategoriesResults).map(key => activeCategoriesResults[key as 'ports' | 'airports'])
      const newMarkers = activeResultLists.filter(resultList => resultList !== null)?.map(resultList => {
        return resultList?.map((point: any, index: any) => {
          const {marker, infoWindow} = getMapMarker(map, maps, {lat: point.details.latitude, lng: point.details.longitude}, point, index + 1, point.details.id, distanceType)
          ref.current = marker
          return {marker, infoWindow}
        })
      }).flat(1)
      setMarkers(
        (prevMarkers || []).length + newMarkers.length === points?.length
        // @ts-ignore
          ? (prevMarkers || []).concat(newMarkers.map(m => m.marker))
          : newMarkers.map(m => m?.marker) 
      )
      dispatch(setInfoWindows(newMarkers.map(m => m?.infoWindow)))
    } else if (!points?.length && !!markers.length && markers[0] !== null) {
      markers.forEach(marker => marker?.setMap && marker.setMap(null))
      setMarkers([])
    }
    // eslint-disable-next-line
  }, [map, maps, markers, activeResultCategories, searchResults, prevMarkers, activeResultBlock, distanceType])

  const handleApiLoaded = (map: any, maps:any) => {
    setMap(map)
    dispatch(setMapMap(map))
    setMaps(maps)
    dispatch(setMapMaps(maps))
  }

  const openLegendModal = () => {
    setIsLegendOpen(true)
  }

  const closeLegendModal = () => {
    setIsLegendOpen(false)
  }

  return (
    <div className={`${classes.map} ${isSmallScreenOpen === undefined ? '' : !!isSmallScreenOpen ? classes.smallScreenOpen : classes.smallScreenClosed}`} ref={myMap} id='map'>
      {isLegendIconVisible &&
        <MapLegendIcon className={classes.legendIcon} onClick={openLegendModal} />
      }
      <GoogleMapReact
        center={mapSettings.center}
        zoom={mapSettings.zoom}
        onChange={handleChangeMap}
        // onClick={handleClickMap}
        options={{
          mapId: '660eef12ca696db8',
          mapTypeControlOptions: {
            position: maps?.ControlPosition?.TOP_RIGHT,
            mapTypeIds: ['satellite', 'roadmap'],
            disableDefaultUI: true
         },
         mapTypeControl: true
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onTilesLoaded={() => setIsLegendIconVisible(true)}
        ref={ref}
      >
      </GoogleMapReact>
      <MapLegend isOpen={isLegendOpen} handleClose={closeLegendModal} />
      {advertisements.map(ad => (
        <AdvertisingBanner
          adData={ad}
          key={ad.place_type}
          style={{
            position: 'absolute',
            ...(ad.place_type.includes('VIP_MAP') ? {top: '0px'} : {bottom: '0px'}),
            left: '0px',
            width: '70%',
            minWidth: '300px',
            maxWidth: '600px',
          }}
        />
      ))}
      <div className={classes.smallScreenGoToResultsBtn} onClick={smallScreenHandleShowList}>
        <ShowListIcon /> View list
      </div>
    </div>
  )
}

interface MapProps {
  center: [number, number] | null
  smallScreenHandleShowList?: () => void
  isNodeDetailsOpen: boolean
  pathCoordinates?: {id: number, info: string, lat: number, lng: number, stopData?: any}[]
  pathIconsType?: 'byFlight' | 'byCruise' | '',
  handleTripStopMarkerClick?: (id: number) => void
  showCenter?: boolean
  isSmallScreenOpen?:boolean
  ignoreCenterChange?: boolean
}

interface LocationType {
  lat: number
  lng: number
}

export default GoogleMap
