import classes from './GoToTopBtn.module.css'
import { ReactComponent as ArrowIcon } from './../../../img/icons/topArrow.svg'
import { useEffect, useState } from 'react';

const GoToTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return (
      <div
        className={classes.scrollButton}
        onClick={scrollToTop}
        style={{
          display: isVisible ? 'block' : 'none'
        }}
      >
        <ArrowIcon />
      </div>
  )
}

export default GoToTopBtn
