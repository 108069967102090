import axios from './../helpers/axiosHelper'
import { AirportApiType, ViatorApiType, BusApiType, PortApiType, RailwayApiType, ServiceApiType, TransportStopsApiType, UserApiType, FlightApiType, CruiseApiType, UserSearchApiType, EventApiType, BlogApiType, AdditionalDataApiType } from '../types/apiTypes'

export const userAPI: UserApiType = {
  signIn(loginData) {
    return axios.post('/login', loginData)
  },
  signUp(signUpData) {
    return axios.post('/sign-up', signUpData)
  },
  sendEmailForNewPassword(email) {
    return axios.post('/login/password', {email})
  },
  setPassword(passwordData) {
    return axios.post('/login/reset-password', passwordData)
  },
  changePassword(passwordData) {
    return axios.post('/users/reset-password', passwordData)
  },
  editUserData(userId, userData) {
    return axios.put(`/users/${userId}`, userData)
  },
  sendUserFeedback(feedbackData) {
    return axios.post(`/feedbacks`, feedbackData)
  }
}

export const airportAPI: AirportApiType = {
  getNearAirports(locationData) {
    return axios.post('/airports/nearest', locationData)
  },
  getAirportsByCity({country_code, start_location, alliance_name, unit, city_name, airline_code, scheduled_flights, destination_location, destination_country_code, radius, show_all_countries, departure_date, non_codeshare, excluded_countries}, headers, source) {
    return axios.post(
      '/airports/filters',
      {country_code, start_location, alliance_name, unit, city_name, airline_code, scheduled_flights, destination_location,  destination_country_code, radius, show_all_countries, departure_date, non_codeshare, excluded_countries},
      {
        ...(source ? {cancelToken: source.token} : {}),
        headers,
      }
    )
  },
  getAllAirlines() {
    return axios.get('/airlines')
  },
  getAllAlliances() {
    return axios.get('/alliances')
  },
  getAirportsWithFlightToLocation(requestData) {
    return axios.post('/airports/airlines/searched-city', requestData)
  },
  getAirportsById(idList) {
    return axios.post('/airports/list', {id_list: idList})
  }
}

export const portAPI: PortApiType = {
  getPortsByCity({country_code, start_location, unit, city_name, destination_location, radius, show_all_countries, cruise_id, cruise_line_id, has_cruises, excluded_countries}, headers, source) {
    return axios.post(
      '/ports/filters',
      {country_code, start_location, unit, city_name, destination_location, radius, show_all_countries, cruise_id, cruise_line_id, has_cruises, excluded_countries},
      {
        ...(source ? {cancelToken: source.token} : {}),
        headers
      }
    )
  },
  getPortsByNameOrCode(name, source) {
    return axios.get(`/ports?search_value=${name}`,
    {...(source ? {cancelToken: source.token} : {})})
  },
  getCruisesForPort(portId, filtersData, source) {
    return axios.post(
      `/cruises/ports/${portId}/filters`,
      filtersData,
      {...(source ? {cancelToken: source.token} : {})}
    )
  }
}

export const busAPI: BusApiType = {
  getBusesByCity({country_code, start_location, show_all_countries}, headers, source) {
    return axios.post(
      '/bus-stations/filters',
      {country_code, start_location, show_all_countries},
      {
        ...(source ? {cancelToken: source.token} : {}),
        headers
      }
    )
  }
}

export const railwayAPI: RailwayApiType = {
  getRailwaysByCity({country_code, start_location, show_all_countries}, headers, source) {
    return axios.post(
      '/railway-stations/filters',
      {country_code, start_location, show_all_countries},
      {
        ...(source ? {cancelToken: source.token} : {}),
        headers
      }
    )
  }
}

export const serviceAPI: ServiceApiType = {
  getNearServices(locationData, headers, source) {
    return axios.post(
      '/services/near',
      locationData,
      {
        ...(source ? {cancelToken: source.token} : {}),
        headers
      }
    )
  },
  getServicesForNode(headers, nodeId, latitude, longitude, source) {
    return axios.get(
      `/services/nodes/${nodeId}${!!latitude ? '?latitude='+latitude+'&longitude='+longitude : ''}`,
      {
        ...(source ? {cancelToken: source.token} : {}),
        headers
      }
    )
  },
  getServiceByLocation(locationData, headers) {
    return axios.post(
      `/services/nodes`,
      locationData,
      {
        headers
      }
    )
  },
  getAllServiceCategories() {
    return axios.get(`/service-categories`)
  },
  getResultsServiceCategories(headers, nodeId, coordinates) {
    return axios.get(
      `/service-categories/nodes?${nodeId? nodeId : ''}${coordinates?.latitude ? (nodeId ? '&' : '') + 'latitude='+coordinates?.latitude+'&longitude='+coordinates?.longitude : ''}`,
      {
        headers
      }
    )
  },
}

export const transportStopsApi: TransportStopsApiType = {
  getNearTransportStops(params) {
    return axios.post(`/stops`, params)
  },
  getNodeTransportStops(params, headers) {
    return axios.post(`/stops/1km`, params, {headers})
  },
  getTransportStopByKey(key) {
    return axios.get(`/stops?stop_key=${key}`)
  },
  getTransportStopsWithDirectRoute(filters) {
    return axios.post(`/stops/filters/direct`, filters)
  },
}

export const viatorApi: ViatorApiType = {
  getViatorAttractions(params) {
    return axios.post(`/viator/attractions`, params)
  },
  getViatorAttractionsFiltered(params, headers) {
    return axios.post(
      `/viator/products`,
      params,
      {
        headers
      }
    )
  },
  getViatorAttractionLocation(productCode, latitude, longitude) {
    return axios.get(`/viator/products/${productCode}/location?latitude=${latitude}&longitude=${longitude}`)
  }
}

export const flightApi: FlightApiType = {
  getFlightByNumberAndDate(number, date, airlineCode) {
    return axios.get(`/flights?flight_no=${number}&departure_date=${date}${!!airlineCode?.length ? '&airline_code=' + airlineCode : ''}`)
  },
  getAvailableDatesForFlight(flightNo, source, airlineCode) {
    return axios.get(`/flights/available-days?flight_no=${flightNo}${!!airlineCode?.length ? '&airline_code=' + airlineCode : ''}`, { cancelToken: source.token })
  },
  getKayakLink(requestData) {
    return axios.post(`/kayak`, requestData)
  }
}

export const cruiseApi: CruiseApiType = {
  getCruiseByLineAndDate(lineId, data, headers, source) {
    return axios.post(
      `/cruises/cruise-lines/${lineId}`,
      data,
      { 
        ...(source ? {cancelToken: source.token} : {}),
        headers
      }
    )
  },
  getCruiseLines({name, typeId}, source) {
    return axios.get(`/cruise-lines${name?.length ? '?cruise_line_name=' + name : ''}${!!typeId ? (name?.length ? '&' : '?') + 'cruise_line_type=' + typeId : ''}`, { cancelToken: source.token })
  },
  getCruiseLineById(id, with_cruises) {
    return axios.get(`/cruise-lines/${id}?with_cruises=${with_cruises}`)
  },
  getCruises(name, without_routes, source) {
    return axios.get(`/cruises${name ? '?cruise_name='+name + '&' : '?'}without_routes=${without_routes}`)
  },
  getCruiseById(id) {
    return axios.get(`/cruises/${id}`)
  },
  getCruiseAvailableDates(data) {
    return axios.post(`/cruises/start-date-locations`, data)
  },
  getCruiseLineTypes() {
    return axios.get(`/cruise-line-types`)
  },
  getCruiseStartLocations(name: string, source) {
    return axios.get(`/cruise-locations?name=${name}`, {cancelToken: source.token})
  },
  getCruiseLengths() {
    return axios.get(`/cruise-lengths`)
  },
  getCruisesByAdvancedSearch(data, headers) {
    return axios.post(
      `/cruises/filters`,
      data,
      {
        headers
      }
    )
  },
  createUserCustomCruise(data) {
    return axios.post(`/user-custom-cruises`, data)
  },
  editUserCustomCruise(userCustomCruiseId, data) {
    return axios.put(`/user-custom-cruises/${userCustomCruiseId}`, data)
  },
  deleteUserCustomCruise(userCustomCruiseId) {
    return axios.delete(`/user-custom-cruises/${userCustomCruiseId}`)
  },
  getUserCustomCruise(userCustomCruiseId) {
    return axios.get(`/user-custom-cruises/${userCustomCruiseId}`)
  },
  getUserCustomCruiseList(cruiseId) {
    return axios.get(`/user-custom-cruises${cruiseId ? '?cruise_id=' + cruiseId : ''}`)
  },
}

export const userSearchApi: UserSearchApiType = {
  getUserSearchList(userId) {
    return axios.get(`/user-search-list/users/${userId}`)
  },
  saveUserSearch(searchData) {
    return axios.post(`/user-searches`, searchData)
  },
  deleteUserSearch(searchId) {
    return axios.delete(`/user-searches/${searchId}`)
  },
  createUserSearchList(listData) {
    return axios.post(`/user-search-list`, listData)
  },
  editUserSearchList(listId, listData) {
    return axios.put(`/user-search-list/${listId}`,listData)
  },
  deleteUserSearchList(listId) {
    return axios.delete(`/user-search-list/${listId}`)
  },
  saveSearchTracking(trackingData, headers) {
    return axios.post(
      `/trackings`,
      trackingData, {
      headers
    })
  },
}

export const eventApi: EventApiType = {
  getUserEvents(pagination, {searchQuery, eventKind}) {
    return axios.post(`/events/my?${!!searchQuery?.length ? 'search_query=' + searchQuery + '&' : ''}event_kind=${eventKind}`, pagination)
  },
  getEventByCode(code) {
    return axios.get(`/events/${code}`)
  },
  createEvent(eventData, headers) {
    return axios.post(`/events`, eventData, {headers})
  },
  editEvent(eventId, eventData, headers) {
    return axios.put(`/events/${eventId}`, eventData, {headers})
  },
  deleteEvent(eventId) {
    return axios.delete(`/events/${eventId}`)
  },
  addEventToUserList(eventCode) {
    return axios.post(`/events/add/${eventCode}`)
  },
  deleteEventFromUserList(eventCode) {
    return axios.delete(`/events/remove/${eventCode}`)
  },
  checkEventUserRelation(eventCode) {
    return axios.get(`/events/${eventCode}/users/relation`)
  },
}

export const blogAPI: BlogApiType = {
  getBlogEntries(fetchParams, source) {
    return axios.post(
      '/blog-entries/filter',
      fetchParams,
      {
        ...(source ? {cancelToken: source.token} : {}),
      }
    )
  },
  getBlogEntryById(id) {
    return axios.get(`/blog-entries/${id}`)
  },
  getBlogEntryByTitle(title) {
    return axios.get(`/blog-entries?title=${title}`)
  },
  getAboutPage() {
    return axios.post(`/page-sections/search`, {type: "ABOUT"})
  },
}

export const additionalDataApi: AdditionalDataApiType = {
  getCountryList() {
    return axios.get(`/countries`)
  },
  getRegionList() {
    return axios.get(`/regions`)
  },
  getTutorialVideo(code) {
    return axios.get(`/tutorial?code=${code}`)
  }
}
