import { useAppDispatch } from '../../../../../../app/hooks'
import { setAttractionLocationCoordinates } from '../../../../../../store/viatorReducer'
import { ActivitiesBtn } from '../../../../Activities/Activities'
import ExternalLinksBlock from '../../../../ServiceResults/ExternalLinksBlock/ExternalLinksBlock'
import classes from './../../Results.module.css'
import TerminalResults from '../../../../TerminalResults/TerminalResults'
import { MultipleExternalLinksDataType } from '../../../../../../types/searchResultTypes'

const MultipleExternalLinksBlock: React.FC<MultipleExternalLinksBlockPropTypes> = ({blocksData}) => {
  const dispatch = useAppDispatch()
  return (
    <>
      {blocksData.map(block => (
        <div className={classes.resultInner} key={block.title} style={block?.showLinks ? {} : {padding: '12px'}}>
          {!!block.title?.length &&
            <div style={{color: 'gray', fontSize: '14px', marginBottom: '10px'}}>
              {block.title}
            </div>
          }
          {!!block?.showLinks &&
            <ExternalLinksBlock
              nodeData={block.dataForLinks}
              dates={block.dates}
            />
          }
          {!!block.terminals &&
            <TerminalResults
              terminals={block.terminals}
              wrapperStyle={{padding: '0px 0px 0px 15px', marginTop: !block.title?.length && !block?.showLinks ? '0px' : ''}}
            />
          }
          <ActivitiesBtn
            style={{padding: '0px', ...(block?.showLinks || block.terminals ? {} : {marginTop: '0px'})}}
            onClick={() => dispatch(setAttractionLocationCoordinates(block.dataForLinks))}
          />
        </div>
      ))}
    </>
  )
}

export default MultipleExternalLinksBlock

interface MultipleExternalLinksBlockPropTypes {
  blocksData: MultipleExternalLinksDataType[]
}
