import { Dispatch, SetStateAction } from 'react'
import { InputGoogleAPI } from '../../../../common/InputGoogleAPI/InputGoogleAPI'
import { Tag, message } from 'antd'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import classes from './../CruiseAdvancedSearch.module.css'

const DestinationLocationsField: React.FC<{setFormValues: Dispatch<SetStateAction<any>>, formValues: any}> = ({setFormValues, formValues}) => {
  const handleDestinationSelect = async(value: string) => {
    if (value.length) {
      const addressData = await geocodeByAddress(value)
      const coordinates = await getLatLng(addressData[0])
      const isDuplicate = formValues.destination_locations?.some((loc: LocationDataType) => loc.latitude === coordinates.lat && loc.longitude === coordinates.lng && loc.name === value)
      if (isDuplicate) {
        message.warning('This coordinates has already been selected!', 2)
      } else {
        const newLocationData = {latitude: coordinates.lat, longitude: coordinates.lng, name: value}
        setFormValues({...formValues, destination_locations: [...(formValues.destination_locations || []), newLocationData]})
      }
    }
  }
  const handleLocationDeselect = async(location: LocationDataType) => {
    const updatedList = formValues.destination_locations.filter((loc: LocationDataType) =>
      loc.latitude !== location.latitude
      && loc.longitude !== location.longitude
      && loc.name !== location.name
    )
    setFormValues({...formValues, destination_locations: updatedList})
  }

  return (
    <div className={classes.formItemWrapper} style={{marginBottom: '25px'}}>
      <div className={classes.formItemLabel}>
        <span>Visits:</span> Ports of call within 100 km of the location
      </div>
      <InputGoogleAPI
        onChange={(value:string) => handleDestinationSelect(value)}
        placeholder='Start typing location'
        suggestionsPosition='vertical'
        style={{maxWidth: 'none', height: '46px'}}
        wrapperStyle={{height: '46px'}}
        clearAfterSelect
        searchOptions={{types: ['(cities)']}}
      />
      <div>
        {formValues.destination_locations?.map((loc: LocationDataType) => (
          <Tag closable onClose={() => handleLocationDeselect(loc)} key={loc.latitude+loc.longitude+(loc.name || '')}>
            {loc.name}
          </Tag>
        ))}
      </div>
    </div>
  )
}

interface LocationDataType {
  latitude: number
  longitude: number
  name?: string
}

export default DestinationLocationsField
