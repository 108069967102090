import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { eventApi, userSearchApi } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { EventKindType, EventPaginationType, EventType, EventUserRelationType, MyEventRequestParamsType } from '../types/eventTypes'
import { GetUserIPThunk, SignOutThunk } from './userReducer'
import { getRequestHeaders } from '../helpers/axiosHelper'

interface InitialStateType {
  userEvents: EventType[] | null,
  userEventsPagination: EventPaginationType,
  userEventsTotalCount: number,
  selectedEvent: EventType | null,
  selectedEventByCode: EventType | null
  eventUserRelation: EventUserRelationType | null,
  myEventSelectedKind: EventKindType
}

const initialState: InitialStateType = {
  userEvents: null,
  userEventsPagination: {size: 8, page: 1},
  userEventsTotalCount: 0,
  selectedEvent: null,
  selectedEventByCode: null,
  eventUserRelation: null,
  myEventSelectedKind: 'Location'
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setUserEvents: (state, action: PayloadAction<EventType[] | null>) => {state.userEvents = action.payload},
    setUserEventsPagination: (state, action: PayloadAction<EventPaginationType>) => {state.userEventsPagination = action.payload},
    setUserEventsTotalCount: (state, action: PayloadAction<number>) => {state.userEventsTotalCount = action.payload},
    setSelectedEvent: (state, action: PayloadAction<EventType | null>) => {state.selectedEvent = action.payload},
    setSelectedEventByCode: (state, action: PayloadAction<EventType | null>) => {state.selectedEventByCode = action.payload},
    setEventUserRelation: (state, action: PayloadAction<EventUserRelationType | null>) => {state.eventUserRelation = action.payload},
    setMyEventSelectedKind: (state, action: PayloadAction<EventKindType>) => {state.myEventSelectedKind = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserEventsThunk.fulfilled, (state, action) => {
        state.userEvents = action.payload.events
        state.userEventsTotalCount = action.payload.total_count
      })
      .addCase(GetEventByCodeThunk.fulfilled, (state, action) => {
        state.selectedEventByCode = action.payload
      })
      .addCase(CreateEventThunk.fulfilled, (state, action) => {
        if (state.myEventSelectedKind === action.payload.eventData.event_kind) {
          state.userEvents = [...state.userEvents || [], {...action.payload.eventData, is_creator: true}]
        }
      })
      .addCase(EditEventThunk.fulfilled, (state, action) => {
        state.userEvents = (state.userEvents || []).map(event => event.id === action.payload.eventData.id ? action.payload.eventData : event)
      })
      .addCase(DeleteEventThunk.fulfilled, (state, action) => {
        state.userEvents = (state.userEvents || []).filter(event => event.id !== action.payload)
      })
      .addCase(AddEventToUserListThunk.fulfilled, (state) => {
        state.eventUserRelation = {is_creator: false, is_member: true}
      })
      .addCase(DeleteEventFromUserListThunk.fulfilled, (state, action) => {
        state.eventUserRelation = {is_creator: false, is_member: false}
        state.userEvents = state.userEvents?.filter(event => event.code !== action.payload) || []
      })
      .addCase(GetEventUserRelationThunk.fulfilled, (state, action) => {
        state.eventUserRelation = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.eventUserRelation = null
        state.userEvents = []
        state.userEventsTotalCount = 0
      })
  }
})

export const {
  setUserEvents,
  setUserEventsPagination,
  setUserEventsTotalCount,
  setSelectedEvent,
  setSelectedEventByCode,
  setEventUserRelation,
  setMyEventSelectedKind
} = eventSlice.actions

export const selectUserEvents = (state: RootState): EventType[] | null => state.events.userEvents
export const selectUserEventsPagination = (state: RootState): EventPaginationType => state.events.userEventsPagination
export const selectUserEventsTotalCount = (state: RootState): number => state.events.userEventsTotalCount
export const selectSelectedEvent = (state: RootState): EventType | null => state.events.selectedEvent
export const selectSelectedEventByCode = (state: RootState): EventType | null => state.events.selectedEventByCode
export const selectEventUserRelation = (state: RootState): EventUserRelationType | null => state.events.eventUserRelation
export const selectMyEventSelectedKind = (state: RootState): EventKindType => state.events.myEventSelectedKind

export const GetUserEventsThunk = createAsyncThunk<{events: EventType[], total_count: number}, {pagination: EventPaginationType, params: MyEventRequestParamsType} , AsyncThunkConfig>(
  'events/getUserEvents',
  async ({pagination, params}, thunkAPI) => {
    try {
      const {data, status} = await eventApi.getUserEvents(pagination, params)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetEventByCodeThunk = createAsyncThunk<EventType, string , AsyncThunkConfig>(
  'events/getEventByCode',
  async (code, thunkAPI) => {
    try {
      const {data, status} = await eventApi.getEventByCode(code)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data,  {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateEventThunk = createAsyncThunk<{eventData: EventType}, FormData, AsyncThunkConfig>(
  'events/createEvent',
  async (eventData, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await eventApi.createEvent(eventData, requestHeaders)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({eventData: data}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditEventThunk = createAsyncThunk<{eventData: EventType}, {eventId: number, eventData: FormData}, AsyncThunkConfig>(
  'events/editEvent',
  async ({eventId, eventData}, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await eventApi.editEvent(eventId, eventData, requestHeaders)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({eventData: data}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteEventThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'events/deleteEvent',
  async (eventId, thunkAPI) => {
    try {
      const {data, status} = await eventApi.deleteEvent(eventId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(eventId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const AddEventToUserListThunk = createAsyncThunk<void, string, AsyncThunkConfig>(
  'events/addEventToUserList',
  async (eventCode, thunkAPI) => {
    try {
      const {data, status} = await eventApi.addEventToUserList(eventCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(undefined, {appStatus: AppStatusType.succeeded, infoMessage: 'Event has been successfully added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteEventFromUserListThunk = createAsyncThunk<string, string, AsyncThunkConfig>(
  'events/deleteEventToUserList',
  async (eventCode, thunkAPI) => {
    try {
      const {data, status} = await eventApi.deleteEventFromUserList(eventCode)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(eventCode, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetEventUserRelationThunk = createAsyncThunk<EventUserRelationType, string, AsyncThunkConfig>(
  'events/getEventUserRelation',
  async (eventCode, thunkAPI) => {
    try {
      const {data, status} = await eventApi.checkEventUserRelation(eventCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SaveEventTrackingThunk = createAsyncThunk<number, string, AsyncThunkConfig>(
  'userSearch/saveSearchTracking',
  async (eventCode, thunkAPI) => {
    const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
    const {status} = await userSearchApi.saveSearchTracking({searched_type: 'Event', event_code: eventCode}, requestHeaders)
    return thunkAPI.fulfillWithValue(status, {appStatus: AppStatusType.idle})
  }
)

export default eventSlice.reducer
 