import { Form, InputNumber, Select } from 'antd'
import moment, { Moment } from 'moment'
import axios from './../../../helpers/axiosHelper'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetFlightAvailableDatesThunk, selectFlightAvailableDates, setFlight, setFlightAvailableDates, setFlights } from '../../../store/flightReducer'
import SearchPageWithBgImage from '../common/SearchPageWithBgImage/SearchPageWithBgImage'
import { useEffect, useState } from 'react'
import { orderBy } from 'lodash'
import { setActiveResultCategories, setNodeServiceDetails } from '../../../store/searchResultsReducer'
import { setNearTransportStops } from '../../../store/transportStopsReducer'
import { setNearServices } from '../../../store/serviceReducer'
import NextLocationForm from '../common/NextLocationForm/NextLocationForm'
import { getFlightSearchUrl } from '../../../helpers/linksHelper'
import { GetAirlinesThunk, selectAirlines } from '../../../store/airportReducer'
import { AirlineType } from '../../../types/airportTypes'
import { setSelectedResultItemId } from '../../../store/travelSearchReducer'
import { Helmet } from 'react-helmet-async'
import { FlightAvailableDatesTypes } from '../../../types/flightTypes'

const ByFlight = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const airlines = useAppSelector(selectAirlines)
  const availableDates = useAppSelector(selectFlightAvailableDates)
  const flightNumberFormat = new RegExp(/^[0-9]{1,4}$/)

  const [isDatesLoading, setIsDatesLoading] = useState(false)
  const [hasFormatError, setHasFormatError] = useState(false)
  const [activeFormStep, setActiveFormStep] = useState<'mainData' | 'nextLocation'>('mainData')
  const [availableAirlines, setAvailableAirlines] = useState<AirlineType[] | null>(null)
  const [airlineSearch, setAirlineSearch] = useState('')
  const [flightNumber, setFlightNumber] = useState<number | null>()
  const [selectedAirlineCode, setSelectedAirlineCode] = useState('')

  useEffect(() => {
    availableAirlines === null && !!airlines.length && setAvailableAirlines(!!airlineSearch.length ? airlines : airlines)
  }, [airlines, availableAirlines, airlineSearch])

  useEffect(() => {
    dispatch(GetAirlinesThunk())
  }, [dispatch])

  useEffect(() => {
    const isFlightNumberValid = flightNumber !== undefined && !!String(flightNumber).length ? flightNumberFormat.test(String(flightNumber)) : true
    setHasFormatError(!isFlightNumberValid)
    dispatch(setFlightAvailableDates(null))
    form.setFieldsValue({date: null})
    if (!!flightNumber && !!String(flightNumber)?.length && isFlightNumberValid && !!selectedAirlineCode) {
      setIsDatesLoading(true)
      dispatch(GetFlightAvailableDatesThunk({flightNo: selectedAirlineCode + flightNumber, source, airlineCode: selectedAirlineCode}))
        .then((resp: any) => {
          if (resp?.payload !== 'canceled') {
            setIsDatesLoading(false)
          }
        })  
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, selectedAirlineCode, flightNumber])

  const handleAirlineSearch = (string: string) => {
    if (string.length) {
      setAvailableAirlines(airlines.filter(airline => airline.name.toLowerCase().includes(string.toLowerCase()) || airline.code.toLowerCase().includes(string.toLowerCase())))
    } else {
      setAvailableAirlines(airlines)
    }
    setAirlineSearch(string)
  }

  const getDataOnSearch = async() => {
    setActiveFormStep('nextLocation')
  }

  const getClosestAvailableDate:() => undefined | Moment = () => {
    if (availableDates?.length) {
      const dayNumberToIndex: { [key: number]: keyof FlightAvailableDatesTypes } = {
        1: "day_1",
        2: "day_2",
        3: "day_3",
        4: "day_4",
        5: "day_5",
        6: "day_6",
        7: "day_7",
      }
  
      const flightOptions = availableDates?.filter(d => moment(d?.effective_date).isBefore(moment()) && moment(d?.discontinued_date).isAfter(moment()))
      const currentDayNumber = moment().isoWeekday()
      let closestDayNumber = 0
      // Find the current day or the closest next day with "true"
      for (let i = currentDayNumber; i <= 7; i++) {
        const index = dayNumberToIndex[i]
        if (index && flightOptions?.some(obj => obj[index])) {
          closestDayNumber = i
          break
        }
      }
  
      // If not found in the remaining days, loop back to the beginning
      for (let i = 1; i < currentDayNumber; i++) {
        const index = dayNumberToIndex[i]
        if (index && flightOptions?.some(obj => obj[index])) {
          closestDayNumber = i
          break
        }
      }
      if (!!closestDayNumber) {
        const dayDifference = (closestDayNumber - currentDayNumber + 7) % 7
        const closestDate = moment().add(dayDifference, 'days')
        return closestDate
      } else {
        return undefined
      }
    }
    return
  }

  const checkIsDateAvailable = (current: Moment):boolean => {
    return !availableDates?.some(dateItem => {
      const isWithinDateRange = moment(current).isBetween(dateItem.effective_date, dateItem.discontinued_date)
      const key = `day_${moment(current).day()}` as keyof typeof dateItem
      return !!dateItem[key] && !!isWithinDateRange
    }) || current < moment().startOf('day')
  }

  useEffect(() => {
    return () => {
      dispatch(setFlight(null))
      dispatch(setSelectedResultItemId(''))
      dispatch(setFlights(null))
      dispatch(setActiveResultCategories(['near']))
      dispatch(setNodeServiceDetails(null))
      dispatch(setNearTransportStops(null))
      dispatch(setNearServices([]))
      dispatch(setFlightAvailableDates(null))
    }
  }, [dispatch])

  return (
    <SearchPageWithBgImage
      title='Flights'
      description={activeFormStep === 'mainData'
        ? 'Find your flight trip'
        : ''
      }
      getDataOnSearch={getDataOnSearch}
      bgType='flight'
      form={form}
      hasFormatError={hasFormatError}
      showDateForm={activeFormStep === 'mainData'}
      checkIsDateAvailable={(current: Moment) => checkIsDateAvailable(current)}
      datePickerPlaceholder={isDatesLoading ? 'Loading available dates...' : (availableDates === null ? 'Enter airline and flight number first' : 'No available dates found')}
      datePickerDisabled={!availableDates?.length}
      dateIsLoading={isDatesLoading}
      closestAvailableDate={getClosestAvailableDate()}
    >
      <Helmet>
        <meta name='description' content='Search flight by airline and flight number'/>
        <title>Relavanti | Search by flight</title>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      {activeFormStep === 'mainData' ? (
        <>
          <Form.Item
            name='airlineCode'
            style={{width: '100%'}}
            rules={[{required: true, message: 'Please select airline!'}]}
          >
            <Select
              placeholder={availableAirlines === null ? 'Loading airlines...' : 'Enter the airline name'}
              showSearch
              allowClear
              onClear={() => form.setFieldsValue({airlineCode: null})}
              onSearch={(val) => handleAirlineSearch(val)}
              onSelect={(val:string) => {
                form.setFieldsValue({date: null})
                setSelectedAirlineCode(val)
              }}
              style={{width: '100%'}}
              filterOption={false}
              disabled={availableAirlines === null}
            >
              {orderBy(availableAirlines, [airline => airline.name.toLowerCase()], ['asc'])?.map((line, index) => (
                <Select.Option value={line.code} key={line.code+index}>
                  {line.name} ({line.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{fontSize: '12px', alignSelf: 'flex-end', color: 'gray'}}>
            Flight code: 1-4 digits
          </div>
          <Form.Item
            name='number'
            style={{width: '100%'}}
            rules={[{required: true, message: 'Please enter flight number!'}]}
          >
            <InputNumber
              placeholder='Enter your flight number'
              style={{width: '100%'}}
              // @ts-ignore
              onChange={(val: number) => setFlightNumber(val)}
              maxLength={4}
            />
          </Form.Item>
          <div
            style={{
              color: 'red',
              marginTop: '-25px',
              fontSize: '12px',
              marginBottom: '10px',
              alignSelf: 'flex-end',
              visibility: hasFormatError ? 'visible' : 'hidden'
            }}
          >
            Wrong format!
          </div>
        </>
      ) : (
        <NextLocationForm
          formData={{...form.getFieldsValue(), number: form.getFieldsValue().airlineCode + form.getFieldsValue().number}}
          getResultsLink={getFlightSearchUrl}
          travelType='flight'
        />
      )}
    </SearchPageWithBgImage>
  )
}

export default ByFlight
