import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../common/Footer/Footer'
import Header from '../common/Header/Header'
import classes from './SearchResults.module.css'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import { useAppDispatch, useAppSelector, usePrevious } from '../../app/hooks'
import { GetAirportsByCityThunk } from '../../store/airportReducer'
import { GetDataForResultTabsFromSharedLinkThunk, GetDataForResultTabsThunk, resetTabsData, selectActiveResultCategories, selectActiveTab, selectSelectedTransportStop, selectTabs } from '../../store/searchResultsReducer'
import { GetNearServicesThunk, GetServiceCategoriesThunk } from '../../store/serviceReducer'
import { decodeSearchResultsExportLink, getLocationsFromLink, getSearchItemAdditionalData } from '../../helpers/linksHelper'
import ResultTabs from './ResultTabs/ResultTabs'
import { GetPortsByCityThunk } from '../../store/portReducer'
import { GetRailwaysByCityThunk } from '../../store/railwayReducer'
import { GetBusesByCityThunk } from '../../store/busReducer'
import { GetTransportStopByKeyThunk } from '../../store/transportStopsReducer'
import { GetViatorAttractionsFilteredThunk, selectAttractionFilters } from '../../store/viatorReducer'
import { Helmet } from 'react-helmet-async'

const SearchResults = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const selectedTransportStop = useAppSelector(selectSelectedTransportStop)
  const filterValues = useAppSelector(selectAttractionFilters)

  const tabPreviewData = decodeSearchResultsExportLink(location.search)?.locations?.map(req => getSearchItemAdditionalData(req))

  const [isTabsLoading, setIsTabsLoading] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const requests = getLocationsFromLink(location.search)
    if (requests[0]?.length === 0) {
      navigate('/')
    } else if (!tabs?.length && !isTabsLoading) {
      const searchData = decodeSearchResultsExportLink(location.search)
      const getDataForTabs = async() => location.search.includes('shared')
        ? dispatch(GetDataForResultTabsFromSharedLinkThunk({locations: searchData.locations, additionalInfo: searchData.sharedDetails!}))
        : dispatch(GetDataForResultTabsThunk({searchRequests: searchData.locations}))
      setIsTabsLoading(true)
      getDataForTabs()
        .then(() => setIsTabsLoading(false))
    }
  }, [location, navigate, activeTab, dispatch, tabs, isTabsLoading])

  useEffect(() => () => {
    dispatch(resetTabsData())
  }, [dispatch])

  const prevActiveTabName = usePrevious(activeTab?.name)
  const prevActiveTabFilters = usePrevious(activeTab?.activeFilters)
  const prevActiveResultCategories = usePrevious(activeResultCategories)

  useEffect(() => {
    if (prevActiveTabName !== activeTab?.name || prevActiveTabFilters !== activeTab?.activeFilters) {
      setIsDataLoading(true)
      const searchData = decodeSearchResultsExportLink(location.search)
      if (searchData.locations[0]?.length !== 0 && activeResultCategories?.[0] === 'near') {
        Promise.all([
          dispatch(GetServiceCategoriesThunk()),
          dispatch(GetViatorAttractionsFilteredThunk({
            coordinates: {
              latitude: activeTab?.coordinates?.[0],
              longitude: activeTab?.coordinates?.[1],
            },
            filters: filterValues,
            attractionAdditionalFilters: {}
          })),
          dispatch(GetNearServicesThunk({
            requestData: {
              latitude: activeTab?.coordinates?.[0],
              longitude: activeTab?.coordinates?.[1],
              radius: 2,
              unit: 'KM',
              place_id_list: [
                ...(!!activeTab?.placeId ? [activeTab?.placeId] : []),
                ...(activeTab?.placeId! !== activeTab?.countryPlaceId && !!activeTab?.countryPlaceId ? [activeTab?.countryPlaceId!] : [])
              ]
            }
          })),
          ...(!!searchData.sharedDetails?.selectedTransportStop && !selectedTransportStop
            ? [dispatch(GetTransportStopByKeyThunk(Number(searchData.sharedDetails?.selectedTransportStop)))]
            : []
          )
        ]).then(() => {
            !location.search.includes('shared') && !!activeResultCategories.includes('near') && setIsDataLoading(false)
            Promise.all([
              dispatch(GetAirportsByCityThunk({activeTabData: activeTab})),
              dispatch(GetPortsByCityThunk({activeTabData: activeTab})),
              dispatch(GetBusesByCityThunk({activeTabData: activeTab})),
              dispatch(GetRailwaysByCityThunk({activeTabData: activeTab})),
            ]).then(() => !activeResultCategories.includes('near') && setIsDataLoading(false))
            !!location.search.includes('shared') && !!activeResultCategories.includes('near') && setIsDataLoading(false)
        })  
      } else if (searchData.locations[0]?.length !== 0 && activeResultCategories?.[0] !== 'near') {
        Promise.all([
          ...(activeResultCategories?.includes('airports') ? [dispatch(GetAirportsByCityThunk({activeTabData: activeTab}))] : []),
          ...(activeResultCategories?.includes('ports') ? [dispatch(GetPortsByCityThunk({activeTabData: activeTab}))] : []),
          ...(activeResultCategories?.includes('buses') ? [dispatch(GetBusesByCityThunk({activeTabData: activeTab}))] : []),
          ...(activeResultCategories?.includes('railways') ? [dispatch(GetRailwaysByCityThunk({activeTabData: activeTab}))] : []),
        ]).then(() => {
          setIsDataLoading(false)
          Promise.all([
            ...(!activeResultCategories?.includes('airports') ? [dispatch(GetAirportsByCityThunk({activeTabData: activeTab}))] : []),
            ...(!activeResultCategories?.includes('ports') ? [dispatch(GetPortsByCityThunk({activeTabData: activeTab}))] : []),
            ...(!activeResultCategories?.includes('buses') ? [dispatch(GetBusesByCityThunk({activeTabData: activeTab}))] : []),
            ...(!activeResultCategories?.includes('railways') ? [dispatch(GetRailwaysByCityThunk({activeTabData: activeTab}))] : []),
            dispatch(GetServiceCategoriesThunk()),
            dispatch(GetViatorAttractionsFilteredThunk({
              coordinates: {
                latitude: activeTab?.coordinates?.[0],
                longitude: activeTab?.coordinates?.[1],
              },
              filters: filterValues,
              attractionAdditionalFilters: {}
            })),
            dispatch(GetNearServicesThunk({
              requestData: {
                latitude: activeTab?.coordinates?.[0],
                longitude: activeTab?.coordinates?.[1],
                radius: 2,
                unit: 'KM',
                place_id_list: [
                  ...(!!activeTab?.placeId ? [activeTab?.placeId] : []),
                  ...(activeTab?.placeId! !== activeTab?.countryPlaceId && !!activeTab?.countryPlaceId ? [activeTab?.countryPlaceId!] : [])
                ]
              }
            }))
          ])
        })
      } 
    } else if (!!(prevActiveResultCategories || []).length && prevActiveResultCategories?.[0] !== 'near' && !!activeTab?.name && activeResultCategories?.[0] === 'near') {
      Promise.all([
        dispatch(GetServiceCategoriesThunk()),
        dispatch(GetViatorAttractionsFilteredThunk({
          coordinates: {
            latitude: activeTab?.coordinates?.[0],
            longitude: activeTab?.coordinates?.[1],
          },
          filters: filterValues,
          attractionAdditionalFilters: {}
        })),
        dispatch(GetNearServicesThunk({
          requestData: {
            latitude: activeTab?.coordinates[0],
            longitude: activeTab?.coordinates[1],
            radius: 2,
            unit: 'KM',
            place_id_list: [
              ...(!!activeTab?.placeId ? [activeTab?.placeId] : []),
              ...(activeTab?.placeId! !== activeTab?.countryPlaceId && !!activeTab?.countryPlaceId ? [activeTab?.countryPlaceId!] : [])
            ]
          }
        })),
      ]).then(() => setIsDataLoading(false))
    }
    // eslint-disable-next-line
  }, [activeTab, dispatch, activeResultCategories])

  return (
    <>
      <div className={classes.wrapper}>
        <Helmet>
          {(activeTab?.name || tabPreviewData?.[0]?.location) &&
            <meta
              name='description'
              content={`Find nearest airports and seaports to ${activeTab?.name || tabPreviewData?.[0]?.location}. Book hotels, car rentals, activities, cruises and more.`}
            />
          }
          {(activeTab?.name || tabPreviewData?.[0]?.location) &&
            <title>
              Relavanti | {activeTab?.name || tabPreviewData?.[0]?.location}
            </title>
          }
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header showMenu={true} className={classes.header}/>
        <div className={classes.breadcrumbsWrapper}>
          <Breadcrumbs showShare showSave/>
        </div>
        {!!tabs?.length &&
          <ResultTabs
            mapCenter={activeTab?.coordinates || [0, 0] }
            isDataLoading={isDataLoading}
          />
        }
      </div>
      <Footer />
    </>
  )
}

export default SearchResults
