import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { serviceAPI } from '../app/api'
import { NearServicesRequestDataType, ServiceCategoryType, ServiceTypes } from '../types/serviceTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { GetUserIPThunk } from './userReducer'
import { AdvertisingType } from '../types/advertisingTypes'
import { CancelTokenSource } from 'axios'
import { getRequestHeaders } from '../helpers/axiosHelper'

interface InitialStateType {
  nearServices: ServiceTypes[]
  nodeServices: ServiceTypes[]
  serviceCategories: ServiceCategoryType[]
  serviceCategoryOptions: string[]
}

const initialState: InitialStateType = {
  nearServices: [],
  nodeServices: [],
  serviceCategories: [],
  serviceCategoryOptions: [],
}

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setNearServices: (state, action: PayloadAction<ServiceTypes[]>) => {state.nearServices = action.payload},
    setNodeServices: (state, action: PayloadAction<ServiceTypes[]>) => {state.nodeServices = action.payload},
    setServiceCategoryOptions: (state, action: PayloadAction<string[]>) => {state.serviceCategoryOptions = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetNearServicesThunk.fulfilled, (state, action) => {
        state.nearServices = action.payload.services
      })
      .addCase(GetServicesForNodeThunk.fulfilled, (state, action) => {
        state.nodeServices = action.payload
      })
      .addCase(GetServicesByLocationThunk.fulfilled, (state, action) => {
        state.nearServices = action.payload
      })
      .addCase(GetServiceCategoriesThunk.fulfilled, (state, action) => {
        state.serviceCategories = action.payload
      })
  }
})

export const { setNearServices, setNodeServices, setServiceCategoryOptions } = serviceSlice.actions

export const selectNearServices = (state: RootState): ServiceTypes[] => state.services.nearServices
export const selectNodeServices = (state: RootState): ServiceTypes[] => state.services.nodeServices
export const selectServiceCategories = (state: RootState): ServiceCategoryType[] => state.services.serviceCategories
export const selectServiceCategoryOptions = (state: RootState): string[] => state.services.serviceCategoryOptions

export const GetNearServicesThunk = createAsyncThunk<{services: ServiceTypes[], advertising_list: AdvertisingType[]}, {requestData: NearServicesRequestDataType, source?: CancelTokenSource}, AsyncThunkConfig>(
  'services/getNearServices',
  async ({requestData, source}, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await serviceAPI.getNearServices(requestData, requestHeaders, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetServicesForNodeThunk = createAsyncThunk<ServiceTypes[], {requestData: {nodeId: number, latitude?: number, longitude?: number}, source?: CancelTokenSource}, AsyncThunkConfig>(
  'services/getServicesForNode',
  async ({requestData, source}, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await serviceAPI.getServicesForNode(requestHeaders, requestData.nodeId, requestData.latitude, requestData.longitude, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.services, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetServicesByLocationThunk = createAsyncThunk<ServiceTypes[], {city_name?: string, country_code: string;}, AsyncThunkConfig>(
  'services/getServicesByLocation',
  async (locationData, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await serviceAPI.getServiceByLocation(locationData, requestHeaders)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.services, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetServiceCategoriesThunk = createAsyncThunk<ServiceCategoryType[], void, AsyncThunkConfig>(
  'services/getServiceCategories',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await serviceAPI.getAllServiceCategories()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data['service_categories'], {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetResultsServiceCategoriesThunk = createAsyncThunk<ServiceCategoryType[], {nodeId?: number, coordinates?: {latitude: number, longitude: number}}, AsyncThunkConfig>(
  'services/getResultsServiceCategories',
  async (searchRequestData, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await serviceAPI.getResultsServiceCategories(requestHeaders, searchRequestData.nodeId, searchRequestData.coordinates)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data['service_categories'], {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default serviceSlice.reducer
 