import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { getCruiseDataFromSearchString, getCruiseDataFromSearchStringAdvanced } from '../../../../../helpers/linksHelper'
import { GetCustomCruiseByIdThunk, GetCustomCruiseListThunk, selectCruise, selectCruiseInfoActiveTab, selectCruiseLine, selectCustomizedCruise, selectSelectedStops, setCruise, setCruiseInfoActiveTab, setCustomizedCruise, setIsCruiseStopsMerged, setSelectedStops } from '../../../../../store/cruiseReducer'
import { NodeServiceDetails } from '../../../../../store/searchResultsReducer'
import { GetServiceCategoriesThunk, selectServiceCategories } from '../../../../../store/serviceReducer'
import { setSelectedResultItemId } from '../../../../../store/travelSearchReducer'
import { AdvancedCruiseSearchData, CruiseSearchDataType, CruiseType } from '../../../../../types/cruiseTypes'
import ItemDetailsTabs from '../../../common/ItemDetailsTabs/ItemDetailsTabs'
import TripNumber from '../../../common/TripNumber/TripNumber'
import CruiseItem, { CruiseBriefInfo, CruiseNightsCount } from '../CruiseItem/CruiseItem'
import { Radio, Rate } from 'antd'
import classes from './CruiseDetails.module.css'
import { isNumber, orderBy, uniq } from 'lodash'
import AdvertisingBanner from '../../../../common/AdvertisingBanner/AdvertisingBanner'
import { selectCruiseAdvertisement } from '../../../../../store/advertisementReducer'

const CruiseDetails: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const { pathname, search } = useLocation()
  const isAdvancedSearch = pathname?.includes('-advanced')
  const searchData = isAdvancedSearch
    ? getCruiseDataFromSearchStringAdvanced(search)
    : getCruiseDataFromSearchString(search)
  const cruise: CruiseType | null = useAppSelector(selectCruise)
  const cruiseLine = useAppSelector(selectCruiseLine)
  const serviceCategories = useAppSelector(selectServiceCategories)
  const selectedStops = useAppSelector(selectSelectedStops)
  const advertisement = useAppSelector(selectCruiseAdvertisement)
  const departurePort = cruise?.cruise_routes[0]
  const arrivalPort = cruise?.cruise_routes[cruise?.cruise_routes.length - 1]

  useEffect(() => {
    if (!serviceCategories?.length) {
      dispatch(GetServiceCategoriesThunk())
    }
  }, [dispatch, searchData, serviceCategories])

  const getNodeData = (key: string):NodeServiceDetails => {
    const nodeData = cruise?.cruise_routes?.find(stop => String(stop.id) === key)!
    return {
      id: nodeData?.port?.id || nodeData?.id,
      name: nodeData?.name,
      type: 'port',
      location: {
        latitude: nodeData?.port?.latitude,
        longitude: nodeData?.port?.longitude
      }
    }
  }

  const updateStopCoordinatesAccordingToGoogle = (tabsData: any[]) => {
    if (!!cruise) {
      dispatch(setCruise(
        {...cruise, cruise_routes: cruise.cruise_routes.map(route => {
          const stopDataFromResp = tabsData?.find(r => r.name === route.name)
          if (Object.keys(stopDataFromResp || {})?.length) {
            return {...route, port: !!route.port ? {...route.port, latitude: stopDataFromResp.coordinates[0], longitude: stopDataFromResp.coordinates[1]} : route.port}
          } else {
            return route
          }
        })}
      ))
      dispatch(setSelectedResultItemId(String(cruise.id)))
    }    
  }

  return (
    <ItemDetailsTabs
      resultItem={{
        tripNumber: cruise?.name!,
        stops: cruise?.cruise_routes || []
      }}
      getNodeData={getNodeData}
      requiredTabs={[
        ...(departurePort?.name === arrivalPort?.name ? [{...departurePort, arrival_time: arrivalPort?.arrival_time}] : [departurePort, arrivalPort])
      ]}
      optionalTabs={selectedStops}
      searchData={isAdvancedSearch
          ? {date: (searchData as AdvancedCruiseSearchData)?.start_date as string}
          : {
              date: (searchData as CruiseSearchDataType)?.date,
              from: (searchData as CruiseSearchDataType)?.from,
              to: (searchData as CruiseSearchDataType)?.to
            }
      }
      setSelectedStops={(key: number) => dispatch(setSelectedStops(key))}
      type='byCruise'
      updateStopCoordinatesAccordingToGoogle={updateStopCoordinatesAccordingToGoogle}
    >
      <TripNumber
        tripNumber={cruiseLine?.name || cruise?.cruise_line_name || ''}
        secondaryInfo='Cruise name'
        primaryInfo={cruise?.name!}
        additionalInfo={{
          cruiseNumber: cruise?.code,
          ship: cruise?.display_info?.split(' Ship: ')[1]?.split('|')[0]
        }}
        imageType='ship'
        handleGoBack={() => {
          dispatch(setCruise(null))
          dispatch(setSelectedResultItemId(''))
          dispatch(setIsCruiseStopsMerged(false))
        }}
        logo={cruiseLine?.logo_url || cruise?.cruise_line_logo_url}
        logoLink={cruiseLine?.website || cruise?.cruise_line_website}
      />
      {!!advertisement?.find(a => a.place_type === 'CRUISE_VIP_BEFORE_INFO') &&
        <AdvertisingBanner
          adData={advertisement?.find(a => a.place_type === 'CRUISE_VIP_BEFORE_INFO')!}
          style={{
            margin: '15px 0px',
            width: 'auto',
          }}
        />
      }
      <DetailsBlock getNodeData={getNodeData} previewInfoOnly={false}/>
    </ItemDetailsTabs>
  )
}

export const DetailsBlock: React.FC<DetailsBlockPropTypes> = ({getNodeData, previewInfoOnly}) => {
  const dispatch = useAppDispatch()
  const { pathname, search } = useLocation()
  const isAdvancedSearch = pathname?.includes('-advanced')
  const cruise: CruiseType | null = useAppSelector(selectCruise)
  const advertisement = useAppSelector(selectCruiseAdvertisement)
  const selectedTab = useAppSelector(selectCruiseInfoActiveTab)
  const customizedCruise = useAppSelector(selectCustomizedCruise)
  const searchData = isAdvancedSearch
    ? getCruiseDataFromSearchStringAdvanced(search)
    : getCruiseDataFromSearchString(search)

  const setSelectedTab = (tab: 'description' | 'program' | 'photo') => {
    dispatch(setCruiseInfoActiveTab(tab))
  }

  useEffect(() => {
    if (!isNumber(customizedCruise)) {
      cruise?.id && dispatch(GetCustomCruiseListThunk(cruise?.id))
    } else {
      dispatch(GetCustomCruiseByIdThunk(customizedCruise))
    }
    // eslint-disable-next-line
  }, [dispatch, cruise])

  useEffect(() => {
    return () => {dispatch(setCustomizedCruise(null))}
  }, [dispatch])

  return (
    <>
      <Radio.Group onChange={(val) => setSelectedTab(val.target.value)} value={selectedTab} style={{ marginBottom: 8 }}>
        <Radio.Button value='description'>Description</Radio.Button>
        <Radio.Button value='program'>Itinerary</Radio.Button>
        {cruise?.image_url !=='null' && !!cruise?.image_url?.length && <Radio.Button value='photo'>Photo</Radio.Button>}
      </Radio.Group>
      
      {selectedTab ==='program' &&
        <>
          <CruiseItem
            previewInfoOnly={previewInfoOnly}
            showStops
            cruise={cruise!}
            onClick={() => {}}
            searchData={isAdvancedSearch
              ? {date: (searchData as AdvancedCruiseSearchData)?.start_date as string}
              : {
                  date: (searchData as CruiseSearchDataType)?.date,
                  from: (searchData as CruiseSearchDataType)?.from,
                  to: (searchData as CruiseSearchDataType)?.to
                }
          }
            getNodeData={getNodeData}
          />
          {orderBy(advertisement?.filter(ad => ad.place_type?.toUpperCase()?.includes('CRUISE_PROGRAM_AFTER_INFO')), ad => ad.place_index)?.map(ad => (
            <AdvertisingBanner
              adData={ad}
              style={{
                margin: '15px 0px',
                width: 'auto',
              }}
            />
          ))}
        </>
      }

      {selectedTab === 'description' &&
        <>
          <div className={classes.descriptionWrapper}>
            <div>
              <div className={classes.descriptionTitle}>
                {uniq(cruise?.cruise_routes?.map(r => r.name)).join(', ')}
              </div>
              <Rate value={Math.round(cruise?.rating || 0)} disabled/>
              <div className={classes.descriptionText}>
                {cruise?.description}
              </div>
            </div>
            <CruiseNightsCount cruise={cruise!} />
            <CruiseBriefInfo cruise={cruise!} />
          </div>
          {orderBy(advertisement?.filter(ad => ad.place_type?.toUpperCase()?.includes('CRUISE_DESCRIPTION_AFTER_INFO')), ad => ad.place_index)?.map(ad => (
            <AdvertisingBanner
              adData={ad}
              style={{
                margin: '15px 0px',
                width: 'auto',
              }}
            />
          ))}
        </>
      }

      {selectedTab === 'photo' &&
        <img src={cruise?.image_url} alt='cruise' className={classes.cruisePhoto}/>
      }
    </>
  )
}

interface DetailsBlockPropTypes {
  getNodeData: (key: string) => NodeServiceDetails
  previewInfoOnly: boolean
}

export default CruiseDetails
