
import { Divider } from 'antd'
import Header from '../common/Header/Header'
import CalendarBlock from './CalendarBlock/CalendarBlock'
import classes from './Events.module.css'
import YourEventsBlock from './YourEventsBlock/YourEventsBlock'
import Footer from '../common/Footer/Footer'
import { Helmet } from 'react-helmet-async'

const Events = () => {
  return (
    <>
      <div className={classes.wrapper}>
        <Helmet>
          <title>Relavanti | Events</title>
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header showMenu={true}/>
        <Divider className={classes.divider}/>
        <div className={classes.contentWrapper}>
          <CalendarBlock />
          <YourEventsBlock />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Events
