import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { railwayAPI } from '../app/api'
import { NearRailwayStationType } from '../types/railwayTypes'
import { ResultTabTypes } from '../types/searchResultTypes'
import { AppStatusType } from './appStatusReducer'
import { GetUserIPThunk } from './userReducer'
import { CancelTokenSource } from 'axios'
import { getRequestHeaders } from '../helpers/axiosHelper'

interface InitialStateType {
  nearRailways: NearRailwayStationType[]
}

const initialState: InitialStateType = {
  nearRailways: [],
}

export const railwaySlice = createSlice({
  name: 'railway',
  initialState,
  reducers: {
    setNearRailways: (state, action: PayloadAction<NearRailwayStationType[]>) => {state.nearRailways = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetRailwaysByCityThunk.fulfilled, (state, action) => {
        state.nearRailways = action.payload
      })
  }
})

export const { setNearRailways } = railwaySlice.actions

export const selectNearRailways = (state: RootState): NearRailwayStationType[] => state.railways.nearRailways

export const GetRailwaysByCityThunk = createAsyncThunk<NearRailwayStationType[], {activeTabData: ResultTabTypes, source?: CancelTokenSource}, AsyncThunkConfig>(
  'railways/getRailwaysByCity',
  async ({activeTabData, source}, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await railwayAPI.getRailwaysByCity({
        ...activeTabData.activeFilters.railways,
        country_code: activeTabData.country_code,
        start_location: {
          latitude: activeTabData.coordinates[0],
          longitude: activeTabData.coordinates[1]
        }
      }, requestHeaders, source)
      if (status === 200 && data) {
        const stationsAsNearRailways = data.railway_stations.map(station => ({
          details: station,
          distance: 0,
          unit: 'KM',
          advertising_list: station?.advertising_list || []
        }))
        return thunkAPI.fulfillWithValue(stationsAsNearRailways, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default railwaySlice.reducer
 