import { Modal } from 'antd'
import classes from './GoogleMap.module.css'
import { getMarkerTypeIcon, markerColors, markerSizes, searchLocationIcon } from '../../../helpers/mapMarkerHelper'
import { TransportType } from '../../../types/searchResultTypes'
import mapIconExample from './../../../img/icons/mapIconExample.svg'
import mapIconExampleAirport from './../../../img/icons/mapIconExampleAirport.svg'
import mapIconExampleAirportNoFlights from './../../../img/icons/mapIconExampleAirportNoFlights.svg'

const MapLegend: React.FC<{isOpen: boolean, handleClose: () => void}> = ({isOpen, handleClose}) => {
  const transportPictures = [
    'airport',
    'port',
    'railway',
    'bus',
    // 'car'
  ]

  return (
    <Modal
      visible={isOpen}
      onCancel={handleClose}
      footer={null}
      title={<h1 style={{fontSize: '20px'}}>Arbitrary signs and symbols</h1>}
      bodyStyle={{padding:'20px'}}
      closable
    >
      <div className={classes.mapLegend}>
        <h2>Transport Icons</h2>

        <h3>Pictures:</h3>
        {transportPictures.map(item => (
          <div className={classes.explanationItem} key={item}>
            <img
              src={`data:image/svg+xml;charset=UTF-8,
                <svg xmlns='http://www.w3.org/2000/svg'>
                  ${getMarkerTypeIcon(item as TransportType, markerColors[item])}
                </svg>
              `}
              alt=''
              style={{width:'50px', height:'50px'}}
            />
            <div className={classes.explanationText}>- {item}</div>
          </div>
        ))}
        
        <h3>Sizes:</h3>
        <div className={classes.explanationItem}>
          <div style={{display: 'flex', alignItems: 'flex-end'}}>
            <img src={mapIconExample} style={{width: markerSizes['3'][0], height: markerSizes['3'][0]}} alt='' />
            <img src={mapIconExample} style={{width: markerSizes['2'][0], height: markerSizes['2'][0]}} alt='' />
            <img src={mapIconExample} style={{width: markerSizes['1'][0], height: markerSizes['1'][0]}} alt='' />
          </div>
          <div style={{marginTop: '10px'}}>- indicates size of a transport node</div>
        </div>

        <h3>Numbers:</h3>
        <div className={classes.explanationItem}>
          <div style={{display: 'flex', alignItems: 'flex-end', marginTop: '-20px'}}>
            <img
              src={`data:image/svg+xml;charset=UTF-8,
                <svg width='50px' height='50px' viewBox='0 0 48.4 73.3' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path fill='%23191559' d='M43.9,37.5L25.3,72.1c-0.2,0.4-0.6,0.6-1,0.6c-0.4,0-0.8-0.2-1-0.6L4.6,37.5c-0.5-1,1.3-2.7,2.3-2c3,1.8,14,10.1,17.3,11.7c3.3-1.6,14.3-9.9,17.3-11.7C42.6,34.9,44.5,36.5,43.9,37.5z'/>
                  <text font-family='century-gothic, sans-serif' fill='white' transform="matrix(1.0071 0 0 1 19.9775 60.3242)">1</text>
                </svg>
              `}
              alt=''
            />
            ...
            <img
              src={`data:image/svg+xml;charset=UTF-8,
                <svg width='50px' height='50px' viewBox='0 0 48.4 73.3' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path fill='%23191559' d='M43.9,37.5L25.3,72.1c-0.2,0.4-0.6,0.6-1,0.6c-0.4,0-0.8-0.2-1-0.6L4.6,37.5c-0.5-1,1.3-2.7,2.3-2c3,1.8,14,10.1,17.3,11.7c3.3-1.6,14.3-9.9,17.3-11.7C42.6,34.9,44.5,36.5,43.9,37.5z'/>
                  <text font-family='century-gothic, sans-serif' fill='white' transform="matrix(1.0071 0 0 1 19.9775 60.3242)">9</text>
                </svg>
              `}
              alt=''
            />
          </div>
          <div>
            - distance from search location (from 1 to 9)
          </div>
        </div>
        
        <h3>Background color:</h3>
        <div className={classes.explanationItem} style={{marginBottom: '20px'}}>
          <img src={mapIconExampleAirport} alt='' style={{width: markerSizes['3'][0], height: markerSizes['3'][0]}} />
          <div className={classes.explanationText}>- with scheduled flights</div>
        </div>
        <div className={classes.explanationItem} >
          <img src={mapIconExampleAirportNoFlights} alt='' style={{width: markerSizes['3'][0], height: markerSizes['3'][0]}} />
          <div className={classes.explanationText}>- without scheduled flights</div>
        </div>
          
        <h3 style={{fontWeight: 700}}>Other Icons</h3>
        <div className={classes.explanationItem}>
          <img src={searchLocationIcon} alt=''/>
          <div className={classes.explanationText}>- searched location</div>
        </div>
      </div>
    </Modal>
  )
}

export default MapLegend
