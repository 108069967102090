import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Avatar, Popover, Switch, Tooltip } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectIsLoggedIn, selectUserData, setUserAvatar, SignOutThunk } from '../../../store/userReducer'
import classes from './Header.module.css'
import logo from './../../../img/logo.png'
import { ReactComponent as MobileMenuIcon } from './../../../img/icons/mobileMenuIcon.svg'
import { ReactComponent as DownArrowIcon } from './../../../img/icons/downArrow.svg'
import { appSettingsHelper } from '../../../helpers/localStorageHelper'
import { selectDistanceType, setDistanceType } from '../../../store/appStatusReducer'
import { useEffect } from 'react'
import { selectVIPAdvertisementHeader } from '../../../store/advertisementReducer'
import AdvertisingBanner from '../AdvertisingBanner/AdvertisingBanner'
import { selectActiveTab } from '../../../store/searchResultsReducer'
import { GetAboutPageThunk, selectAboutPage } from '../../../store/blogReducer'

const Header: React.FC<HeaderProps> = ({showMenu, className}) => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const vipHeaderAdvertisement = useAppSelector(selectVIPAdvertisementHeader)
  const activeTab = useAppSelector(selectActiveTab)

  useEffect(() => {
    const refreshAvatarUrlInState = (e: Event) => {
      // @ts-ignore
      const newAvatarUrl = e.detail
      if(userData.photo_url !== newAvatarUrl) {
        dispatch(setUserAvatar(newAvatarUrl))
      }
    }
    window.addEventListener('storageAvatarRefresh', refreshAvatarUrlInState);
    return () => {
      window.removeEventListener('storageAvatarRefresh', refreshAvatarUrlInState);
    };
  }, [dispatch, userData]);

  return (
    <header className={`${classes.header} ${className}`}>
      <Link to='/' className={classes.logo}>
        <img src={logo} alt='logo'/>
      </Link>
      {!!vipHeaderAdvertisement && !!Object.keys(activeTab)?.length &&
        <AdvertisingBanner
          adData={vipHeaderAdvertisement}
          style={{margin: '0px 7px', width: '280px'}}
        />
      }
      {showMenu &&
        <>
          <Popover
            content={
              <NavigationMenu className={classes.menuSmallScreen} />
            }
            className={classes.popoverMenu}
            trigger='click'
            placement='bottomRight'
          >
            <MobileMenuIcon  className={classes.popoverMenuIcon}/>
          </Popover>
          <NavigationMenu className={classes.menu}/>
        </>
      }
    </header>
  )
}

const NavigationMenu: React.FC<{className?: string}> = ({className}) => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const {pathname} = useLocation()
  const aboutPageData = useAppSelector(selectAboutPage)

  useEffect(() => {
    if (aboutPageData === null) {
      dispatch(GetAboutPageThunk())
    }
  }, [dispatch, aboutPageData])

  const hideDistanceSwitch = pathname === '/'
    || pathname === '/advertising'
    || pathname === '/b-2-b'
    || pathname.includes('/blog')
    || pathname === '/about'
    || pathname === '/events'
    || pathname === '/travel'
    || pathname === '/travel/search'
    || pathname === '/travel/by-flight'
    || pathname === '/travel/by-cruise'
    || pathname === '/travel/by-cruise-advanced'

  const headerMenuItems = [
    // {name: 'Shipping', link: '/'},
    {name: 'Advertising', link: '/advertising'},
    {name: 'B2B', link: '/b-2-b'},
    {name: 'How to', link: '/#howTo'},
    {name: 'Blog', link: '/blog'},
    ...(aboutPageData ? [{name: 'About', link: '/about'}] : []),
    {name: 'Events', link: '/events'},
    // ...(isLoggedIn ? [] : [{name: 'Sign in', link: '/sign-in'}]),
  ]

  return (
    <nav className={`${className}`}>
      {headerMenuItems.map(item => (
        item.name === 'Shipping' ? (
          <Tooltip title='Coming soon' key={item.name}>
            {item.name}
          </Tooltip>
        ) : (
          <HashLink
            smooth
            to={item.link}
            key={item.link}
            className={pathname.startsWith(item.link) ? classes.active : ''}
          >
            {item.name}
          </HashLink> 
        )
      ))}
      {isLoggedIn ? (
        <UserAvatar />
      ) : (
        <>
        <AccountDropdown /></>
      )}
      {!hideDistanceSwitch && <DistanceTypeSwitch />}
    </nav>
  )
}

const DistanceTypeSwitch = () => {
  const dispatch = useAppDispatch()
  const distanceType = useAppSelector(selectDistanceType)

  const handleChange = (checked: boolean) => {
    appSettingsHelper.setAppSettings({distanceType: checked ? 'km' : 'miles'})
    dispatch(setDistanceType(checked ? 'km' : 'miles'))
  }

  return (
    <Switch
      onChange={handleChange}
      style={{width: '60px'}}
      className='distanceTypeSwitch'
      checkedChildren='km'
      unCheckedChildren='miles'
      checked={distanceType === 'km'}
    />
  )
}

const UserAvatar = () => {
  const user = useAppSelector(selectUserData)
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 1133px)'})

  if (isSmallScreen) {
    return <UserMenu />
  } else {
    return (
      <Popover
        content={<UserMenu />}
        trigger='click'
        className={classes.userMenuPopover}
        overlayInnerStyle={{background: 'transparent', boxShadow: 'none', marginTop: '-12px'}}
      >
        <div style={{display:'flex', alignItems: 'center'}}>
        <Avatar
          size='default'
          className={classes.userAvatar}
          src={user.photo_url}
        >
          {user?.first_name?.[0]?.toUpperCase()}
        </Avatar>
        <DownArrowIcon />
        </div>
      </Popover>
    )
  }
}

const AccountDropdown = () => {
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 1133px)'})
  const { pathname } = useLocation()

  if (isSmallScreen) {
    return (
      <>
        <HashLink
          smooth
          to={'/sign-in'}
          className={pathname.startsWith('/sign-in') ? classes.active : ''}
        >
          Sign in
        </HashLink> 
        <HashLink
          smooth
          to={'/sign-up'}
          className={pathname.startsWith('/sign-up') ? classes.active : ''}
        >
          Sign up
        </HashLink>
      </>
    )
  }
  return (
    <Popover
      content={
        <div className={classes.userMenu}>
          <HashLink smooth to='/sign-in' className={classes.userMenuItem}>Sign In</HashLink>
          <HashLink smooth to='/sign-up' className={classes.userMenuItem}>Sign Up</HashLink>
        </div>
      }
      trigger='click'
      className={classes.userMenuPopover}
      overlayInnerStyle={{background: 'transparent', boxShadow: 'none', marginTop: '-12px'}}
    >
      <div style={{display:'flex', alignItems: 'center'}}>
        Account
        <DownArrowIcon />
      </div>
    </Popover>
  )
}

const UserMenu = () => {
  const dispatch = useAppDispatch()

  const signOut = () => {
    dispatch(SignOutThunk())
  }

  return (
    <div className={classes.userMenu}>
      <HashLink smooth to='/profile' className={classes.userMenuItem}>
        Profile
      </HashLink>
      <div onClick={signOut} className={classes.userMenuItem}>
        Sign Out
      </div>
    </div>
  )
}

interface HeaderProps {
  showMenu: boolean
  className?: string
}

export default Header
