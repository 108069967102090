import { useLocation, useNavigate } from 'react-router-dom'
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react'
import { Button, Select, Spin, Tabs } from 'antd'
import { pickBy } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import classes from './ResultTabs.module.css'
import { InputGoogleAPI } from '../../common/InputGoogleAPI/InputGoogleAPI'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { AddResultTabThunk,
  removeTab,
  selectActiveResultCategories,
  selectActiveTab,
  selectSearchResults,
  selectTabs,
  setActiveTab,
  setNodeServiceDetails,
  setSelectedTerminal,
  setSelectedTransportStop,
  setTabs,
  setActiveResultBlock,
  setSelectedTransportStops,
} from '../../../store/searchResultsReducer'
import { decodeSearchResultsExportLink, deleteSearchItemFromUrl, getDatesForUrl, getPeopleCountForUrl, getSearchItemAdditionalData, } from '../../../helpers/linksHelper'
import { ReactComponent as CloseIcon } from './../../../img/icons/close.svg'
import Results from './Results/Results'
import { ResultTabTypes } from '../../../types/searchResultTypes'
import Filters from '../Filters/Filters'
import { setNearTransportStops, setTransportStopFilters } from '../../../store/transportStopsReducer'
import { clearAdvertisement } from '../../../store/advertisementReducer'

const ResultTabs: React.FC<ResultTabsPropTypes> = ({ mapCenter, isDataLoading }) => {
  const dispatch = useAppDispatch()
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const navigate = useNavigate()
  const location = useLocation()
  const isSmallScreen = useMediaQuery({query: '(min-width: 100px) and (max-width: 767px)'})
  const maxTabAmount = 4
  const [isAddingTab, setIsAddingTab] = useState(false)
  const [newTabName, setNewTabName] = useState('')

  const selectTab = (tabName: string) => {
    dispatch(setActiveTab(tabs.find((tab: ResultTabTypes) => tab?.name === tabName)!))
    dispatch(setNodeServiceDetails(null))
    dispatch(setSelectedTransportStop(null))
    dispatch(setSelectedTransportStops([]))
    dispatch(activeResultCategories[0] !== 'near' ? setActiveResultBlock('Nodes') : setActiveResultBlock('Services'))
    dispatch(clearAdvertisement())
    dispatch(setTransportStopFilters(null))
  }

  const handleAddTab = () => {
    if (newTabName?.length !== 0 && !tabs.some(tab => tab.name === newTabName)) {
      const [mainData, sharedData] = `/travel/results${decodeURIComponent(location.search)}`.split('-shared')
      dispatch(AddResultTabThunk(newTabName)).then(resp => {
        if (!resp.type.includes('rejected')) {
          dispatch(setSelectedTransportStop(null))
          dispatch(setSelectedTransportStops([]))
          dispatch(setActiveTab(resp.payload as ResultTabTypes))
          dispatch(setTransportStopFilters(null))
          const newLink = mainData + `&${newTabName}${getDatesForUrl()}${getPeopleCountForUrl()}` + (sharedData?.length ? '-shared' + sharedData : '')
          navigate(newLink)
        }
      })
    }
    setNewTabName('')
    setIsAddingTab(false)
  }

  const handleRemoveTab = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>, tab: string) => {
    e.stopPropagation()
    const filteredTabs = tabs.filter(t => t.name !== tab)
    if (activeTab?.name === tab) {
      dispatch(setActiveTab(filteredTabs[0]))
      dispatch(setTabs(filteredTabs))
    }
    const newLink = deleteSearchItemFromUrl(location.search, tab)
    navigate(newLink)
    dispatch(setNodeServiceDetails(null))
    dispatch(setSelectedTerminal(null))
    dispatch(setNearTransportStops(null))
    dispatch(removeTab(tab))
    dispatch(setActiveResultBlock(activeResultCategories.includes('near') ? 'Services' : 'Nodes'))
  }

  const props = {
    mapCenter,
    isDataLoading,
    handleRemoveTab,
    selectTab,
    handleAddTab,
    isAddingTab,
    setIsAddingTab,
    newTabName,
    setNewTabName,
    maxTabAmount,
  }

  return (
    <>
      {isSmallScreen ? <TabsSmallScreen {...props}/> : <TabsRegularScreen {...props}/>}
    </>
  )
}

const TabsRegularScreen: React.FC<TabsPropTypes> = (props) => {
  const location = useLocation()
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)
  const tabPreviewData = decodeSearchResultsExportLink(location.search)?.locations?.map(req => getSearchItemAdditionalData(req))

  return (
    <Tabs
      activeKey={activeTab?.name}
      onTabClick={(key) => props.selectTab(key)}
      type='editable-card'
      className={classes.tabs}
      hideAdd={tabs?.length === props.maxTabAmount}
      renderTabBar={(tabBarProps) => (
        <div className={classes.tabsWrapper} style={{ display: 'flex' }}>
          {!!tabs?.length ? (
            tabs?.map(tab => (
              <div
                key={tab.name}
                className={`${classes.tab} ${tabBarProps.activeKey === tab.name && classes.active}`}
                onClick={(e) => tabBarProps.onTabClick(tab.name, e)}
              >
                <CloseIcon className={classes.removeTabIcon} onClick={(e) => props.handleRemoveTab(e, tab.name)} />
                <h2>{tab.name}</h2>
              </div>
            ))
          ) : (
            tabPreviewData?.map((tab, index) => (
              <div
                key={tab.location}
                className={`${classes.tab} ${index === 0 && classes.active}`}
                style={{cursor: 'default'}}
              >
                <h2>{tab.location}</h2>
              </div>
            ))
          )}
          {tabs?.length < props.maxTabAmount && !props.isAddingTab &&
            <div className={classes.tab} onClick={() => tabs?.length ? props.setIsAddingTab(true) : {}}>
              +
            </div>
          }
          {props.isAddingTab &&
            <div className={classes.tab}>
              <InputGoogleAPI
                value={props.newTabName}
                onSelect={(value) => props.setNewTabName(value)}
                placeholder='Enter location'
                suggestionsPosition='vertical'
              />
              <Button type='primary' onClick={props.handleAddTab}>
                Search
              </Button>
              <Button onClick={() => props.setIsAddingTab(false)}>
                Cancel
              </Button>
            </div>
          }
        </div>
      )}
    >
      {!!tabs?.length ? (
        tabs?.map(tab => (
          <Tabs.TabPane tab={tab.name} key={tab.name} closable={true}>
            {props.isDataLoading ? (
              <div className={classes.spinnerWrapper}>
                <Spin size='large' />
              </div>
            ) : (
              tab?.name === activeTab?.name ? <Results mapCenter={props?.mapCenter} /> : <></>
            )}
          </Tabs.TabPane>
        ))
      ) : (
       tabPreviewData?.map(tab => (
          <Tabs.TabPane tab={tab.location} key={tab.location} closable={true}>
            <div className={classes.spinnerWrapper}>
              <Spin size='large' />
            </div>
          </Tabs.TabPane>
        ))
      )}
    </Tabs>
  )
}

const TabsSmallScreen: React.FC<TabsPropTypes> = (props) => {
  const tabs = useAppSelector(selectTabs)
  const activeTab = useAppSelector(selectActiveTab)
  const searchResults = useAppSelector(selectSearchResults)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  
  const [isOpen, setIsOpen] = useState(false)

  const resultsForActiveCategories = pickBy(searchResults, function(_, key) {
    return activeResultCategories.some(category => category.toLowerCase() === key.toLowerCase())
  })
  const filtersAreAvailable = () => {
    return Object?.values(resultsForActiveCategories)?.some(results => results?.length! > 0)
      || (activeTab?.activeFilters && Object?.keys(activeTab.activeFilters)?.some(key => activeResultCategories?.includes(key)))
  }

  return (
    <div className={classes.tabsSmallScreenWrapper}>
      <div className={classes.tabsSmallScreen}>
        <Filters filtersAreAvailable={filtersAreAvailable()}>
        <Select
          open={isOpen}
          onDropdownVisibleChange={(val) => setIsOpen(val)}
          value={activeTab?.name}
          style={{maxWidth: 'calc(100vw - 20px - 88px)'}}
          dropdownRender={() => (
            <div>
              {tabs?.map((tab: ResultTabTypes) => (
                <div
                  key={tab.name}
                  className={`
                    ${classes.tabOption}
                    ${activeTab?.name === tab?.name ? classes.active : ''}
                  `}
                  onClick={() => {
                    props.selectTab(tab?.name)
                    setIsOpen(false)
                  }}
                >
                  <div>{tab?.name}</div>
                  <CloseIcon
                    onClick={(e) => {
                      props.handleRemoveTab(e, tab?.name)
                      setIsOpen(false)
                    }}
                    style={{width: '50px'}}
                  />
                </div>
              ))}
              {tabs?.length < props.maxTabAmount && !props.isAddingTab &&
                <div
                  onClick={() => props.setIsAddingTab(true)}
                  style={{color: 'black', textAlign: 'center', fontSize: '20px'}}
                >
                  + New Search
                </div>
              }
              {props.isAddingTab &&
                <div className={classes.newSearchSmallScreen}>
                  <InputGoogleAPI
                    value={props.newTabName}
                    onChange={(value) => props.setNewTabName(value)}
                    placeholder='Enter location'
                    suggestionsPosition='vertical'
                  />
                  <div style={{ marginTop: '10px' }}>
                    <Button type='primary' onClick={props.handleAddTab} style={{marginRight: '10px'}}>
                      Search
                    </Button>
                    <Button onClick={() => props.setIsAddingTab(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              }
            </div>
          )}
        />
        </Filters>
      </div>

      {props.isDataLoading ? (
        <div className={classes.spinnerWrapper}>
          <Spin size='large' />
        </div>
      ) : (
        <Results mapCenter={props.mapCenter} />
      )}
    </div>
  )
}

interface ResultTabsPropTypes {
  mapCenter: [number, number]
  isDataLoading: boolean
}

interface TabsPropTypes extends ResultTabsPropTypes {
  handleRemoveTab: (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>, tabName: string) => void
  selectTab: (tabName: string) => void
  handleAddTab: () => void
  isAddingTab: boolean
  setIsAddingTab: Dispatch<SetStateAction<boolean>>
  newTabName: string
  setNewTabName: Dispatch<SetStateAction<string>>
  maxTabAmount: number
}

export default ResultTabs
