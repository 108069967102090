import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pickBy } from 'lodash'
import { userAPI } from '../app/api'
import { tokenDataHelper, userDataHelper } from '../helpers/localStorageHelper'
import { SignInDataType, SignUpDataType, UserDataType, UserFeedbackType } from './../types/userTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { TokenDataType } from '../types/appTypes'

interface InitialStateType {
  isLoggedIn: boolean
  userData: UserDataType
  tokenData: TokenDataType
  userIP: string
}

const initialState: InitialStateType = {
  isLoggedIn: !!userDataHelper.getUserData(),
  userData: userDataHelper.getUserData(),
  tokenData: tokenDataHelper.getTokenData(),
  userIP: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {state.isLoggedIn = action.payload},
    setUserData: (state, action: PayloadAction<UserDataType>) => {state.userData = action.payload},
    setUserAvatar: (state, action: PayloadAction<string>) => {state.userData = {...state.userData, photo_url: action.payload}},
    setUserIp: (state, action: PayloadAction<string>) => {state.userIP = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginThunk.fulfilled, (state, action) => {
        state.isLoggedIn = true
        state.userData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.isLoggedIn = false
        state.tokenData = {} as TokenDataType
        state.userData = {} as UserDataType
      })
      .addCase(SetPasswordThunk.fulfilled, (state, action) => {
        state.isLoggedIn = true
        state.userData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
      })
      .addCase(EditUserDataThunk.fulfilled, (state, action) => {
        state.userData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
      })
      .addCase(GetUserIPThunk.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.userIP = action.payload
        }
      })
  }
})

export const { setIsLoggedIn, setUserData, setUserIp, setUserAvatar } = userSlice.actions

export const selectIsLoggedIn = (state: RootState): boolean => state.user.isLoggedIn
export const selectUserData = (state: RootState): UserDataType => state.user.userData
export const selectTokenData = (state: RootState): TokenDataType => state.user.tokenData
export const selectUserId = (state: RootState): number => state.user.userData.user_id
export const selectUserIP = (state: RootState): string => state.user.userIP

export const LoginThunk = createAsyncThunk<UserDataType, SignInDataType, AsyncThunkConfig>(
  'user/login',
  async (loginData, thunkAPI) => {
    try {
      const { status, data } = await userAPI.signIn(loginData)
      if (status === 200 && data) {
        userDataHelper.setUserData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
        tokenDataHelper.setTokenData(data.token!)
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SignUpThunk = createAsyncThunk<UserDataType, SignUpDataType, AsyncThunkConfig>(
  'user/signUp',
  async (signUpData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.signUp(signUpData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SendPasswordEmailThunk = createAsyncThunk<string, {email: string, showSuccessMessage: boolean}, AsyncThunkConfig>(
  'user/sendPasswordEmail',
  async (emailData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.sendEmailForNewPassword(emailData.email)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: emailData.showSuccessMessage ? AppStatusType.succeeded : AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SetPasswordThunk = createAsyncThunk<UserDataType, {token: string, password: string, autoLogin: boolean}, AsyncThunkConfig>(
  'user/password',
  async (newPasswordData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.setPassword({password: newPasswordData.password, token: newPasswordData.token})
      if (status === 200 && data) {
        !!newPasswordData.autoLogin && userDataHelper.setUserData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
        !!newPasswordData.autoLogin && tokenDataHelper.setTokenData(data.token!)
        return thunkAPI.fulfillWithValue(newPasswordData.autoLogin ? data: {} as UserDataType, {appStatus: AppStatusType.succeeded})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const ChangePasswordThunk = createAsyncThunk<UserDataType, {user_id: number, old_password: string, password: string}, AsyncThunkConfig>(
  'user/changePassword',
  async (passwordData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.changePassword(passwordData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditUserDataThunk = createAsyncThunk<UserDataType, {userId: number, userData: FormData}, AsyncThunkConfig>(
  'user/editUserData',
  async ({userId, userData}, thunkAPI) => {
    try {
      const {data, status} = await userAPI.editUserData(userId, userData)
      if (status === 200 && data) {
        userDataHelper.setUserData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
        tokenDataHelper.setTokenData(data.token!)
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SignOutThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
  'user/signOut', (_, thunkAPI) => {
    userDataHelper.removeUserData()
    tokenDataHelper.removeTokenData()
    return thunkAPI.fulfillWithValue(_, {appStatus: AppStatusType.idle})
  }
)

export const SendUserFeedbackThunk = createAsyncThunk<void, UserFeedbackType, AsyncThunkConfig>(
  'user/sendUserFeedback',
  async (feedbackData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.sendUserFeedback(feedbackData)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetUserIPThunk = createAsyncThunk<string | null, void, AsyncThunkConfig>(
  'user/getUserIP',
  async (_, thunkAPI) => {
    try {
      const response = await fetch('https://geolocation-db.com/json/')
      const userLocationData = await response?.json()
      if (!!userLocationData.IPv4.length) {
        return thunkAPI.fulfillWithValue(userLocationData.IPv4, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.idle})
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue('canceled')
      // return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default userSlice.reducer
 