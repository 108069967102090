import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { airportAPI } from '../app/api'
import { AirlineType, AirportDataType, AllianceType, GetAirportsWithFlightToLocationRequestType, LocationDataType, NearAirportType } from '../types/airportTypes'
import { ResultTabTypes } from '../types/searchResultTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import moment from 'moment'
import { GetUserIPThunk } from './userReducer'
import { CancelTokenSource } from 'axios'
import { getRequestHeaders } from '../helpers/axiosHelper'
import { WidgetLocationType } from '../types/widgetTypes'

interface InitialStateType {
  nearAirports: NearAirportType[]
  airlines: AirlineType[]
  alliances: AllianceType[],
  airportsWithFlightToLocation: AirportDataType[] | null
  airportsById: {[key: string]: AirportDataType[]} | null
}

const initialState: InitialStateType = {
  nearAirports: [],
  airlines: [],
  alliances: [],
  airportsWithFlightToLocation: null,
  airportsById: null,
}

export const airportSlice = createSlice({
  name: 'airport',
  initialState,
  reducers: {
    setNearAirports: (state, action: PayloadAction<NearAirportType[]>) => {state.nearAirports = action.payload},
    setAirlines: (state, action: PayloadAction<AirlineType[]>) => {state.airlines = action.payload},
    setAlliances: (state, action: PayloadAction<AllianceType[]>) => {state.alliances = action.payload},
    setAirportsWithFlightToLocation: (state, action: PayloadAction<AirportDataType[] | null>) => {state.airportsWithFlightToLocation = action.payload},
    setAirportsById: (state, action: PayloadAction<{[key: string]: AirportDataType[]} | null>) => {state.airportsById = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetNearAirportsThunk.fulfilled, (state, action) => {
        state.nearAirports = action.payload
      })
      .addCase(GetAirportsByCityThunk.fulfilled, (state, action) => {
        state.nearAirports = action.payload
      })
      .addCase(GetAirlinesThunk.fulfilled, (state, action) => {
        state.airlines = action.payload
      })
      .addCase(GetAlliancesThunk.fulfilled, (state, action) => {
        state.alliances = action.payload
      })
      .addCase(GetAirportsWithFlightToLocationThunk.fulfilled, (state, action) => {
        state.airportsWithFlightToLocation = action.payload
      })
      .addCase(GetAirportsByIdThunk.fulfilled, (state, action) => {
        state.airportsById = action.payload
      })
  }
})

export const {
  setNearAirports,
  setAirlines,
  setAlliances,
  setAirportsWithFlightToLocation,
  setAirportsById,
} = airportSlice.actions

export const selectNearAirports = (state: RootState): NearAirportType[] => state.airports.nearAirports
export const selectAirlines = (state: RootState): AirlineType[] => state.airports.airlines
export const selectAlliances = (state: RootState): AllianceType[] => state.airports.alliances
export const selectAirportsWithFlightToLocation = (state: RootState): AirportDataType[] | null => state.airports.airportsWithFlightToLocation
export const selectAirportsById = (state: RootState): {[key: string]: AirportDataType[]} | null => state.airports.airportsById

export const GetNearAirportsThunk = createAsyncThunk<NearAirportType[], LocationDataType, AsyncThunkConfig>(
  'airports/getNearAirports',
  async (locationData, thunkAPI) => {
    try {
      const {data, status} = await airportAPI.getNearAirports(locationData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.airports, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAirportsByCityThunk = createAsyncThunk<NearAirportType[], {activeTabData?: ResultTabTypes, widgetLocation?: WidgetLocationType, departure_date?: string, source?: CancelTokenSource}, AsyncThunkConfig>(
  'airports/getAirportsByCity',
  async ({activeTabData, widgetLocation, departure_date, source}, thunkAPI) => {
    try {
      const userLocationHeader = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const requestHeaders = {
        ...userLocationHeader,
        ...(widgetLocation ? {Authorization: false} : {})
      }
      const requestData = {
        country_code: (activeTabData?.country_code || widgetLocation?.country_code) as string,
        city_name: activeTabData?.city_name || widgetLocation?.city_name || null,
        start_location: {
          latitude: (activeTabData?.coordinates?.[0] || widgetLocation?.coordinates?.[0]) as number,
          longitude: (activeTabData?.coordinates?.[1] || widgetLocation?.coordinates?.[1]) as number
        },
        departure_date: departure_date || moment(activeTabData?.dates[0]).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
        ...activeTabData?.activeFilters?.airports || {}
      }
      const {data, status} = await airportAPI.getAirportsByCity(requestData, requestHeaders, source)
      if (status === 200 && data) {
        const airportsAsNearAirports = data.airports.map(airport => ({
          details: airport,
          distance: 0,
          unit: 'KM',
          advertising_list: airport?.advertising_list || []
        }))
        return thunkAPI.fulfillWithValue(airportsAsNearAirports, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAirlinesThunk = createAsyncThunk<AirlineType[], void, AsyncThunkConfig>(
  'airports/getAirlines',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await airportAPI.getAllAirlines()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.airlines, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAlliancesThunk = createAsyncThunk<AllianceType[], void, AsyncThunkConfig>(
  'airports/getAlliances',
  async (_, thunkAPI) => {
    try {
      const {data, status} = await airportAPI.getAllAlliances()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.alliances, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAirportsWithFlightToLocationThunk = createAsyncThunk<AirportDataType[], GetAirportsWithFlightToLocationRequestType, AsyncThunkConfig>(
  'airports/getAirportsWithFlightToLocation',
  async (requestData, thunkAPI) => {
    try {
      const {data, status} = await airportAPI.getAirportsWithFlightToLocation(requestData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.airports, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAirportsByIdThunk = createAsyncThunk<{[key: string]: AirportDataType[]}, {[key: string]: number[]}, AsyncThunkConfig>(
  'airports/getAirportsById',
  async (locationAirports, thunkAPI) => {
    try {
      const resp = await Promise.all(Object.values(locationAirports)?.map(locationAirport => (
        airportAPI.getAirportsById(locationAirport)
      )))
      const airportsData: {[key: string]: AirportDataType[]} = {}
      resp.forEach((resData, index) => airportsData[String(index + 1)] = resData.data.airports)    
      if (resp?.[0]?.status === 200) {
        return thunkAPI.fulfillWithValue(airportsData, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(resp?.[0]?.data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default airportSlice.reducer
 