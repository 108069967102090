export const defaultActiveFilters = {
  airports: {
    scheduled_flights: true,
    destination_location_name: '',
    show_all_countries: true
  },
  ports: {
    destination_location_name: '',
    show_all_countries: true,
  }
}
