import { Link } from 'react-router-dom'
import { useCallback, useLayoutEffect, useState } from 'react'
import { Modal } from 'antd'
import classes from './Footer.module.css'
import {ReactComponent as FacebookIcon} from './../../../img/footer/facebook.svg'
import {ReactComponent as TwitterIcon} from './../../../img/footer/twitter.svg'
import {ReactComponent as YoutubeIcon} from './../../../img/footer/youtube.svg'
import Feedback from '../Feedback/Feedback'

const Footer = () => {
  const [iubendaLink, setIubendaLink] = useState('')  
  const [cookieLinkElement, setCookieLinkElement] = useState<any>('')
  const [hasWrapperForUSACookie, setHasWrapperForUSACookie] = useState<any>(true)

  const simulateUSABannerClick = useCallback(() => {
    if (cookieLinkElement) {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });
      cookieLinkElement.dispatchEvent(clickEvent);
    }
  }, [cookieLinkElement])

  useLayoutEffect(() => {
    const wrapperElement = document.getElementsByClassName('iubenda-uspr-btn')[0]
    const element = document.getElementsByClassName('iubenda-cs-preferences-link')[0]

    setHasWrapperForUSACookie(!!wrapperElement)
    setCookieLinkElement(element)
  }, [])

  return (
    <footer className={classes.footer}>
      <nav className={classes.navMenu}>
        {footerMenuItems.map(item => (
          <Link to={item.link} key={item.link}>
            {item.name}
          </Link>
        ))}
        {footerPolicyMenuItems.map(item => (
          <div
            key={item.name}
            onClick={() => setIubendaLink(item.link)}
          >
            {item.name}
          </div>
        ))}
      </nav>
      <div className={classes.social}>
        {socialMediaLinks.map(item => (
          <Link to={item.link} key={item.name}>
            <item.icon />
          </Link>
        ))}
      {/* eslint-disable-next-line */}
      <a
        href='#'
        className={hasWrapperForUSACookie || cookieLinkElement ? '' : 'iubenda-cs-preferences-link'}
        onClick={hasWrapperForUSACookie || cookieLinkElement ? simulateUSABannerClick : () => {}}
        style={{position: 'absolute', right: '10px', fontSize: '14px', color: 'white'}}
      >
        Your Privacy Choices
      </a>
      </div>
      <div className={classes.copyrightWrapper}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <a href='https://www.scopiatours.com' target='_blank' rel='noreferrer' className={classes.copyright}>
            © Copyright 2023 Scopia LLC
          </a>
          <a
            target='_blank'
            href='https://www.iubenda.com/privacy-policy/88823004/cookie-policy'
            className={`${classes.copyright} iubenda-cs-uspr-link`}
            rel="noreferrer"
          >
            Notice at Collection
          </a>
        </div>
        <Feedback />
        {!!iubendaLink.length &&
          <PolicyModal
            link={iubendaLink}
            resetLink={() => setIubendaLink('')}
          />
        }
      </div>
    </footer>
  )
}

const PolicyModal: React.FC<{link: string, resetLink: () => void}> = ({link, resetLink}) => {
  return (
    <Modal
      visible={!!link.length}
      footer={null}
      bodyStyle={{height: '500px'}}
      className={classes.policyModal}
      onCancel={resetLink}
    >
      <div
        dangerouslySetInnerHTML={{__html:
          `<iframe class=${classes.policyFrame} src=${link} width='97%'/>`
        }}
      >
      </div>
    </Modal>
  )
}

const footerMenuItems = [
  {name: 'How to', link: '/#howTo'},
]

const footerPolicyMenuItems = [
  {name: 'Privacy Policy', link: 'https://www.iubenda.com/privacy-policy/88823004'},
  {name: 'Cookie Policy', link: 'https://www.iubenda.com/privacy-policy/88823004/cookie-policy'},
  {name: 'Terms and Conditions', link: 'https://www.iubenda.com/terms-and-conditions/88823004'},
]

const socialMediaLinks = [
  {name: 'Facebook', link: '/', icon: FacebookIcon},
  {name: 'Twitter', link: '/', icon: TwitterIcon},
  {name: 'YouTube', link: '/', icon: YoutubeIcon},
]

export default Footer
