import classes from './../EventCard.module.css'

const EventDetailsTag: React.FC<{tag: string}> = ({tag}) => {
  return (
    <div className={classes.eventDetailsTagWrapper}>
      { tag }
    </div>
  )
}

export default EventDetailsTag
