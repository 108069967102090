import { Button, Modal, Radio, Tooltip } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import classes from './Travel.module.css'
import Header from '../common/Header/Header'
import BackBtn from '../common/BackBtn/BackBtn'
import searchImage from './../../img/travel/search.webp'
import flightImage from './../../img/travel/flight.webp'
import cruiseImage from './../../img/travel/cruise.webp'
import listImage from './../../img/travel/list.webp'
import { useState } from 'react'
import shipImg from './../../img/travel/shipImg.webp'
import { ReactComponent as PlaneImg } from './../../img/travel/byFlight/planeImg.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectCruiseSearchType, setCruiseSearchType } from '../../store/cruiseReducer'
import { CruiseSearchTypeType } from '../../types/cruiseTypes'
import { Helmet } from 'react-helmet-async'
import { selectFlightSearchType, setFlightSearchType } from '../../store/flightReducer'

const Travel = () => {
  const options = [
    {title: 'By Locations', description: 'Enter address, attraction, city name', link: '/travel/search', image: searchImage},
    {title: 'By Flight', description: 'Tell us your flight number(s)', link: '/travel/by-flight', image: flightImage},
    {title: 'By Cruise', description: 'Choose your cruise', link: '/travel/by-cruise', image: cruiseImage},
    {title: 'By List/Website', description: 'Add a list or article/website link.', link: '/travel', image: listImage},
  ]

  const [searchTypeModal, setSearchTypeModal] = useState<false | 'cruise' | 'flight'>(false)

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Relavanti | Travel</title>
        <link rel='canonical' href='https://www.relavanti.com/travel' />
      </Helmet>
      <Header showMenu={true}/>
      <BackBtn type='button'/>
      <div className={classes.pageContent}>
        <h1>
          Travel
        </h1>
        <div className={classes.description}>
          How would you like to search? 
        </div>
        <div className={classes.searchOptions}>
          {options.map(option => (
            <div className={classes.option} key={option.title}>
              <img src={option.image} alt={option.title} />
              <div className={classes.optionContent}>
                <div className={classes.optionDescription}>
                  {option.description}
                </div>
                {option.title === 'By List/Website' ? (
                  <Tooltip title='Coming soon'>
                    <Button type='primary' style={{cursor: 'default'}}>
                      {option.title}
                    </Button>
                  </Tooltip>
                ) : (
                  option.title === 'By Cruise' || option.title === 'By Flight' ? (
                    <Button
                      type='primary'
                      onClick={() => setSearchTypeModal(option.title?.includes('Cruise') ? 'cruise' : 'flight')}
                    >
                      {option.title}
                    </Button>
                  ) : (
                    <Link to={option.link}>
                      <Button type='primary'>
                        {option.title}
                      </Button>
                    </Link>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <SearchTypeModal
        isVisible={searchTypeModal !== false}
        onClose={() => setSearchTypeModal(false)}
        modalType={searchTypeModal}
      />
    </div>
  )
}

const SearchTypeModal:React.FC<{isVisible: boolean, onClose:() => void, modalType: 'cruise' | 'flight' | false}> = ({isVisible, onClose, modalType}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const cruiseSearchType = useAppSelector(selectCruiseSearchType)
  const flightSearchType = useAppSelector(selectFlightSearchType)

  const isDefaultSelected = modalType === 'cruise' ? cruiseSearchType === 'default' : flightSearchType === 'default' 

  const selectSearchType = (type: CruiseSearchTypeType) => {
    if (modalType === 'cruise') {
      dispatch(setCruiseSearchType(type))
    } else {
      dispatch(setFlightSearchType(type))
    }
  }

  const goToSearch = () => {
    navigate(`/travel/by-${modalType}${isDefaultSelected ? '' : '-advanced'}`)
  }
  
  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width='340px'
    >
      {modalType === 'flight' && <PlaneImg style={{margin: '0 auto', width: '100%'}}/>}
      {modalType === 'cruise' && <img src={shipImg} alt='ship' style={{margin: '0 auto', width: '100%'}}/>}

      <div style={{color: '#101828', fontSize: '18px', textAlign: 'center', fontWeight: 600, marginBottom: '10px'}}>
        Select the type of search
      </div>
      <div className={classes.optionDescription} style={{height: '100%', textAlign: 'center'}}>
        There are two ways to search for a {modalType} in our app: an advanced search or a specific cruise search. Please indicate which type of search you are interested in
      </div>
      <Radio.Group
        onChange={e => selectSearchType(e.target.value)}
        value={modalType === 'cruise' ? cruiseSearchType : flightSearchType}
      >
        <Radio value='advanced' style={{marginBottom: '7px'}}>Advanced search</Radio>
        <Radio value='default'>Specific {modalType} search</Radio>
      </Radio.Group>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px', marginTop: '15px'}}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type='primary' onClick={goToSearch}>Next</Button>
      </div>
    </Modal>
  )
}

export default Travel
