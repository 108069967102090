import { Button, InputNumber, Modal, Radio, Select } from 'antd'
import classes from './FiltersModal.module.css'
import { InputGoogleAPI } from '../../InputGoogleAPI/InputGoogleAPI'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetTransportStopsWithDirectRouteThunk, selectIsTransportStopsLoading, setTransportStopFilters } from '../../../../store/transportStopsReducer'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { selectDistanceType } from '../../../../store/appStatusReducer'
import { useState } from 'react'
import { TransportStopFiltersType } from '../../../../types/transportStopsTypes'
import { selectActiveTab, selectNodeServiceDetails, selectSelectedTerminal, selectSelectedTransportStops } from '../../../../store/searchResultsReducer'
import { selectCurrentActiveTabData } from '../../../../store/travelSearchReducer'

interface FiltersModalPropTypes {
  isModalOpen: boolean
  handleClose: () => void
  stopsType: 'node' | 'near'
  getAllStops: () => Promise<any>
}

const FiltersModal: React.FC<FiltersModalPropTypes> = ({isModalOpen, handleClose, stopsType, getAllStops}) => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectActiveTab)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const selectedTerminal = useAppSelector(selectSelectedTerminal)
  const distanceType = useAppSelector(selectDistanceType)
  const selectedTransportStops = useAppSelector(selectSelectedTransportStops)
  const isTransportStopsLoading = useAppSelector(selectIsTransportStopsLoading)
  const travelStopsActiveTabData = useAppSelector(selectCurrentActiveTabData)

  const getDepartureCoordinates = () => {
    if (!!selectedTransportStops?.length) {
      return {
        latitude: selectedTransportStops?.[selectedTransportStops?.length - 1]?.location?.latitude!,
        longitude: selectedTransportStops?.[selectedTransportStops?.length - 1]?.location?.longitude!,
      }
    } else if (!!selectedTerminal?.id) {
      return {
        latitude: selectedTerminal?.location.latitude!,
        longitude: selectedTerminal?.location?.longitude!,
      }
    } else if (!!nodeServiceDetails?.id) {
      return {
        latitude: nodeServiceDetails.location.latitude!,
        longitude: nodeServiceDetails?.location?.longitude!,
      }
    } else if (!!Object.keys(activeTab)?.length) {
      return {
        latitude: activeTab.coordinates[0]!,
        longitude: activeTab.coordinates[1]!,
      }
    } else if (!!travelStopsActiveTabData?.location?.latitude) {
      return {
        latitude: travelStopsActiveTabData?.location?.latitude!,
        longitude: travelStopsActiveTabData?.location?.longitude!,
      }
    } else {
      return {
        latitude: 0,
        longitude: 0,
      }
    }
  }

  const getDefaultFilterValues = () => {
    return {
      departure_location: {
        ...getDepartureCoordinates(),
        radius: distanceType === 'km' ? 1 : 0.6,
        unit: distanceType.toUpperCase() as 'KM' | 'MILES',
      },
      arrival_location: {
        latitude: 0,
        longitude: 0,
        radius: distanceType === 'km' ? 1 : 0.6,
        unit: distanceType.toUpperCase() as 'KM' | 'MILES',
      }
    }
  }

  const [formValues, setFormValues] = useState<TransportStopFiltersType>(getDefaultFilterValues())
  const [filterType, setFilterType] = useState<'all' | 'direct'>(!!formValues?.arrival_location?.latitude? 'direct' : 'all')

  const onLocationChange = async(value: string) => {
    if (value.length) {
      const addressData = await geocodeByAddress(value)
      const coordinates = await getLatLng(addressData[0])
      setFormValues({
        departure_location: {
          ...getDepartureCoordinates(),
          radius: formValues.departure_location.radius,
          unit: formValues.departure_location.unit,
        },
        arrival_location: {
          ...formValues.arrival_location,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          name: value
        }
      })
    }
  }

  const clearAll = () => {
    setFilterType('all')
    setFormValues(getDefaultFilterValues())
    dispatch(setTransportStopFilters(null))
    getAllStops().then(() => handleClose())
  }

  const applyFilters = () => {
    if (filterType === 'all') {
      setFormValues(getDefaultFilterValues())
      dispatch(setTransportStopFilters(null))
      getAllStops().then(() => handleClose())
    } else {
      dispatch(setTransportStopFilters(formValues))
      dispatch(GetTransportStopsWithDirectRouteThunk({
        filters: {
          departure_location: {
            ...formValues.departure_location,
            radius: formValues.departure_location.radius * 1000
          },
          arrival_location: {
            ...formValues.arrival_location,
            radius: formValues.arrival_location.radius * 1000
          },
        },
        type: stopsType
      })).then(() => handleClose())
    }
  }

  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleClose}
      footer={null}
      centered
      bodyStyle={{
        padding:'0px',
        overflow: 'auto',
        minHeight: '100px',
      }}
      style={{top: '12px'}}
    >
      <div className={classes.modalTitle}>
        Filters
      </div>
      <div className={classes.modalAdditionalTitle}>
        Public Transportation Stops
      </div>
      <div className={classes.formArea}>
        <div className={classes.fieldLabel}>
          Type of stops
        </div>
        <Radio.Group
          value={filterType}
          onChange={e => {
            // setFormValues(defaultFilterValues)
            if (e.target.value === 'all') {
              dispatch(setTransportStopFilters(null))
            }
            setFilterType(e.target.value as 'all' | 'direct')
          }}
        >
          <Radio value='all'><span style={{fontSize: '14px'}}>Show all</span></Radio>
          <Radio value='direct'><span style={{fontSize: '14px'}}>Direct routes</span></Radio>
        </Radio.Group>
        <div className={classes.fieldLabel}>
          Direct routes to the location
        </div>
        <InputGoogleAPI
          placeholder='Enter location'
          value={formValues?.arrival_location?.name || ''}
          onChange={(value) => onLocationChange(value)}
          disabled={filterType === 'all'}
        />
        <div className={classes.fieldLabel}>
          Stop search radius
        </div>
        <div className={classes.doubleInputField}>
          <InputNumber
            controls={false}
            style={{width: '100%'}}
            min={0.1}
            disabled={filterType === 'all'}
            value={formValues?.arrival_location.radius}
            onChange={val => {
              setFormValues({
                arrival_location: {
                  ...formValues.arrival_location,
                  radius: val || 0.1
                },
                departure_location: {
                  ...formValues.departure_location,
                  radius: val || 0.1
                },
              })
            }}
          />
          <Select
            value={formValues?.arrival_location?.unit}
            disabled={filterType === 'all'}
            onChange={val => setFormValues(
              {
                departure_location: {
                  ...formValues.departure_location,
                  unit: val
                },
                arrival_location: {
                  ...formValues.arrival_location,
                  unit: val
                }
              }
            )}
            placeholder='Units'
          >
            <Select.Option value='KM'>Km</Select.Option>
            <Select.Option value='MILES'>Miles</Select.Option>
          </Select>
        </div>
      </div>
      <div className={classes.buttonsWrapper}>
        <div className={classes.clearAll} onClick={clearAll}>
          {filterType === 'direct' && 'Clear all'}
        </div>
        <Button
          type='primary'
          onClick={applyFilters}
          disabled={filterType === 'direct' && formValues?.arrival_location?.latitude === 0}
          loading={isTransportStopsLoading}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
}

export default FiltersModal
