import { Select } from 'antd'

const SelectLocations: React.FC<SelectLocationsPropTypes> = ({handleLocationSelect, availableLocations, showLabel}) => {
  return (
    <>
      {showLabel &&
        <div style={{fontSize: '16px', alignSelf: 'start'}}>
          Locations from your search list
        </div>
      }
      <Select
      // @ts-ignore
        onSelect={(val: string) => handleLocationSelect(val)}
        style={{width: '100%'}}
        options={availableLocations.map(location => ({value: location.name, label: location.name}))}
        optionFilterProp='label'
        placeholder='Select locations'
        value={[]}
      />
    </>
  )
}


interface SelectLocationsPropTypes {
  handleLocationSelect: (location: string) => void
  availableLocations: {name: string, url: string}[]
  showLabel: boolean
}
export default SelectLocations
