import classes from './TripNumber.module.css'
import { ReactComponent as GoBackIcon } from './../../../../img/icons/goBack.svg'
import { ReactComponent as PlaneImg } from './../../../../img/travel/byFlight/planeImg.svg'
import shipImg from './../../../../img/travel/shipImg.webp'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'

const TripNumber: React.FC<TripNumberPropTypes> = ({
  tripNumber,
  secondaryInfo,
  primaryInfo,
  additionalInfo,
  imageType,
  goBackLink,
  handleGoBack,
  logo,
  logoLink,
  hideGoBack
}) => {
  const navigate = useNavigate()

  return (
    <div
      className={`${classes.wrapper} ${hideGoBack ? classes.hideGoBack : ''}`}
      onClick={() => goBackLink ? navigate(goBackLink) : hideGoBack ? {} : handleGoBack!()}
    >
      {!hideGoBack && <GoBackIcon />}
      <div style={{position: 'relative', zIndex: 1}}>
        <div className={classes.tripNumber}>
          {tripNumber}
        </div>
        {imageType === 'ship' && !!Object.keys(additionalInfo || {})?.length ? (
          <>
            <div className={classes.primaryInfo} style={{fontWeight: 600}}>
              {primaryInfo}
            </div>
            {additionalInfo?.cruiseNumber &&
              <div>
                <div className={classes.secondaryInfo}>
                  Cruise number:
                  <span className={classes.primaryInfo} style={{marginLeft: '6px'}}>
                    {additionalInfo?.cruiseNumber}
                  </span>
                </div>
              </div>
            }
            {!!additionalInfo?.ship &&
              <div>
                <div className={classes.secondaryInfo}>
                  Ship:
                  <span className={classes.primaryInfo} style={{marginLeft: '6px'}}>
                    {additionalInfo?.ship}
                  </span>
                </div>
              </div>
            }
          </>
        ) : (
          <>
            <div className={classes.secondaryInfo}>
              {secondaryInfo}
            </div>
            <div className={classes.primaryInfo}>
              {primaryInfo}
            </div>
          </>
        )}
      </div>
      {imageType === 'plane' && <PlaneImg className={classes.transportImg} onClick={(e) => e.stopPropagation()}/> }
      {imageType === 'ship' && !logo &&
        <>
          {logoLink ? (
            <a
              href={logoLink.includes('https') || logoLink.includes('http') ? logoLink : '//' + logoLink}
              target='_blank'
              rel='noreferrer'
              className={classes.transportImgLogo}
              style={{zIndex: 1000}}
              onClick={(e) => e.stopPropagation()}
            >
              <img src={shipImg} alt='ship' className={classes.transportImg} style={{objectFit: 'contain'}}/>
              {/* <ShipImg className={classes.transportImg}/> */}
            </a>
          ) : (
            <img src={shipImg} alt='ship' className={classes.transportImg} onClick={(e) => e.stopPropagation()} style={{zIndex: 1000, objectFit: 'contain'}}/>
            // <ShipImg className={classes.transportImg} onClick={(e) => e.stopPropagation()} style={{zIndex: 1000}}/>
          )}
        </>
        
      }
      {imageType === 'ship' && !!logo &&
        <>
          {logoLink ? (
            <a
              href={logoLink.includes('https') || logoLink.includes('http') ? logoLink : '//' + logoLink}
              target='_blank'
              rel='noreferrer'
              className={classes.transportImgLogo}
              style={{zIndex: 1000}}
              onClick={(e) => e.stopPropagation()}
            >
              <CompanyLogo logoUrl={logo} showBtn/>
            </a>
          ) : (
            <CompanyLogo logoUrl={logo} onClick={(e) => e.stopPropagation()}/>
          )}
        </>
      }
    </div>
  )
}

const CompanyLogo: React.FC<CompanyLogoPropTypes> = ({logoUrl, onClick, showBtn}) => {
  return (
    <div className={classes.transportLogo} onClick={onClick}>
      <img
        src={logoUrl}
        alt='cruise line logo'
        className={`${classes.transportLogoImg} ${showBtn ? '' : classes.noBtn}`}
      />
      {showBtn &&
        <Button type='primary'>
          Book now
        </Button>
      }
    </div>
  )
}

interface TripNumberPropTypes {
  tripNumber: string
  secondaryInfo: string
  primaryInfo: string
  additionalInfo?: {
    ship?: string
    cruiseNumber?: string
  }
  goBackLink?: string
  handleGoBack?: () => void
  imageType: 'ship' | 'plane' | null
  logo?: string
  logoLink?: string
  hideGoBack?: boolean
}

interface CompanyLogoPropTypes {
  logoUrl: string
  onClick?: (e:any) => void
  showBtn?: boolean
}

export default TripNumber
