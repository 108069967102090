import { Form, FormInstance, Select, Spin } from 'antd'
import { debounce, sortBy, uniqWith } from 'lodash'
import moment, { Moment } from 'moment'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import axios from './../../../helpers/axiosHelper'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetCruiseAvailableDatesThunk, GetCruiseLineOptionsThunk, GetCruisesByLineIdThunk, selectCruise, selectCruiseDateOptions, selectCruiseLineOptions, selectCruiseOptions, selectCruises, setCruise, setCruiseDateOptions, setCruiseLine, setCruiseLineOptions, setCruiseOptions, setCruises } from '../../../store/cruiseReducer'
import SearchPageWithBgImage from '../common/SearchPageWithBgImage/SearchPageWithBgImage'
import { setActiveResultCategories, setNodeServiceDetails } from '../../../store/searchResultsReducer'
import { setNearTransportStops } from '../../../store/transportStopsReducer'
import { setNearServices } from '../../../store/serviceReducer'
import NextLocationForm from '../common/NextLocationForm/NextLocationForm'
import { getCruiseSearchUrl } from '../../../helpers/linksHelper'
import { setSelectedResultItemId } from '../../../store/travelSearchReducer'
import { CruiseType } from '../../../types/cruiseTypes'
import classes from './ByCruise.module.css'
import { Helmet } from 'react-helmet-async'

const ByCruise = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const cruise = useAppSelector(selectCruise)
  const cruises = useAppSelector(selectCruises)
  const availableDates = useAppSelector(selectCruiseDateOptions)

  const [activeFormStep, setActiveFormStep] = useState<'mainData' | 'nextLocation'>('mainData')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(setCruiseLine(null))
    return () => {
      dispatch(setCruise(null))
      dispatch(setCruises(null))
      dispatch(setCruiseLine(null))
      dispatch(setCruiseLineOptions(null))
      dispatch(setCruiseDateOptions(null))
      dispatch(setSelectedResultItemId(''))
      dispatch(setActiveResultCategories(['near']))
      dispatch(setNodeServiceDetails(null))
      dispatch(setNearTransportStops(null))
      dispatch(setNearServices([]))
    }
  }, [dispatch])

  useEffect(() => {
    if (!availableDates?.some(d => moment(d).isSame(form.getFieldValue('date'), 'day'))) {
      form.setFieldsValue({date: undefined})
    }
  }, [availableDates, form])

  const getDataOnSearch = async() => {
    setActiveFormStep('nextLocation')
  }

  const checkIsDateAvailable = (current: Moment):boolean => {
    return current < moment().startOf('day') || !availableDates?.includes(current.format('YYYY-MM-DD'))
  }

  return (
    <SearchPageWithBgImage
      title='Cruise'
      description={activeFormStep === 'mainData'
        ? 'Find your cruise trip'
        : cruises && cruises?.length === 1 && !!cruise?.cruise_routes?.[0]?.name 
          ? <span>Your cruise starts from <span style={{color: 'var(--app-primary-color)', fontWeight: 600}}>{cruise?.cruise_routes?.[0]?.name}</span></span>
          : <span></span>
      }
      getDataOnSearch={getDataOnSearch}
      bgType='cruise'
      form={form}
      showDateForm={activeFormStep === 'mainData'}
      checkIsDateAvailable={(current) => checkIsDateAvailable(current)}
      datePickerPlaceholder={isLoading 
        ? 'Loading options'
        : availableDates === null
          ? 'Select cruise line first'
          : 'No available dates found'
      }
      datePickerDisabled={!availableDates?.length || isLoading}
      closestAvailableDate={availableDates?.[0] ? moment(availableDates?.[0]) : undefined}
    >
      <Helmet>
        <link rel='canonical' href={window.location.href} />
        <meta name='description' content='Search cruise by cruise line and cruise name'/>
        <title>Relavanti | Cruise search</title>
      </Helmet>
      {activeFormStep === 'mainData' ? (
        <SearchByCruiseLine
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          form={form}
        />
      ) : (
        <NextLocationForm
          formData={form.getFieldsValue()}
          getResultsLink={getCruiseSearchUrl}
          travelType='cruise'
        />
      )}
    </SearchPageWithBgImage>
  )
}

const SearchByCruiseLine: React.FC<CruiseFormPropTypes> = ({
  form,
  setIsLoading,
  isLoading,
}) => {
  const dispatch = useAppDispatch()

  const [lineId, setLineId] = useState(0)
  const [isCruiseOptionsLoading, setIsCruiseOptionsLoading] = useState(false)

  const getAvailableDates = (lineId: number, cruiseId?: number[]) => {
    const formData = form.getFieldsValue(true)
    if (!cruiseId && lineId) {
      dispatch(GetCruiseAvailableDatesThunk({cruise_line: formData?.cruiseLine?.value}))
    } else if (cruiseId && lineId) {
      dispatch(GetCruiseAvailableDatesThunk({cruise_line: formData?.cruiseLine?.value, cruise_id_list: cruiseId}))
    }
  }

  return (
    <>
      <CruiseLineField 
        setLineId={setLineId}
        form={form}
        getAvailableDates={getAvailableDates}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsCruiseOptionsLoading={setIsCruiseOptionsLoading}
      />
      <CruiseField
        lineId={lineId}
        form={form}
        getAvailableDates={getAvailableDates}
        isLoading={isLoading}
        isCruiseOptionsLoading={isCruiseOptionsLoading}
        allowClear
      />
    </>
  )
}

export const CruiseLineField = ({
  setLineId,
  form,
  getAvailableDates,
  isLoading,
  setIsLoading,
  setIsCruiseOptionsLoading
}:any) => {
  const dispatch = useAppDispatch()
  const [lineName, setLineName] = useState('')
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const cruiseLineOptions = useAppSelector(selectCruiseLineOptions)

  const [isSelectOpen, setIsSelectOpen] = useState(false)

  useEffect(() => {
    if (!!lineName.length) {
      dispatch(setCruiseLineOptions(null))
      setIsLoading(true)
      dispatch(GetCruiseLineOptionsThunk({name: lineName, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
  // eslint-disable-next-line
  }, [dispatch, lineName])

  const selectCruiseLine = (lineId: number) => {
    setLineId(lineId)
    form.setFieldsValue({cruiseName: undefined})
    getAvailableDates(lineId)
    dispatch(setCruiseOptions(null))
    if (!!setIsCruiseOptionsLoading) {
      setIsCruiseOptionsLoading(true)
      dispatch(GetCruisesByLineIdThunk({lineId: lineId, data: {with_cruise_routes: false}, source}))
        .then(() => setIsCruiseOptionsLoading(false))
    }
  }

    // eslint-disable-next-line
    const onSearch = useCallback(
      debounce((val) => {
        setLineName(val)
      }, 400), []
    )

  return (
    <div id='cruise-line-select' style={{width: '100%'}}>
      <Form.Item
        name='cruiseLine'
        style={{width: '100%'}}
        rules={[{required: true, message: 'Please select cruise line!'}]}
      >
        <Select
          placeholder='Enter the cruise line'
          showSearch
          onSelect={(lineData: any) => selectCruiseLine(lineData.value)}
          onSearch={onSearch}
          style={{width: '100%'}}
          filterOption={false}
          getPopupContainer={() => document.getElementById('cruise-line-select')!}
          labelInValue
          open={isSelectOpen}
          autoClearSearchValue={false}
          onDropdownVisibleChange={(isOpen => setIsSelectOpen(isLoading || isOpen))}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!lineName.length && !cruiseLineOptions?.length && 'No results found'}
              {!lineName.length && !cruiseLineOptions?.length && 'Start typing line name'}
            </>
          )}
        >
          {cruiseLineOptions?.map((line:any) => (
            <Select.Option value={line.id} key={line.id}>
              {line.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export const CruiseField = ({
  form,
  getAvailableDates,
  isLoading,
  lineId,
  isCruiseOptionsLoading,
  placeholder,
  allowClear,
  onSelect,
  disabled,
  dynamicCruiseSearch
}:any) => {
  const dispatch = useAppDispatch()
  const cruiseOptions = useAppSelector(selectCruiseOptions)

  const [cruiseName, setCruiseName] = useState('')
  const [isDynamicSearchLoading, setIsDynamicSearchLoading] = useState(false)

  useEffect(() => {
    if (!!dynamicCruiseSearch) {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      if (cruiseName?.length) {
        setIsDynamicSearchLoading(true)
        dispatch(GetCruisesByLineIdThunk({
          lineId,
          data: {cruise_name: cruiseName, with_cruise_routes: false},
          source
        })).then(() => setIsDynamicSearchLoading(false))
      } else {
        dispatch(setCruiseOptions(null))
      }
      return() => {source.cancel()}
    }
    // eslint-disable-next-line
  }, [dispatch, cruiseName])

  // eslint-disable-next-line
  const onCruiseSearch = useCallback(
    debounce((name: string) => {
      setCruiseName(name)
    }, 400), []
  )

  const getOnlyUniqueCruiseName:(opt: CruiseType[]) => CruiseType[] = (cruiseNameOptions: CruiseType[]) => {
    const uniqNames = uniqWith(
      cruiseNameOptions,
      (optA, optB) => optA.name.trim() === optB.name.trim()
        && optA?.display_info === optB?.display_info
    )
    if (cruiseName?.length) {
      return uniqNames.filter(n => n.name.toLowerCase().includes(cruiseName.toLowerCase()))
    } else {
      return uniqNames
    }
  }

  const selectCruise = (val: any) => {
    const name = val?.label[0] || ''
    const display_info = val?.label[1]?.props?.children || ''
    const id = val?.value
    form.setFieldsValue({cruiseName: val})
    const duplicatedCruiseId = cruiseOptions
      ?.filter(c => c.name === name && c.display_info === display_info && c.id !== +id)
      ?.map(c => c.id) || []
    getAvailableDates(lineId, [+id, ...duplicatedCruiseId])
  }

  const deselectCruise = () => {
    form.setFieldsValue({cruiseName: undefined})
    getAvailableDates(lineId)
  }

  return (
    <div id='cruise-select' style={{width: '100%'}}>
      <Form.Item
        name='cruiseName'
        style={{width: '100%'}}
      >
        <Select
          placeholder={isCruiseOptionsLoading || isDynamicSearchLoading
            ? 'Loading options'
            : placeholder ? placeholder : !lineId
              ? 'Select cruise line first'
              : 'Enter the cruise name'
          }
          showSearch
          allowClear={allowClear}
          onClear={allowClear ? () => form.setFieldsValue({cruiseName: ''}) : undefined}
          onSearch={(val) => dynamicCruiseSearch ? onCruiseSearch(val) : setCruiseName(val)}
          onSelect={(val:any) => {
            selectCruise(val)
            !!onSelect && onSelect(val)
          }}
          onDeselect={deselectCruise}
          style={{width: '100%'}}
          filterOption={false}
          labelInValue
          getPopupContainer={() => document.getElementById('cruise-select')!}
          autoClearSearchValue={false}
          disabled={disabled !== undefined ? disabled : !lineId || isLoading || isCruiseOptionsLoading}
          notFoundContent={isCruiseOptionsLoading || isDynamicSearchLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {cruiseOptions === null ? 'Enter the cruise name' : 'No results found'}
            </>
          )}
        >
          {getOnlyUniqueCruiseName(sortBy(cruiseOptions, cruise => cruise.name))?.map((cruise:any) => (
            <Select.Option className={classes.cruiseNameOption} value={cruise.id} key={cruise.id}>
              {cruise.name.trim()} 
              <span style={{color: 'gray', fontSize: '14px', marginLeft: '7px'}}>
                {!!cruise?.display_info?.length && cruise?.display_info}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

interface CruiseFormPropTypes {
  form: FormInstance
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
}

export default ByCruise
