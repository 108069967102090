import { Avatar, Button, Form, Input, InputNumber, message, Upload } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import classes from './Account.module.css'
import {ReactComponent as UploadIcon} from './../../../img/icons/upload.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/delete.svg'
import { EditUserDataThunk, selectUserData } from '../../../store/userReducer'
import { SignUpDataType, UserDataType } from '../../../types/userTypes'
import { beforeUpload, dummyRequest, getImageUrl } from '../../../helpers/files_helper'

const Account = () => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)
  const [avatar, setAvatar] = useState('')
  const [deleteAvatar, setIsDeleteAvatar] = useState(false)
  const [preview, setPreview] = useState<string>('')

  const first_name = Form.useWatch('first_name', form)
  const last_name = Form.useWatch('last_name', form)
  const phone_number = Form.useWatch('phone_number', form)
  const email = Form.useWatch('email', form)

  useEffect(() => {
    !!userData.photo_url?.length && setPreview(userData.photo_url)
  }, [userData])

  const resetForm = () => {
    setPreview(userData.photo_url || '')
    form.resetFields()
    setAvatar('')
    setIsDeleteAvatar(false)
  }

  const onFinish = (formData: SignUpDataType) => {
    setIsLoading(true)

    const userUpdatedData = new FormData()
    if (!!avatar && typeof avatar !== 'string') {
      userUpdatedData.append('photo', avatar)
    }
    userUpdatedData.append('user_settings', new Blob([JSON.stringify({delete_photo: deleteAvatar}, null, 2)], {type: 'application/json'}))
    userUpdatedData.append('user_request', new Blob([JSON.stringify({...formData, is_active: true}, null, 2)], {type: 'application/json'}))

    dispatch(EditUserDataThunk({
      userId: userData.user_id,
      userData: userUpdatedData
    }))
      .then((resp) => {
        setIsLoading(false)
        if (!resp.type.includes('rejected')) {
          message.success('Account data has been changed successfully')
          setPreview((resp.payload as UserDataType)?.photo_url || '')
        }
      })
  }

  return (
    <Form
      name='account'
      onFinish={onFinish}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
      form={form}
      initialValues={{...userData, photo: null}}
    >
      <div className={classes.doubleField}>
        <div>
          <div className={classes.label}>
            First Name
          </div>
          <Form.Item name='first_name'>
            <Input placeholder='Enter your first name' />
          </Form.Item>
        </div>

        <div>
          <div className={classes.label}>
            Last Name
          </div>
          <Form.Item name='last_name'>
            <Input placeholder='Enter your last name' />
          </Form.Item>
        </div>
      </div>

      <div className={classes.label}>
        Phone Number
      </div>
      <Form.Item name='phone_number'>
        <InputNumber
          placeholder='Enter your phone number'
          controls={false}
          style={{width: '100%'}}
        />
      </Form.Item>

      <div className={classes.label}>
        Email Address
      </div>
      <Form.Item name='email' rules={[{ type: 'email', message: 'Wrong email format!' }]}>
        <Input placeholder='Enter your email address' />
      </Form.Item>

      <AvatarUploader
        setAvatar={setAvatar}
        setIsDeleteAvatar={setIsDeleteAvatar}
        setPreview={setPreview}
        preview={preview}
      />

      {/* <div>
        <Form.Item className={classes.subscriptionCheckbox}>
          <Checkbox>
            <span style={{color: '#0068FF'}}>
              I agree to be added to the mailing list
            </span>
          </Checkbox>
        </Form.Item>
      </div> */}

      <div className={classes.btnAreaWrapper}>
        <Button
          disabled={
            first_name === userData?.first_name
            && last_name === userData?.last_name
            && email === userData?.email
            && phone_number === userData?.phone_number
            && ((!userData.photo_url && !preview) || (userData.photo_url === preview))
            && !deleteAvatar
          }
          onClick={() => resetForm()}
        >
          Cancel
        </Button>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Save changes
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

const AvatarUploader: React.FC<AvatarUploaderPropTypes> = ({setAvatar, setIsDeleteAvatar, setPreview, preview}) => {
  const userData = useAppSelector(selectUserData)

  const normFile = (e: any) => {
    addAvatar(e.file)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const addAvatar = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    setPreview(imageUrl)
    setIsDeleteAvatar(false)
    setAvatar(file.originFileObj)
  }

  const deleteAvatar = () => {
    setAvatar(null)
    setPreview('')
    setIsDeleteAvatar(true)
  }

  return (
    <div className={classes.avatarArea}>
      <div className={classes.avatarWrapper}>
        <Avatar className={`${classes.userAvatar} ${preview?.length ? '' : classes.defaultAvatar}`} size={110} src={preview}>
          {userData?.first_name?.[0]?.toUpperCase()}
        </Avatar>
        {!!preview?.length && <DeleteIcon onClick={deleteAvatar}/>}
      </div>
      <Upload.Dragger
        customRequest={dummyRequest}
        beforeUpload={(file) => beforeUpload(file)}
        accept={'image/png, image/jpeg, image/jpg'}
        maxCount={1}
        onChange={normFile}
        showUploadList={false}
        name='file'
        multiple={false}
        >
        <UploadIcon />
        <p className={classes.uploadText}>
          Click to upload or drag and drop
        </p>
        <p className={classes.uploadDescription}>
          PNG or JPG
        </p>
      </Upload.Dragger>
    </div>
  )
}

interface AvatarUploaderPropTypes {
  setAvatar: Dispatch<SetStateAction<any>>
  setIsDeleteAvatar: Dispatch<SetStateAction<boolean>>
  setPreview: Dispatch<SetStateAction<string>>
  preview: string
}

export default Account
