import { useState } from 'react'
import { omit } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import ExternalLinksBlock from '../../SearchResults/ServiceResults/ExternalLinksBlock/ExternalLinksBlock'
import classes from './TransportStopDetails.module.css'
import { ReactComponent as GoBackIcon } from './../../../img/icons/goBack.svg'
import { selectActiveServices, selectSelectedTransportStops, setSelectedTransportStop, setSelectedTransportStops } from '../../../store/searchResultsReducer'
import ServiceResults from '../../SearchResults/ServiceResults/ServiceResults'
import { NodeServiceResultType } from '../../../types/serviceTypes'
import ServiceOptions from '../../SearchResults/ServiceResults/ServiceOptions/ServiceOptions'
import PublicTransportStops from '../PublicTransportStops/PublicTransportStops'
import { setIsTransportStopsListOpen, setNearTransportStops } from '../../../store/transportStopsReducer'
import { ActivitiesBtn } from '../../SearchResults/Activities/Activities'
import ServicesAvailableString from '../ServicesAvailableString/ServicesAvailableString'

export const TransportStopDetails = () => {
    const dispatch = useAppDispatch()
    const selectedTransportStops = useAppSelector(selectSelectedTransportStops)
    const activeServices = useAppSelector(selectActiveServices)
    const currentTransportStop = selectedTransportStops[selectedTransportStops.length - 1]
  
    const [isServiceDataLoading, setIsServiceDataLoading] = useState(true)

    return (
      <>
        <ServiceOptions />
        <div className={classes.blockWrapper}>
          <div
            onClick={() => {
              const updatedSelectedTransportStops = selectedTransportStops.slice(0, -1)
              dispatch(setSelectedTransportStops(updatedSelectedTransportStops))
              dispatch(setSelectedTransportStop(omit(updatedSelectedTransportStops[updatedSelectedTransportStops.length - 1] || null, ['type']) as NodeServiceResultType))
              dispatch(setIsTransportStopsListOpen(false))
              dispatch(setNearTransportStops(null))
            }}
            className={classes.goBackBtn}
          >
            <GoBackIcon />{currentTransportStop?.name}
          </div>
          <ServicesAvailableString name={currentTransportStop?.name || 'selected stop'} />
        {!!currentTransportStop &&
            <ExternalLinksBlock
                nodeData={{
                    id: currentTransportStop.id,
                    name: currentTransportStop.name,
                    type: 'Transport Stop',
                    location: currentTransportStop?.location!
                }}
            />
        }
        </div>
        <ActivitiesBtn />
        <PublicTransportStops />
        <div className={`${classes.resultList} ${!!activeServices?.length ? '' : classes.emptyResultList}`}>
          <ServiceResults
            isServiceDataLoading={isServiceDataLoading}
            setIsServiceDataLoading={setIsServiceDataLoading}
          />
        </div>
      </>
    )
  }
