import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { busAPI } from '../app/api'
import { NearBusStationType } from '../types/busTypes'
import { ResultTabTypes } from '../types/searchResultTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { GetUserIPThunk } from './userReducer'
import { CancelTokenSource } from 'axios'
import { getRequestHeaders } from '../helpers/axiosHelper'

interface InitialStateType {
  nearBuses: NearBusStationType[]
}

const initialState: InitialStateType = {
  nearBuses: [],
}

export const busSlice = createSlice({
  name: 'bus',
  initialState,
  reducers: {
    setNearBuses: (state, action: PayloadAction<NearBusStationType[]>) => {state.nearBuses = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetBusesByCityThunk.fulfilled, (state, action) => {
        state.nearBuses = action.payload
      })
  }
})

export const { setNearBuses } = busSlice.actions

export const selectNearBuses = (state: RootState): NearBusStationType[] => state.buses.nearBuses

export const GetBusesByCityThunk = createAsyncThunk<NearBusStationType[], {activeTabData: ResultTabTypes, source?: CancelTokenSource}, AsyncThunkConfig>(
  'buses/getBusesByCity',
  async ({activeTabData, source}, thunkAPI) => {
    try {
      const requestHeaders = await getRequestHeaders(() => thunkAPI.getState(), async() => thunkAPI.dispatch(GetUserIPThunk()))
      const {data, status} = await busAPI.getBusesByCity({
        ...activeTabData.activeFilters.buses,
        country_code: activeTabData.country_code,
        start_location: {
          latitude: activeTabData.coordinates[0],
          longitude: activeTabData.coordinates[1]
        }
      }, requestHeaders, source)
      if (status === 200 && data) {
        const stationsAsNearBuses = data.bus_stations.map(station => ({
          details: station,
          distance: 0,
          unit: 'KM',
          advertising_list: station?.advertising_list || []
        }))
        return thunkAPI.fulfillWithValue(stationsAsNearBuses, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default busSlice.reducer
 