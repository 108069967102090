import { useNavigate } from 'react-router-dom'
import classes from './BackBtn.module.css'
import {ReactComponent as LeftArrow} from './../../../img/icons/leftArrow.svg'
import {ReactComponent as Close} from './../../../img/icons/close.svg'

const BackBtn: React.FC<BackBtnProps> = ({type, className, onClick, text}) => {
  const navigate = useNavigate()

  const goBaCk = () => {
    !!onClick ? onClick() : navigate(-1)
  }

  return (
    <div className={`${classes.wrapper} ${className}`} onClick={goBaCk}>
      {type === 'button' ? (
        <>
          <LeftArrow /> {!!text?.length ? <span>{text}</span> : <span>Back</span>}
        </>
      ) : (
        <Close/>
      )
      }
    </div>
  )
}

interface BackBtnProps {
  className?: string
  type: 'button' | 'closeIcon'
  onClick?: () => void
  text?: string
}

export default BackBtn
