import { Button, Input, message, Modal } from 'antd'
import { useEffect, useState } from 'react'
import classes from './UserSearchListModal.module.css'
import {ReactComponent as CreateImg} from './../../../../img/createImg.svg'
import {ReactComponent as EditImg} from './../../../../img/editImg.svg'
import {ReactComponent as DeleteImg} from './../../../../img/deleteImg.svg'

const UserSearchListModal: React.FC<UserSearchListModalPropTypes> = ({isOpen, onClose, formType, currentName, getSubmitAction, modalName}) => {
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  
  useEffect(() => {
    if (formType === 'edit' && currentName && !name) {
      setName(currentName)
    }
  }, [currentName, formType, name])
  
  const handleSubmit = () => {
    setIsLoading(true)
    getSubmitAction(name)
      .then((resp) => {
        setIsLoading(false)
        if (!resp.type.includes('rejected')) {
          message.success(formType === 'delete' 
            ? `${modalName} has been deleted`
            : (formType === 'create' ? `${modalName} has been created` : `${modalName} has been changed`)
          )
          onClose()
        }
      }) 
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
      footer={null}
      width='340px'
      zIndex={1000}
      closable={false}
    >
      <div className={classes.wrapper} onClick={e => {e.stopPropagation(); e.preventDefault()}}>
        {formType === 'create' && <CreateImg />}
        {formType === 'edit' && <EditImg />}
        {formType === 'delete' && <DeleteImg />}

        <div className={classes.modalTitle}>
          {formType === 'create' && `Create new ${modalName.toLowerCase()}`}
          {formType === 'edit' && `Edit ${modalName.toLowerCase()}`}
          {formType === 'delete' && `Delete ${modalName.toLowerCase()}`}
        </div>
        <div className={classes.description}>
          {formType === 'create' && `Please enter a name for this ${modalName.toLowerCase()}`}
          {formType === 'edit' && `Change the name for this ${modalName.toLowerCase()}`}
          {formType === 'delete' && `Are you sure you want to delete this ${modalName.toLowerCase()}? All saved locations in this list will be deleted.`}
        </div>
        {formType !== 'delete' && 
          <div style={{width: '100%'}} onClick={e => {e.stopPropagation(); e.preventDefault()}}>
            <div className={classes.inputLabel}>
              List name
            </div>
            <Input
              placeholder={`Enter ${modalName.toLowerCase()} name`}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        }  
        <div className={classes.btnArea}>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button type='primary' onClick={handleSubmit} loading={isLoading}>
            {formType === 'delete' ? 'Delete' : 'Save'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

interface UserSearchListModalPropTypes {
  isOpen: boolean
  onClose: () => void
  formType: 'create' | 'edit' | 'delete'
  currentName?: string
  getSubmitAction: (name?: string) => Promise<any>
  modalName: string
}

export default UserSearchListModal
