import GoogleMapReact from 'google-map-react'
import { useEffect, useRef, useState } from 'react'
import { AirportSearchResultType } from '../../../../types/airportTypes'
import { PortSearchResultType } from '../../../../types/portTypes'
import { getCenterIcon, getMapMarker, getMapZoom, showTransportStopMarker } from '../../../../helpers/mapMarkerHelper'
import { useAppSelector, usePrevious } from '../../../../app/hooks'
import { selectActiveTab, selectAirports, selectPorts, selectSelectedTransportStop } from '../../../../store/widgetReducer'
import { selectDistanceType } from '../../../../store/appStatusReducer'
import logo from './../../../../img/logo.png'
import classes from './Map.module.css'

const Map: React.FC<{center: [number, number]}> = ({center}) => {
  const airports = useAppSelector(selectAirports)
  const ports = useAppSelector(selectPorts)
  const selectedTransportStop = useAppSelector(selectSelectedTransportStop)
  const activeTab = useAppSelector(selectActiveTab)
  const distanceType = useAppSelector(selectDistanceType)

  const prevActiveTab = usePrevious(activeTab)
  const mapCenter = center || [0, 0]
  const ref = useRef(null)
  const location = {
    lat: mapCenter[0],
    lng: mapCenter[1],
  }

  const [points, setPoints] = useState<Array<AirportSearchResultType| PortSearchResultType>>([])
  const [mapSettings, setMapSettings] = useState<{center: LocationType, zoom: number}>(
      {
        center: location,
        zoom: JSON.stringify(center) === '[0, 0]' ? 1 : getMapZoom(location, points.length)
      }
  )
  const [map, setMap] = useState()
  const [maps, setMaps] = useState<any>()
  const [markers, setMarkers] = useState<any[]>([])
  const [transportStopMarker, setTransportStopMarker] = useState<any>()
  const [searchLocationMarker, setSearchLocationMarker] = useState<any>()

  const handleChangeMap = (a: {center: LocationType, zoom: number}) => {
    setMapSettings({center: a.center, zoom: a.zoom})
  }

  const handleApiLoaded = (map: any, maps:any) => {
    setMap(map)
    setMaps(maps)
  }

  // adding center or start point
  useEffect(() => {
    const hasNoCenterYet = !searchLocationMarker
    if (hasNoCenterYet) {
      const marker = getCenterIcon(maps, map, mapCenter[0], mapCenter[1], '', 0)
      setMapSettings({...mapSettings, center: {lat: mapCenter[0], lng: mapCenter[1]}})
      ref.current = marker
      if (marker !== null) {
        setSearchLocationMarker(marker)
      }
    }
    // eslint-disable-next-line
  }, [mapSettings, map, maps, searchLocationMarker, center])

  // adding node markers
  useEffect(() => {
    if (markers?.length && prevActiveTab !== activeTab) {
      markers.forEach((marker:any) => marker?.setMap && marker?.setMap(null))
    }
    if (!!Object.keys(maps || {})?.length && !markers.length && ((activeTab === 'airports' && !!airports) || (activeTab === 'ports' && !!ports))) {
      const nodeData = activeTab === 'airports' ? airports : ports
      const points = nodeData!.map(a => ({...a, type: activeTab === 'airports' ? 'airport' : 'port'}))
      // @ts-ignore  
      setPoints(points)
      setTimeout(() => {
        const newMarkers = points?.map((point: any, index: any) => {
          const {marker, infoWindow} = getMapMarker(map, maps, {lat: point.details.latitude, lng: point.details.longitude}, point, index + 1, point.details.id, distanceType)
          ref.current = marker
          return {marker, infoWindow}
        })
        setMarkers(newMarkers.map(m => m?.marker))
      }, 100)
    }
  }, [map, maps, markers, airports, ports, activeTab, prevActiveTab, distanceType])

  // adding transport stop marker
  useEffect(() => {
    const hasSelectedStopChanged = !!transportStopMarker && !!selectedTransportStop?.id && selectedTransportStop?.id !== transportStopMarker.id
    const isStopDeselected = !selectedTransportStop?.latitude && !!transportStopMarker
    const isStopSelected = !!selectedTransportStop?.latitude && !!maps && !transportStopMarker
    if (isStopDeselected || hasSelectedStopChanged) {
      !!transportStopMarker?.setMap && transportStopMarker.setMap(null)
      setTransportStopMarker(null)
      setMapSettings({
        ...mapSettings,
         zoom: 11
       })
    }
    if (isStopSelected || hasSelectedStopChanged) {
      const marker = showTransportStopMarker(map, maps, {id: selectedTransportStop?.id!, location: {latitude: selectedTransportStop.latitude, longitude: selectedTransportStop.longitude}, name: selectedTransportStop?.name!})
      ref.current = marker
      setTransportStopMarker(marker)
      setMapSettings({
        center: {
          lat: selectedTransportStop?.latitude!,
          lng: selectedTransportStop?.longitude!
        },
        zoom: 15
      })
    }
    // eslint-disable-next-line
  }, [selectedTransportStop, map, maps, transportStopMarker])

  return (
    <div className={classes.map}>
      <GoogleMapReact
        center={mapSettings.center}
        zoom={mapSettings.zoom}
        onChange={handleChangeMap}
        options={{
          mapId: '660eef12ca696db8',
         mapTypeControl: false,
         fullscreenControl: false,
         zoomControl: true,
         zoomControlOptions: {
           position: google.maps.ControlPosition.TOP_RIGHT,
         },
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        ref={ref}
      />
      <a
        href='https://www.relavanti.com/'
        target='_blank'
        rel='noreferrer'
        className={classes.poweredBy}
      >
        Powered by <img src={logo} alt='Relavanti logo' />
      </a>
    </div>
  )
}

interface LocationType {
  lat: number
  lng: number
}

export default Map
