import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector, usePrevious } from '../../../../app/hooks'
import { selectActiveResultCategories, selectActiveServices, selectActiveTab, selectNodeServiceDetails, setActiveServices } from '../../../../store/searchResultsReducer'
import { selectServiceCategoryOptions } from '../../../../store/serviceReducer'
import { setNearTransportStops, setNodeTransportStops } from '../../../../store/transportStopsReducer'
import classes from './ServiceOptions.module.css'

const ServiceOptions: React.FC<ServiceOptionsPropTypes> = ({className}) => {
  const dispatch = useAppDispatch()
  const activeServices = useAppSelector(selectActiveServices)
  const serviceCategoryOptions = useAppSelector(selectServiceCategoryOptions)
  const activeTab = useAppSelector(selectActiveTab)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const activeResultCategories = useAppSelector(selectActiveResultCategories)
  const prevActiveResultCategories = usePrevious(activeResultCategories)

  const [includesDefaultCategories, setIncludesDefaultCategories] = useState(false)

  useEffect(() => {
    dispatch(setNearTransportStops(null))
    dispatch(setNodeTransportStops(null))
  }, [dispatch])

  useEffect(() => {
    if (!includesDefaultCategories && !activeServices.length && serviceCategoryOptions.some(opt => opt !== 'Public Transportation Stops' && opt !== 'Terminals')) {
      dispatch(setActiveServices(serviceCategoryOptions.filter(opt => opt !== 'Public Transportation Stops')))
      setIncludesDefaultCategories(true)
    }
    // eslint-disable-next-line
  }, [dispatch, serviceCategoryOptions, includesDefaultCategories, nodeServiceDetails, activeTab, activeResultCategories])
  
  useEffect(() => {
    if (prevActiveResultCategories !== activeResultCategories) {
      setIncludesDefaultCategories(false)
    }
  }, [activeResultCategories, prevActiveResultCategories])

  const selectService = (service: string) => {
    if (activeServices.includes(service)) {
      dispatch(setActiveServices(activeServices.filter(s => s !== service)))
    } else {
      dispatch(setActiveServices([...activeServices, service]))
    }
  }
  
  return (
    <div className={`${classes.services} ${className}`}>
      {serviceCategoryOptions?.map(category => (
        <div
          key={category}
          onClick={() => selectService(category)}
          className={`${classes.service} ${activeServices.includes(category) && classes.selected}`}
        >
          {category}
        </div>
      ))}
    </div>
  )
}

interface ServiceOptionsPropTypes {
  className?: string
}

export default ServiceOptions
