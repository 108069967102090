import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetFlightByNumberAndDateThunk, selectActiveInfoBlock, selectFlight, selectFlights, setFlight, setFlights, setSelectedStops } from '../../../../store/flightReducer'
import { getFlightDataFromSearchString } from '../../../../helpers/linksHelper'
import SearchResultPage from '../../common/SearchResultPage/SearchResultPage'
import NoResults from '../../common/NoResults/NoResults'
import FlightDetails from './FlightDetails/FlightDetails'
import ResultList from '../../common/ResultList/ResultList'
import FlightItem from './FlightItem/FlightItem'
import { useLocation } from 'react-router-dom'
import { FlightDetailsType } from '../../../../types/flightTypes'
import { useEffect } from 'react'
import { setActiveResultCategories, setNodeServiceDetails } from '../../../../store/searchResultsReducer'
import { setNearTransportStops } from '../../../../store/transportStopsReducer'
import { setNearServices } from '../../../../store/serviceReducer'
import { setActiveResultsTabKey, setSelectedResultItemId } from '../../../../store/travelSearchReducer'
import { AirportDataType } from '../../../../types/airportTypes'
import { SharedFlightCruiseDetailsType } from '../../../../types/appTypes'
import { Helmet } from 'react-helmet-async'

const SearchResults = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const searchData = getFlightDataFromSearchString(search)
  const flights = useAppSelector(selectFlights)
  const flight = useAppSelector(selectFlight)
  const activeInfoBlock = useAppSelector(selectActiveInfoBlock)

  const goBackLink = '/travel/by-flight'

  const departureLocation = {
    id: flight?.departure_airport?.id || 0,
    info: getMapIconHoverInfo(flight?.departure_airport!),
    lat: flight?.departure_airport?.latitude!,
    lng: flight?.departure_airport?.longitude!
  }
  const arrivalLocation = {
    id: flight?.arrival_airport?.id || 0,
    info: getMapIconHoverInfo(flight?.arrival_airport!),
    lat: flight?.arrival_airport?.latitude!,
    lng: flight?.arrival_airport?.longitude!
  }
  const stops = flight?.airport_stops?.map(s => ({
    id: s.id,
    info: getMapIconHoverInfo(s),
    lat: s.latitude,
    lng: s.longitude,
    stopData: s
  }))

  useEffect(() => {
    return () => {
      dispatch(setFlight(null))
      dispatch(setSelectedResultItemId(''))
      dispatch(setFlights(null))
      dispatch(setActiveResultCategories(['near']))
      dispatch(setNodeServiceDetails(null))
      dispatch(setNearTransportStops(null))
      dispatch(setNearServices([]))
    }
  }, [dispatch])

  const getData = (data: {number: string, date: string}, sharedDetails?: SharedFlightCruiseDetailsType) => {
    return dispatch(GetFlightByNumberAndDateThunk({flightInfo: {...data, number: data.number.toUpperCase()}, sharedDetails}))
  }

  const selectFlightItem = (flight: FlightDetailsType) => {
    dispatch(setFlight(flight))
    dispatch(setSelectedResultItemId(flight.id))
  }

  const handleStopMarkerClick = (id: number) => {
    dispatch(setSelectedStops(id))
    dispatch(setActiveResultsTabKey(String(id)!))
  }

  return (
    <SearchResultPage
      dataList={flights}
      dataItem={flight}
      getData={getData}
      getSearchDataFromUrl={getFlightDataFromSearchString}
      isDetailsPageOpen={activeInfoBlock === 'flightDetails'}
      itemMapCenter={{lat: flight?.departure_airport?.latitude || 0, lng: flight?.departure_airport?.longitude || 0}}
      departureLocation={departureLocation}
      stopLocations={stops || []}
      arrivalLocation={arrivalLocation}
      searchType='byFlight'
      handleStopMarkerClick={handleStopMarkerClick}
      activeInfoBlock={activeInfoBlock}
    >
      <Helmet>
        <link rel='canonical' href={window.location.href} />
        {searchData?.number &&
          <meta name='description' content={'Flight number' + searchData?.number + ', date: ' + searchData?.date} />
        }
        {searchData?.number &&
          <title>Relavanti | {'Flight number ' + searchData?.number + ', date: ' + searchData?.date}</title>
        }
      </Helmet>
      {!!flights && !flights.length && <NoResults goBackLink={goBackLink} />}
      {activeInfoBlock === 'flights' && !!flights?.length &&
        <ResultList
          title='List of Flights'
          searchData={{...searchData, number: 'Flight number: ' + searchData.number.toUpperCase()}}
          goBackLink={goBackLink}
          searchType='byFlight'
        >
          {flights.map(flight => (
            <FlightItem
              key={flight.id}
              flight={flight}
              onClick={selectFlightItem}
            />
          ))}
        </ResultList>
      }
      {activeInfoBlock === 'flightDetails' && !!flights?.length  && <FlightDetails />}
    </SearchResultPage>
  )
}

const getMapIconHoverInfo = (stop: AirportDataType) => {
  return (`
    <div style="font-size: 18px; margin-bottom: 10px">
      Airport: ${stop?.name} (${stop?.code})
    </div>
  `)
}

export default SearchResults
