import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { transportStopsApi } from '../app/api'
import { StopDataType, TransportStopFiltersType } from '../types/transportStopsTypes'
import { AppStatusType, DistanceTypeType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './../types/appTypes'
import { pickBy } from 'lodash'

export const getStopsForNodeRadius = (nodeCategory: number, distanceType: DistanceTypeType) => {
  if (nodeCategory === 3) {
    return distanceType === 'km' ? 1000 : 0.62
  } else if (nodeCategory === 2) {
    return distanceType === 'km' ? 500 : 0.31
  } else {
    return distanceType === 'km' ? 100 : 0.06
  }
}

interface InitialStateType {
  nearTransportStops: StopDataType[] | null,
  nodeTransportStops: StopDataType[] | null,
  isTransportStopsLoading: boolean
  isTransportStopsListOpen: boolean
  transportStopFilters: TransportStopFiltersType | null
}

const initialState: InitialStateType = {
  nearTransportStops: null,
  nodeTransportStops: null,
  isTransportStopsLoading: false,
  isTransportStopsListOpen: false,
  transportStopFilters: null,
}

export const transportStopsSlice = createSlice({
  name: 'transportStops',
  initialState,
  reducers: {
    setNearTransportStops: (state, action: PayloadAction<StopDataType[] | null>) => {state.nearTransportStops = action.payload},
    setNodeTransportStops: (state, action: PayloadAction<StopDataType[] | null>) => {state.nodeTransportStops = action.payload},
    setIsTransportStopsLoading: (state, action: PayloadAction<boolean>) => {state.isTransportStopsLoading = action.payload},
    setIsTransportStopsListOpen: (state, action: PayloadAction<boolean>) => {state.isTransportStopsListOpen = action.payload},
    setTransportStopFilters: (state, action: PayloadAction<TransportStopFiltersType | null>) => {state.transportStopFilters = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetNearTransportStopsThunk.fulfilled, (state, action) => {
        state.nearTransportStops = action.payload  
        state.isTransportStopsLoading = false
      })
      .addCase(GetNodeTransportStopsThunk.fulfilled, (state, action) => {
        state.nodeTransportStops = action.payload
        state.isTransportStopsLoading = false
      })
      .addCase(GetNearTransportStopsThunk.rejected, (state) => { 
        state.isTransportStopsLoading = false
      })
      .addCase(GetNodeTransportStopsThunk.rejected, (state) => {
        state.isTransportStopsLoading = false
      })
      .addCase(GetTransportStopsWithDirectRouteThunk.fulfilled, (state, action) => {
        state.isTransportStopsLoading = false
        if (action.payload.type === 'near') {
          state.nearTransportStops = action.payload.data
        } else {
          state.nodeTransportStops = action.payload.data
        }
      })
  }
})

export const {
  setNearTransportStops,
  setNodeTransportStops,
  setIsTransportStopsLoading,
  setIsTransportStopsListOpen,
  setTransportStopFilters,
} = transportStopsSlice.actions

export const selectNearTransportStops = (state: RootState): StopDataType[] | null => state.transportStops.nearTransportStops
export const selectNodeTransportStops = (state: RootState): StopDataType[] | null => state.transportStops.nodeTransportStops
export const selectIsTransportStopsLoading = (state: RootState): boolean => state.transportStops.isTransportStopsLoading
export const selectIsTransportStopsListOpen = (state: RootState): boolean => state.transportStops.isTransportStopsListOpen
export const selectTransportStopFilters = (state: RootState): TransportStopFiltersType | null => state.transportStops.transportStopFilters

export const GetNearTransportStopsThunk = createAsyncThunk<StopDataType[], {latitude: number, longitude: number, radius: 1000 | 500 | 100, isWidget?: boolean}, AsyncThunkConfig>(
  'transportStops/getNearTransportStops',
  async (locationData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsTransportStopsLoading(true))
      const requestData = {
        radius: locationData.radius,
        route_types: [],
        location: {latitude: locationData.latitude, longitude: locationData.longitude}
      }
      const requestHeaders = {
        ...(locationData.isWidget ? {Authorization: false} : {})
      }
      const {data, status} = await transportStopsApi.getNodeTransportStops(requestData, requestHeaders)
      if (status === 200 &&  data) {
        return thunkAPI.fulfillWithValue(
          Object.entries(data.stopsMap)
            .map(([transportType, stops]) => {
              return stops.map(stop => ({...stop, type: `${transportType.split('_list')[0]} stops`}))
            })
            .flat(),
          {appStatus: AppStatusType.idle}
        )
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetNodeTransportStopsThunk = createAsyncThunk<StopDataType[], {location: {latitude: number, longitude: number}, radius: number, isPort: boolean} , AsyncThunkConfig>(
  'transportStops/getNodeTransportStops',
  async (nodeData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsTransportStopsLoading(true))
      const ferriesStopsTypeId = 4
      const {data, status} = await transportStopsApi.getNodeTransportStops({
        radius: nodeData.radius,
        route_types: nodeData.isPort ? [ferriesStopsTypeId] : [],
        location: nodeData.location
      })
      if (status === 200 &&  data) {
        return thunkAPI.fulfillWithValue(
          Object.entries(data.stopsMap)
            .map(([transportType, stops]) => {
              return stops.map(stop => ({...stop, type: `${transportType.split('_list')[0]} stops`}))
            })
            .flat(),
          {appStatus: AppStatusType.idle}
        )
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetTransportStopByKeyThunk = createAsyncThunk<StopDataType, number, AsyncThunkConfig>(
  'transportStops/getTransportStopByKey',
  async (stopKey, thunkAPI) => {
    try {
      const {data, status} = await transportStopsApi.getTransportStopByKey(stopKey)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({...data, type: ''}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetTransportStopsWithDirectRouteThunk = createAsyncThunk<{data: StopDataType[], type: 'node' | 'near'}, {filters: TransportStopFiltersType, type: 'node' | 'near'}, AsyncThunkConfig>(
  'transportStops/getTransportStopsWithDirectRoute',
  async ({filters, type}, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsTransportStopsLoading(true))
      const {data, status} = await transportStopsApi.getTransportStopsWithDirectRoute({
        ...filters,
        arrival_location: pickBy(filters.arrival_location, (_, key) => key !== 'name')
      } as TransportStopFiltersType)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(
          {
            data: Object.entries(data.stopsMap)
              .map(([transportType, stops]) => {
                return stops.map(stop => ({...stop, type: `${transportType.split('_list')[0]} stops`}))
              })
              .flat(),
            type
          },
          {appStatus: AppStatusType.idle}
        )
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default transportStopsSlice.reducer
 