import { Select } from 'antd'
import classes from './../CruiseAdvancedSearch.module.css'
import { useAppSelector } from '../../../../../app/hooks'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { selectCountries } from '../../../../../store/additionalDataReducer'
import { sortBy } from 'lodash'
import { CountryType } from '../../../../../types/additionalDataTypes'

const CountryLocationField: React.FC<{
  setFormValues: Dispatch<SetStateAction<any>>,
  formValues: any,
  label: string,
  field: string
}> = ({setFormValues, formValues, label, field}) => {
  const countries = useAppSelector(selectCountries)

  const [searchString, setSearchString] = useState('')
  const [countryOptions, setCountryOptions] = useState<CountryType[]>(countries || [])

  useEffect(() => {
    if (!!searchString?.length) {
      setCountryOptions(countries?.filter(c => c.name.toLowerCase().includes(searchString.toLowerCase())) || [])
    } else {
      setCountryOptions(countries || [])
    }
  }, [searchString, countries])

  const handleSelect = (id: number) => {
    setFormValues({...formValues, [field]: [...(formValues?.[field] || []), id]})
    setSearchString('')
  }
  
  const handleDeselect = (id: number) => {
    setFormValues({...formValues, [field]: formValues?.[field].filter((c:number) => c !== id)})
  }

  const value =  formValues?.[field]?.map((c:any) => ({value: c, label: countries?.find((count) => count.id === c)?.name})) || []

  return (
    <div className={classes.formItemWrapper}>
      <div className={classes.formItemLabel}>
        <span>{label}</span>
      </div>
      <div id={label}>
        <Select
          showSearch
          style={{width: '100%', marginBottom: '25px'}}
          placeholder='Start typing country name'
          onSearch={searchQuery => setSearchString(searchQuery)}
          onSelect={(val:any) => handleSelect(val.value)}
          onDeselect={(val:any) => handleDeselect(val.value)}
          searchValue={searchString}
          labelInValue
          filterOption={false}
          mode='multiple'
          getPopupContainer={() => document.getElementById(label)!}
          value={value}
          options={sortBy(countryOptions?.map(c => ({value: c.id, label: c.name})), c => c.label.toLowerCase())}
        >  
        </Select>
      </div>
    </div>
  )
}

export default CountryLocationField
