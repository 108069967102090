import { Navigate, useLocation } from 'react-router-dom'
import classes from './Auth.module.css'
import ForgotPasswordFrom from './forms/ForgotPasswordFrom'
import SignInFrom from './forms/SignInFrom'
import SignUpFrom from './forms/SignUpFrom'
import NewPasswordForm from './forms/NewPasswordForm'
import authMainImage from './../../img/auth/authMainImage.webp'
import authMainImageSmallScreen from './../../img/auth/authMainImageSmallScreen.webp'
import Header from '../common/Header/Header'
import { useAppSelector } from '../../app/hooks'
import { selectIsLoggedIn } from '../../store/userReducer'

export enum AuthPage {
  SignIn = 'signIn',
  SignUp = 'signUp',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
  CreatePassword = 'createPassword',
}

const Auth: React.FC<AuthPropTypes> = ({page}) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const {state}: {state: any} = useLocation()

  if (isLoggedIn) {
    return <Navigate to={state?.navigateLink ? state?.navigateLink : '/'} />
  }

  return (
    <div className={classes.wrapper}>
      <div>
        <Header showMenu={false} />
        {page === AuthPage.SignIn && <SignInFrom />}
        {page === AuthPage.SignUp && <SignUpFrom />}
        {page === AuthPage.ForgotPassword && <ForgotPasswordFrom />}
        {page === AuthPage.ResetPassword && <NewPasswordForm page={AuthPage.ResetPassword}/>}
        {page === AuthPage.CreatePassword && <NewPasswordForm page={AuthPage.CreatePassword}/>}
      </div>
      <img src={authMainImageSmallScreen} alt='' className={classes.decorationImgSmallScreen} />
      <img src={authMainImage} alt='' className={classes.decorationImg} />
    </div>
  )
}

interface AuthPropTypes {
  page: AuthPage.SignIn | AuthPage.SignUp | AuthPage.ForgotPassword | AuthPage.ResetPassword | AuthPage.CreatePassword
}

export default Auth
