import { Collapse } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import classes from './PublicTransportStops.module.css'
import { ReactComponent as CollapseArrow } from './../../../img/icons/collapseArrow.svg'
import { ReactComponent as PublicTransportIcon } from './../../../img/icons/publicTransportIcon.svg'
import { ReactComponent as FiltersSmallIcon } from './../../../img/icons/filtersSmall.svg'
import { GetNearTransportStopsThunk, GetNodeTransportStopsThunk, getStopsForNodeRadius, selectIsTransportStopsListOpen, selectIsTransportStopsLoading, selectNearTransportStops, selectNodeTransportStops, setIsTransportStopsListOpen } from '../../../store/transportStopsReducer'
import { selectActiveTab, selectNodeServiceDetails, selectSelectedTerminal, selectSelectedTransportStops, setSelectedTransportStop } from '../../../store/searchResultsReducer'
import { useEffect, useState } from 'react'
import FiltersModal from './FiltersModal/FiltersModal'
import TransportStopList from './TransportStopList/TransportStopList'
import { selectCurrentActiveTabData } from '../../../store/travelSearchReducer'
import { selectDistanceType } from '../../../store/appStatusReducer'

interface PublicTransportStopsPropTypes {
  nodeId?: number
  onTransportStopListOpen?: () => Promise<any>
}

const PublicTransportStops: React.FC<PublicTransportStopsPropTypes> = ({nodeId, onTransportStopListOpen}) => {
  const dispatch = useAppDispatch()
  const isListOpen = useAppSelector(selectIsTransportStopsListOpen)
  const isTransportStopsLoading = useAppSelector(selectIsTransportStopsLoading)
  const nearTransportStops = useAppSelector(selectNearTransportStops)
  const nodeTransportStops = useAppSelector(selectNodeTransportStops)
  const selectedTransportStops = useAppSelector(selectSelectedTransportStops)
  const activeTab = useAppSelector(selectActiveTab)
  const nodeServiceDetails = useAppSelector(selectNodeServiceDetails)
  const selectedTerminal = useAppSelector(selectSelectedTerminal)
  const travelStopsActiveTabData = useAppSelector(selectCurrentActiveTabData)
  const distanceType = useAppSelector(selectDistanceType)

  const relatedToNode = travelStopsActiveTabData !== null || !!nodeId

  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  useEffect(() => {
    return () => {dispatch(setIsTransportStopsListOpen(false))}
  }, [dispatch])

  const defaultOnTransportStopListOpen = async() => {
    if (!!selectedTransportStops.length) {
      const stopData = selectedTransportStops[selectedTransportStops.length - 1]
      return dispatch(GetNearTransportStopsThunk({latitude: stopData.location?.latitude!, longitude:stopData.location?.longitude!, radius: 1000}))
    } else if (selectedTerminal) {
      return dispatch(GetNodeTransportStopsThunk({location: selectedTerminal?.location, radius: getStopsForNodeRadius(0, distanceType), isPort: nodeServiceDetails?.type === 'port'}))
    } else if (nodeServiceDetails?.id && nodeServiceDetails?.location) {
      return dispatch(GetNodeTransportStopsThunk({location: nodeServiceDetails?.location, radius: getStopsForNodeRadius(nodeServiceDetails?.category || 0, distanceType), isPort: nodeServiceDetails.type === 'port'}))
    } else {
      return dispatch(GetNearTransportStopsThunk({latitude: activeTab?.coordinates[0], longitude: activeTab?.coordinates[1], radius: 1000}))
    }
  }

  const onCollapseChange = (e: any) => {
    if (!isListOpen && (
      (!!relatedToNode && nodeTransportStops === null) || (!relatedToNode && nearTransportStops === null)
    )) {
      !!onTransportStopListOpen
        ? onTransportStopListOpen()
        : defaultOnTransportStopListOpen()
    }
    if (!!isListOpen) {
      dispatch(setSelectedTransportStop(null))
    }
    dispatch(setIsTransportStopsListOpen(!isListOpen))
  }

  const handleFiltersClose = () => {
    setIsFiltersOpen(false)
  }

  return (
    <>
      <Collapse
        expandIconPosition='right'
        ghost
        expandIcon={({ isActive }) => (
          <CollapseArrow
            style={{
              rotate: isActive ? '90deg' : '0deg',
              right: isActive ? '15px' : '0px',
              top: isActive? '13px' : '50%',
              transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
            }}
          />
        )}
        activeKey={isListOpen ? ['1'] : []}
        onChange={onCollapseChange}
        className={classes.collapseBtn}
      >
        <Collapse.Panel
          header={
            <div className={classes.collapseBtnContent}>
              <div className={classes.collapseBtnText}>
                <PublicTransportIcon style={{marginRight: '7px'}}/>
                Public transport
              </div>
              {isListOpen && !isTransportStopsLoading &&
                <div
                  className={classes.filtersBtn}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsFiltersOpen(true)
                  }}
                >
                  <FiltersSmallIcon /> Filters
                </div>
              }
            </div>
          }
          style={{width: '100%'}}
          key='1'
        >
          <TransportStopList relatedToNode={relatedToNode} />
        </Collapse.Panel>
      </Collapse>
      <FiltersModal
        isModalOpen={isFiltersOpen}
        handleClose={handleFiltersClose}
        getAllStops={!!onTransportStopListOpen
          ? onTransportStopListOpen
          : defaultOnTransportStopListOpen
        }
        stopsType={(!selectedTransportStops.length && selectedTerminal)
          || (nodeServiceDetails?.id && nodeServiceDetails?.location)
          || !!onTransportStopListOpen
            ? 'node'
            : 'near'
        }
      />
    </>
  )
}

export default PublicTransportStops
