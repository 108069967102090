import { Button, Form, Input } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../../app/hooks'
import { SendPasswordEmailThunk, SignUpThunk } from '../../../store/userReducer'
import classes from './../Auth.module.css'

const SignUpFrom = () => {
  const dispatch = useAppDispatch()
  const [isSent, setIsSent] = useState(false)
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (formData: FormValuesType) => {
    setIsLoading(true)
    const username = formData.email.split('@')[0]
    dispatch(SignUpThunk({...formData, username}))
      .then((resp) => {
        setIsLoading(false)
        if (!resp.type.includes('rejected')) {
          setIsSent(true)
          setEmail(formData.email)
        }
      })
  }

  const sendAgain = () => {
    dispatch(SendPasswordEmailThunk({email, showSuccessMessage: true}))
  }

  if (isSent) {
    return (
      <div className={classes.contentWrapper}>
        <h1 className={classes.formTitle}>
          Confirm your email
        </h1>
        <div className={classes.message}>
          <div>
            We sent a confirmation email to <span style={{color: 'var(--app-highlight-color)'}}>{email}</span>
          </div>
          <div>
            Check your email and click on the confirmation link to continue
          </div>
        </div>
        <div className={classes.additionalLink}>
          Didn’t receive the email? <span style={{color: 'var(--app-highlight-color)', cursor: 'pointer'}} onClick={sendAgain}>Click to send again</span>
        </div>
      </div>
    )
  }

  return (
    <Form
      name='signUp'
      onFinish={onFinish}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
      // initialValues={{subscribe: true}}
    >
      <h1 className={classes.formTitle}>
        Sign up
      </h1>

      <div className={classes.label}>
        First Name
      </div>
      <Form.Item
        name='first_name'
        rules={[{ required: true, message: 'Please enter your first name!' }]}
      >
        <Input placeholder='Enter your full name'/>
      </Form.Item>

      <div className={classes.label}>
        Last Name
      </div>
      <Form.Item
        name='last_name'
        rules={[{ required: true, message: 'Please enter your last name!' }]}
      >
        <Input placeholder='Enter your full name'/>
      </Form.Item>

      <div className={classes.label}>
        Phone Number
      </div>
      <Form.Item
        name='phone_number'
        rules={[{ required: true, message: 'Please enter your phone!' }]}
      >
        <Input placeholder='Enter your phone number'/>
      </Form.Item>

      <div className={classes.label}>
        Email Address
      </div>
      <Form.Item
        name='email'
        rules={[
          { required: true, message: 'Please enter your email!' },
          { type: 'email', message: 'Invalid e-mail!' },
        ]}
      >
        <Input placeholder='Enter your email'/>
      </Form.Item>

      {/* <Form.Item name='subscribe' valuePropName='checked'>
        <Checkbox>
          <span style={{fontSize: '14px', marginLeft: '8px'}}>
            I agree to be added to the mailing list.
          </span>
        </Checkbox>
      </Form.Item> */}

      <Form.Item>
        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
          Sign up
        </Button>
      </Form.Item>

      <div className={classes.additionalLink}>
        Already have an account? <Link to='/sign-in'>Sign in</Link>
      </div>
    </Form>
  )
}

interface FormValuesType {
  first_name: string
  last_name: string
  phone_number: string
  email: string
}

export default SignUpFrom
