import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { useLocation } from 'react-router-dom'
import classes from './Share.module.css'
import { ReactComponent as ShareIcon } from '../../../../../img/icons/share.svg'
import shareImg from '../../../../../img/shareImg.webp'
import { decodeSearchResultsExportLink, getLocationsFromLink } from '../../../../../helpers/linksHelper'
import { sortBy } from 'lodash'
import CopyLinkBlock from '../common/CopyLinkBlock/CopyLinkBlock'
import SelectLocations from '../common/SelectLocations/SelectLocations'
import SelectedLocation from '../common/SelectedLocation/SelectedLocation'

const Share = () => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  
  return (
    <>
      <div className={classes.share} onClick={() => setIsShareModalOpen(true)}>
        <ShareIcon /> Share
      </div>
      {isShareModalOpen &&
        <ShareModal onCancel={() => setIsShareModalOpen(false)}/>
      }
    </>
  )
}

export const ShareBtn = () => {
  return (
    <div className={classes.share}>
      <ShareIcon /> Share
    </div>
  )
}

const ShareModal: React.FC<{onCancel: () => void}> = ({onCancel}) => {
  const { pathname } = useLocation()
  const search = window.location.search

  const linkLocations = getLocationsFromLink(search)
  const options = decodeSearchResultsExportLink(search.split('-shared')[0]).locations
    .map(searchItem => ({
      name: decodeURIComponent(searchItem.split('-from')[0]),
      url: searchItem
    }))

  const [selectedLocations, setSelectedLocations] = useState<LocationOptionType[]>([])
  const [currentsStep, setCurrentStep] = useState<CurrentsStepType>('selectLocations')
  const [availableLocations, setAvailableLocations] = useState<LocationOptionType[]>(options)

  useEffect(() => {
    if ((!selectedLocations.length && availableLocations.length === 1)
      || pathname.includes('by-flight')
      || pathname.includes('by-cruise')
    ) {
      setCurrentStep('copyLink')
      if (!selectedLocations.length && availableLocations.length === 1) {
        setSelectedLocations(availableLocations)
        setAvailableLocations([])
      }
    }
  }, [selectedLocations, availableLocations, pathname])

  const handleLocationSelect = (location: string) => {
    const selectedLocation = options.find(option => option.name === location)!
    const updatedSelectedLocations = sortBy(
      [...selectedLocations, selectedLocation],
      location => options.findIndex(option => option.name === location.name)
    )
    setSelectedLocations(updatedSelectedLocations)
    setAvailableLocations(availableLocations.filter(l => l.name !== location))
  }

  const handleLocationDeselect = (location: LocationOptionType) => {
    setAvailableLocations([...availableLocations, location])
    setSelectedLocations(selectedLocations.filter(l => l.name !== location.name))
  }

  return (
    <Modal
      visible
      onCancel={onCancel}
      footer={null}
      width='340px'
    >
      <div className={classes.modalContent}>
        <img src={shareImg} alt='share'/>
        <h3>
          Share your search
        </h3>
        <div className={classes.description}>
          {currentsStep === 'selectLocations' 
            ? 'Select from the list of locations you want\nto share with your friends'
            : 'Share selected locations with your friends'
          }
        </div>

        {currentsStep === 'selectLocations' && !!availableLocations.length && 
          <SelectLocations
            handleLocationSelect={handleLocationSelect}
            availableLocations={availableLocations}
            showLabel
          />
        }

        <div>
          {selectedLocations.map(location => (
            <SelectedLocation
              location={location}
              handleLocationDeselect={currentsStep === 'selectLocations' ? handleLocationDeselect : undefined}
              key={location.name}
            />
          ))}
        </div>
        {currentsStep === 'selectLocations' &&
          <div className={classes.btnArea}>
            <Button onClick={onCancel}>
              Cancel
            </Button>
            <Button type='primary' onClick={() => {setCurrentStep('copyLink')}} disabled={!selectedLocations.length}>
              Next
            </Button>
          </div>
        }

        {currentsStep === 'copyLink' &&
          <CopyLinkBlock
            goBack={linkLocations?.length > 1 && !pathname.includes('by-flight') && !pathname.includes('by-cruise')
              ? () => setCurrentStep('selectLocations')
              : undefined
            }
            selectedLocations={selectedLocations}
          />
        }
      </div>
    </Modal>
  )
}

type CurrentsStepType = 'selectLocations' | 'copyLink'

type LocationOptionType = {
  name: string //example: "New York, NY, USA"
  url: string //example: "New%20York,%20NY,%20USA-from-20230301-to-20230302-adults-1-children-0"
}

export default Share
